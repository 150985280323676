export function calculatePackagePrice(reservationDetails, allPaxArray, productPriceFullPackage, productPriceBreakDown, productItemsPrices, reservationCategory) {
  let totalPrice = 0;
  if (reservationCategory === "private") {
    let adultPaxCount = 0;
    let childPaxCount = 0;
    productItemsPrices?.forEach(service => {
      if (service?.totalPrice) {
        totalPrice = totalPrice + service?.totalPrice;
      } else {
        let adultPrice = +service?.price_adult ? (+service?.price_adult * adultPaxCount) : 0;
        let childPrice = +service?.price_child ? (+service?.price_child * childPaxCount) : 0;
        totalPrice = totalPrice + (adultPrice + childPrice);
      }
    })
  } else {
    if (productPriceFullPackage) {
      allPaxArray?.adults?.forEach(adult => {
        totalPrice = totalPrice + (adult?.price || 0);
      });
      allPaxArray?.childs?.forEach(child => {
        totalPrice = totalPrice + (child?.price || 0);
      });
    }
    else if (productPriceBreakDown) {
      totalPrice = 0;
      let assignedChildCount = allPaxArray?.childs?.filter(child => child?.combination_id).length;
      let assignedAdultsCount = allPaxArray?.adults?.filter(adult => adult?.combination_id).length;
      allPaxArray?.adults?.forEach(adult => totalPrice = totalPrice + (adult?.price || 0));
      allPaxArray?.childs?.forEach(child => totalPrice = totalPrice + (child?.price || 0));
      reservationDetails?.product?.product_items?.forEach(item => {
        if (item?.item?.id !== 6) {
          let adultPrice = +item?.itemable?.price_adult ? +item?.itemable?.price_adult * assignedAdultsCount : 0;
          let childPrice = +item?.itemable?.price_child && assignedChildCount > 0 ? +item?.itemable?.price_child * assignedChildCount : 0;
          totalPrice = totalPrice + (adultPrice + childPrice);
        }
      })
    }
  }
  return totalPrice;
}