import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import React from "react";
import WathcIconsSrc from "assets/images/umrah-operations/watch-icon.svg";
import {
	ClockTimeIcon,
	HotelTimeIcon,
} from "modules/UmrahOperations/shared/Icons";
import Locale from 'translations';
import moment from 'moment';

export default function CheckoutHotel({ state, handleInputs }) {
	const { operationStatement } = Locale;
	const data = state?.hotel_checkout;
	const today = new Date().toLocaleDateString();

	return (
		<div className="item-row w-100 bus-row">
			<h4 className="header-title">
				<ClockTimeIcon />
				<span className="mx-3">{operationStatement.Checkouttimefromthehotel}</span>
			</h4>
			<div className="item-content">
				<div className="header-tabs-bus header-tabs ">
					<div className="tab">
						<label htmlFor="">{operationStatement.City}</label>
					</div>
					<div className="tab">
						<label htmlFor="">{operationStatement.Beforemoving}</label>
					</div>
				</div>

				<div>
					{/*** Makkah */}
					<div className="content-tabs-bus content-tabs">
						<div className="label-data tab ">
							<h1>
								<HotelTimeIcon />
								<p className="d-inline mx-2">{operationStatement.Makkah}</p>
							</h1>
						</div>
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
									name="makkah_checkout"
									id="makkah_checkout"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm a"
									date={
										data?.find(
											(item) => item.city_id === 3 && item.city_name === "Makkah"
										)?.time
									}
									onChangeDate={(time) =>
										handleInputs(time, "hotel_checkout", "Makkah", 3, "time")
									}
									onKeyDown={(e) => {
										if (e.keyCode == 32 || e.keyCode == 8) {
											handleInputs(
												moment(`${today} ${"00:00"}`).toDate(),
												"hotel_checkout", "Makkah", 3, "time"
											);
										}
									}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
					{/*** Madinah */}
					<div className="content-tabs-bus content-tabs">
						<div className="label-data tab">
							<h1>
								<HotelTimeIcon />
								<p className="d-inline mx-2">{operationStatement.Madinah}</p>
							</h1>
						</div>

						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
									name="madinah_checkout"
									id="makkah_checkout"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm a"
									date={
										data?.find(
											(item) =>
												item.city_id === 4 && item.city_name === "Madinah"
										)?.time
									}
									onChangeDate={(time) =>
										handleInputs(time, "hotel_checkout", "Madinah", 4, "time")
									}
									onKeyDown={(e) => {
										if (e.keyCode == 32 || e.keyCode == 8) {
											handleInputs(
												moment(`${today} ${"00:00"}`).toDate(),
												"hotel_checkout", "Madinah", 4, "time"
											);
										}
									}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
					{/*** Jeddah */}
					<div className="content-tabs-bus content-tabs">
						<div className="label-data tab">
							<h1>
								<HotelTimeIcon />
								<p className="d-inline mx-2">{operationStatement.Jeddah}</p>
							</h1>
						</div>
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
									name="jeddah_checkout"
									id="jeddah_checkout"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm a"
									date={
										data?.find(
											(item) =>
												item.city_id === 134 && item.city_name === "Jeddah"
										)?.time
									}
									onChangeDate={(time) =>
										handleInputs(time, "hotel_checkout", "Jeddah", 134, "time")
									}
									onKeyDown={(e) => {
										if (e.keyCode == 32 || e.keyCode == 8) {
											handleInputs(
												moment(`${today} ${"00:00"}`).toDate(),
												"hotel_checkout", "Jeddah", 134, "time"
											);
										}
									}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
