import React from 'react'
import Locale from 'translations';
import { ReactComponent as HotelIcon } from "assets/images/market-place/services-hotel-icon.svg";
import { ReactComponent as VisaIcon } from "assets/images/market-place/services-visaonline-icon.svg";
import { ReactComponent as OpenEyeIcon } from "assets/images/market-place/open-eye-icon.svg";
import { ReactComponent as CheckMarkIcon } from "assets/images/market-place/checkmark-circle-icon.svg";
import { ReactComponent as CalenderIcon } from "assets/images/market-place/calendar-outline-icon.svg";
import { ReactComponent as DepartureIcon } from "assets/images/market-place/departur-transport-icon.svg";
import { ReactComponent as InternalIcon } from "assets/images/market-place/services-transport-icon.svg";
import { ReactComponent as SightSeenIcon } from "assets/images/market-place/sightseen-icon.svg";
import { ReactComponent as InsuranceIcon } from "assets/images/market-place/insurance-icon.svg";
import { ReactComponent as OtherServicesIcon } from "assets/images/market-place/otherservices-icon.svg";
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSBSState } from 'context/global';
import { formatPrice } from 'helpers/utils';


export default function PackageCard({ packageData }) {
  const { marketPlace, inventory } = Locale;
  const history = useHistory();
  const { locale } = useSBSState();

  const filesURL = process.env.REACT_APP_FILEUPLOAD_URL + '/fetch';
  const packageRating = +packageData?.rating > 5 ? 5 : +packageData?.rating || null;
  const packageItemsDistinct = [...new Set(packageData?.items?.map(item => item?.id))];

  const packageIcons = {
    1: <DepartureIcon />,
    2: <InternalIcon />,
    4: <VisaIcon />,
    5: <InsuranceIcon />, //insurance
    6: <HotelIcon />,
    7: <SightSeenIcon />, // Sightseen 
    8: <OtherServicesIcon />, // other services 
  }

  return (
    <div className='selling-service-card'>

      <div className="card-img-container">
        <img src={packageData?.photo ? `${filesURL}/${packageData?.photo}` : ""} alt={packageData?.name} />
        <button className="view-overlay" onClick={()=> history.push(`/market-package/package-details/${packageData?.product_uuid}`)}>
          <OpenEyeIcon />
          <span className='mx-2'>{inventory.messages.view}</span>
        </button>
      </div>

      <div className="card-content">
        <h4 className='card-title'>{packageData?.name}</h4>
        {packageRating ?
          <div className="rating">
            {[1, 2, 3, 4, 5]?.map(rate => {
              return (
                <span key={`rate-${rate}`}>
                  {rate > packageRating
                    ? <i className="far fa-star empty-rate" aria-hidden="true" key={rate} />
                    : <i className="fa fa-star" aria-hidden="true" key={rate} />}
                </span>
              )
            })}
          </div>
          :
          null
        }

        <span className='service-type'>
          {/* {marketPlace.package} : */}
        
         {packageData?.service_type?.name?.[locale]}</span>
        <p className='my-2'>
          <CalenderIcon />
          <span className='mx-2'>{moment(packageData?.from).format("ddd, MMM DD")} - {moment(packageData?.to).format("MMM DD")}</span>
        </p>

        <div className='d-flex align-items-center my-1'>
          <CheckMarkIcon />
          <span className='mx-2'>{marketPlace.included} :</span>

          {packageItemsDistinct?.map((item, index) => {
            return (
              <p className={`${index > 0 ? "mx-1" : ""}`} key={`package-item-${index}`}>
                {packageIcons[item]}
              </p>
            )
          })}
        </div>
      </div>

      <div className="card-footer justify-content-end">
        <div className='price-container'>
          <span>{marketPlace.Price}</span>
          <p>
            <span className='price'>{packageData?.price ? formatPrice(packageData?.price) : ""} </span>
            <span> {packageData?.currency}</span>
          </p>
        </div>
      </div>
    </div>
  )
}
