import SelectField from "components/Form/SelectField/SelectField";
import { useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import createDOMPurify from "dompurify";
import { formatPrice } from 'helpers/utils';
import { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { checkHotelAvailability } from "services/marketplace";
import Locale from "translations";
const Room = ({
	rooms,
	selectedHotel,
	image,
	indexKey,
	selectedDataReq,
	setSelectedDataReq,
}) => {
	const { marketPlace, inventory } = Locale;
	const { id } = useParams();
	const { locale } = useSBSState();
	const DOMPurify = createDOMPurify(window);
	const [modalTerms, setModalTerms] = useState(false);
	const toggleModalTerms = () => setModalTerms(!modalTerms);

	const [modalDays, setModalDays] = useState(false);
	const toggleModalDays = () => setModalDays(!modalDays);

	const [NoteReta, setNoteReta] = useState(false);
	const toggleNoteReta = () => setNoteReta(!NoteReta);

	const [CancellationModel, setCancellationModel] = useState(false);
	const toggleCancellationModel = () =>
		setCancellationModel(!CancellationModel);
	const { filters, hotelFilters } = useMarketplaceState();

	const history = useHistory();
	const dispatch = useMarketplaceDispatch();
	const [selectedData, setSelectedData] = useState({});

	const [RoomsList, setRoomsList] = useState([]);
	const [optionsSelected, setOptionsSelected] = useState([]);
	const [total, setTotal] = useState(0);
	const search = window.location.search;

	const name = new URLSearchParams(search);
	const [params, setParams] = useState(search);
	useEffect(() => {
		let x = [];
		x.push({
			id: 0,
			name: 0,
		});
		if (hotelFilters.rooms_data&&hotelFilters.rooms_data.length > 0) {
			
	hotelFilters.rooms_data.map((res, index) => {
			x.push({
				id: index + 1,
				name: index + 1,
			});
		});
		
	}
		setRoomsList(x);
	}, []);

	const [selected, setSelected] = useState({
		// pax: 1,
		search_token: id,
		roomKey: rooms.roomKey,
		quantity: rooms.availabilityCount,
		price: rooms.room_total_price,
		checkId: null,
	});

	// const checkAvailability = async (roomKey, roomConfigurationId) => {
	// 	const availabilityData = {
	// 		search_token: id,
	// 		rooms_data: [
	// 			{
	// 				roomKey: rooms.roomKey,
	// 				roomConfigurationId: roomConfigurationId,
	// 				roomKeyInsideRateBasis: roomKey,
	// 				quantity: +filters.roomType,
	// 				noOfAdults: +filters.adult,
	// 			},
	// 		],
	// 		lang: locale,
	// 		date_from: hotelFilters.date_from,
	// 		date_to: hotelFilters.date_to,
	// 		nationality: filters.nationality,
	// 	};

	// 	if (+filters.child >= 1) {
	// 		availabilityData.rooms_data[0].childrens = [];
	// 		availabilityData.rooms_data[0].childrens.push({
	// 			quantity: filters.child,
	// 			age: 11,
	// 		});
	// 	}

	// 	const availability = await checkHotelAvailability(availabilityData);
	// 	if (availability.status === 200) {
	// 		localStorage.setItem(
	// 			"selected",
	// 			JSON.stringify({
	// 				...selectedHotel,
	// 				...selected,
	// 				checkId: availability.data.data.hotel_check_id,
	// 			})
	// 		);

	// 		setSelected({
	// 			...selected,
	// 			checkId: availability.data.data.hotel_check_id,
	// 		});
	// 		dispatch({
	// 			type: "selected",
	// 			payload: {
	// 				...selectedHotel,
	// 				...selected,
	// 				checkId: availability.data.data.hotel_check_id,
	// 			},
	// 		});
	// 		history.push(`/hotel/confirm`);
	// 	} else {
	// 		return false;
	// 	}
	// };

	useEffect(() => {
		setOptionsSelected(rooms.listSeleted);
	}, [rooms.listSeleted]);

	const removedOption = (e) => {};

	useEffect(() => {
		let totalx = 0;

		if (selectedDataReq?.length > 0) {
			selectedDataReq.forEach((res) => {
				if (res.room_description === rooms.roomData.roomText) {
					totalx = totalx + res.room_rates[0].selected;
				}
			});

			setTotal(totalx);
			let x = [{ id: 0, name: 0 }];
			let roomsRemainigCount = (selectedHotel?.gds === 9 || selectedHotel?.gds === 5 || selectedHotel?.gds === 12) ? rooms.listSeleted?.[1]?.id : rooms.listSeleted.length - 1
			if (totalx === 0) {
				x = [...x, { id: roomsRemainigCount, name: roomsRemainigCount }]
			}
			else if (roomsRemainigCount - totalx >= 0) {
				[...Array(roomsRemainigCount - totalx).keys()].forEach(
					(res, index) => {
						x.push({
							id: index + 1,
							name: index + 1,
						});
					}
				);
			}
			setOptionsSelected(x);
		}
	}, [rooms.listSeleted.length, rooms.roomData.roomText, selectedDataReq, selectedHotel?.gds]);

	console.log(selectedDataReq);

	return (
		<>
			<div className="hotel-room-count my-2">
				<div className="hotel-room-box p-0 pt-2">
					<div className="available-section">
						<div className="row m-0">
							<div className="col-md-12">
								<div className="room-description d-flex justify-content-between">
									{/* {roomTypes.find((type) => type.id === room.quantity).name} */}
									{/* {selectedHotel.gds == 5
										? marketPlace.reservNum + " " + ++indexKey
										: rooms.roomData.roomText} */}
									{rooms.roomData.roomText}
									{selectedHotel?.token_data && 
										<div className="d-flex align-items-center gap-10">
											<p>
												<span className="mx-1 font-weight-bold">{marketPlace.CheckinDate}: </span>
												<span>{filters?.date_from}</span>
											</p>
											<p>
												<span className="mx-1 font-weight-bold">{marketPlace.CheckoutDate}: </span>
												<span>{filters?.date_to}</span>
											</p>
											{/*  */}
											<button  className="btn bg-nxt" 
												onClick={()=> {
													dispatch({ type: "searchResults", payload: undefined });
													history.push(`/market-view?token=${selectedHotel?.token_data?.token}`);
												}} 
											>
												Change
												</button>
										</div>
									}
								</div>
								<div className="container details-item m-auto pb-3">
									{/*room.images */}
									<div className="row">
										<table className="table roomTypesTable">
											<tr>
												<th className="font-weight-bold">
													{marketPlace.RoomType}
												</th>
												<th className="font-weight-bold">
													{marketPlace.BoardBasis}
												</th>
												<th className="font-weight-bold" 	>
													{marketPlace.Price}
												</th>
												<th className="font-weight-bold px-0" 	>
													{marketPlace.Policy}
												</th>
												<th className="font-weight-bold px-0" 	>
													{marketPlace.Remarks}
												</th>
												<th className="font-weight-bold" 	>
													{marketPlace.status}
												</th>
												<th className="font-weight-bold" 	>
													{marketPlace.cancellation}
												</th>
												{/* {selectedHotel.gds == 5 ? (
													""
												) : (
													<th className="font-weight-bold">
														{marketPlace.selectRooms}
													</th>
												)} */}
												<th className="font-weight-bold">
													{marketPlace.Numberofrooms}
												</th>
											</tr>
											{rooms.roomData.roomTypes.map((room, IND) => {
												
												let x = selectedHotel.currency != selectedHotel.providerCurrency ? "exchange_room_rates":"room_rates"

												return room[x].map((res, index) => {
													return (
														<tr className="hover-roomTypesTable">
															<td className="w-25 ">
																{room.name}
																<i
																	title="Room info"
																	onClick={() => {
																		toggleModalTerms();
																		setSelectedData({
																			room_rate: res,
																			room: room,
																		});
																	}}
																	className="fas fa-info  span-info px-2"
																></i>
																{/* <p>
																	{selectedHotel.gds == 5 ? (
																		<>
																			<p
																				className="d-flex"
																				style={{ fontStyle: "italic" }}
																			>
																				<span className="">
																					{marketPlace.messages.adult} :
																				</span>
																				<strong className="font-weight-bold mx-2">
																					{room?.paxes?.maxAdult}
																				</strong>
																				{" , "}
																				<span className="">
																					{marketPlace.messages.child} :
																				</span>

																				<strong className="font-weight-bold mx-2">
																					{room?.paxes?.maxChildren}
																				</strong>
																			</p>
																		</>
																	) : (
																		""
																	)}
																</p> */}
															</td>
															<td>
																{marketPlace.messages[res.mealTitle] !=
																undefined
																	? marketPlace.messages[res.mealTitle]
																	: res.mealTitle}
															</td>
															<td>

															
																
																
																{res.room_total_price&&res.room_total_price >0 ? formatPrice(res.room_total_price) : null} {res.room_price_currency}
																<i
																	title="Room info"
																	onClick={() => {
																		toggleModalDays();
																		setSelectedData({
																			room_rate: res,
																			room: room,
																		});
																	}}
																	className="fas fa-info  span-info px-2"
																></i>
															</td>
															<td className='px-0'>
																<span
																	className="span-warining"
																	onClick={() => {
																		toggleCancellationModel();
																		setSelectedData({
																			room_rate: res,
																			room: room,
																		});
																	}}
																>
																	<i
																		className="fas fa-exclamation-triangle"
																		title="Cancellation Policies"
																	></i>
																</span>{" "}
																</td>
																<td className='px-0'>
																<span
																	className="span-comment"
																	onClick={() => {
																		toggleNoteReta();
																		setSelectedData({
																			room_rate: res,
																			room: room,
																		});
																	}}
																>
																	<i
																		className="fas fa-comment-alt"
																		title="Rate Notes"
																	></i>
																</span>
																</td>
															<td>
																{" "}
																{res.onRequest ? (
																	<span className="span-Request">
																		{marketPlace.OnRequest}
																	</span>
																) : null}
																{res.isBookable ? (
																	<span className="span-Available">
																		{marketPlace.Available}
																	</span>
																) : null}
															</td>
															<td>
																{!res.withinCancellationDeadline ? (
																	<span className="text-success">
																		{marketPlace.refundable}
																	</span>
																) : (
																	<span className="text-danger">
																		<i className="fas fa-exclamation-circle"></i>{" "}
																		{marketPlace.nonrefundable}
																	</span>
																)}
															</td>
															{/* {selectedHotel.gds == 5 ? (
																""
															) : (
																<td className="select-room">
																	<SelectField
																		room={true}
																		options={optionsSelected}
																		hasLabel={false}
																		onChange={(e) => {
																			removedOption(e);
																			if (
																				selectedDataReq.filter(
																					(res) =>
																						res.indexssss ==
																						"listClients" +
																							index +
																							IND +
																							rooms.roomData.roomText
																				).length > 0
																			) {
																				let x = [...selectedDataReq];
																				let indexxxxxx = x.findIndex(
																					(rank) =>
																						rank.index ==
																						"listClients" + index + IND
																				);
																				let z = [];
																				z.push({
																					...res,
																					selected: e.id,
																				});
																				x[indexxxxxx] = {
																					...x[indexxxxxx],
																					room_rates: z,
																				};
																				setSelectedDataReq(x);
																			} else {
																				let newData = {
																					...room,
																					index: "listClients" + index + IND,
																					indexssss:
																						"listClients" +
																						index +
																						IND +
																						rooms.roomData.roomText,
																					room_description:
																						rooms.roomData.roomText,
																					adultsCount:
																						rooms.roomData.adultsCount,
																					childrenAges:
																						rooms.roomData.childrenAges,
																					childrenCount:
																						rooms.roomData.childrenCount,
																				};

																				newData.room_rates = [];
																				newData.room_rates.push({
																					...res,
																					selected: e.id,
																				});
																				let x = [...selectedDataReq];
																				x.push(newData);
																				setSelectedDataReq(x);
																			}
																		}}
																	/>
																</td>
															)} */}
															<td className="select-room">
																<SelectField
																	room={true}
																	options={optionsSelected}
																	hasLabel={false}
																	onChange={(e) => {
																		removedOption(e);
																		if (
																			selectedDataReq.filter(
																				(res) =>
																					res.indexssss ==
																					"listClients" +
																					index +
																					IND +
																					rooms.roomData.roomText
																			).length > 0
																		) {
																			let x = [...selectedDataReq];
																			let indexxxxxx = x.findIndex(
																				(rank) =>
																					rank.index ==
																					"listClients" + index + IND
																			);
																			let z = [];
																			z.push({
																				...res,
																				selected: e.id,
																			});
																			x[indexxxxxx] = {
																				...x[indexxxxxx],
																				room_rates: z,
																			};
																			setSelectedDataReq(x);
																		} else {
																			let newData = {
																				...room,
																				index: "listClients" + index + IND,
																				indexssss:
																					"listClients" +
																					index +
																					IND +
																					rooms.roomData.roomText,
																				room_description:
																					rooms.roomData.roomText,
																				adultsCount:
																					rooms.roomData.adultsCount,
																				childrenAges:
																					rooms.roomData.childrenAges,
																				childrenCount:
																					rooms.roomData.childrenCount,
																			};

																			newData.room_rates = [];
																			newData.room_rates.push({
																				...res,
																				selected: e.id,
																			});
																			let x = [...selectedDataReq];
																			x.push(newData);
																			setSelectedDataReq(x);
																		}
																	}}
																/>
															</td>															
														</tr>
													);
												});
											})}
										</table>

										<div className="d-flex w-100 px-3 justify-content-end">
											{/* {selectedHotel.gds == 5 ? (
												<button
													className="btn px-4  bg-nxt"
													onClick={() => {
														let list = [];
														rooms.roomData.roomTypes.map((roomz, INDz) => {
															let newData = {
																...roomz,
																index: "listClients" + INDz,
																indexssss:
																	"listClients" +
																	INDz +
																	rooms.roomData.roomText,
																room_description: rooms.roomData.roomText,
																adultsCount: roomz.paxes.adults,
																childrenAges: rooms.roomData.childrenAges,
																childrenCount: roomz.paxes.children,
															};
															newData.room_rates = [];

															roomz.room_rates.map((resx, indexxx) => {
																newData.room_rates.push({
																	...resx,
																	selected: indexxx + 1,
																});
															});
															list.push(newData);
														});

														setSelectedDataReq(list);
														localStorage.setItem(
															"newCheckAvailability",
															JSON.stringify(list)
														);

														let x = {};
														name.forEach(function (value, key) {
															x = { ...x, [key]: value };
														});

														params.includes("token")
															? history.push(
																	`/Umrah/confirm-hotel?token=${x.token}`
															  )
															: history.push(`/Umrah/confirm-hotel`);
													}}
												>
													check out
												</button>
											) : (
												""
											)} */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				size="lg"
				className="mt-8"
				isOpen={modalTerms}
				toggle={toggleModalTerms}
			>
				<ModalHeader className=" align-items-center" toggle={toggleModalTerms}>
					{selectedData?.room?.name}
				</ModalHeader>
				<ModalBody className="mx-3">
					<div className="row">
						<div className="col-md-12 bg-text p-4">
							<h6 className="d-flex h5 font-weight-bold">
								{marketPlace.messages.Roomoccupancy}
							</h6>
							<div className="col-md-12 ">
								<p className="d-flex">
									<span className="">
										{marketPlace.messages.MaximumAdults} :
									</span>
									<strong className="font-weight-bold ">
										{selectedData?.room?.paxes?.maxAdult}
									</strong>
								</p>
								<p className="d-flex">
									<span className="">
										{marketPlace.messages.MaximumChildren} :
									</span>

									<strong className="font-weight-bold ">
										{selectedData?.room?.paxes?.maxChildren}
									</strong>
								</p>
								<p className="d-flex">
									<span className="">{marketPlace.messages.maxExtraBed} :</span>
									<strong className="font-weight-bold ">
										{selectedData?.room?.paxes?.maxExtraBed}
									</strong>
								</p>
							</div>
						</div>
						{selectedData?.room?.features &&
						selectedData?.room?.features.length > 0 ? (
							<div className="col-md-12 bg-text p-4 mt-3">
								<p className="h5 font-weight-bold">
									{marketPlace.messages.ROOMAMENITIES}
								</p>
								<div className="col-md-12 ">
									<div className="row">
										{selectedData?.room?.features.map((res) => {
											return (
												<div className="col-md-4 text-muted">
													<i className="fa fa-check px-2"></i>
													{res}
												</div>
											);
										})}
									</div>
								</div>
							</div>
						) : null}
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-gray-150 text-dark border-0 px-4"
						onClick={toggleModalTerms}
					>
						{inventory.messages.cancel}
					</Button>
				</ModalFooter>
			</Modal>

			<Modal
				size="md"
				isOpen={modalDays}
				toggle={toggleModalDays}
				style={{ maxWidth: "500px !important" }}
			>
				<ModalHeader className="align-items-center" toggle={toggleModalDays}>
					{inventory.messages.DailyRates}
				</ModalHeader>
				<ModalBody className="mx-3">
					<div className="row">
						<div className="col-md-12 bg-text p-4">
							{/* <h6 className="h5 font-weight-bold">
								{marketPlace.messages.Roomoccupancy}
							</h6> */}
							<div className="col-md-12 ">
								<p className="d-flex">
									<span className="font-weight-bold">
										{inventory.messages.date}
									</span>
									&nbsp;
									<strong className="font-weight-bold ">
										{inventory.messages.Price}
									</strong>
								</p>
								{selectedData?.room_rate?.dates.map((res) => {
									return (
										<p className="d-flex">
											{" "}
											<span className="w-105 mx-2">{res.date} </span>
											<strong className="w-105">
												{formatPrice(res.total_price)}{" "}
												{selectedData?.room_rate?.room_price_currency}
											</strong>
										</p>
									);
								})}
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-gray-150 text-dark border-0 px-4"
						onClick={toggleModalDays}
					>
						{inventory.messages.cancel}
					</Button>
				</ModalFooter>
			</Modal>

			<Modal size="lg" isOpen={NoteReta} toggle={toggleNoteReta}>
				<ModalHeader className="align-items-center" toggle={toggleNoteReta}>
					{inventory.messages.rateNotes}
				</ModalHeader>
				<ModalBody className="mx-3">
					<div className="row">
						<div className="row">
							<p className="h5 font-weight-bold">
								{/* {marketPlace.messages.termsAndConditions} */}
							</p>
							<div className="col-md-12 text-left">
								<div
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(
											selectedData?.room_rate?.termsAndConditions
										),
									}}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-gray-150 text-dark border-0 px-4"
						onClick={toggleNoteReta}
					>
						{inventory.messages.cancel}
					</Button>
				</ModalFooter>
			</Modal>
			
			{/* Cancellation and Amendment Policies Modal */}
			<Modal
				size="lg"
				isOpen={CancellationModel}
				toggle={toggleCancellationModel}
				className="mt-8 T-cancellation-modal"
			>
				<ModalHeader
					className="align-items-center"
					toggle={toggleCancellationModel}
				>
					{inventory.messages.CancellationAndAmendmentsPolicies}
				</ModalHeader>
				<ModalBody className="mx-3">
					<div className="col-md-11 m-auto">
						<div className="row">
							{selectedData?.room_rate?.cancellation_policy.map((res) => {
								return (
									<>
										{!res.hasOwnProperty("fromDate") ? (
											<>
												<p className="w-100 mt-2">
													{inventory.messages.before} {res.toDate}
												</p>
												<div className="col">
													{res.cancelCharge == 0 ? (
														<div className="boxCancellation bg-Ground">
															<p>{inventory.messages.noCancellationCharge}</p>
														</div>
													) : res.cancelRestricted ? (
														<div className="boxCancellation bg-dangerr">
															<p>
																{" "}
																{inventory.messages.cancellationRestricted}
															</p>
														</div>
													) : (
														<div className="boxCancellation">
															{inventory.messages.cancellationCharge}
															<p>
																{" "}
																{formatPrice(res.cancelCharge)}{" "}
																{selectedData?.room_rate?.room_price_currency}
															</p>
														</div>
													)}
												</div>
												<>
													{res.amendCharge == 0 ? (
														<div className="col ">
															<div className="boxCancellation bg-Ground">
																<p>{inventory.messages.noAmendCharge}</p>
															</div>
														</div>
													) : res.amendRestricted ? (
														<div className="col ">
															<div className="boxCancellation bg-dangerr">
																{inventory.messages.amendRestricted}
															</div>
														</div>
													) : selectedHotel.gds != 5 &&
													  selectedHotel.gds != 6 ? (
														<div className="col ">
															<div className="boxCancellation">
																<p>{inventory.messages.amendmentCharge}</p>
																{formatPrice(res.amendCharge)} {" "}
																{selectedData?.room_rate?.room_price_currency}
															</div>
														</div>
													) : (
														""
													)}
												</>
											</>
										) : !res.hasOwnProperty("toDate") &&
										  !res.hasOwnProperty("noShowPolicy") ? (
											<>
												<p className="w-100 mt-3">
													{inventory.messages.after} {res.fromDate}
												</p>
												<div className="col">
													{res.cancelCharge == 0 ? (
														<div className="boxCancellation bg-Ground">
															<p>{inventory.messages.noCancellationCharge}</p>
														</div>
													) : res.cancelRestricted ? (
														<div className="boxCancellation bg-dangerr">
															<p>{inventory.messages.cancellationRestricted}</p>
														</div>
													) : (
														<div className="boxCancellation">
															{inventory.messages.cancellationCharge}
															<p>
																{" "}
																{formatPrice(res.cancelCharge)}{" "}
																{selectedData?.room_rate?.room_price_currency}
															</p>
														</div>
													)}
												</div>
												<>
													{res.amendCharge == 0 ? (
														<div className="col ">
															<div className="boxCancellation bg-Ground">
																<p>{inventory.messages.noAmendCharge}</p>
															</div>
														</div>
													) : res.amendRestricted ? (
														<div className="col ">
															<div className="boxCancellation bg-dangerr">
																<p>{inventory.messages.amendRestricted}</p>
															</div>
														</div>
													) : selectedHotel.gds != 5 &&
													  selectedHotel.gds != 6 && selectedHotel.gds != 9 ? (
														<div className="col ">
															<div className="boxCancellation">
																<p>{inventory.messages.amendmentCharge}</p>
																{formatPrice(res.amendCharge)}{" "}
																{selectedData?.room_rate?.room_price_currency}
															</div>
														</div>
													) : (
														""
													)}
												</>
											</>
										) : res.hasOwnProperty("noShowPolicy") ? (
											<>
												<p className="w-100 mt-3">
													{inventory.messages.after} {res.fromDate}
												</p>

												<div className="col-md-12">
													<div className="boxCancellation bg-dangerNew ">
														<p>{inventory.messages.noShowPolicyCharge}</p>
														{formatPrice(res.charge)}{" "}
														{selectedData?.room_rate?.room_price_currency}
													</div>
												</div>
											</>
										) : (
											<>
												<p className="w-100 mt-3">
													{" "}
													between {res.fromDate} and {res.toDate}
												</p>
												<>
													{res.cancelCharge == 0 && selectedHotel?.gds !== 11 ? 
														<div className="col">
															<div className="boxCancellation bg-Ground">
																<p> {inventory.messages.noAmendCharge}</p>
															</div>
														</div>
													: res.cancelCharge == 0 && selectedHotel?.gds === 11 ?
														<div className="col">
															<div className="boxCancellation bg-Ground">
																<p> {inventory.messages.noCancellationCharge}</p>
															</div>
														</div>
													: res.cancelRestricted ? (
														<div className="col">
															<div className="boxCancellation bg-dangerr">
																<p>
																	{inventory.messages.cancellationRestricted}
																</p>{" "}
															</div>
														</div>
													) : selectedHotel.gds != 6 ? (
														<div className="col">
															<div className="boxCancellation">
																<p>{inventory.messages.cancellationCharge}</p>
																<p>
																	{" "}
																	{formatPrice(res.cancelCharge)}{" "}
																	{selectedData?.room_rate?.room_price_currency}
																</p>
															</div>
														</div>
													) : (
														""
													)}
												</>
												<>
													{res.amendCharge == 0 ? (
														<div className="col ">
															<div className="boxCancellation bg-Ground">
																<p>{inventory.messages.noAmendCharge}</p>
															</div>
														</div>
													) : res.amendRestricted ? (
														<div className="col">
															<div className="boxCancellation bg-dangerr">
																<p>{inventory.messages.amendRestricted}</p>
															</div>
														</div>
													) : selectedHotel.gds != 5 &&
													  selectedHotel.gds != 6 ? (
														<div className="col">
															<div className="boxCancellation">
																<p>{inventory.messages.amendmentCharge}</p>
																<p>
																	{" "}
																	{formatPrice(res.amendCharge)}{" "}
																	{selectedData?.room_rate?.room_price_currency}
																</p>
															</div>
														</div>
													) : (
														""
													)}
												</>
											</>
										)}
									</>
								);
							})}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-gray-150 text-dark border-0 px-4"
						onClick={toggleCancellationModel}
					>
						{inventory.messages.cancel}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default Room;
