import backArrow from "assets/images/backArrow.svg";
import noHotel from "assets/images/placeHoleder.png";
import { useSBSDispatch, useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useParams } from "react-router-dom";
import Carousel from "react-spring-3d-carousel";
import { fetchHotel, fetchHotelGuest } from "services/marketplace";
import Locale from "translations";
import Room from "./Room";
import ShowForPermission from "helpers/showForPermission";

// import Cart from "./Cart";
function Hotel() {
	const search = window.location.search;
	const name = new URLSearchParams(search);
	const [params, setParams] = useState(search);
	const { marketPlace } = Locale;
	const history = useHistory();
	const { id } = useParams();
	const { locale,isAuth } = useSBSState();

	const [Count, setCount] = useState(0);

	const [selectedDataReq, setSelectedDataReq] = useState([]);
	const { filters, hotelFilters } = useMarketplaceState();
	const dispatchGlobal = useSBSDispatch();

	const selectedHotelSearch = JSON.parse(
		localStorage.getItem("selectedHotelSearch")
	);
	const dispatch = useMarketplaceDispatch();
	const [hotel, setHotel] = useState(null);
	const [images, setImages] = useState(null);
	const [active, setActive] = useState(0);
	const [Silders, setSilders] = useState([]);
	const [selectedHotel, setSelectedHotel] = useState({
		roomsCacheKey: null,
		name: null,
		address: null,
		from: null,
		to: null,
		adult: 1,
		child: 0,
		type: null,
		quantity: 1,
		price: null,
	});

	// const handleChange = (e, i) => {
	// 	const name = e.target.name;
	// 	const value = e.target.value;
	// 	setSelectedHotel({ ...selectedHotel, [name]: value });
	// };

	const book = (selected) => {
		dispatch({
			type: "selected",
			payload: { ...selectedHotel, ...selected },
		});
	};

	// TODO: children
	useEffect(() => {
		async function fetchData() {
			let isSubscribed = true;

			if (params.includes("token")) {
				let x = {};
				name.forEach(function (value, key) {
					x = { ...x, [key]: value };
				});

				let response;
				response = await fetchHotelGuest({
					date_from: filters.date_from,
					date_to: filters.date_to,
					rooms_data: hotelFilters?.rooms_data,
					...x,
					
					search_token: id.split("?", 1)[0]!="null" ?id.split("?", 1)[0]:null ,
					is_offer: params.includes("offer"),
				});
				if (response.status === 200) {
					let data = response.data;
					let filtersNew = {};
					if (x?.date_from&&x?.date_to) {
						filtersNew = {
							date_from: moment(x.date_from).format("YYYY-MM-DD"),
							date_to: moment(x.date_to).format("YYYY-MM-DD"),
							checkIn: moment(x.date_from).format("YYYY-MM-DD"),
							checkOut: moment(x.date_to).format("YYYY-MM-DD"),
						};
					}
					
					// dispatch({ type: "setHotelFilters", payload: {hotelFilters:{ ...response?.data?.token_data?.search_payload }} });
					dispatchGlobal({
						type: "searchOffer",
						payload: response.data.token_data,
					});
					// localStorage.setItem("filters", JSON.stringify({ ...test, ...x }));
					dispatch({ type: "filter", payload: { ...filters, ...filtersNew } });
		
					let rooms = data.rooms.map((res) => {
						let listSeleted = [{ id: 0, name: 0 }];
						// dida travel  provider
						if (data?.gds === 9 || data?.gds === 5 || data?.gds === 12) {
							listSeleted = [...listSeleted, { id: res.repeat.length, name: res.repeat.length } ]
						} else {
							[...Array(res.repeat.length).keys()].map((resa, index) => {
								listSeleted.push({
									id: index + 1,
									name: index + 1,
								});
							});
						}

						return { ...res, listSeleted: listSeleted };
					});

					setHotel({ ...response.data, rooms: rooms });

					let silders =
						response.data.images && response.data.images.length > 0
							? response.data.images.map((res, index) => {
									return {
										key: index + "uuid.v4(2)",
										content: (
											<img
												className="bg-white"
												src={res.url}
												alt={index + 1}
												onError={(e) => {
													e.target.onerror = null;
													e.target.src = noHotel;
												}}
											/>
										),
										onClick: () => setActive(index),
									};
							  })
							: null;
					setSilders(silders);
					localStorage.setItem("HotelData", JSON.stringify(response.data));

					setSelectedHotel({
						...response.data,
						hotel_code: response.data.hotel_code,
						name: response.data.name,
						address: response.data.address,
						from: filters?.checkIn ? filters?.checkIn:x?.date_from,
						to: filters?.checkOut ?filters?.checkIn:x?.date_from,
						adult: filters?.adult,
						child: filters?.child,
						type: filters?.roomType,
						rating: response.data.rating,
					});
					setImages(
						response.data.images.map((img) => ({
							original: `${img.imageUrl}`,
							thumbnail: `${img.imageUrl}`,
						}))
					);
				}
			} else if (filters != undefined) {
				const data = {
					search_token: id!="null"?id:null,
					hotel_code: selectedHotelSearch?.hotel_code,
					date_from: moment(filters.checkIn).format("YYYY-MM-DD"),
					date_to: moment(filters.checkOut).format("YYYY-MM-DD"),
					nationality: "SA",
					room_type: filters.roomType,
					lang: locale,
					rooms_data: [
						{
							quantity: +filters.roomType,
							noOfAdults: +filters.adult,
						},
					],
					...hotelFilters,
				};

				if (+filters.child >= 1) {
					data.rooms_data[0].childrens = [];
					data.rooms_data[0].childrens.push({
						quantity: +filters.child,
						age: 11,
					});
				}
				if (hotel === null || locale) {
					let response;
					response = await fetchHotel(data);
					if (response.status === 200) {
						let data = response.data;
						
						let rooms = data.rooms.map((res) => {
							let listSeleted = [{ id: 0, name: 0 }];
							// dida travel  provider
							if (data?.gds === 9 || data?.gds === 5 || data?.gds === 12) {
								listSeleted = [...listSeleted, { id: res.repeat.length, name: res.repeat.length }]
							} else {
								[...Array(res.repeat.length).keys()].map((resa, index) => {
									listSeleted.push({
										id: index + 1,
										name: index + 1,
									});
								});
							}
							return { ...res, listSeleted: listSeleted };
						});

						setHotel({ ...response.data, rooms: rooms });

						let x =
							response.data.images && response.data.images.length > 0
								? response.data.images.map((res, index) => {
										return {
											key: index + "uuid.v4(2)",
											content: (
												<img
													className="bg-white"
													src={res.url}
													alt={index + 1}
													onError={(e) => {
														e.target.onerror = null;
														e.target.src = noHotel;
													}}
												/>
											),
											onClick: () => setActive(index),
										};
								  })
								: null;
						setSilders(x);
						localStorage.setItem("HotelData", JSON.stringify(response.data));
						setSelectedHotel({
							...response.data,
							hotel_code: response.data.hotel_code,
							name: response.data.name,
							address: response.data.address,
							from: filters?.checkIn,
							to: filters?.checkOut,
							adult: filters?.adult,
							child: filters?.child,
							type: filters?.roomType,
							rating: response.data.rating,
						});
						setImages(
							response.data.images.map((img) => ({
								original: `${img.imageUrl}`,
								thumbnail: `${img.imageUrl}`,
							}))
						);
					} else {
						history.push("/market-view");
					}
				}
				return () => (isSubscribed = false);
			} else {
				const { date_from, date_to, nationality, rooms_data } = hotelFilters;
				const room_type = filters.roomType;
				const data = {
					search_token: id!="null"?id :null,
					hotel_code: selectedHotelSearch.hotel_code,
					date_from,
					date_to,
					nationality,
					room_type,
					lang: locale,
					rooms_data,
				};
				let response;
				response = await fetchHotel(data);
				if (response.status === 200) {
					setHotel(response.data);
					setSelectedHotel({
						...response.data,
						hotel_code: response.data.hotel_code,
						name: response.data.name,
						address: response.data.address,
						from: filters?.checkIn,
						to: filters?.checkOut,
						adult: filters?.adult,
						child: filters?.child,
						type: filters?.roomType,
						rating: response.data.rating,
					});
					setImages(
						response.data.images.map((img) => ({
							original: `${img.imageUrl}`,
							thumbnail: `${img.imageUrl}`,
						}))
					);
				} else {
					history.push("/market-view");
				}
			}
		}
		fetchData();
	}, []);

	useEffect(() => {
		let selectedcount = 0;
		selectedDataReq.map((res) => {
			selectedcount = selectedcount + res.room_rates[0].selected;
		});
		setCount(selectedcount);
	}, [selectedDataReq]);

	return (
		hotel !== null && (
			<>
				<section className="marketplace-details ">
					{/* <!-- create search area--> */}
					{/* <SearchBar hotel={hotel} /> */}
					{/* SearchBar.js */}
					<div className="container bg-white mt-3">
						{/* <!-- start transport slider --> */}
						<div className="transport-slider-container">
							<div className="slider-headers d-flex flex-wrap justify-content-between">
								<div className="d-flex flex-column">
									<h5 className="font-weight-bold text-headline">
										{hotel.name}
										<div className="hotel-star-rate d-inline mx-2">
											{[...Array(hotel.rating).keys()].map((i) => (
												<i
													key={i}
													className="fa fa-star"
													aria-hidden="true"
												></i>
											))}
										</div>
									</h5>
									<div className="d-flex">
										<p className="hotel-title2">
											<span>
												{hotel.address !== ""
													? hotel.address + " |"
													: hotel.address}
											</span>

											<span className="mx-1 link">
												<a
													href={`https://maps.google.com/?q=${hotel.latitude},${hotel.longitude}`}
													target="_blank"
													className="text-decor"
													rel="noreferrer"
												>
													{marketPlace.messages.showInMap}
												</a>
											</span>
										</p>
									</div>
								</div>

								<button
									onClick={() => {
										if (selectedHotel?.token_data?.token) {
											dispatch({ type: "searchResults", payload: undefined });
											history.push(`/market-view?token=${selectedHotel?.token_data?.token}`)
										}else {
											history.goBack()
										}
									}}
									className="btn btn-backNew mx-2"
								>
									<img src={backArrow} className="mx-2" alt="" srcset="" />
									{marketPlace.BackSearch}
								</button>

								<div className="col-md-12">
									<span className="border-d my-4"></span>
								</div>
							</div>

							{/* <!-- slider content --> */}
							<div className="">
								{Silders && Silders.length > 0 ? (
									<div
										style={{
											height: "300px",
											width: "100%",
											overflow: "hidden",
										}}
									>
										<Carousel
											slides={Silders}
											offsetRadius={2}
											goToSlide={active}
										/>
									</div>
								) : null}
							</div>
						</div>
						{/* <!-- end transport slider --> */}
						{hotel?.description !== "" && hotel?.description !== null ? (
							<div className="overview box-container">
								<h5 className="my-2 text-headline font-weight-bold">
									{marketPlace.messages.overview}
								</h5>
								<p className="mb-1">{hotel.description.replace(/(<([^>]+)>)/gi, "")}</p>
							</div>
						) : null}

						{hotel?.amenities?.length > 0 ? (
							<div className="hotel-amenities-new">
								<h5 className="my-3 text-headline font-weight-bold">
									{marketPlace.messages.hotelFacility}
								</h5>
								<ul className="my-2">
									{hotel.amenities?.map((facility, i) => {
										return (
											<li key={i}>
												<i className="fas fa-check mx-1"></i> {facility}
											</li>
										);
									})}
								</ul>
							</div>
						) : null}

						{hotel.policies?.length > 0 &&
							hotel.policies.map((policy, i) => (
								<div className="overview" key={i}>
									<h5 className="my-2 text-headline font-weight-bold">
										{/* {marketPlace.messages.overview} */}
										{policy.name}
									</h5>
									<p className="mb-1">{policy.description}</p>
								</div>
							))}

						{hotel.terms_conditions !== "" ? (
							<div className="overview">
								<h5 className="my-2 text-headline font-weight-bold">
									{marketPlace.messages.termsAndCondition}
								</h5>
								<p className="mb-1">{hotel.terms_conditions}</p>
							</div>
						) : null}

						{/* <div className="overview">
						<h5 className="my-2 text-headline font-weight-bold">
							{marketPlace.messages.paymentPolicy}
						</h5>
					</div> */}

						<div className="overview">
							<h5 className="my-2 text-headline font-weight-bold">
								{marketPlace.messages.availableRooms}
							</h5>

							{hotel.rooms.map((room, i) => {
								if (
									room.roomData.roomTypes &&
									room.roomData.roomTypes.length > 0
								) {
									return (
										<Room
											// transporter={transporter}
											// handleChange={handleChange}
											selectedDataReq={selectedDataReq}
											setSelectedDataReq={setSelectedDataReq}
											selectedHotel={selectedHotel}
											book={book}
											key={i}
											indexKey={i}
											rooms={room}
											image={hotel?.images}
										/>
									);
								}
							})}
							{/* <div className="col-md-3">
												<Cart />
											</div> */}
							<div className="col-md-12">
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-10"></div>
										<div className="col-md-2">
											{false ? (
												""
											) : (
												isAuth?
												<ShowForPermission permission="Manage-Marketplace-Hotels" >
													<button
														className="btn btn-success btn-lg w-100	mb-4 mt-3"
														disabled={Count <= 0}
														onClick={() => {
															localStorage.setItem(
																"newCheckAvailability",
																JSON.stringify(selectedDataReq)
															);
															let x = {};
															name.forEach(function (value, key) {
																x = { ...x, [key]: value };
															});

															
															if (x.hasOwnProperty("search_token")&&x.hasOwnProperty("hotel_code")) {
																localStorage.setItem(
																	"selectedHotelSearch",
																	JSON.stringify({
																	
																		search_token_selected: x.search_token,
																		hotel_code: x.hotel_code,
																	})
																)
															}

															params.includes("token")
																? history.push(
																		`/Umrah/confirm-hotel?token=${x.token}`
																  )
																: history.push(`/Umrah/confirm-hotel`);
														}}
													>
														{marketPlace.bookNow}
													</button>
												</ShowForPermission>:<button
														className="btn btn-success btn-lg w-100	mb-4 mt-3"
														disabled={Count <= 0}
														onClick={() => {
															localStorage.setItem(
																"newCheckAvailability",
																JSON.stringify(selectedDataReq)
															);											
															let x = {};
															name.forEach(function (value, key) {
																x = { ...x, [key]: value };
															});											

															params.includes("token")
																? history.push(
																		`/Umrah/confirm-hotel?token=${x.token}`
																  )
																: history.push(`/Umrah/confirm-hotel`);
														}}
													>
														{marketPlace.bookNow}
													</button>
												
											)}
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	);
}

export default Hotel;
