import React, { Fragment, useState } from "react";
import walletImg from "assets/images/wallet/wallet2.svg";
import img from "assets/images/setting.png";
import { Collapse } from "reactstrap";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import moment from "moment";
import { useMarketplaceDispatch, useMarketplaceState } from "context/marketplace";
import { useSBSState } from "context/global";
import ShowForPermission from 'helpers/showForPermission';
import { cabinClassLookup } from '../helpers/formatSearchData';
import { formatPrice } from 'helpers/utils';

function toHoursAndMinutes(totalMinutes) {
	const hours = Math.floor(+totalMinutes / 60);
	const minutes = +totalMinutes % 60;
	return `${hours}h ${minutes}m`;
}

function FlightItem({ journey, solution, searchResults = [], inbound }) {
	const { marketPlace } = Locale;

	const { locale } = useSBSState();

	const history = useHistory();
	const [isopen, setisopen] = useState(false);

	const segments = journey?.segments;
	const segmentsCount = segments?.length;

	const departureAirPort = segments[0];
	const arrivalAirPort = segments[segmentsCount - 1];

	const departureTime = departureAirPort?.departureTime;
	const arrivalTime = arrivalAirPort?.arrivalTime;

	const {  flightsSearch } = useMarketplaceState();
	const dispatch = useMarketplaceDispatch();

	const oneWay = flightsSearch?.type === "2"

	const flightsNumbers = segments.reduce(
		(prev, current, index) =>
			prev + (index === 0 ? "" : " | ") + current?.airline?.code+ current?.flightNum,
		""
	);



	return (
		<>
					<div className="flight_item mb-2">
				<div className="head-toggel">
					<div
						className="btn-toggel"
						onClick={() => {
							setisopen(!isopen);
						}}
					>
						{isopen ? (
							<>
								{marketPlace.hide} <i class="fas fa-angle-up mx-1"></i>
							</>
						) : (
							<>
								{marketPlace.ShowDetails}
								<i class="fas fa-angle-down mx-1"></i>
							</>
						)}
					</div>
				</div>
				<div className="time-line">
					<div className="infoAir" style={{position:"relative",top:"-4px"}}>
						<h4 className="takeoff mx-1" >{departureTime} </h4>
						<p className="airport-name">
							{" "}
							<span className="blue me-1">
								{departureAirPort?.departure?.code}
							</span>
							{departureAirPort?.departure?.names?.[locale]}
						</p>
					</div>
					<div className="text-center">
					{locale === "ar"? 
            <i class="fas fa-plane-departure mx-1" style={{ color: "#1A61AF",transform:'rotateY(180deg)' }}></i>
            :
            <i class="fas fa-plane-departure mx-1" style={{ color: "#1A61AF" }}></i>
            }						<p className="spent">
							{toHoursAndMinutes(journey?.journeyTime)}{" "}
							<span className="blue">{`${
								journey.stops.length
									? journey.stops.join("|") + " Stop"
									: "Non-stop"
							}`}</span>{" "}
						</p>
					</div>
					<div className="Landing" style={{position:"relative",top:"-4px"}}>
						<h4 className="takeoff mx-1">{arrivalTime}</h4>
						<p className="airport-name">
							{" "}
							<span className="blue">{arrivalAirPort?.arrival?.code}</span>{" "}
							{arrivalAirPort?.arrival?.names?.[locale]}
						</p>
					</div>
				</div>
				{/************************ STOPS ************************/}
				<Collapse isOpen={isopen}>
					{segments.map((segment, index) => {
						const stopTime = moment(
							`${segments[index + 1]?.departureDate} ${
								segments[index + 1]?.departureTime
							}`
						).diff(`${segment?.arrivalDate} ${segment?.arrivalTime}`, "m");
						return (
							<Fragment key={segment.segmentId}>
								<div className="mx-4 collapse-box p-2">
									<div className="d-flex align-items-center">
										<img
											className="img-company mr-2"
											src={segment?.airline?.logo}
											alt=""
										/>
										<div>
											<p className="takeoff">{segment?.airline?.name[locale]}</p>
											<p style={{ fontSize: "14px" }}>
												{/* {segment.airline?.code + segment.flightNum} */}
												{flightsNumbers}
											</p>
										</div>
									</div>
									<div className="time-line">
										<div className="infoAir">
											<p className="airport-name">
												{" "}
												<span className="me-1 blue">
													{segment?.departure?.code}
												</span>
												{segment?.departure?.names?.[locale] || segment?.departure?.code}
											</p>
											<span className="all-date">
												{" "}
												{`${moment(segment?.departureDate).format(
													"DD-MM-YYYY"
												)} ${segment?.departureTime} ${moment(
													segment?.departureDate
												).format("ddd")}`}
											</span>
										</div>
										<div className="text-center">
											<p className="spent">
												{toHoursAndMinutes(segment?.flightTime)}
											</p>
										</div>
										<div className="Landing">
											<p className="airport-name">
												{" "}
												<span className="me-1 blue">
													{segment?.arrival?.code}
												</span>{" "}
												{segment?.arrival?.names?.[locale] || segment?.arrival?.code}
											</p>
											<span className="all-date">
												{" "}
												{`${moment(segment?.arrivalDate).format("DD-MM-YYYY")} ${
													segment.arrivalTime
												} ${moment(segment?.arrivalDate).format("ddd")}`}
											</span>
										</div>
									</div>
								</div>

								{segmentsCount > 1 && index < segmentsCount - 1 && (
									<div className={`mx-4 stop-box${locale === "ar" ?"-ar":""}`}>
										<span
											className="blue pl-4"
											style={{ display: "inline-block", width: "43%" }}
										>
											Stop {segment?.arrival?.names?.[locale] || segment?.arrival?.code}
										</span>
										<span className="stop">{toHoursAndMinutes(stopTime)} </span>
									</div>
								)}
							</Fragment>
						);
					})}
				</Collapse>

				{/************************ SELECT ************************/}

				<div className="px-4">
					<div className="footer-toogle">
						<div className="d-flex justify-content-start align-items-center">
							<img
								className="img-company me-1"
								src={departureAirPort?.airline?.logo}
								alt=""
							/>
							<div>
								<p className="takeoff">
									{departureAirPort?.airline?.name?.[locale] || ""}
								</p>
								<p style={{ fontSize: "14px" }}>{flightsNumbers}</p>
							</div>
						</div>
						<div className="d-flex justify-content-between align-items-center col-4">
							<div className="d-flex ">
								{segments?.map((segment) => (
									<p
										key={segment.segmentId}
										style={{
											backgroundColor: "#EBEBEB",
											borderRadius: "2px",
											marginInlineEnd: "4px",
										}}
										className="px-1"
									>
										{segment?.equipment}
									</p>
								))}
							</div>
							<p style={{ fontSize: "14px" }}>{cabinClassLookup[departureAirPort.cabinClass] || departureAirPort.cabinClass }</p>
						</div>
						<div className="d-flex justify-content-end align-items-end">
							<img src={walletImg} alt="" className="me-1" />
							<div>
								<p
									style={{
										fontSize: "14px",
										color: "#DCA248",
										lineHeight: "1",
									}}
								>
									
									{!inbound && !oneWay?	<span className='d-block ' style={{lineHeight:"14px"}}>{marketPlace.messages.waysTicket2}</span> : marketPlace.messages.totalPrice}
								</p>
								<p
									style={{
										fontSize: "16px",
										color: "#0C3B5C",
										fontWeight: "bold",
										lineHeight: "1",
									}}
								>
									{`${solution.totalPrice&&solution.totalPrice>0 ? formatPrice(solution.totalPrice):null} ${solution.currency}`}
								</p>
							</div>
							<ShowForPermission permission="Manage-Marketplace-Flights">
								<button
									className="btn flight-select"
									onClick={() => {
										dispatch({
											type: inbound ? "inboundFlight" : "outboundFlight",
											payload: solution,
										});
										history.push(
											inbound || oneWay ? "flight-summray" : "inbound-view"
										);
									}}
									type="submit"
								>
									{marketPlace.select}
								</button>
							</ShowForPermission>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FlightItem;
