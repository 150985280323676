import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import UploadFileComponent from "modules/WebBuilder-V2/shared/UploadFile";
import React, { useEffect, useRef, useState } from "react";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import { ReactComponent as LogoPlaceholder } from "assets/images/webBuilder/logo-placeholder.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";
import HeaderButtonModal from "../models/HeaderButton";
import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/call.svg";
import { ReactComponent as MenuIcon } from "assets/images/webBuilder/menu.svg";
import { ReactComponent as CloseIcon } from "assets/images/webBuilder/close-nav.svg";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Locale from 'translations';

function HeroHeader({ editHeader }) {
	const { webBuilder } = Locale;
	const { hero, mainMenu, style, selectedLanguage } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const [openButtonModal, setOpenButtonModal] = useState(false);
	const navRef = useRef(null);

	const location = useLocation();
	const preview = !location.pathname.includes("edit");


	const header = hero?.header;
	const layoutType = header?.layout?.layoutType;
	const hasButton = header?.layout?.hasButton;
	const hasLogo = header?.layout?.hasLogo;
	const headerBg = header?.layout?.background === "white";
	const call_us = header?.layout?.callUs;
	const visibleMenuItems = mainMenu?.filter(item => item?.visible)

	const scrollToSection = (id) => {
		if (id) {
			const element = document.getElementById(id);
			element?.scrollIntoView({ behavior: "smooth" });
		}
	};

	const updateHeroHeader = ({ name, value, type }) => {
		dispatch({
			type: "updateHeroHeader",
			payload: {
				name,
				value,
				type,
			},
		});
	};
	const history = useHistory();
	const params = useParams();

	// scroll to location from view package page
	useEffect(() => {
		if (location.state) {
			setTimeout(() => {
				scrollToSection(location.state);
			}, 500);
		}
	}, []);


	const nav = (
		<nav className="header-nav" ref={navRef}>
			<button className="close-nav" onClick={handleOpenMobileNav}>
				<CloseIcon />
			</button>
			<ul className="main-nav">
				<li
					onClick={() => {
						if (preview) {
							if (params.name) {
								history.push(`/public-page-v2/${params.name}`);
							} else {
								history.push(`/web-builder-v2/preview`);
							}
						}
					}}
					className={headerBg ? "text-secondary-color" : ""}
				>
					{webBuilder.home}
				</li>
				{visibleMenuItems?.map(item => {
					return (
						<li key={item?.id} className="position-relative"
							onClick={() => {
								if (!item?.subNavs || item?.subNavs?.length === 0) {
									let url = params.name
										? `/web-v2/${params.name}/${item.url}`
										: `/web-builder-v2/preview/${item?.url}`;
									if (preview) {
										history.push({ pathname: `${url}` });
									}
									handleOpenMobileNav()
								}
							}}
						>
							{item?.subNavs?.length > 0 ? null : <span>{item?.title?.[selectedLanguage]}</span>}
							{/********************************************** sub navs *************************************************************/}
							{item?.subNavs?.length > 0 &&
								<UncontrolledDropdown>
									<DropdownToggle caret className={`service-dropdown`}>
										{item?.title?.[selectedLanguage]}
									</DropdownToggle>

									<DropdownMenu right>
										{item?.subNavs.map((subNav) =>
											<DropdownItem key={subNav.id}
												onClick={() => {
													let url = params.name
														? `/web-v2/${params.name}/${subNav.url}`
														: `/web-builder-v2/preview/${subNav?.url}`;
													if (preview) {
														history.push({ pathname: `${url}` });
													}
													handleOpenMobileNav()
												}}
											>
												{subNav.title[selectedLanguage]}
											</DropdownItem>
										)}
									</DropdownMenu>
								</UncontrolledDropdown>
							}
						</li>
					)
				})}


			</ul>
		</nav>
	);
	const logoImage = header?.logo ? <img src={header?.logo} alt="Logo" /> : null;
	const logo = preview ? (
		logoImage
	) : (
			<UploadFileComponent
				icon={LogoPlaceholder}
				id="hero-logo"
				label=""
				// label="Change image"
				onUpload={(value) => updateHeroHeader({ name: "logo", value })}
				image={logoImage}
			/>
		);

	const toggleButtonModal = (e) => {
		e?.stopPropagation();
		setOpenButtonModal((prev) => !prev);
	};

	const clickContactButton = () => {
		if (preview) {
			scrollToSection(header?.contactUs?.id);
			return;
		}
		toggleButtonModal();
	};

	function handleOpenMobileNav() {
		if (navRef.current.classList.contains("nav-opened")) {
			navRef.current.classList.remove("nav-opened")
		} else {
			navRef.current.classList.add("nav-opened")
		}
	}

	// const languageSwitcherButton = languages?.length > 1 &&
	// 	<div>
	// 		<ul>
	// 			<span className="font-weight-bold">{languages.find(l=> l.code === selectedLanguage).name}</span>
	// 			{languages?.map(language => {
	// 				return (
	// 					<li>{selectedLanguage === language?.code ? "" : language?.name}</li>
	// 				)
	// 			})}
	// 		</ul>
	// 	</div>

	const contactButton = hasButton && (
		<div className="d-flex-language-contact d-flex align-items-center gap-10">
			<button
				className="contact-button mr-2"
				onClick={() => {
					// check if we inside view package page when click scroll back to preview page the scroll using useeffect
					let url = params.name
						? `/public-page-v2/${params.name}`
						: "/web-builder-v2/preview";
					if (params.id) {
						history.push({ pathname: `${url}`, state: header?.contactUs?.id });
					} else {
						clickContactButton();
					}
				}}
			>
				{header?.contactUs?.name}
			</button>
			{/* {languageSwitcherButton} */}

			{call_us && (
				<div className="d-flex align-items-center ml-2 call-us">
					<PhoneIcon color="#1e85ff" />
					<div className="webBuilder-contact d-flex flex-column ml-1">
						<p>{webBuilder.callUs}</p>
						<EditableParagraph
							className="body text-primary-web-builder"
							contentEditable={true}
							onInput={(value) => {
								updateHeroHeader({ name: "phoneNumber", value });
							}}
							text={header?.phoneNumber}
							preventTranslate={true}
						/>
					</div>
				</div>
			)}
		</div>
	);

	const onSaveButton = ({ name, id }) => {
		updateHeroHeader({ name: "name", value: name, type: "contactUs" });
		updateHeroHeader({ name: "id", value: id, type: "contactUs" });
	};
	return (
		<>
			<div className={`web-builder-hero-header`} onClick={editHeader}>
				{layoutType === 1 || layoutType === 2 ? (
					<header className={`${layoutType === 2 ? "flex-row-reverse " : ""}`}>
						{hasLogo && (
							<div className="logo">
								{header?.layout?.hasLogo ?
									logo
									:
									null
								}
							</div>
						)}
						{(style?.theme === "theme-1" || style?.theme === "theme-3")  &&
							<>
								{nav}
								{contactButton}
								<button className="nav-mobile-icon" onClick={handleOpenMobileNav}>
									<MenuIcon />
								</button>
							</>
						}
						{style?.theme === "theme-2" &&
							<div className="nav-container">
								{nav}
								{contactButton}
								<button className="nav-mobile-icon" onClick={handleOpenMobileNav}>
									<MenuIcon />
								</button>
							</div>
						}

					</header>
				) : (
					<header className={`${layoutType === 4 ? "flex-row-reverse " : ""}`}>
						{contactButton}

						{hasLogo && (
							<div className="logo">
								{header?.layout?.hasLogo ?
									logo
									:
									null
								}
							</div>
						)}
						{nav}
					</header>
				)}
			</div>
			<HeaderButtonModal
				save={onSaveButton}
				isOpen={openButtonModal}
				toggle={toggleButtonModal}
			/>
		</>
	);
}

export default HeroHeader;


// export function MobileNav() {
// 	return (
// 		<ul></ul>
// 	)
// }
