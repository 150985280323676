import { roomTypesLookup } from './localLookups';

export function formatTravelers(reservationDetails) {
  let productPriceBreakDown = reservationDetails?.product?.product_price?.product_price_type?.id === 0
  if (reservationDetails) {
    const combinations = reservationDetails?.product?.product_price_combinations;
    let travelers = reservationDetails?.travelers;
    travelers = travelers?.map(traveler => {
      let priceCombination = combinations.find(combination => combination?.id === traveler?.combination_id);
      let price = 0;
      if (productPriceBreakDown) {
        priceCombination?.combinations?.forEach(combination => {
          let paxPrice = traveler?.is_adult ? combination?.price_adult : !traveler?.is_adult ? combination?.price_child : 0
          price = price + paxPrice;
        })
      } else {
        price = traveler?.is_adult ? priceCombination?.full_price_after_discount : priceCombination?.child_price_after_discount;
      }
      return {
        id: traveler?.id,
        roomType: {
          value: traveler?.roomAvailabilities[0]?.room_type,
          id: traveler?.roomAvailabilities[0]?.room_type,
          label: roomTypesLookup[traveler?.roomAvailabilities[0]?.room_type],
          name: roomTypesLookup[traveler?.roomAvailabilities[0]?.room_type],
          room_type: traveler?.roomAvailabilities[0]?.room_type,
        },
        price: traveler?.roomAvailabilities?.length > 0 ? price : 0,
        combination_id: traveler?.combination_id,
        is_adult: traveler?.is_adult
      }
    });
    let productAdultsTravelers = travelers?.filter(traveler => traveler?.is_adult);
    let productChildsTravelers = travelers?.filter(traveler => !traveler?.is_adult);

    const adultsPaxCount = reservationDetails?.adults_pax ? +reservationDetails?.adults_pax : 0;
    const childsPaxCount = reservationDetails?.children_pax ? +reservationDetails?.children_pax : 0;

    let newAdults = [];
    let newChilds = [];

    if (adultsPaxCount && adultsPaxCount > productAdultsTravelers?.length) {
      newAdults = [
        ...Array(adultsPaxCount - productAdultsTravelers?.length).keys(),
      ];
    }
    if (childsPaxCount && childsPaxCount > productChildsTravelers?.length) {
      newChilds = [
        ...Array(childsPaxCount - productChildsTravelers?.length).keys(),
      ];
    }

    return {
      adults: [...productAdultsTravelers, ...newAdults],
      childs: [...productChildsTravelers, ...newChilds],
    }
  }
}