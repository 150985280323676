import OperationStatementHeader from "modules/UmrahOperations/OperationStatement/View/StatementHeader";
import BreadCrump from "modules/UmrahOperations/shared/BreadCrump";
import OperationTabs from "modules/UmrahOperations/shared/Tabs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "assets/images/umrah-operations/close-icon.svg";
import Locale from "translations";
import { useSBSState } from "context/global";
import AccountingArrivalDeparture from "./ArrivalDeparture";
import AccountingTotalPricing from "./PackagePricing";
import AccountingHotels from "./Hotels";
import AccountingCatering from "./Catering";
import AccountingAttractions from "./Attractions";
import AccountingTransportation from "./Transportation";
import AccountingOtherservices from "./OtherServices";
import AccountingVisa from "./Visa";
import ChangePricingConfirmationModal from "./PricingConfirmationModal";
import { SaveIcon } from "modules/UmrahOperations/shared/Icons";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
	getAccountingView,
	saveFullPackagePricing,
	updateArrivalPricing,
	updateAttractionsPricing,
	updateCateringPricing,
	updateHotelPricing,
	updateOhterServicesPricing,
	updatePricingType,
	updateTaxType,
	updateTransportationPricing,
	updateVisaPricing,
} from "services/operationStatement";
import {
	calculateAllService,
	formatViewAccounting,
	handleResponesNotification,
	renderOperationTabsItems,
} from "modules/UmrahOperations/helper/accountingHelpers";
import ShowSubscription from 'helpers/ShowSubscription';

export default function ViewEditAccounting() {
	const { operationStatement, companyDetails } = Locale;
	const { locale, permissions } = useSBSState();
	const history = useHistory();
	const { id } = useParams();
	const location = useLocation()
	debugger

	const pricePerPackageID = "package";
	const pricePerServiceID = "service";

	const [pricingType, setPricingType] = useState(null);
	const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
	const [accountingStatement, setAccountingStatement] = useState(null);
	const [activeTab, setActiveTab] = useState("");
	const [prevActiveTab, setPrevActiveTab] = useState(null);
	const [operationTabsItems, setOperationTabsItems] = useState([]);

	const isPricePerService = pricingType === pricePerServiceID;
	const attractionPricingType = accountingStatement?.attraction_pricing;
	const prevTabRef = useRef(null);
	const hasManagePermission = permissions && permissions?.includes("Manage-operation-accounting");
	const canEdit = location.pathname.includes("operation-edit") && accountingStatement?.payment_status !== "paid" && hasManagePermission;


	let currentTabIndex = operationTabsItems.findIndex(
		(tab) => tab?.url === activeTab
	);

	function toggleConfirmationModal() {
		setConfirmationModalIsOpen(!confirmationModalIsOpen);
	}

	// get priceing data and format the data
	async function fetchAccountingView() {
		const viewRes = await getAccountingView(id);
		if (viewRes?.status === 200) {
			const formatAccountingData = formatViewAccounting(viewRes?.data?.data);
			const tabItmes = renderOperationTabsItems(id, formatAccountingData);
			setAccountingStatement(formatAccountingData);
			setOperationTabsItems(tabItmes);
			setActiveTab(tabItmes.find((tab) => tab && !tab?.disabled)?.url);
			setPricingType(formatAccountingData?.pricing_type);
		}
	}
	// handle next button
	function gotoNextTab(tabIndex) {
		tabIndex = tabIndex + 1
		let nextTab = operationTabsItems[tabIndex];
		if ((nextTab?.disabled || !nextTab?.url)) {
			gotoNextTab(tabIndex);
		}
		else {
			setActiveTab(nextTab.url);
			setPrevActiveTab(operationTabsItems[currentTabIndex].url);
		}
	}
	// handle back button
	function gotoPrevTab(tabIndex) {
		tabIndex = tabIndex - 1;
		let prevTab = operationTabsItems[tabIndex];
		if ((prevTab?.disabled || !prevTab?.url)) {
			gotoPrevTab(tabIndex);
		}
		else {
			setActiveTab(prevTab.url);
			setPrevActiveTab(operationTabsItems[currentTabIndex].url);
		}
	}

	useEffect(() => {
		if (id && !accountingStatement) {
			fetchAccountingView();
		}
	}, [id]);

	// save new trip prices
	async function saveArrivalPricing() {
		const data = {
			buying_adult_price:
				+accountingStatement.trip.pricing?.buying_adult_price || 0,
			buying_child_price:
				+accountingStatement.trip?.pricing?.buying_child_price || 0,
			buying_infant_price:
				+accountingStatement.trip?.pricing?.buying_infant_price || 0,
			selling_adult_price:
				+accountingStatement.trip?.pricing?.selling_adult_price || 0,
			selling_child_price:
				+accountingStatement.trip?.pricing?.selling_child_price || 0,
			selling_infant_price:
				+accountingStatement.trip?.pricing?.selling_infant_price || 0,
		};
		return await updateArrivalPricing(id, data);
	}

	// save new tranportation prices
	async function saveTransportationPricing() {
		const data = {
			buying_price: +accountingStatement?.transportation?.pricing?.buying_price,
			selling_price:
				+accountingStatement?.transportation?.pricing?.selling_price,
		};
		return await updateTransportationPricing(id, data);
	}
	// save new attrations prices
	async function saveAttractionPricing() {
		let data = {
			attraction_pricing_type: attractionPricingType,
		};
		// check attraction price type
		if (attractionPricingType === "all") {
			data = {
				...data,
				pricing: {
					buying_price:
						+accountingStatement?.per_all_attraction_pricing?.buying_price || 0,
					selling_price:
						+accountingStatement?.per_all_attraction_pricing?.selling_price || 0,
				},
			};
		} else {
			data = {
				...data,
				attractions: accountingStatement?.attractions?.map((attraction) => {
					return {
						attraction_id: attraction?.id,
						buying_price: +attraction?.pricing?.buying_price || 0,
						selling_price: +attraction?.pricing?.selling_price || 0,
					};
				}),
			};
		}
		return await updateAttractionsPricing(id, data);
	}

	// save new attrations prices
	async function saveVisaPricing() {
		let data = {
			visas: accountingStatement?.visas?.map((visa) => {
				return {
					visa_id: visa?.id,
					buying_price:
						visa?.pricing?.buying_price > 0
							? visa?.pricing?.buying_price
							: null,
					selling_price:
						visa?.pricing?.selling_price > 0
							? visa?.pricing?.selling_price
							: null,
				};
			}),
		};
		return await updateVisaPricing(id, data);
	}

	// save new attrations prices
	async function saveOtherServicesPricing() {
		let data = {
			services: accountingStatement?.other_services?.map((service) => {
				return {
					service_id: service?.id,
					buying_price:
						service?.pricing?.buying_price > 0
							? service?.pricing?.buying_price
							: null,
					selling_price:
						service?.pricing?.selling_price > 0
							? service?.pricing?.selling_price
							: null,
				};
			}),
		};
		return await updateOhterServicesPricing(id, data);
	}
	//Save new hotel prices
	async function saveHotelPricing() {
		let data = {
			hotels: accountingStatement?.hotels?.map((hotel) => {
				return {
					hotel_id: hotel.id,
					rooms: hotel?.rooms?.map((item) => ({
						room_type: item.room_type,
						buying_price: item?.buying_price !== "" ? item?.buying_price : 0,
						selling_price: item?.selling_price !== "" ? item?.selling_price : 0,
					})),
				};
			}),
		};
		return await updateHotelPricing(id, data);
	}
	//Save new Catering prices
	async function saveCateringPricing() {
		let data = {
			catering: accountingStatement?.catering?.map((cater) => {
				return cater?.catering?.map((item) => {
					if (item?.catering?.length !== 0) {
						return {
							catering_id: item.id,
							buying_price: item?.pricing?.buying_price,
							selling_price: item?.pricing?.selling_price,
						};
					}
				});
			}),
		};
		return await updateCateringPricing(id, {
			catering: data.catering
				.flat(Infinity)
				?.filter((item) => item && (item?.buying_price || item?.selling_price)),
		});
	}

	// update pricing item based on active tab
	async function updatePricing(tab, isPriceChanged) {
		let res;
		let message = operationStatement.msgSegment;
		if (isPricePerService) {
			if (tab === "accounting-arrival-departure" && isPriceChanged) {
				res = await saveArrivalPricing();
			} else if (tab === "accounting-transportation" && isPriceChanged) {
				res = await saveTransportationPricing();
			} else if (tab === "accounting-attraction" && isPriceChanged) {
				res = await saveAttractionPricing();
			} else if (tab === "accounting-visa" && isPriceChanged) {
				res = await saveVisaPricing();
			} else if (tab === "accounting-other" && isPriceChanged) {
				res = await saveOtherServicesPricing();
			} else if (tab === "accounting-hotels" && isPriceChanged) {
				res = await saveHotelPricing();
			} else if (tab === "accounting-catering" && isPriceChanged) {
				res = await saveCateringPricing();
			}
		} else {
			// update price per package
			message = operationStatement.msgPriceUpdates;
		}
		if (res?.status === 200) {
			handleResponesNotification({ alertType: "success", message, title: "" });
			// fetchAccountingView();
		}
	}

	// reset all priceing when change pricing type
	async function changePricingType() {
		const data = {
			pricing_type: isPricePerService ? "package" : "service",
		};
		const res = await updatePricingType(id, data);
		if (res?.status === 200) {
			setConfirmationModalIsOpen(false);
			fetchAccountingView();
			setPricingType(data.pricing_type);
			handleResponesNotification(
				"success",
				"pricing type changed successfully"
			);
		}
	}

	// save full package pricing
	async function updateFullPackagePricing() {
		const data = {
			buying_price: accountingStatement?.total_package_buying,
			selling_price: accountingStatement?.total_package_selling,
			with_taxes: accountingStatement?.with_taxes ? 1 : 0,
		};
		const res = await saveFullPackagePricing(id, data);
		if (res?.status === 200) {
			const formatAccountingData = formatViewAccounting(res?.data?.data);
			setAccountingStatement(formatAccountingData);
			handleResponesNotification({ alertType: "success", message: "Price updated successfully", title: "" });
		}
	}

	async function applyTaxesOnSelling() {
		const data = {
			with_tax: !accountingStatement?.with_taxes ? 1 : accountingStatement?.with_taxes ? 0 : !accountingStatement?.with_taxes,
		}
		const res = await updateTaxType(id, data);
		if (res?.status === 200) {
			setAccountingStatement({ ...accountingStatement, with_taxes: !accountingStatement?.with_taxes });
			handleResponesNotification({ alertType: "success", message: res?.data?.message, title: "" });

		}
	}

	// function calcService
	const totalPrice = useMemo(() => {
		// get all buying and selling amounts from pricing data;
		if (accountingStatement) {
			const { totalBuying, totalSelling } = calculateAllService(
				accountingStatement,
				isPricePerService
			);
			return { totalBuying, totalSelling };
		}
		return { totalBuying: 0, totalSelling: 0 };
	}, [accountingStatement, isPricePerService]);


	function handleAutoSave(isPriceChanged) {
		// trip
		if (prevActiveTab === "accounting-arrival-departure") {
			isPriceChanged =
				JSON.stringify(accountingStatement?.trip?.pricing) !==
				JSON.stringify(prevTabRef.current?.trip?.pricing);
		}

		// hotels
		else if (prevActiveTab === "accounting-hotels") {
			isPriceChanged =
				JSON.stringify(accountingStatement?.hotels) !==
				JSON.stringify(prevTabRef.current?.hotels);
		}
		// catraing
		else if (prevActiveTab === "accounting-catering") {
			isPriceChanged =
				JSON.stringify(accountingStatement?.catering) !==
				JSON.stringify(prevTabRef.current?.catering);
		}
		// transportation
		else if (prevActiveTab === "accounting-transportation") {
			isPriceChanged =
				JSON.stringify(accountingStatement?.transportation?.pricing) !==
				JSON.stringify(prevTabRef.current?.transportation?.pricing);
		}
		// attraction price per one
		else if (
			prevActiveTab === "accounting-attraction" &&
			accountingStatement?.attraction_pricing === "one"
		) {
			isPriceChanged = JSON.stringify(accountingStatement?.attractions) !== JSON.stringify(prevTabRef.current?.attractions) ||
				JSON.stringify(accountingStatement?.attraction_pricing) !== JSON.stringify(prevTabRef.current?.attraction_pricing)
		}
		// attraction price per alll
		else if (
			prevActiveTab === "accounting-attraction" &&
			accountingStatement?.attraction_pricing === "all"
		) {
			isPriceChanged = JSON.stringify(accountingStatement?.per_all_attraction_pricing) !== JSON.stringify(prevTabRef.current?.per_all_attraction_pricing) ||
				JSON.stringify(accountingStatement?.attraction_pricing) !== JSON.stringify(prevTabRef.current?.attraction_pricing)
		}
		// visas
		else if (prevActiveTab === "accounting-visa") {
			isPriceChanged =
				JSON.stringify(accountingStatement?.visas) !==
				JSON.stringify(prevTabRef.current?.visas);
		}
		// other services
		else if (prevActiveTab === "accounting-other") {
			isPriceChanged =
				JSON.stringify(accountingStatement?.other_services) !==
				JSON.stringify(prevTabRef.current?.other_services);
		}

		updatePricing(prevActiveTab, isPriceChanged);
	}
	// handle auto save when change tabs
	useEffect(() => {
		let isPriceChanged;
		handleAutoSave(isPriceChanged);
		// assign prev values
		prevTabRef.current = accountingStatement;
	}, [activeTab]);

	return (
		<ShowSubscription module="operation_accounting">
			{accountingStatement ? (
				<div className="operation-statement-view px-new-26 operation-accounting-edit pb-4">
					<BreadCrump title={operationStatement.accounting} />

					<div className="container-fluid ">
						<OperationStatementHeader
							OperationStatement={accountingStatement}
						/>

						{/* pricing type */}
						<div className="radio-group-container pricing-type">
							<h2 className="header">{operationStatement.PricingType}</h2>
							<div className="d-flex flex-wrap align-items-center">
								<div className="item">
									<input
										type="radio"
										id={`price-per-${pricePerServiceID}`}
										name={`price-per-${pricePerServiceID}`}
										checked={pricingType === pricePerServiceID}
										onChange={() => changePricingType()}
										disabled={!canEdit}
									/>
									<span className="checkmark"></span>
									<label htmlFor={`price-per-${pricePerServiceID}`}>
										{operationStatement.PricingPerService}
									</label>
								</div>

								<div className="item">
									<input
										type="radio"
										id={`price-per-${pricePerPackageID}`}
										name={`price-per-${pricePerPackageID}`}
										checked={pricingType === pricePerPackageID}
										onChange={() => setConfirmationModalIsOpen(true)}
										disabled={!canEdit}
									/>
									<span className="checkmark"></span>
									<label htmlFor={`price-per-${pricePerPackageID}`}>
										{operationStatement.PricingPerPackage}
									</label>
								</div>
							</div>
						</div>

						<div className="operating-view-container my-4 px-new-26 w-100">
							{/* back and next buttons */}
							<div className="w-100 d-flex justify-content-between align-items-center flex-wrap my-3">
								<h3 className="operating-title-view">
									{operationStatement.OperatingStatement}
								</h3>
								<div className="d-flex align-content-center">
									{/* back button */}
									<button
										className={`operating-btn-back mx-1`}
										onClick={() => gotoPrevTab(currentTabIndex)}
										disabled={
											currentTabIndex === 0 ||
											operationTabsItems.findIndex((tab) => tab && !tab?.disabled) === currentTabIndex ||
											operationTabsItems.every(tab => !tab || tab?.disabled)
										}
									>
										{locale === "en" ? (
											<i className="fas fa-arrow-left mx-2"></i>
										) : (
											<i className="fas fa-arrow-right mx-2"></i>
										)}
										{companyDetails.back}
									</button>
									{/* next button */}
									<button
										className={`operating-btn-next mx-1`}
										onClick={() => gotoNextTab(currentTabIndex)}
										disabled={
											currentTabIndex === 6 ||
											operationTabsItems.findLastIndex((tab) => tab && !tab?.disabled) === currentTabIndex ||
											operationTabsItems.every(tab => !tab || tab?.disabled)
										}
									>
										{companyDetails.next}
										{locale === "en" ? (
											<i className="fas fa-arrow-right mx-2"></i>
										) : (
											<i className="fas fa-arrow-left mx-2"></i>
										)}
									</button>
								</div>
							</div>

							{/* accounting tabs */}
							<OperationTabs
								tabs={operationTabsItems}
								activeTab={activeTab}
								IsNotPushUrl={true}
								setActiveTab={setActiveTab}
								setPrevActiveTab={setPrevActiveTab}
								classNames=" col-md-12 col-12  mx-auto"
							/>

							<div className="mt-4 w-100">
								{activeTab === "accounting-arrival-departure" ? (
									<AccountingArrivalDeparture
										isPricePerService={isPricePerService}
										accountingStatement={accountingStatement}
										setAccountingStatement={setAccountingStatement}
										canEdit={canEdit}
									/>
								) : null}
								{activeTab === "accounting-attraction" ? (
									<AccountingAttractions
										isPricePerService={isPricePerService}
										accountingStatement={accountingStatement}
										setAccountingStatement={setAccountingStatement}
										attractionPricingType={attractionPricingType}
										canEdit={canEdit}
									/>
								) : null}
								{activeTab === "accounting-transportation" ? (
									<AccountingTransportation
										isPricePerService={isPricePerService}
										accountingStatement={accountingStatement}
										setAccountingStatement={setAccountingStatement}
										canEdit={canEdit}
									/>
								) : null}
								{activeTab === "accounting-visa" ? (
									<AccountingVisa
										isPricePerService={isPricePerService}
										accountingStatement={accountingStatement}
										setAccountingStatement={setAccountingStatement}
										canEdit={canEdit}
									/>
								) : null}
								{activeTab === "accounting-other" ? (
									<AccountingOtherservices
										isPricePerService={isPricePerService}
										accountingStatement={accountingStatement}
										setAccountingStatement={setAccountingStatement}
										canEdit={canEdit}
									/>
								) : null}

								{activeTab === "accounting-hotels" ? (
									<AccountingHotels
										isPricePerService={isPricePerService}
										accountingStatement={accountingStatement}
										setAccountingStatement={setAccountingStatement}
										canEdit={canEdit}
									/>
								) : null}
								{activeTab === "accounting-catering" ? (
									<AccountingCatering
										isPricePerService={isPricePerService}
										accountingStatement={accountingStatement}
										setAccountingStatement={setAccountingStatement}
										canEdit={canEdit}
									/>
								) : null}
							</div>
						</div>

						<AccountingTotalPricing
							isPricePerService={isPricePerService}
							totalPrice={totalPrice}
							accountingStatement={accountingStatement}
							setAccountingStatement={setAccountingStatement}
							applyTaxesOnSelling={applyTaxesOnSelling}
							canEdit={canEdit}
						/>

						<div className="d-flex justify-content-between mt-4">
							<button
								className="btn close-btn"
								onClick={() => history.push("/umrah-operations/accounting")}
							>
								<CloseIcon />
								<span className="mx-2">{operationStatement.Close}</span>
							</button>

							<button
								className="btn save-btn"
								onClick={() =>
									isPricePerService
										? updatePricing(activeTab, true)
										: updateFullPackagePricing()
								}
								disabled={!canEdit}
							>
								<SaveIcon />
								<span>{operationStatement.Save}</span>
							</button>
						</div>

						{confirmationModalIsOpen ? (
							<ChangePricingConfirmationModal
								isOpen={confirmationModalIsOpen}
								toggle={toggleConfirmationModal}
								confirmFn={changePricingType}
							/>
						) : null}
					</div>
				</div>
			) : null}
		</ShowSubscription>
	)
}
