import { useSBSState } from 'context/global';
import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AlertCircleIcon } from 'modules/UmrahOperations/shared/Icons'
import { useHistory } from 'react-router-dom';
import Locale from 'translations';

export default function ShowSubscription(props) {
  const history = useHistory();
  const {subscriptionModule}=Locale
  const { subscriptionPlan } = useSBSState();
  if (subscriptionPlan) {
    if (subscriptionPlan?.includes(props.module) && subscriptionPlan.length > 0) {
      return (
        <div>
          <React.Fragment>{props.children}</React.Fragment>
        </div>
      )
    } else {
      return (
        <Modal className="confirm-theme-modal" isOpen={true} centered>
          <ModalHeader toggle={() => history.push("/market-place-new")}></ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column gap-10 align-items-start">
              <span className="icon mb-2">
                <AlertCircleIcon color="#D92D20" />
              </span>
              <h6 className="header">{subscriptionModule.UpgradePlan}</h6>
              <p className="info-text">{subscriptionModule.subscriptionMsg} </p>
            </div>
            <div className="d-flex gap-10 mt-4">
              <a href="https://bit.ly/3yENkbJ" className="btn reject-btn" target="_blank" rel="noopener noreferrer">
                {subscriptionModule.liveChat}
                </a>
              {/* <button className="btn reject-btn" onClick={() => history.push("/market-place-new")}></button> */}
              <button className="btn confirm-btn btn-nxt" onClick={() => history.push("/contact-us")}>{subscriptionModule.ContactUS}</button>
            </div>
          </ModalBody>
        </Modal>
      )
    }
  } else {
    return null
  }
}
