import AuthRoute from "components/Routes/AuthRoute";
import LandPage from 'modules/LandPage';
// import SafaRegistration from 'modules/auth/pages/SafaRegistration';
import Activation from "modules/auth/pages/Activation";
import PasswordChangeSucsses from "modules/auth/pages/PasswordChangeSucsses";
// import SelectRegisterType from 'modules/auth/pages/SelectRegisterType';
import NewLanding from "modules/newLanding";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import LinkSafaAccount from 'modules/auth/pages/LinkSafaAccount';
import SafaRegisterationSucceed from 'modules/auth/pages/SafaRegistration/SafaRegisterationSucceed';
import Otp from 'modules/auth/pages/Otp';
import NewLandingApps from 'modules/apps/LandingPageApp';
import PrivacyPolicy from 'modules/auth/pages/PrivacyPolicy';
import ContactUsPage from 'modules/ContactUs';
// import AboutUs from 'modules/AboutUs';
import AboutUs from 'modules/about-us';
import SurveyPage from 'components/Surveypage';
import ErrorPreLogin from 'components/Errors/Error404/ErrorPreLogin';
import SinglePromo from 'modules/Promo/Hotels/Components/SinglePromo';
import BestDealsPage from 'modules/Promo/Hotels/BestDeals/main';
import MarketPlaceRoute from 'components/Routes/MarketPlaceRoute';
// import LoginTwo from 'modules/auth/pages/LoginTwo';
import TDSRouter from 'components/Routes/TDSRouter';
// import TDSVisaReservation from 'modules/TDSVisa/Reservation';
import PassangerProfile from 'modules/TDSVisa/Reservation/PassangerProfile';
import EditPassangerProfile from 'modules/TDSVisa/Reservation/EditPassangerProfile';
import TDSVisaReservation from 'modules/TDSVisa/Reservation';
import TDSPackagesList from 'modules/TDSVisa/PackagesList';
import TDSVisaInfo from 'modules/TDSVisa/VisaInfo';
import PaymentAction from 'modules/payment/paymentAction';
import PaymentHyperpay from 'modules/payment/PaymentHyperpay';
// import PassangerProfile from 'modules/TDSVisa/Reservation/PassangerProfile';
// import EditPassangerProfile from 'modules/TDSVisa/Reservation/EditPassangerProfile';
// import TDSRouter from 'components/Routes/TDSRouter';
import WebBuilderRoute from 'components/Routes/WebBuilderRoute';
import PreviewServices from 'modules/WebBuilder/PreviewServices';
import TDSVisaIntro from 'modules/TDSVisa/PackagesList/Intro';
import PublicOperationStatementView from 'modules/UmrahOperations/OperationStatement/View/PublicOperationStatementView';
// import ErrorPreLogin from 'components/Errors/Error404/ErrorPreLogin';

import WebBuilder from 'modules/WebBuilder';
import PackageSearch from 'modules/WebBuilder-V2/PackageSearch';
import WebBuilderV2 from 'modules/WebBuilder-V2';
import PreviewServicesV2 from 'modules/WebBuilder-V2/Pages/PreviewServices';
import MadinaConferenceRegistration from 'modules/MadinaConferenceRegistration';
import RegistrationV2 from 'modules/auth/Auth-v2/Registration';
import LoginV2 from 'modules/auth/Auth-v2/Login';
import LoginWithSafaV2 from 'modules/auth/Auth-v2/LoginWithSafa';
import InstantSafaLogin from 'modules/auth/Auth-v2/InstantSafaLogin';
import ResetOfficerPassword from 'modules/auth/Auth-v2/ResetOfficerPassword';
import ResetOfficerPasswordSuccessPage from 'modules/UmrahOperations/ResetOfficerPasswordSuccessPage';
// import List from "modules/market-place/HotelResponse/HotelList";
// import HotelListMap from "modules/market-place/HotelResponse/HotelList/HotelListMap";
//import Details from "modules/market-place/HotelResponse/Details";
//import UmrahHolidaysHotel from "modules/market-place/HotelResponse/Summary/Umrah-holidays-hotel";

// Lazy Components
// const Error404 = lazy(() => import("./components/Errors/Error404/Error404"));
// const Login = lazy(() => import("./modules/auth/pages/Login"));
// const LoginWithSafa = lazy(() => import("./modules/auth/pages/LoginWithSafa"));
const ForgotPassword = lazy(() =>
	import("./modules/auth/pages/ForgotPassword")
);
const ForgotPasswordSubmitted = lazy(() =>
	import("./modules/auth/pages/ForgotPasswordSubmitted")
);
const ResetPassword = lazy(() => import("./modules/auth/pages/ResetPassword"));
const RegistrationSucceed = lazy(() =>
	import("modules/auth/pages/RegistrationSucceed")
);
// const Register = lazy(() => import("modules/auth/pages/Register"));
const marketplaceGist = lazy(() => import("modules/market-place/HotelResponse/HotelList"));

const HotelListMap = lazy(() => import("modules/market-place/HotelResponse/HotelList/HotelListMap"));
const Details = lazy(() => import("modules/market-place/HotelResponse/Details"));
const UmrahHolidaysHotel = lazy(() => import("modules/market-place/HotelResponse/Summary/Umrah-holidays-hotel"));



// Application Routes
const preLoginRoutes = (
	<Switch>
		{/* ****************** Start Auth Route ******************** */}

		{/* Login Module */}
		<AuthRoute path="/" exact component={LoginV2} />
		<TDSRouter 	path="/tds/visa-reservation/:status/:id"
			exact
			component={TDSVisaReservation} />
					<TDSRouter
			path="/tds-visa/Add/Passenger/manual"
			component={PassangerProfile}
		/>
		<TDSRouter
			path="/tds-visa/Add/Passenger"
			component={PassangerProfile}
		/>

		<TDSRouter
			path="/tds-visa/Edit/Passenger"
			component={EditPassangerProfile}
		/>
		<AuthRoute path="/promo" exact component={SinglePromo} />
		<AuthRoute path="/about-us" exact component={AboutUs} />
		<AuthRoute path="/PrivacyPolicy" exact component={PrivacyPolicy} />
		<AuthRoute path="/contact-us" exact component={ContactUsPage} />
		<AuthRoute path="/about-us" exact component={AboutUs} />

		{/* <AuthRoute path="/auth/login" exact component={Login} /> */}
		<AuthRoute path="/auth/otp" exact component={Otp} />
		{/* <AuthRoute path="/auth/login_safa" exact component={LoginWithSafa} /> */}
		<AuthRoute path="/public/umrah-operations/:id" component={PublicOperationStatementView} />


		{/* <AuthRoute
			path="/auth/select-register-type"
			exact
			component={SelectRegisterType}
		/> */}
		{/* <AuthRoute path="/auth/register" exact component={Register} /> */}
		{/* <AuthRoute path="/auth/register_safa" exact component={SafaRegistration} /> */}
		<AuthRoute
			path="/auth/link_safa_account"
			exact
			component={LinkSafaAccount}
		/>
		<AuthRoute
			path="/auth/register/success"
			exact
			component={RegistrationSucceed}
		/>
		<AuthRoute
			path="/auth/register_safa/success"
			exact
			component={SafaRegisterationSucceed}
		/>
		<AuthRoute path="/auth/forgot_password" exact component={ForgotPassword} />
		<AuthRoute
			path="/auth/forgot_password_submitted"
			exact
			component={ForgotPasswordSubmitted}
		/>
		<AuthRoute
			path="/auth/reset_password/:token/:email"
			exact
			component={ResetPassword}
		/>
		{/* auth routes v2  */}
		<AuthRoute path="/auth/register" exact component={RegistrationV2} />
		<AuthRoute path="/auth/login" exact component={LoginV2} />
		<AuthRoute path="/auth/login_safa" exact component={LoginWithSafaV2} />
		<AuthRoute path="/auth/instant_login_safa" exact component={InstantSafaLogin} />
		<AuthRoute path="/auth/reset_officer_password" exact component={ResetOfficerPassword} />
		<AuthRoute path="/auth/officer-password-changed/successfully" exact component={ResetOfficerPasswordSuccessPage} />



		<AuthRoute path="/sbs/welcome" exact component={LandPage} />
		<AuthRoute path="/new-landing" exact component={NewLanding} />
		<AuthRoute path="/landing-apps" exact component={NewLandingApps} />
		<AuthRoute path="/promo/:type" exact component={BestDealsPage} />
		<AuthRoute path="/PayOnline/:tap" component={PaymentAction} />

		<AuthRoute path="/PayOnline" component={PaymentAction} />
		<AuthRoute path="/hyperpay" component={PaymentHyperpay} />
		<AuthRoute
			path="/auth/passwordChanged/successfully"
			exact
			component={PasswordChangeSucsses}
		/>
		<Route path="/auth/activation/:token/:email" component={Activation} />
		<Route path="/survey-page" component={SurveyPage} />
		<AuthRoute path="/auth/activation/resend" component={RegistrationSucceed} />
		<AuthRoute
			path="/auth/register/success"
			exact
			component={RegistrationSucceed}
		/>
		<MarketPlaceRoute path="/market-view" component={marketplaceGist} />
		<MarketPlaceRoute path="/market-view-map" component={HotelListMap} />
		<MarketPlaceRoute path="/market-details/:id" component={Details} />

		<MarketPlaceRoute
			path="/Umrah/confirm-hotel"
			component={UmrahHolidaysHotel}
		/>
		<Route path="/tds-visa-packages" component={TDSPackagesList} />
		<Route path="/tds-visa/details" component={TDSVisaInfo} />
		<Route path="/tds-visa" component={TDSVisaIntro} />
		{/* <WebBuilderRoute  path="/public-page" component={WebBuilder} /> */}

		{/* version 1 */}
		<WebBuilderRoute exact path="/public-page/:name" component={WebBuilder} />
		<WebBuilderRoute path="/web-v1/:name/:service/:id" component={PreviewServices} />


		{/* version 2 */}
		<WebBuilderRoute exact path="/public-page-v2/:name" component={WebBuilderV2} />
		<WebBuilderRoute path="/web-v2/:name/:service/:id" component={PreviewServicesV2} />
		<WebBuilderRoute path='/web-v2/:name/packages-results' component={PackageSearch} />
		<WebBuilderRoute path='/web-v2/:name/:pageName' exact component={WebBuilderV2} />


		<Route path="/2023-stats" component={MadinaConferenceRegistration} />

		{/* ****************** End Auth Route ******************** */}

		{/* UI Static Routes */}

		<Route path="*" component={ErrorPreLogin} />
	</Switch>
);

export default preLoginRoutes;
