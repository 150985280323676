import React, { useState } from "react";
import folder from "assets/images/MarketInvoice/Folder.png";
import Locale from "translations";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MRZ from "./MRZ";
import passport_1 from "assets/images/visa/passport-1.png";
import passport_2 from "assets/images/visa/passport-2.png";
import passport_3 from "assets/images/visa/passport-3.png";
import passport_4 from "assets/images/visa/passport-4.png";
import passport_5 from "assets/images/visa/passport-5.png";
import { ReactComponent as ErrorIcon } from "assets/images/visa/error.svg";
import { ReactComponent as CheckIcon } from "assets/images/visa/check.svg";



const START_ADD = "START_ADD";
const INSTRUCTIONS_1 = "INSTRUCTIONS_1";
const INSTRUCTIONS_2 = "INSTRUCTIONS_2";
const SELECT_MRZ = "SELECT_MRZ";

export default function AddNewPassenger({ checkedIds,manaul,setPassengerData }) {
	const { marketPlace, onlineVisa } = Locale;
	const history = useHistory();
	const [uploadPassportStep, setUploadPassportStep] = useState(manaul ? INSTRUCTIONS_1 :START_ADD);
	const [files, setFiles] = useState({});
	const [PassportPhoto, setPassportPhoto] = useState({});

	const startAdd = (
		<div className="text-center">
			<div className="visa-add-mrz mb-3">
				<p className="d-flex align-items-center">
					<img src={folder} alt="folder" />
					<span className="mx-2">{onlineVisa.getDataReadingMRZ}</span>
				</p>
				<button
					className="btn bg-nxt px-4 shadow-none"
					onClick={() => setUploadPassportStep(INSTRUCTIONS_1)}
				>
					{marketPlace.start}
				</button>
			</div>
			{onlineVisa.or}
			<div className="visa-add-manual mt-3">
				<button
					className="btn shadow-none"
					onClick={() => history.push("/Online-Visa/Add/Passenger")}
				>
					{onlineVisa.manualInput}
				</button>
			</div>
		</div>
	);

	const instructions_1 = (
		<div className="">
			<div className="w-100 text-center row mx-0">
				<div className="col-md-3 my-3">
					<div className="border rounded p-2 h-100 position-relative">
            <img alt="" src={passport_1} className='img-fluid h-100' style={{objectFit:"cover"}}/>
            <ErrorIcon className='position-absolute' style={{
              bottom:6,
              insetInlineEnd:6,
              height:18
            }} />
          </div>
				</div>
				<div className="col-md-3 my-3">
					<div className="border rounded p-2 h-100 position-relative">
          <img alt="" src={passport_2} className='img-fluid h-100' style={{objectFit:"cover"}}/>
          <ErrorIcon className='position-absolute' style={{
            bottom:6,
            insetInlineEnd:6,
            height:18
          }} />
            
          </div>
				</div>
				<div className="col-md-3 my-3">
					<div className="border rounded p-2 h-100 position-relative">
          <img alt="" src={passport_3} className='img-fluid h-100' style={{objectFit:"scale-down"}}/>
          <ErrorIcon className='position-absolute' style={{
            bottom:6,
            insetInlineEnd:6,
            height:18
          }} />
            
          </div>
				</div>
				<div className="col-md-3 my-3">
					<div className="border rounded p-2 h-100 position-relative">
          <img alt="" src={passport_4} className='img-fluid h-100' style={{objectFit:"contain"}}/>
          <CheckIcon className='position-absolute' style={{
            bottom:6,
            insetInlineEnd:6,
            height:18
          }} />
            
          </div>
				</div>

				<div className="text-center w-100">
					<button
						className="btn bg-nxt px-4 shadow-none"
						onClick={() => setUploadPassportStep(INSTRUCTIONS_2)}
					>
						{marketPlace.start}
					</button>
				</div>
			</div>
		</div>
	);
	const instructions_2 = (
		<div className="">
			<div className="w-100 text-center row mx-0">
				<div className="col-md-12 my-3">
          <div className='mrz-warning'>
          <i class="fas fa-exclamation-triangle orange-color me-1"></i>
						<span>{onlineVisa.pleaseSelectMRZArea}</span>
          </div>
					<div className="border rounded p-2 passport-image">
          <img alt="" src={passport_5} className="img-fluid passport-image" />

          </div>
				</div>


				<div className="text-center w-100">
					<button className="btn bg-nxt file-btn-mrz py-2 px-4 mx-2 my-2 text-uppercase">
						{onlineVisa.uploadPassport}
						<input
							type="file"
							className="custom-file-mrz"
							accept="image/png, image/gif, image/jpeg"
							onChange={(e) => {
								setPassportPhoto(window.URL.createObjectURL(e.target.files[0]));

								setFiles({
									...files,
									passport_image: e.target.files[0],
								});
                setUploadPassportStep(SELECT_MRZ)
							}}
							multiple
						/>
					</button>
				</div>
			</div>
		</div>
	);

	const select_MRZ = (
		<div>
			<MRZ
				checkedIds={checkedIds}
				files={files}
				setFiles={setFiles}
				PassportPhoto={PassportPhoto}
        setPassengerData={manaul ? setPassengerData : undefined}
			/>
		</div>
	);

	const mrzModalDetails = {
		START_ADD: startAdd,
		INSTRUCTIONS_1: instructions_1,
		INSTRUCTIONS_2: instructions_2,
		SELECT_MRZ: select_MRZ,
	};

	return mrzModalDetails[uploadPassportStep];
}
