import { useEffect, useMemo, useState } from "react";
import { TabPane } from "reactstrap";
import Locale from "translations";
import { changeRequestStatus, requestViewReservation } from "services/productbuilder";
import { useParams } from "react-router-dom";
import SummaryTab from "./SummaryTab";
import Listpax from "./paxDetails/ListPax";
import Listpayemnts from "./payments/ListPayments";
import ColLogs from "./CallLogs";
import History from "./History";
import PassangerModal from "./paxDetails/passangerModal";
import PaymentsModal from "./payments/paymentsModal";
import { store } from "react-notifications-component";
import ExtraService from "./ExtraService";
import { formatTravelers } from './helpers/formatPackagesTravelers';
import { calculatePackagePrice } from './helpers/calculatePackagePrice';
import RequestPackageItems from './PackageItems';
import ProductItinerary from 'modules/market-place/NewPackageResponse/Details/ProductItinerary';
import { formatProductItems } from 'modules/market-place/NewPackageResponse/helpers/formatServicesItems';
import { formatRoomsData } from 'modules/B2bRequests/RequestView/helpers/formatRoomsData';
import { useSBSState } from 'context/global';
import RequestGuestsPerPax from './GuestsPerPax';
import RequestsGuestsPerRoom from './GuestsPerRoom';


export default function RequestView() {
	const { payment, inventory, productsBuilder } = Locale;
	const { locale } = useSBSState()
	const { id } = useParams();
	const [activeTap, setActiveTap] = useState(1);
	const [activeTapSide, setActiveTapSide] = useState(1);
	const [reservationDetails, setReservationDetails] = useState(null);
	const [totalPrice, setTotalPrice] = useState();
	const [spasificPassenger, setSpasificPassenger] = useState({});
	const [Reload, setReload] = useState(false);
	const [allPaxArray, setAllPaxArray] = useState(null);
	const [modalPass, setmodalPass] = useState({
		isOpen: false,
		title: "add Passport",
	});
	const [modalPax, setmodalPax] = useState({
		isOpen: false,
		title: "add Payments",
	});
	const [roomsData, setRoomsData] = useState([
		{ roomType: null, roomCount: 0, childCount: 0, },
	]);
	const [productItemsPrices, setProductItemsPrices] = useState(null);
	
	const reservationType = reservationDetails?.reservation_type;
	const productPriceFullPackage = reservationDetails?.product?.product_price?.product_price_type?.id === 1;
	const productPriceBreakDown = reservationDetails?.product?.product_price?.product_price_type?.id === 0;


	const togglepax = () => {
		setmodalPax({ isOpen: !modalPax.isOpen });
	};

	const togglepass = (type = null, passanger) => {
		setmodalPass({ isOpen: !modalPass.isOpen, title: type });
		setSpasificPassenger(passanger);
	};


	async function getViewReservation() {
		// to can catch change Request id guest
		const viewReservationRes = await requestViewReservation(id);
		if (viewReservationRes?.status >= 200 && viewReservationRes?.status < 300) {
			// if reservation is private
			if (viewReservationRes?.data?.data?.reservation_category === "private") {
				const servicesList = formatProductItems(viewReservationRes?.data?.data?.product?.product_items, locale);
				let roomsList = formatRoomsData(viewReservationRes?.data?.data);
				roomsList = roomsList?.length > 0 ? roomsList : [{ roomType: null, roomCount: 0, childCount: 0, }]
				!productItemsPrices && setProductItemsPrices(servicesList);
				setRoomsData(roomsList);
				setReservationDetails(viewReservationRes?.data?.data);
				
			}
			// if reservation is shared
			else {
				const paxArray = formatTravelers(viewReservationRes?.data?.data);
				setAllPaxArray(paxArray);
			}
			setReservationDetails(viewReservationRes?.data?.data);
			setTotalPrice(viewReservationRes?.data?.data?.total_price);
		} else {
			// history.push('/products-builder/requests');
		}
	}


	useEffect(() => {
		if (id) {
			getViewReservation();
		}
	}, [id, Reload]);


	async function changePackageStatus(status) {
		const Data = {
			reservation_num: reservationDetails?.reservation_num,
			status: status,
		};
		const res = await changeRequestStatus(Data);

		if (res?.status === 200 || res?.status === 201) {
			store.addNotification({
				title: "info!",
				message: res?.massage,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					pauseOnHover: true,
				},
			});
			getViewReservation();
		}
	}

	// calculate Total package Price
	const calculateTotalPackagePrice = useMemo(() => {
		if (reservationDetails) {
			return calculatePackagePrice(
				reservationDetails,
				allPaxArray,
				productPriceFullPackage,
				productPriceBreakDown,
				productItemsPrices,
				reservationDetails?.reservation_category
			);
		} else {
			return 0
		}
	}, [allPaxArray, productItemsPrices, productPriceBreakDown, productPriceFullPackage, reservationDetails]);

	// handle calc total price of the package
	useEffect(() => {
		setTotalPrice(calculateTotalPackagePrice);
	}, [allPaxArray, calculateTotalPackagePrice]);


	return (
		<div className='package-details-container'>
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-12">
						<div>
							<div className="requestTaps">
								<TabPane
									className={`tap ${activeTap === 1 && `active`}`}
									onClick={() => {
										setActiveTap(1);
									}}
									tabId={1}
								>
									{payment.messages.summardetails}
								</TabPane>
								<TabPane
									className={`tap ${activeTap === 2 && `active`}`}
									onClick={() => {
										setActiveTap(2);
									}}
									tabId={2}
								>
									{payment.messages.paxdetails}
								</TabPane>
								{reservationDetails?.reservation_from === "online" &&
									reservationDetails?.reservation_type === "auto" ? null : (
									<TabPane
										className={`tap ${activeTap === 3 && `active`}`}
										onClick={() => {
											setActiveTap(3);
										}}
										tabId={3}
									>
										{payment.messages.Payments}
									</TabPane>
								)}
								{reservationDetails?.reservation_status === "pending" ? null : (
									<TabPane
										className={`tap ${activeTap === 4 && `active`}`}
										onClick={() => {
											setActiveTap(4);
										}}
										tabId={4}
									>
										{inventory.extraService}
									</TabPane>
								)}
							</div>
							{activeTap === 1 && reservationDetails && (
								<SummaryTab reservationDetails={reservationDetails} />
							)}
							{activeTap === 2 && (
								<Listpax
									togglepass={togglepass}
									setReload={setReload}
									Reload={Reload}
									reservationDetails={reservationDetails}
								/>
							)}
							{activeTap === 3 && (
								<Listpayemnts
									toggle={togglepax}
									setReload={setReload}
									Reload={Reload}
									reservationDetails={reservationDetails}
								/>
							)}
							{activeTap === 4 && (
								<ExtraService
									setReload={setReload}
									Reload={Reload}
									reservationDetails={reservationDetails}
								/>
							)}
						</div>
						
						{reservationDetails && reservationDetails?.reservation_category === "shared" && (
							<RequestGuestsPerPax
								reservationDetails={reservationDetails}
								allPaxArray={allPaxArray}
								setAllPaxArray={setAllPaxArray}
								setReload={setReload}
								productPriceFullPackage={productPriceFullPackage}
								productPriceBreakDown={productPriceBreakDown}
							/>
						)}
						{reservationDetails && reservationDetails?.reservation_category === "private" && (
							<RequestsGuestsPerRoom
								reservationDetails={reservationDetails}
								roomsData={roomsData}
								setRoomsData={setRoomsData}
								productItemsPrices={productItemsPrices}
								setProductItemsPrices={setProductItemsPrices}
								setReload={setReload}
							/>
						)}
						{/* {guests && Object.keys(allLookupsBuilder).length > 0 ? (
							<GuestsBox
								reservationDetails={reservationDetails}
								reservationOffline={reservationOffline}
								reservationPaid={reservationPaid}
								guests={guests}
								setGuests={setGuests}
								activeTap={activeTap}
								setTotalPrice={setTotalPrice}
								getViewReservation={getViewReservation}
								allLookupsBuilder={allLookupsBuilder}
							/>
						) : null} */}

						{reservationDetails ?
							<>
								<div className='package-item' id="tab-101">
									<h2 className='package-title'>{productsBuilder.itinerary}</h2>
									<ProductItinerary itineraryData={reservationDetails?.product_itinerary} />
								</div>
								<RequestPackageItems reservationDetails={reservationDetails} />
							</>
							:
							null
						}



						{/* accept or reject application */}
						<div className="d-flex justify-content-between align-items-center my-4">
							<div style={{ fontSize: "18px" }}>
								<p>{inventory.messages.TotalPackagePrice}</p>
								<p style={{ color: "#10A711" }}>
									<strong style={{ fontWeight: "bold" }}>
										{totalPrice} {reservationDetails?.currency}
									</strong>
									/ {reservationDetails?.nights} {inventory.messages.nights}
								</p>
							</div>
							{reservationType === "manual" &&
								reservationDetails?.reservation_status === "pending" &&
								reservationDetails?.reservation_from === "online" ? (
								<div>
									<button
										className="btn btn-guests me-2"
										style={{ color: "#fff", backgroundColor: "#C30101" }}
										onClick={() => changePackageStatus("reject")}
									>
										{inventory.messages.reject}
									</button>
									<button
										style={{ color: "#fff", backgroundColor: "#027B30" }}
										className="btn btn-guests"
										onClick={() => changePackageStatus("accept")}
									>
										{inventory.messages.accept}
									</button>
								</div>
							) : null}
						</div>
					</div>
					{/* calls log and history tabs */}
					<div className="col-md-4 col-12 ">
						<div className="side-col w-100">
							<div className="requestTaps">
								<TabPane
									className={`tap ${activeTapSide === 1 && `active`}`}
									onClick={() => {
										setActiveTapSide(1);
									}}
									tabId={1}
								>
									{inventory.messages.CallsLog}
								</TabPane>
								<TabPane
									className={`tap ${activeTapSide === 2 && `active`}`}
									onClick={() => {
										setActiveTapSide(2);
									}}
									tabId={2}
								>
									{inventory.messages.History}
								</TabPane>
							</div>
							{activeTapSide === 1 && reservationDetails && (
								<ColLogs
									reservationDetails={reservationDetails}
									getViewReservation={getViewReservation}
								/>
							)}
							{activeTapSide === 2 && <History />}
						</div>

						{/* <div className="d-flex justify-content-between">
							<button
								className="btn btn btn-color text-white  px-3 my-3"
								onClick={dawnloadApplication}
							>
								{inventory.ApplicationReport}
							</button>

							<button
								className="btn btn btn-color text-white  px-3 my-3"
								onClick={dawnloadApplicationReservation}
							>
								{inventory.InitialReservation}
							</button>
						</div> */}
					</div>
				</div>
				{/* add passport modal */}
				{spasificPassenger ? (
					<PassangerModal
						modalPass={modalPass}
						toggle={togglepass}
						setReload={setReload}
						Reload={Reload}
						reservationDetails={reservationDetails}
						spasificPassenger={spasificPassenger}
					/>
				) : null}

				{/* add payments modal */}
				<PaymentsModal
					modal={modalPax}
					toggle={togglepax}
					setReload={setReload}
					Reload={Reload}
					reservationDetails={reservationDetails}
				/>
			</div>
		</div>
	);
}
