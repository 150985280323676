import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL + "/v1";

export const subscriptionList = async () => {
	return await axios
		.post(`${baseURL}/safavisa/subscription-packages`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const paySubscription = async (data) => {
	return await axios
		.post(`${baseURL}/safavisa/pay-package-subscription`,data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const FetchsubscriptionPackage = async () => {
	return await axios
		.get(`${baseURL}/subscription-package`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const fetchsubscriptionActivePlan = async () => {
	return await axios
		.get(`${baseURL}/subscription-plan/active-plan`)
		.then((res) => res)
		.catch((err) => err.response)
}