import { useEffect, useRef, useState } from "react";
import { Modal } from "reactstrap";
import Locale from "translations";
import BannerInfo from "./BannerInfo";
import FlightItem from "./FlightItem";
import SideFilghtFilter from "./SideFilghFilter";
import SortFilghtFilter from "./SortFilter";
import SearchFilghtForm from "../../Search/FlightsForm/SearchFilghtForm";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import moment from "moment";
import { useSBSState } from "context/global";
import { fetchFlights, getFlightsFiltersLookup } from "services/marketplace";
import { formatSearchData } from "../helpers/formatSearchData";
import Pagination from "components/Pagination";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import _ from "lodash";

export default function Inbound() {
	const { inventory, marketPlace, messages } = Locale;

	const [isopenmodel, setisopenmodel] = useState(false);
	const modeltoggel = () => {
		setisopenmodel(!isopenmodel);
	};

	const [filtersLookup, setfiltersLookup] = useState({});

	const dispatch = useMarketplaceDispatch();

	const previousValue = useRef({
		transferCount: [],
		stops: [],
		airLines: [],
		departureTime: {},
		arrivalTime: {},
		durationTime: {},
		sortBy: "price",
		sortType: "desc",
		page: 1,
	});

	const [filters, setFilters] = useState({
		transferCount: [],
		stops: [],
		airLines: [],
		departureTime: {},
		arrivalTime: {},
		durationTime: {},
		sortBy: "price",
		sortType: "desc",
		page: 1,
	});
	const { locale } = useSBSState();
	const { outboundFlight, flightsSearch, inboundFlightsSearchResults } =
		useMarketplaceState();
	const history = useHistory();
	function goTo(page) {
		setFilters((prev) => ({ ...prev, page }));
	}

	const handleSearch = async (filters = {}, page) => {
		const filter = { ...filters, solutionKey: outboundFlight.solutionKey };

		let data = formatSearchData(flightsSearch, filter);
		const response = await fetchFlights(data, page);
		if (response.status === 200) {
			const data = response.data;

			if (response.data.data.length > 0) {
				dispatch({
					type: "inboundFlightsSearchResults",
					payload: data,
				});
			} else {
				store.addNotification({
					title: messages.noResults,
					message: messages.noSearchResults,
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
				});
			}
		}
	};

	useEffect(() => {
		if (inboundFlightsSearchResults === undefined) {
			handleSearch();
		}
	}, [flightsSearch]);

	useEffect(() => {
		if (
			inboundFlightsSearchResults &&
			!_.isEqual(filters, previousValue.current)
		) {
			handleSearch(filters);
			previousValue.current = filters;
		}
	}, [filters]);

	useEffect(() => {
		async function fetchFiltersLookup() {
			const sideFilters = await getFlightsFiltersLookup(
				flightsSearch?.cacheKey,
				outboundFlight?.solutionKey
			);
			
			if (sideFilters?.status === 200) {
				setfiltersLookup(sideFilters?.data?.lookups);
			}
		}
		flightsSearch?.cacheKey && fetchFiltersLookup();
	}, [flightsSearch?.cacheKey]);

	const segments = outboundFlight.journeys[0].segments;
	const segmentsCount = segments.length;

	const flightsNumbers = segments.reduce(
		(prev, current, index) =>
			prev + (index === 0 ? "" : " | ") + current.flightNum,
		""
	);

	const departureAirPort = segments[0];
	const arrivalAirPort = segments[segmentsCount - 1];

	const depart = `${moment(departureAirPort.departureDate).format("MMM D")} ${
		departureAirPort.departureTime
	}`;
	const arrive = `${moment(arrivalAirPort.arrivalDate).format("MMM D")} ${
		arrivalAirPort.arrivalTime
	}`;

	return (
		<>
			<BannerInfo modeltoggel={modeltoggel} />
			<div className="container">
				<div className="row info-outbound mb-4">
					<div className="type">
						<p>{inventory.messages.Outbound}</p>
					</div>
					<div className="FlightNo">
						<p className="bold">{inventory.messages.FlightNo}</p>
						<p>{flightsNumbers}</p>
					</div>
					<div className="BookingCode">
						<p className="bold">{inventory.messages.BookingCode}</p>
						<p>{segments[0].bookingCode}</p>
					</div>
					<div className="airports">
						<p cla>
							<span className="me-1 blue">
								{departureAirPort?.departure?.code}
							</span>
							{departureAirPort.departure.names[locale]}
						</p>
						<p>
							<span className="me-1 blue">{arrivalAirPort.arrival.code}</span>
							{arrivalAirPort?.arrival?.names[locale]}
						</p>
					</div>
					<div className="date">
						<div>
							<p>
								<strong className="bold">{inventory.messages.departure}</strong>
								：{depart}
							</p>
							<p>
								<strong className="bold">{inventory.messages.arrival}</strong>：
								{arrive}
							</p>
						</div>
						<div>
							<button
								className="btn"
								onClick={() => history.push("/outbound-view")}
							>
								{marketPlace.messages.change}
							</button>
						</div>
					</div>
				</div>
				<div className="row ">
					<div className="col-md-4 col-12">
						<SideFilghtFilter
							filtersLookup={filtersLookup}
							filters={filters}
							setFilters={setFilters}
						/>
					</div>
					<div className="col-md-8 col-12">
						<SortFilghtFilter
							title={inventory.messages.Inbound}
							filters={filters}
							setFilters={setFilters}
							flightCount={inboundFlightsSearchResults?.meta?.total}
						/>

						{inboundFlightsSearchResults?.data?.length > 0 &&
							inboundFlightsSearchResults?.data?.map((solution) =>
								solution.journeys.map((journey) => (
									<FlightItem
										journey={journey}
										solution={solution}
										searchResults={inboundFlightsSearchResults?.data}
										inbound
									/>
								))
							)}
						<div className="mt-4">
							<Pagination info={inboundFlightsSearchResults?.meta} goTo={goTo} />
						</div>	
					</div>
				</div>
			</div>
			<Modal isOpen={isopenmodel} toggle={modeltoggel}>
				<div className="d-flex align-items-center justify-content-between border-bottom mb-4 p-2 px-4 pt-0 w-100">
					<h3>Modify</h3>
					<div onClick={modeltoggel} className=" btn-x">
						<i class="fas fa-times"></i>
					</div>
				</div>
				<div className='flight-modify-container'>

				<SearchFilghtForm modify closeModal={modeltoggel} />
				</div>
			</Modal>
		</>
	);
}
