import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { useLocation, useParams, useHistory } from 'react-router-dom';


export default function OverLayServiceCard(props) {
  let { openEditModal, name, price, image, currency, stars, locationName, city, cardType, isNew, product_uuid, initialName } = props;
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const trimName = name ? name?.length > 16 ? `${name?.substr(0, 16)}...` : name : initialName
  const hotelRating = +stars > 5 ? 5 : +stars;
  const priceFormat = price ? new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(price) : 0;
  const preview = !location.pathname.includes("edit");

  return (
    <article className={`overlay-service-card ${isNew ? "empty-service" : ""}`} style={{ backgroundImage: image ? `url(${image})` : "" }} 
      onClick={() => {
        if (product_uuid) {
          if (preview) {
            if (params.name) {
              history.push(`/web-v2/${params.name}/${cardType}/${product_uuid}`);
            } else {
              history.push(`/web-builder-v2/preview/${cardType}/${product_uuid}`);
            }
          }
        }
      }}
      key={product_uuid}
    >
      {/* overlay */}
      {isNew && !preview ?
        <button className="btn add-service-btn" onClick={() => openEditModal({ ...props, index: props.index })}>
          <AddIcon />
        </button>
        :
        null
      }
      {/* hotel details */}
      <div className="card-details">
        <div className="d-flex flex-column">
          {cardType === "hotels" ?
            <div className="rate mb-2">
              {[...Array(5)].map((star, i) =>
                i < hotelRating ? <GoldenStarIcon key={i} /> : <StarIcon key={i} />
              )}
            </div>
            :
            null
          }
          
          <h4 title={name} className="hotel-name">{trimName}</h4>
          {cardType === "tours" ? 
            <div className="location">
              <LocationIcon />
              <span className="mx-1">{locationName}, {city}</span>
            </div>
          :
          null
          }
        </div>
        <div className="price">
          <span>From</span>
          <p className="amount">
            {priceFormat} {" "}
            <span className="currency">{currency || ""}</span>
          </p>
        </div>
      </div>
    </article>
  )
}