import React, { useState } from 'react'
import {
	CircleArrowRight,
	HotelIcon,
	SettingIcon,
	TransportationIcon,
	DepartureIcon,
	CateringIcon,
	CarIcon,
	GlobalIcon,
	FileIcon,
	MasgedIcon,
	ExportToPdf,
	WhatsAppIcon,
	SendIcon
} from '../shared/Icons'
import { ReactComponent as CheckCircleIcon } from 'assets/images/umrah-operations/check-circle.svg'
import { ReactComponent as EditIcon } from 'assets/images/umrah-operations/edit-icon.svg'
import { ReactComponent as MoreIcon } from 'assets/images/umrah-operations/more-square.svg'
import { ReactComponent as CheckedIcon } from 'assets/images/umrah-operations/tick-circle.svg'

import CategoryCard from '../shared/CategoryCard'
import Locale from 'translations'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { updateStatusOperation } from 'services/operationStatement'
import { store } from 'react-notifications-component'
import ViewIcon from 'modules/products-builder/Pricing/IconsSVG/ViewIcon'
import ShowForPermission from 'helpers/showForPermission'
import usePrintingInvoices from './usePrintingInvoices'

const imageUrl = process.env.REACT_APP_API_URL + "/v1/files/fetch/";


export default function AccountingTableRow({ statement, getStatementAccounting }) {
	// ** hooks
	const { operationStatement } = Locale
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(false);
	const filesUrl = process.env.REACT_APP_FILEUPLOAD_URL + '/fetch'
	const {
		printFullPackageInvoice,
		printArrivalInvoice,
		printHotelInvoice,
		printTransportationInvoice,
		printVisaInvoice,
		printCateringInvoice,
		printAttractionsInvoice,
		printOtherServicesInvoice,
	} = usePrintingInvoices();

	function closeActionMenu() {
		setIsOpen(false)
	}

	const detectClickOutsideRef = useDetectClickOutside({
		onTriggered: closeActionMenu
	})

	const updateStatementStatus = async (payment_status, id) => {
		const res = await updateStatusOperation(payment_status, id)
		if (res?.status === 200) {
			await getStatementAccounting()
			store.addNotification({
				// title: 'Info!',
				message: operationStatement?.updatingOperationStatementStatus,
				type: 'success',
				insert: 'top',
				container: 'top-right',
				animationIn: ['animated', 'fadeIn'],
				animationOut: ['animated', 'fadeOut'],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true
				}
			})
			setIsOpen(false)
		}
	}


	return (
		<>
			<div className='table-row account-status'>
				<div className='d-flex align-items-center'>
					<span
						className={`status`}
						style={{
							background:
								statement.payment_status === 'paid'
									? '#219653'
									: statement.payment_status === 'unpaid'
										? '#EA5455'
										: '#FB9435'
						}}
					></span>

					<div className='statement-info'>
						{statement?.company_reference_number && (
							<span className='confirmation-no' style={{ color: '#050001' }}>
								#{statement?.company_reference_number}
							</span>
						)}

						<span className='file-no c-grey'>
							{operationStatement.fileNo}:{' '}
							{statement?.file_number ? statement?.file_number : '_'}
						</span>

						{statement?.arrival_date && (
							<p className='d-flex align-items-center'>
								<CircleArrowRight color='#219653' />
								<span className='file-no mx-1'>
									{moment(statement?.arrival_date).format('DD-MM-YYYY')}
								</span>
							</p>
						)}
					</div>
				</div>

				<div className='statement-info'>
					<p className='fontStyleAccounting'>{operationStatement.agentName}</p>
					<p style={{ fontSize: '14px', fontWeight: 600 }}>
						{statement?.agent?.name}
					</p>
					<div className='d-flex align-items-center'>
						<img
							src={`${imageUrl}${statement?.agent?.country?.flag}`}
							alt={statement?.agent?.country?.name}
							className='rounded-circle mr-1'
							width={'16px'}
							height={'16px'}
							style={{ flex: '0 0 16px' }}
						/>
						<p className='fontStyleAccounting'>
							{statement?.agent?.country?.name}
						</p>
					</div>
				</div>

				<CategoryCard
					categroyTitle={operationStatement.Transportation}
					categroyInfo={{
						name: statement?.transportation?.company_name,
						icon: <TransportationIcon color='#EA5455' />,
						color: '#EA5455'
					}}
					categorySub={[
						{
							name: `${operationStatement.CredentialNo}: ${statement?.transportation?.credential_number ?? '_'
								}`,
							icon: <CheckCircleIcon />
						},
						{
							name: `${operationStatement.OperatingID}:  ${statement?.transportation?.operation_id ?? '_'
								}`,
							icon: <SettingIcon color='#219653' width='16px' height='16px' />
						}
					]}
				/>

				<div className='statement-info w-icons-mob'>
					<p className='fontStyleAccounting'>
						{operationStatement.pricingType}
					</p>
					<p style={{ fontSize: '14px', fontWeight: 600 }}>
						{statement?.pricing_type === 'package'
							? operationStatement.pricingPerPackage
							: operationStatement.pricingPerService}
					</p>

					<div className='elements'>
						{/* flight */}
						<span
							className={`ele-icon ${(statement?.pricing_type === 'package' &&
								statement?.total_price > 0) ||
								statement?.items?.indexOf('flight') > -1
								? 'active'
								: ''
								}`}
						>
							<DepartureIcon
								color={`${(statement?.pricing_type === 'package' &&
									statement?.total_price > 0) ||
									statement?.items?.indexOf('flight') > -1
									? '#FFF'
									: '#707170'
									}`}
							/>
						</span>
						{/* hotel */}
						<span
							className={`ele-icon ${(statement?.pricing_type === 'package' &&
								statement?.total_price > 0) ||
								statement?.items?.indexOf('hotels') > -1
								? 'active'
								: ''
								}`}
						>
							<HotelIcon
								color={`${(statement?.pricing_type === 'package' &&
									statement?.total_price > 0) ||
									statement?.items?.indexOf('hotels') > -1
									? '#FFF'
									: '#707170'
									}`}
							/>
						</span>
						{/* catering */}
						<span
							className={`ele-icon ${(statement?.pricing_type === 'package' &&
								statement?.total_price > 0) ||
								statement?.items?.indexOf('catering') > -1
								? 'active'
								: ''
								}`}
						>
							<CateringIcon
								color={`${(statement?.pricing_type === 'package' &&
									statement?.total_price > 0) ||
									statement?.items?.indexOf('catering') > -1
									? '#FFF'
									: '#707170'
									}`}
							/>
						</span>
						{/* attractions */}
						<span
							className={`ele-icon ${(statement?.pricing_type === 'package' &&
								statement?.total_price > 0) ||
								statement?.items?.indexOf('attractions') > -1
								? 'active'
								: ''
								}`}
						>
							<MasgedIcon
								color={`${(statement?.pricing_type === 'package' &&
									statement?.total_price > 0) ||
									statement?.items?.indexOf('attractions') > -1
									? '#FFF'
									: '#707170'
									}`}
							/>
						</span>
						{/* transportation */}
						<span
							className={`ele-icon ${(statement?.pricing_type === 'package' &&
								statement?.total_price > 0) ||
								statement?.items?.indexOf('transportation') > -1
								? 'active'
								: ''
								}`}
						>
							<CarIcon
								color={`${(statement?.pricing_type === 'package' &&
									statement?.total_price > 0) ||
									statement?.items?.indexOf('transportation') > -1
									? '#FFF'
									: '#707170'
									}`}
							/>
						</span>
						{/* visa */}
						<span
							className={`ele-icon ${(statement?.pricing_type === 'package' &&
								statement?.total_price > 0) ||
								statement?.items?.indexOf('visas') > -1
								? 'active'
								: ''
								}`}
						>
							<GlobalIcon
								color={`${(statement?.pricing_type === 'package' &&
									statement?.total_price > 0) ||
									statement?.items?.indexOf('visas') > -1
									? '#FFF'
									: '#707170'
									}`}
							/>
						</span>
						{/* other services */}
						<span
							className={`ele-icon ${(statement?.pricing_type === 'package' &&
								statement?.total_price > 0) ||
								statement?.items?.indexOf('other_services') > -1
								? 'active'
								: ''
								}`}
						>
							<FileIcon
								color={`${(statement?.pricing_type === 'package' &&
									statement?.total_price > 0) ||
									statement?.items?.indexOf('other_services') > -1
									? '#FFF'
									: '#707170'
									}`}
							/>
						</span>
					</div>
				</div>

				<div className='statement-info'>
					<p className='fontStyleAccounting'>{operationStatement.totalPrice}</p>
					<p className='heading-accounting'>
						{statement?.total_price}{' '}
						<span className='font-style-dark'>SAR</span>
					</p>
					{statement?.with_taxes > 0 && (
						<small
							style={{ fontSize: '13px', fontWeight: 400, color: '#707170' }}
						>
							{operationStatement.includeVAT}
						</small>
					)}
				</div>

				<div
					className={`${statement.payment_status === 'paid'
						? 'paid'
						: statement.payment_status === 'unpaid'
							? 'unpaid'
							: 'new'
						}`}
				>
					<span>
						{statement?.payment_status === 'paid'
							? operationStatement.Paid
							: statement?.payment_status === 'unpaid'
								? operationStatement.Unpaid
								: operationStatement.New}
					</span>
				</div>

				<div className='statement-info'>
					<p className='fontStyleAccounting'>{operationStatement.Actions}</p>
					<div className='d-flex align-items-center'>
						{statement?.payment_status !== 'paid' ? (
							<ShowForPermission permission='Manage-operation-accounting'>
								<button
									className='btn hero-content-layout'
									onClick={() =>
										history.push(
											`/umrah-operations/accounting/operation-edit/${statement?.id}`
										)
									}
								>
									<EditIcon />
								</button>
							</ShowForPermission>
						) : (
							<ShowForPermission permission='View-operation-accounting'>
								<button
									className='btn hero-content-layout'
									onClick={() =>
										history.push(
											`/umrah-operations/accounting/operation-view/${statement?.id}`
										)
									}
								>
									<ViewIcon />
								</button>
							</ShowForPermission>
						)}

						{statement?.payment_status !== 'new' && (
							<div className='position-relative' ref={detectClickOutsideRef}>
								<ShowForPermission permission='Manage-operation-accounting'>
									<button
										className='btn p-0 mx-2'
										onClick={() => setIsOpen(!isOpen)}
									>
										<MoreIcon />
									</button>
								</ShowForPermission>
								<ul className={`actions-list p-3 ${isOpen ? 'show' : ''}`}>
									<p className='payment-text'>{operationStatement.payment}</p>

									{statement && statement?.payment_status === 'unpaid' && (
										<li className='item stroke payment-row' role='button'
											onClick={() => {
												updateStatementStatus('paid', statement?.id)
												closeActionMenu()
											}}
										>
											<CheckedIcon className='payment-icon' />
											<span className='payment-text-label'>
												{operationStatement.paid}
											</span>
										</li>
									)}

									{statement && statement?.payment_status === 'paid' && (
										<li className='item stroke payment-row' role='button'
											onClick={() => updateStatementStatus('unpaid', statement?.id)}
										>
											<EditIcon className='payment-icon' />
											<span className='payment-text-label'>
												{operationStatement.unPaidAndEdit}
											</span>
										</li>
									)}

									{statement?.pricing_type === "package" || (statement?.pricing_type === "service" && statement?.items?.length > 0) ?
										<hr /> : null
									}

									{/* print Full Package Invoice */}
									{statement?.pricing_type === "package" &&
										<>
											<h5 className='d-flex justify-content-between align-items-center text-dark'>
												{operationStatement.fullPackageInvoice}
											</h5>
											<li className='item' role='button' onClick={() => printFullPackageInvoice(statement?.id)}>
												<ExportToPdf color='#292D32' />
												<span className='mx-1'>{operationStatement.ExportPDF}</span>
											</li>
											<li className='item'>
												<WhatsAppIcon color='#292D32' />
												<span className='mx-1'>
													{operationStatement.SendWhatsApp}
												</span>
											</li>
											<li className='item'>
												<SendIcon color='#292D32' />
												<span className='mx-1'>{operationStatement.SendEmail}</span>
											</li>
										</>
									}

									{/* print Arrival Invoice */}
									{statement?.pricing_type === "service" && statement?.items?.indexOf('flight') > -1 &&
										<>
											<h5>{operationStatement.arrivalAnddepartureInvoice}</h5>
											<li className='item' role='button' onClick={() => printArrivalInvoice(statement?.id)}>
												<ExportToPdf color='#292D32' />
												<span className='mx-1'>{operationStatement.ExportPDF}</span>
											</li>
											<li className='item'>
												<WhatsAppIcon color='#292D32' />
												<span className='mx-1'>
													{operationStatement.SendWhatsApp}
												</span>
											</li>
											<li className='item'>
												<SendIcon color='#292D32' />
												<span className='mx-1'>{operationStatement.SendEmail}</span>
											</li>
										</>
									}

									{/* print Hotel Invoice */}
									{statement?.pricing_type === "service" && statement?.items?.indexOf('hotels') > -1 &&
										<>
											<h5>{operationStatement.accommodationInvoice}</h5>
											<li className='item' role='button' onClick={() => printHotelInvoice(statement?.id)}>
												<ExportToPdf color='#292D32' />
												<span className='mx-1'>{operationStatement.ExportPDF}</span>
											</li>
											<li className='item'>
												<WhatsAppIcon color='#292D32' />
												<span className='mx-1'>
													{operationStatement.SendWhatsApp}
												</span>
											</li>
											<li className='item'>
												<SendIcon color='#292D32' />
												<span className='mx-1'>{operationStatement.SendEmail}</span>
											</li>
										</>
									}
									{/* print Transportation Invoice */}
									{statement?.pricing_type === "service" && statement?.items?.indexOf('transportation') > -1 &&
										<>
											<h5>{operationStatement.transportationInvoice}</h5>
											<li className='item' role='button' onClick={() => printTransportationInvoice(statement?.id)}>
												<ExportToPdf color='#292D32' />
												<span className='mx-1'>{operationStatement.ExportPDF}</span>
											</li>
											<li className='item'>
												<WhatsAppIcon color='#292D32' />
												<span className='mx-1'>
													{operationStatement.SendWhatsApp}
												</span>
											</li>
											<li className='item'>
												<SendIcon color='#292D32' />
												<span className='mx-1'>{operationStatement.SendEmail}</span>
											</li>
										</>
									}

									{/* print Visa Invoice */}
									{statement?.pricing_type === "service" && statement?.items?.indexOf('visas') > -1 &&
										<>
											<h5>{operationStatement.visaInvoice}</h5>
											<li className='item' role='button' onClick={() => printVisaInvoice(statement?.id)}>
												<ExportToPdf color='#292D32' />
												<span className='mx-1'>{operationStatement.ExportPDF}</span>
											</li>
											<li className='item'>
												<WhatsAppIcon color='#292D32' />
												<span className='mx-1'>
													{operationStatement.SendWhatsApp}
												</span>
											</li>
											<li className='item'>
												<SendIcon color='#292D32' />
												<span className='mx-1'>{operationStatement.SendEmail}</span>
											</li>
										</>
									}

									{/* print Catering Invoice */}
									{statement?.pricing_type === "service" && statement?.items?.indexOf('catering') > -1 &&
										<>
											<h5>{operationStatement.cateringInvoice}</h5>
											<li className='item' role='button' onClick={() => printCateringInvoice(statement?.id)}>
												<ExportToPdf color='#292D32' />
												<span className='mx-1'>{operationStatement.ExportPDF}</span>
											</li>
											<li className='item'>
												<WhatsAppIcon color='#292D32' />
												<span className='mx-1'>
													{operationStatement.SendWhatsApp}
												</span>
											</li>
											<li className='item'>
												<SendIcon color='#292D32' />
												<span className='mx-1'>{operationStatement.SendEmail}</span>
											</li>
										</>
									}
									{/* print Attractions Invoice*/}
									{statement?.pricing_type === "service" && statement?.items?.indexOf('attractions') > -1 &&
										<>
											<h5>{operationStatement.attractionsInvoice}</h5>
											<li className='item' role='button' onClick={() => printAttractionsInvoice(statement?.id)}>
												<ExportToPdf color='#292D32' />
												<span className='mx-1'>{operationStatement.ExportPDF}</span>
											</li>
											<li className='item'>
												<WhatsAppIcon color='#292D32' />
												<span className='mx-1'>
													{operationStatement.SendWhatsApp}
												</span>
											</li>
											<li className='item'>
												<SendIcon color='#292D32' />
												<span className='mx-1'>{operationStatement.SendEmail}</span>
											</li>
										</>
									}
									{/* print Other Services Invoice */}
									{statement?.pricing_type === "service" && statement?.items?.indexOf('other_services') > -1 &&
										<>
											<h5>{operationStatement.otherServices}</h5>
											<li className='item' role='button' onClick={() => printOtherServicesInvoice(statement?.id)}>
												<ExportToPdf color='#292D32' />
												<span className='mx-1'>{operationStatement.ExportPDF}</span>
											</li>
											<li className='item'>
												<WhatsAppIcon color='#292D32' />
												<span className='mx-1'>
													{operationStatement.SendWhatsApp}
												</span>
											</li>
											<li className='item'>
												<SendIcon color='#292D32' />
												<span className='mx-1'>{operationStatement.SendEmail}</span>
											</li>
										</>
									}

								</ul>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
