import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
import safaLogoMinimal from "assets/images/dashboard/safaLogoSmall.svg";
import { useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import Locale from "translations";
import "../../scss/modules/Dashboard/_sidenav.scss";
import {
	// AboutUsIcon,
	ActivityLogIcon,
	Arrow,
	CRMIcon,
	// ContactUsIcon,
	HotelFIightsIcon,
	MyBookingIcon,
	MyTeamIcon,
	PackagePlanIcon,
	SafaOfflineIcon,
	// StatisticsIcon,
	SubscriptionPlanIcon,
	TeamBranchesIcon,
	TeamGroupsIcon,
	TeamMemberIcon,
	TripPlannerIcon,
	TripsManagementIcon,
	WalletIcon,
	WalletRequestsIcon,
	WalletTransactionsIcon,
} from "./icons";
import { ReactComponent as OperatingStatementIcon } from "assets/images/umrah-operations/operation-statement-icon.svg";
import { ReactComponent as DollarCircleIcon } from "assets/images/subscription-plan/dollar-circle.svg";
import { ReactComponent as UpgradeSubscription } from "assets/images/upgrade-subscription.svg";



import useShowPermission from "hooks/useShowPermission";

export default function SideNav({ show, setShow }) {
	const {
		dashboard,
		marketPlace,
		inventory,
		payment,
		productsBuilder,
		teamManagement,
		// newLand,
		operationStatement,
		CRM,
		subscriptionModule
	} = Locale;
	const { companyInfo, subscriptionPlan } = useSBSState();
	const location = useLocation();

	// const history = useHistory();
	const { pathname } = useLocation();
	const marketPlacePrmission = useShowPermission({
		permission: [
			"View-Marketplace-Hotels",
			"Manage-Marketplace-Hotels",
			"View-Marketplace-Transfer",
			"Manage-Marketplace-Transfer",
			"View-Marketplace-Tours",
			"Manage-Marketplace-Tours",
			"View-Marketplace-Visa",
			"Manage-Marketplace-Visa",
			"View-Marketplace-Flights",
			"Manage-Marketplace-Flights",
		],
	});
	const [dashboardRoutes, setDashboardRoutes] = useState([
		// umrah operations
		(companyInfo?.is_umrah_agent && companyInfo?.company_category === "umrah_company" &&
		{
			id: "sidenav-umrah-operations",
			route: companyInfo?.company_category === "services_company"
				? "/umrah-operations/movements/delegations-management"
				: "/umrah-operations/operation-statement",
			icon: <OperatingStatementIcon />,
			text: `${operationStatement.bravo}`,
			ring: true,
			permission: ["View-operation-statement", "Manage-operation-statement"],
			subscriptionModuleName: "operation_statement",
		}),
		// marketplace
		{
			id: "sidenav-market-search",
			icon: <HotelFIightsIcon />,
			text: `${dashboard.messages.hotelsFlights}`,
			class: "fix-color",
			ring: false,
			route: "/market-place-new/",
			permission: [
				"View-Marketplace-Hotels",
				"Manage-Marketplace-Hotels",
				"View-Marketplace-Transfer",
				"Manage-Marketplace-Transfer",
				"View-Marketplace-Tours",
				"Manage-Marketplace-Tours",
				"View-Marketplace-Visa",
				"Manage-Marketplace-Visa",
				"View-Marketplace-Flights",
				"Manage-Marketplace-Flights",
			],
			subscriptionModuleName: "marketplace",
			show: false,
		},
		// mybookings
		{
			id: "sidenav-mybooking",
			icon: <MyBookingIcon />,
			text: `${dashboard.messages.myBookings}`,
			ring: false,
			show: false,
			route: "/inventory/",
			permission: [
				"View-Inventory-Hotels",
				"Manage-Inventory-Hotels",
				"View-Inventory-Transfer",
				"Manage-Inventory-Transfer",
				"View-Inventory-Tours",
				"Manage-Inventory-Tours",
				"View-Inventory-Visa",
				"Manage-Inventory-Visa",
				"View-Inventory-Flights",
				"Manage-Inventory-Flights"
			],
			subscriptionModuleName: "marketplace",
			subNavs: [
				{
					id: "sidenav-mybooking-hotels",
					route: "/inventory/hotels",
					text: marketPlace.messages.hotels,
					icon: "",
					permission: ["View-Inventory-Hotels", "Manage-Inventory-Hotels"],
				},
				{
					id: "sidenav-mybooking-transfer",
					route: "/inventory/transfer",
					text: marketPlace.messages.transfer,
					icon: "",
					permission: ["View-Inventory-Transfer", "Manage-Inventory-Transfer"],
				},
				{
					id: "sidenav-mybooking-visa-requests",
					route: "/inventory/visa-requests",
					text: inventory.messages.visaRequests,
					icon: "",
					permission: ["View-Inventory-Visa", "Manage-Inventory-Visa"],
				},
				// {
				// 	id: "sidenav-mybooking-transportations",
				// 	route: "/inventory/transportations",
				// 	text: marketPlace.messages.Transportation,
				// 	icon: "",
				// 	permission: [],
				// },
				{
					id: "sidenav-mybooking-tour",
					route: "/inventory/tour",
					text: marketPlace.messages.tours,
					icon: "",
					permission: ["View-Inventory-Tours", "Manage-Inventory-Tours"],
				},
				{
					id: "sidenav-mybooking-flights",
					route: "/inventory/flights",
					text: marketPlace.messages.flights,
					icon: "",
					permission: ["View-Inventory-Flights", "Manage-Inventory-Flights"],
				},
				{
					id: "sidenav-mybooking-packages",
					route: "/inventory/packages",
					text: inventory.messages.packages,
					icon: "",
					permission: [],
				},
			],
		},

		// CRM
		{
			id: "sidenav-crm",
			icon: <CRMIcon />,
			text: CRM.cRM,
			class: "fix-color fill-color",
			ring: false,
			route: "/crm/main",
			permission: [],
			show: false,
			subscriptionModuleName: "crm",
		},

		// product builder
		{
			id: "sidenav-products-builder",
			route: "/products-builder",
			class: "fix-color hidden-mobile",

			icon: <TripsManagementIcon />,
			text: `${dashboard.messages.tripsManagement}`,
			ring: false,
			permission: [
				"View-Trip-Management-Module",
				"Manage-Trip-Management-Module",
			],
			subscriptionModuleName: "trip_builder",
			subNavs: [
				{
					id: 111,
					route: "/products-builder/products?version=2",
					text: productsBuilder.productBuilder,
					icon: <MyBookingIcon />,
					permission: [
						"View-Trip-Management-Module",
						"Manage-Trip-Management-Module",
					],
				},
				{
					id: 112,
					route: "/products-builder/requests",
					text: payment.messages.requests,
					icon: <MyBookingIcon />,
					permission: [
						"View-Trip-Management-Module",
						"Manage-Trip-Management-Module",
					],
				},
				// {
				// 	id: 112,
				// 	route: "/b2b-requests",
				// 	text: payment.messages.b2bRequests,
				// 	icon: <MyBookingIcon />,
				// 	permission: [
				// 		"View-Trip-Management-Module",
				// 		"Manage-Trip-Management-Module",
				// 	],
				// },
				{
					id: 114,
					route: "/web-page-requests",
					text: payment.messages.webPageRequests,
					icon: <MyBookingIcon />,
					permission: [
						"View-Trip-Management-Module",
						"Manage-Trip-Management-Module",
					],
				},

			],
			
		},
		// trip planner
		{
			id: "sidenav-trip-planner",
			route: "/trip-planner",
			class: "fix-color hidden-mobile",

			icon: <TripPlannerIcon />,
			text: `${dashboard.messages.packagePlanner}`,
			ring: false,
			permission: [
				"View-Trip-Management-Module",
				"Manage-Trip-Management-Module",
			],
			subscriptionModuleName: "package_planner",
		},
		// safa offline
		{
			id: "sidenav-safaOffline",
			route: "/visa",
			icon: <SafaOfflineIcon />,
			text: `${dashboard.messages.SafaOffline}`,
			class: "fix-color",
			ring: false,
			permission: ["View-Safa-Offline", "Manage-Safa-Offline"],
			subscriptionModuleName: "safa_offline",
			subNavs: [{
				id: "SafaOffline",
				route: "/visa",
				text: dashboard.messages.SafaOffline,
				permission: ["View-Safa-Offline", "Manage-Safa-Offline"],
			}, {
				id: "Subscription",
				route: "/subscription",
				text: teamManagement.Subscription,
				permission: ["View-Safa-Offline", "Manage-Safa-Offline"],
			}]
		},
		// myteam
		{
			id: "sidenav-myteam",
			route: "/team-managementlist",
			icon: <MyTeamIcon />,
			text: `${dashboard.messages.MyTeam}`,
			ring: false,
			permission: ["View-My-Team", "Manage-My-Team"],
			subscriptionModuleName: "team_management",
			subNavs: [
				{
					id: "sidenav-myteam-member",
					route: "/team-managementlist/member",
					text: teamManagement.manageMember,
					icon: <TeamMemberIcon />,
					permission: ["View-My-Team", "Manage-My-Team"],
				},
				{
					id: "sidenav-myteam-team",
					route: "/team-managementlist/team",
					text: teamManagement.manageTeam,
					icon: <TeamGroupsIcon />,
					permission: ["View-My-Team", "Manage-My-Team"],
				},
				{
					id: "sidenav-myteam-branch",
					route: "/team-managementlist/branch",
					text: teamManagement.manageBranch,
					icon: <TeamBranchesIcon />,
					permission: ["View-My-Team", "Manage-My-Team"],
				},
			],
		},
		// wallet
		{
			id: "sidenav-wallet",
			route: "/wallet",
			icon: <WalletIcon />,
			text: `${dashboard.messages.myWallet}`,
			ring: true,
			permission: ["View-Wallets", "Manage-Wallets"],
			subscriptionModuleName: "free",
			subNavs: [
				{
					id: "sidenav-wallet-transactions",
					route: "/wallet/transactions",
					text: payment.messages.transactions,
					icon: <WalletTransactionsIcon />,
					permission: ["View-Wallets", "Manage-Wallets"],
				},
				{
					id: "sidenav-wallet-requests",
					route: "/wallet/requests",
					text: payment.messages.requests,
					icon: <WalletRequestsIcon />,
					permission: ["View-Wallets", "Manage-Wallets"],
				},
			],
		},

		// Subscription plan
		{
			id: "subscription-plan",
			icon: <DollarCircleIcon />,
			text: subscriptionModule.SubscriptionPlan,
			ring: false,
			class: "fix-color",
			route: "/subscription",
			permission: [
				"View-subscription-plan",
				"Manage-subscription-plan"
			],
			subNavs: [
				{
					id: "sidenav-plan",
					route: "/subscription/plans",
					text: subscriptionModule.SubscriptionPlan,
					permission: [
						"View-subscription-plan",
						"Manage-subscription-plan"
					],
					icon: <SubscriptionPlanIcon />,
					class: "stroke"
				},
				{
					id: "sidenav-plan",
					route: "/subscription/packages-plans",
					text: subscriptionModule.PackagePlan,
					permission: [
						"View-subscription-plan",
						"Manage-subscription-plan"
					],
					icon: <PackagePlanIcon />,
					class: "stroke"
				},
			],
			subscriptionModuleName: "free",
			show: false,
		},
		// umrah operations
		(companyInfo?.is_umrah_agent && companyInfo?.company_category !== "umrah_company" && 
		{
			id: "sidenav-umrah-operations",
			route: companyInfo?.company_category === "services_company"
				? "/umrah-operations/movements/delegations-management"
				: "/umrah-operations/operation-statement",
			icon: <OperatingStatementIcon />,
			text: `${operationStatement.bravo}`,
			ring: true,
			permission: ["View-operation-statement", "Manage-operation-statement"],
			subscriptionModuleName: "operation_statement",
		}),
		// activity log
		{
			id: "sidenav-activity-log",
			route: "/activity-logs",
			icon: <ActivityLogIcon />,
			text: `${dashboard.messages.activityLogs}`,
			ring: true,
			permission: ["View-Activity-Logs"],
			subscriptionModuleName: "free",
		},
		// statistics
		// {
		// 	id: "sidenav-statistics",
		// 	route: "/statistics",
		// 	icon: <StatisticsIcon />,
		// 	text: `${dashboard.messages.statistics}`,
		// 	ring: true,
		// 	permission: [],
		// 	subscriptionModuleName: "free",
		// },
		// // about us
		// {
		// 	id: "sidenav-about-us",
		// 	route: "/about-us",
		// 	icon: <AboutUsIcon />,
		// 	text: `${newLand.aboutUs}`,
		// 	ring: true,
		// 	permission: [],
		// 	subscriptionModuleName: "free",
		// },
		// // contact us
		// {
		// 	id: "sidenav-contact-us",
		// 	route: "/contact-us",
		// 	icon: <ContactUsIcon />,
		// 	text: `${newLand.contactUs}`,
		// 	ring: true,
		// 	permission: [],
		// 	subscriptionModuleName: "free",
		// },

	]);



	useEffect(() => {
		const subNavShow = dashboardRoutes?.map((dashboard) => {
			if (dashboard?.subNavs && dashboard?.subNavs.length > 0) {
				return {
					...dashboard,
					show:
						dashboard?.subNavs?.filter(
							(subRoute) => subRoute?.route === location.pathname
						).length > 0,
				};
			}
			return {
				...dashboard,
				show: false,
			};
		});
		setDashboardRoutes(subNavShow);
	}, [location.pathname]);

	const dashboardList = dashboardRoutes &&
		(companyInfo?.company_category === "services_company"
			? dashboardRoutes?.filter(route => route.id === "sidenav-umrah-operations")
			: dashboardRoutes)?.map((dashboard, index) => {
				const routeHasSubNav = dashboard?.subNavs && dashboard?.subNavs.length > 0 ? true : false;
				return (
					<li className={`sidenav_item ${dashboard?.class || ""}`} key={`nav-${dashboard?.id}-${index}`}>
						<ShowForPermission permission={dashboard?.permission}>
							<NavLink
								id={dashboard?.id}
								className={`sidenav_link  `}
								activeClassName="active"
								to={dashboard?.route || ""}
								onClick={(e) => {
									routeHasSubNav && e.preventDefault();
									if (show) {
										setDashboardRoutes(
											dashboardRoutes.map((route) => {
												return {
													...route,
													show:
														route.id === dashboard.id ? !dashboard.show : false,
												};
											})
										);
									}
									if (!routeHasSubNav && show) {
										setShow(false);
									}
								}}
							>
								<span className={`sidenav_icon ${dashboard?.id === "sidenav-umrah-operations" ? "stroke" : ""}`}>
									{dashboard?.icon}
									{!subscriptionPlan?.includes(dashboard?.subscriptionModuleName) && dashboard?.subscriptionModuleName !== "free" && !show ? <span className='upgrade-subscription-icon'><UpgradeSubscription /></span> : ""}
								</span>
								<span
									className={`sidenav_arrow ${dashboard?.show && routeHasSubNav ? "toggled" : ""
										}`}
								>
									<Arrow />
								</span>
								<span className="dash-text">
									{dashboard?.text}
									{!subscriptionPlan?.includes(dashboard?.subscriptionModuleName) && dashboard?.subscriptionModuleName !== "free" ? <span className='upgrade-subscription-icon-in-text'><UpgradeSubscription /></span> : ""}
								</span>
							</NavLink>
						</ShowForPermission>

						{/* dropdown */}
						{routeHasSubNav ? (
							<ul className="sidenav_dropdown">
								{show ? (
									<Collapse isOpen={dashboard.show}>
										{dashboard?.subNavs?.map((subNav, idx) => {
											return (
												<ShowForPermission
													permission={subNav?.permission}
													key={`subnav-${subNav?.id}-${idx}`}
												>
													<li className="dropdown_item">
														<NavLink
															to={subNav?.route || ""}
															value={dashboard?.id}
															className="dropdown_link dropdown_link_subscription_icon "
															activeClassName="active"
															onClick={() =>
																pathname !== "/" || show ? setShow(false) : false
															}
														>
															{/* <span className="">{subNav?.icon}</span> */}
															<span className="mx-2">{subNav?.text}</span>
															{!subscriptionPlan?.includes(dashboard.subscriptionModuleName) && dashboard.subscriptionModuleName !== "free" ? <span className='upgrade-subscription-icon-in-text'><UpgradeSubscription /> {subscriptionModule.upgrade} </span> : ""}

														</NavLink>
													</li>
												</ShowForPermission>
											);
										})}
									</Collapse>
								) : (
									dashboard?.subNavs?.map((subNav, idx) => {
										return (
											<ShowForPermission
												permission={subNav?.permission}
												key={`subnav-hidden-${subNav?.id}-${idx}`}
											>
												<li className={`dropdown_item ${subNav.class || ""}`}>
													<NavLink
														to={subNav?.route || ""}
														value={dashboard?.id}
														className="dropdown_link"
														activeClassName="active"
														onClick={() =>
															pathname !== "/" || show ? setShow(false) : false
														}
													>
														<span className="">{subNav?.icon}</span>
														<span className="mx-1">{subNav?.text}</span>
														{!subscriptionPlan?.includes(dashboard.subscriptionModuleName) && dashboard.subscriptionModuleName !== "free" ? <span className='upgrade-subscription-icon-in-text'><UpgradeSubscription /></span> : ""}
													</NavLink>
												</li>
											</ShowForPermission>
										);
									})
								)}
							</ul>
						) : null}
					</li>
				);
			});

	return (
		<nav id="sideNav" className={show ? "opend" : "closed"}>
			<Link
				className={`navbar-brand ${show ? "w-100 m-0" : "m-0"} mt-3`}
				onClick={() => {
					show && setShow(false);
					// if (companyInfo?.company_category === "services_company") {
					// 	history.push("/umrah-operations/movements/delegations-management");
					// } else {
					// 	history.push(marketPlacePrmission ? "/market-place-new/" : "/statistics");
					// }
				}}
				to={
					companyInfo?.company_category === "services_company" ? "/umrah-operations/movements/delegations-management" :
						companyInfo?.company_category !== "services_company" && marketPlacePrmission ? "/market-place-new/" : "/statistics"
				}
			>
				<span className={`navbar-brand-icon`}>
					<img
						className={show ? "img-fluid px-4" : ""}
						src={show ? SafaLogo : safaLogoMinimal}
						alt="Logo"
					/>
				</span>
			</Link>
			{/* nav list */}
			<ul className="sidenav_list">
				<h1 className={`sidenav_header`}>{dashboard.messages.menu}</h1>
				{dashboardList}
			</ul>
		</nav>
	);
}
