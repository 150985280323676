import React from 'react'
import LocationIcon from '../../icons/location'
import DiscountIcon from '../../icons/discountIcon'
import Locale from 'translations';
import { ReactComponent as OpenEyeIcon } from "assets/images/market-place/open-eye-icon.svg";
import HotelPlaceHolder from "assets/images/new-hotels.svg";
import { fetchHotels } from "services/marketplace";
import { useMarketplaceDispatch } from 'context/marketplace';
import { useSBSDispatch } from 'context/global';
import { useHistory, useParams } from 'react-router-dom';
import { store } from 'react-notifications-component';




export default function HotelCard({ hotelData, searchData }) {
  const { productsBuilder, marketPlace, messages, inventory } = Locale;
  const dispatch = useMarketplaceDispatch();
  const dispatchSBS = useSBSDispatch();
  const history = useHistory();
  const { status } = useParams();
  const hotelRating = +hotelData?.rating > 5 ? 5 : +hotelData?.rating;

  async function handleHotelSearch(hotel, dataSelected) {
    let data = {
      ...hotel,
      search_filters: {
        ...hotel.search_filters,
        hotel_ids: [dataSelected?.hotel_id],
      },
    };

    localStorage.setItem("hotelOffer", 0);
    const response = await fetchHotels(data);
    if (response.status === 200) {
      if (response.data.data.length > 0) {
        // TO DO fix DTO
        dispatch({
          type: "newSearch",
          payload: {
            searchResults: response.data,
            hotelFilters: data,
            priceRanges: {
              min: response.data.filter_data.price.min_price,
              max: response.data.filter_data.price.max_price,
            },
            filters: {
              ...data,
              checkIn: data.date_from,
              checkOut: data.date_to,
              goingTo: {
                label: dataSelected.name,
                value: dataSelected.hotel_id,
                id: dataSelected.hotel_id,

                type: "hotel",
                name: dataSelected.name,
                country: {
                  flag: HotelPlaceHolder,
                },
              },
              country: {
                label: dataSelected.name,
                value: dataSelected.hotel_id,
                id: dataSelected.hotel_id,
                type: "hotel",
                name: dataSelected.name,
                country: {
                  flag: HotelPlaceHolder,
                },
              },
              residence: {
                label: data.residency,
                value: data.residency,
                code: data.residency,
              },
              nationality: {
                label: data.nationality,
                value: data.nationality,
                code: data.nationality,
              },
              myAds: false,
              rooms: data.rooms_data.map((res) => {
                return { ...res, roomLength: 1, roomsList: [{ children: [] }] };
              }),
            },

            searchBy: "hotel",
          },
        });

        dispatchSBS({ type: "searchOfferClear", payload: "" });

        history.push(`/${status}/market-view`);
      } else {
        store.addNotification({
          title: messages.noResults,
          message: messages.noSearchResults,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
        });
      }
    }
  }

  return (
    <div className='selling-service-card'>
      <div className="card-img-container">
        <img src={hotelData?.image || ""} alt={hotelData?.name} />
        <button className="view-overlay" onClick={() => handleHotelSearch(searchData, hotelData)}>
          <OpenEyeIcon />
          <span className='mx-2'>{inventory.messages.view}</span>
        </button>
      </div>


      <div className="card-content">
        <h4 className='card-title'>{hotelData?.name}</h4>
        {/*  */}
        <div className="rating">
          {[1, 2, 3, 4, 5]?.map(rate => {
            return (
              <>
                {rate > hotelRating
                  ? <i className="far fa-star empty-rate" aria-hidden="true" key={rate} />
                  : <i className="fa fa-star" aria-hidden="true" key={rate} />}
              </>
            )
          })}
        </div>

        <span className='service-type'>{marketPlace.hotels}</span>

        <div className="location">
          <LocationIcon width="20px" />
          <span className='mx-2'>{hotelData?.address}</span>
        </div>
      </div>
      <div className={`card-footer ${hotelData?.discount ? "justify-content-between" : "justify-content-end"} `}>
        {hotelData?.discount ?
          <div className='additional-info discount'>
            <DiscountIcon />
            {productsBuilder.specialRate}
          </div>
          :
          null
        }

        <div className='price-container'>
          <span>{productsBuilder.startFrom}</span>
          <p>
            <span className='price'>{hotelData?.price ? hotelData?.price.toFixed(2) : ""} </span>
            <span>{hotelData?.currency}/</span>
            <span className="price-for">  {marketPlace.messages.night}</span>
          </p>
        </div>
      </div>
    </div>

  )
}
