import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { ReactComponent as AlignLeftIcon } from "assets/images/webBuilder/align-left20.svg";
import { ReactComponent as AlignRightIcon } from "assets/images/webBuilder/align-right20.svg";
import { ReactComponent as AlignCenterIcon } from "assets/images/webBuilder/align-vertically20.svg";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import { useState } from "react";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "../../../interfaces/blockTypes";
import { AddElement } from "../../../shared/AddElement";
import { EditBox } from "../../../shared/EditBox";
import EditableParagraph from "../../../shared/EditableParagraph";
import AddMedia from "modules/WebBuilder/shared/AddMedia";
import { useLocation } from 'react-router-dom';
import Locale from 'translations';

const aboutBlocksType = "aboutBlocksType";

function AboutUs({ details, onFocus, focusContainer }) {
	const { items, id} = details;
	const [mediaModal, setmediaModal] = useState(false);
	const { selectedLanguage } = useWebBuilderState();

	const toggleMedia = () => {
		setmediaModal((prev) => !prev);
	};

	const dispatch = useWebBuilderDispatch();

	const updateAboutUs = ({ name, value, itemId, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				itemId,
				language
			},
		});
	};
	const sortAboutUsItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const contentActions = {
		onChangeTitle: (value, itemId) =>
			updateAboutUs({ name: "title", value, itemId,language: selectedLanguage }),
		onChangeHead: (value, itemId) =>
			updateAboutUs({ name: "head", value, itemId, language: selectedLanguage }),
		onChangeBody: (value, itemId) =>
			updateAboutUs({ name: "body", value, itemId, language: selectedLanguage }),
	};
	const mediaActions = {
		onChangeMedia: ({ value, itemId, name }) =>
			updateAboutUs({ name, value, itemId }),
		mediaModal,
		toggleMedia,
	};

	const itemsBoxMediaActions = (itemId) => {
		return [
			<span
				key={`${itemId}-edit-icon`}
				onClick={toggleMedia}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};
	const itemsBoxContentActions = (itemId) => {
		return [
			<span
				key={`${itemId}-align-left-icon`}
				onClick={() =>
					updateAboutUs({ name: "textAlign", value: "start", itemId })
				}
				className="pointer-event"
			>
				<AlignLeftIcon />
			</span>,
			<span
				key={`${itemId}-align-center-icon`}
				onClick={() =>
					updateAboutUs({ name: "textAlign", value: "center", itemId })
				}
				className="pointer-event"
			>
				<AlignCenterIcon />
			</span>,
			<span
				key={`${itemId}-align-right-icon`}
				onClick={() =>
					updateAboutUs({ name: "textAlign", value: "end", itemId })
				}
				className="pointer-event"
			>
				<AlignRightIcon />
			</span>,
		];
	};

	// const itemsBoxActions = (itemId) => {
	// 	return [
	// 		<span
	// 			key={`${itemId}-trash-icon`}
	// 			onClick={() =>
	// 				deleteBodyContent({ itemId, deleteType: "container_item" })
	// 			}
	// 			className="pointer-event"
	// 		>
	// 			<TrashIcon />
	// 		</span>,
	// 	];
	// };

	// const containerBoxActions = [
	// 	<span
	// 		onClick={() => deleteBodyContent({ deleteType: "container" })}
	// 		className="pointer-event"
	// 	>
	// 		<TrashIcon />
	// 	</span>,
	// ];

	const AboutItems = {
		content: AboutContent,
		media: AboutMedia,
	};

	return (
		<div
			onClick={() => onFocus(id)}
			id={id}
		>
			<EditBox editMode={focusContainer === id}>
				<div className="web-builder-content-about-us container">
					{items.map((item, index) => {
						const componentType = item.id.includes("media")
							? "media"
							: "content";
						const Component = AboutItems[componentType];
						const actions =
							componentType === "content" ? contentActions : mediaActions;

						const editBoxActions =
							componentType === "content"
								? [
										...itemsBoxContentActions(item.id),
										// ...itemsBoxActions(item.id),
								  ]
								: [
									...itemsBoxMediaActions(item.id),
										// ...itemsBoxActions(item.id),
								  ];
						return (
							<DragAndDropContainer
								accept={aboutBlocksType}
								type={aboutBlocksType}
								id={item.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortAboutUsItems({ dragIndex, hoverIndex })
								}
								// onDrop={}
								key={item.id}
							>
								<EditBox
									editMode={focusContainer === id}
									actions={editBoxActions}
								>
									<Component details={{ ...item, head: item?.head?.[selectedLanguage], body: item?.body?.[selectedLanguage],  ...actions }} />
								</EditBox>
							</DragAndDropContainer>
						);
					})}
				</div>
			</EditBox>
		</div>
	);
}

export default AboutUs;

const AboutContent = ({ details }) => {
	const { webBuilder } = Locale;
	const {
		title,
		head,
		body,
		onChangeTitle,
		onChangeHead,
		onChangeBody,
		id,
		textAlign = "start",
		readMoreText,
		readLessText
	} = details;
	const location = useLocation();
	const preview = !location.pathname.includes('edit');
	let trimBody = body ? body?.length > 400 ? `${body?.substr(0, 400)}...` : body : "";
	const [isFullContentShown, setIsFullContentShown] = useState(false);

	function toggleContent() {
		setIsFullContentShown(!isFullContentShown)
	}

	return (
		<div className="about-us-details" style={{ textAlign }}>
			<EditableParagraph
				contentEditable={false}
				onInput={(value) => onChangeTitle(value, id)}
				className="about-us-title"
				text={webBuilder.aboutus}
			/>

			<EditableParagraph
				contentEditable={true}
				onInput={(value) => onChangeHead(value, id)}
				className="h4 mb-3"
				text={head}
			/>
			
			<EditableParagraph
				contentEditable={true}
				onInput={(value) => onChangeBody(value, id)}
				className="text-body"
				text={preview && !isFullContentShown ? trimBody : body}
			/>
			{preview && body?.length > 200 ?
				<button className="btn web-builder-filled-btn mt-3" onClick={toggleContent}>{isFullContentShown ? readLessText : readMoreText}</button>
			: 
			null
			}
		</div>
	);
};

const AboutMedia = ({ details }) => {
	const { mediaUrl, mediaType, id, onChangeMedia, mediaModal, toggleMedia } =
		details;

	const onChange = ({ mediaType, mediaUrl }) => {
		onChangeMedia({ value: mediaType, itemId: id, name: "mediaType" });
		onChangeMedia({ value: mediaUrl, itemId: id, name: "mediaUrl" });
	};

	return (
		<>
			<div className={`about-media-container ${mediaType === "image" ? "about-media-image" : ""}`}>
				{mediaUrl ? (
					mediaType === "video" ? (
						<iframe
							style={{ maxWidth: "100%" }}
							className="w-100 h-100"
							width="auto"
							height="auto"
							src={`https://www.youtube.com/embed/${mediaUrl}`}
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
					) : (
						<img src={mediaUrl} className="img-fluid" alt="about us" />
					)
				) : (
					<AddElement onClick={toggleMedia} />
				)}
			</div>

			<AddMedia
				handleModalState={toggleMedia}
				label="Media"
				onAdd={onChange}
				open={mediaModal}
			/>
		</>
	);
};
