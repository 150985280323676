import { formatProductItems } from 'modules/market-place/NewPackageResponse/helpers/formatServicesItems';
import { roomTypesLookup } from 'modules/products-builder/Requestes/RequestView/helpers/localLookups';



export function formatRoomsData(reservation) {
  let childCount = 0;
  let adultsCount = 0;
  let servicesList = formatProductItems(reservation?.product?.product_items);
  let hotelsCount = servicesList?.filter(service => service?.id === 6).length;
  const combinations = reservation?.product?.product_price_combinations;
  const roomAvailabilities = reservation?.roomAvailabilities;


  let roomsList = roomAvailabilities?.map((room, index) => {
    const prevRoom = index > 0 ? roomAvailabilities[index - 1] : null;
    const nextRoom = roomAvailabilities[index + 1];
    const combination = combinations?.find(comb => comb?.combinations[0]?.room_type === room?.room_type?.toString());
    // get last index of room type to get full count of room type
    let indexOfLastType = nextRoom?.room_type !== room?.room_type ? index : null;
    // reset if room type changed
    if (prevRoom && prevRoom?.room_type !== room?.room_type) {
      adultsCount = 0;
      childCount = 0;
    }
    adultsCount = adultsCount + room?.adults_count;
    childCount = childCount + room?.children_count;
    const roomCount = indexOfLastType ? adultsCount / room?.room_type / hotelsCount : 0;
    return {
      indexOfLastType,

      roomType: {
        id: room?.room_type,
        value: room?.room_type,
        name: roomTypesLookup[room?.room_type],
        label: roomTypesLookup[room?.room_type],
        room_type: room?.room_type
      },
      roomCount: roomCount,
      childCount: childCount / hotelsCount,
      combination_id: combination?.id,
      childrenAvailability: indexOfLastType && [...Array((roomCount * 2) + 1).keys()].map(count => ({ name: `${count}`, id: `${count}`, value: `${count}` })),
      // calc each room services price
      servicesPrices: indexOfLastType && servicesList?.map(service => {
        let pricePerRoom = 0;
        let adultPrice = 0;
        let childPrice = 0;
        let currentHotelCombination = combination?.combinations?.find(comb => comb?.product_hotel_id === service?.product_hotel_id);

        if (currentHotelCombination) {
          adultPrice = +currentHotelCombination?.adults_count * +currentHotelCombination?.price_adult * +roomCount;
          childPrice = +currentHotelCombination?.price_child * (+childCount / hotelsCount);
        } else {
          adultPrice = +service?.price_adult * (+roomCount * room?.room_type);
          childPrice = +service?.price_child * (+childCount / hotelsCount);
        }

        return {
          ...service,
          pricePerRoom: pricePerRoom + adultPrice + (childPrice || 0)
        }
      })
    }
  });


  roomsList = roomsList.filter(room => room?.indexOfLastType);
  return roomsList;
}