import moment from 'moment'
import { useEffect, useState } from 'react'
import {
	requestsListReservationsB2b,
	webPageRequests
} from 'services/productbuilder'
import Locale from 'translations'
import Filterrequestes from './WebPageFiltering'
import WebPageRequestsList from './WebPageRequestsList'
import ShowSubscription from 'helpers/ShowSubscription'

function WebPageRequests() {
	const { payment } = Locale
	const [requestsList, setRequestsList] = useState()
	const [filters, setFilters] = useState({
		search: '',
		request_date: '',
		nationality: '',
		page:1
	})
	const [meta, setMeta] = useState()
	// const [total, setTotal] = useState([]);

	async function getRequestsList(filters) {
		const params = {
			...filters,
			search: filters?.search,
			request_date:
				filters.request_date !== ''
					? moment(filters.request_date).format('YYYY-MM-DD')
					: '',
			nationality: filters?.nationality,
			page: filters?.page
		}
		const requestsListRes = await webPageRequests(params)
		if (requestsListRes?.status >= 200 && requestsListRes?.status < 300) {
			setRequestsList(requestsListRes?.data?.data)
			setMeta(requestsListRes?.data?.meta)
			// setTotal();
		}
	}

	useEffect(() => {
		getRequestsList(filters)
	}, [filters?.page])

	const goTo = (page = 1) => {
		setFilters({ ...filters, page: page });
	}

	// add new offline application

	// get products list for new application

	return (
		<ShowSubscription module="crm">

			<div className='container'>
				<div className='d-flex justify-content-between align-items-center py-2 px-2 border bg-white'>
					<p style={{ fontWeight: 'bold' }} className='text-dark'>
						{payment.messages.requests}({meta?.total})
					</p>
				</div>

				<Filterrequestes
					filters={filters}
					setFilters={setFilters}
					getRequestsList={getRequestsList}
				/>

				<WebPageRequestsList
					requestsList={requestsList}
					meta={meta}
					goTo={goTo}
				/>
			</div>
		</ShowSubscription>
	)
}

export default WebPageRequests
