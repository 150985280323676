import React, { useEffect, useState } from "react";
import SubscriptionItem from "./SubscriptionItem";
import { paySubscription, subscriptionList } from "services/subscription";
import SubscriptionPaymentModal from "./SubscriptionPaymentModal";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Locale from 'translations';
import { useSBSState } from 'context/global';
import ShowSubscription from 'helpers/ShowSubscription';

function Subscription() {
	const {companyDetails,marketPlace} = Locale
	const { locale} = useSBSState();
	const [subscripList, setSubscripList] = useState([]);
	const [isOpenpaymentModal, setIsOpenPaymentModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const {push} = useHistory()
	const togglePaymentModal = (itemData = null) => {
		setIsOpenPaymentModal(!isOpenpaymentModal);
		setSelectedItem(itemData);
	};

	useEffect(() => {
		async function fetchSubscriptionList() {
			const res = await subscriptionList();
			if( res?.data?.data?.authError) push("/visa/login")
			if (res.status === 200) {
				const data = res.data.data;
				setSubscripList(data);
			}
		}
		fetchSubscriptionList();
		return () => {};
	}, []);

	const onPayment = async ({package_id,package_name }) => {
		const data = {
			package_name,
			package_id,
		};
		const res = await paySubscription(data);
		if (res.status === 200) {
			togglePaymentModal();
		}
	};
	return (
		<div className="container">
			<ShowSubscription module={"safa_offline"}>

				<p className="title-page">
					{locale === "en"&&<p>{companyDetails.safaVisa}</p>}
					<p className="bold-sup"> {marketPlace.subscriptions}</p>
					{locale === "ar"&&<p>{companyDetails.safaVisa}</p>}

				</p>
				<div className="Subscription-box">
					{subscripList.map((item) => (
						<SubscriptionItem
							details={item}
							togglePaymentModal={togglePaymentModal}
						/>
					))}
				</div>

				{isOpenpaymentModal && (
					<SubscriptionPaymentModal
						isOpen={isOpenpaymentModal}
						toggleModal={togglePaymentModal}
						selectedItem={selectedItem}
						onPayment={onPayment}
					/>
				)}
			</ShowSubscription>
		</div>
	);
}

export default Subscription;
