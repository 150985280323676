import FLightView from 'modules/WebBuilder/PreviewServices/Flights';
import PackageHotelView from 'modules/market-place/NewPackageResponse/Details/HotelView';
import PackageAttractionsView from 'modules/market-place/NewPackageResponse/Details/AttractionsView';
import { ReactComponent as CheckMarkCircleIcon } from "assets/images/market-place/tick-circle.svg";
import { ReactComponent as StopIcon } from "assets/images/market-place/stop-icon.svg";
import { ReactComponent as RequiredDocIcon } from "assets/images/market-place/required-document-text.svg";
import Locale from 'translations';
import { itineraryElementsIds } from './helpers/localLookups';


export default function RequestPackageItems({ reservationDetails }) {
  const { inventory, productsBuilder, packages } = Locale;

  const packageFlights = reservationDetails?.product?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.external);
  const packageHotels = reservationDetails?.product?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.hotel);
  const packageAttraction = reservationDetails?.product?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.sightseeing);

  return (
    <>
      {/* flights */}
      {packageFlights?.length > 0 ?
        <div>
          {packageFlights?.map((item, index) => {
            return (
              <div className='public-page-preview-services mt-4' index={index} key={item?.itemable?.id}>
                <FLightView details={item} />
              </div>
            )
          })}
        </div>
        :
        null
      }

      {/* hotels */}
      {packageHotels?.length > 0 ?
        <div className='package-item' id={`tab-${itineraryElementsIds.hotel}`}>
          <h2 className='package-title'>{inventory.messages.hotels}</h2>
          {packageHotels?.map((element, index) => {
            return <PackageHotelView hotelData={element?.itemable} key={index} />
          })}
        </div>
        :
        null
      }

      {/* attractions */}
      {packageAttraction?.length > 0 ?
        <div className='package-item' id={`tab-${itineraryElementsIds.hotel}`}>
          <h2 className='package-title'>{productsBuilder.attraction}</h2>
          {packageAttraction?.map((element, index) => {
            return <PackageAttractionsView attractionData={element?.itemable} key={index} />
          })}
        </div>
        :
        null
      }

      {/* services */}
      <div className='package-item'>
        <h2 className='package-title'>Services</h2>
        <div className='mt-4'>
          <p className="d-flex align-items-center mb-2">
            {reservationDetails?.product?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.internal)?.length > 0
              ? <CheckMarkCircleIcon />
              : <StopIcon />
            }
            <span className="mx-1">{productsBuilder.internalTransportations}</span>
          </p>
          <p className="d-flex align-items-center mb-2">
            {reservationDetails?.product?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.visa)?.length > 0
              ? <CheckMarkCircleIcon />
              : <StopIcon />
            }
            <span className="mx-1">{productsBuilder.visa}</span>
          </p>
          <p className="d-flex align-items-center mb-2">
            {reservationDetails?.product?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.insurance)?.length > 0
              ? <CheckMarkCircleIcon />
              : <StopIcon />
            }
            <span className="mx-1">{productsBuilder.insurance}</span>
          </p>
          <p className="d-flex align-items-center mb-2">
            {reservationDetails?.product?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.otherServices)?.length > 0
              ? <CheckMarkCircleIcon />
              : <StopIcon />
            }
            <span className="mx-1">{productsBuilder.other}</span>
          </p>
        </div>

      </div>


      {/* Terms & conditions */}
      <div className='package-item'>
        <h2 className='package-title'>{packages.termsAndConditions}</h2>
        <div className='mt-4'>
          {reservationDetails?.product?.share_terms ?? (
            <p className="text-muted text-center">Not Found Data</p>
          )}
        </div>
      </div>

      {/* Required Documents */}
      <div className='package-item required-docs'>
        <div className='d-flex align-items-center'>
          <RequiredDocIcon />
          <h2 className='package-title mx-2'>{productsBuilder.requiredDocuments}</h2>
        </div>
        <div className='mt-4'>
          {reservationDetails?.product?.share_required_documents ?? (
            <p className="text-muted text-center">Not Found Data</p>
          )}
        </div>
      </div>
    </>
  )
}
