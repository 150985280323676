import { ServiceCardDetails } from 'modules/WebBuilder-V2/shared/ServiceCardDetails'
import React from 'react';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';

const hotelBlocksType = "hotelBlocksType";

export default function ToursTheme3({ items, sortHotelsItems, focusContainer, editItem, itemsBoxActions, type }) {
  return (
    <div className="slider-edit-container">
      {items.map((props, index) => (
        <DragAndDropContainer
          accept={hotelBlocksType}
          type={hotelBlocksType}
          id={props?.id}
          index={index}
          action={SORT_ITEMS_IN_CONTAINER_ACTION}
          moveItemInContainer={(dragIndex, hoverIndex) =>
            sortHotelsItems({ dragIndex, hoverIndex })
          }
          key={props?.id || index}

        >
          <EditBox
            editMode={focusContainer === type}
            actions={[
              ...editItem(props, index),
              ...itemsBoxActions(props?.id),
            ]}
          >
            <ServiceCardDetails {...props} itemType="tours" />
          </EditBox>
        </DragAndDropContainer>
      ))}

    </div>
  )
}
