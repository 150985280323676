import React from "react";
import globalSrc from "assets/images/market-place/servicesIcon/new-global.png";
import umrahSrc from "assets/images/market-place/servicesIcon/new-umrah.png";
import MosqueIcon from "assets/images/market-place/services-mazarat-icon.svg";
import FlightIcon from "assets/images/market-place/services-flight-icon.svg";
import BusIcon from "assets/images/market-place/services-transport-icon.svg";
import HotelIcon from "assets/images/market-place/services-hotel-icon.svg";
import ToursIcon from "assets/images/market-place/services-tour-icon.svg";
import VisaIcon from "assets/images/market-place/services-visaonline-icon.svg";
import PackagesIcon from "assets/images/market-place/services-packages-icon.svg";
import Locale from "translations";
// import SafaLogo from "assets/images/safa_logo.png";
import { useHistory } from "react-router-dom";

export default function NewMarketPlaceUI() {
	const { marketPlace, visa, inventory } = Locale;

	const history = useHistory();
	const boxLength = [
		{ name: "umrah", title: inventory.umrahBookingServices },
		{ name: "global", title: inventory.globalBookingServices },
	];

	return (
		<div className="container">
			<div className="d-flex flex-wrap justify-content-center align-items-center">
				{/* <img
					src={SafaLogo}
					alt="safa soft logo"
					className="mt-3 mb-5"
					width="195px"
				/> */}
				<h1 className="text-center mb-3 title-marketPlace w-100 mb-4">
					{marketPlace.startChoosingYourService}
				</h1>
				<div
					className="d-flex justify-content-center align-items-center flex-wrap w100-mo w-100"
					style={{ gap: "30px" }}
				>
					{boxLength.map((item, index) => (
						<div className="new-market-service-box col-md-6 col-12" key={item.name}>
							<div
								className="main-img-container pointer"
								onClick={() => {
									history.push(
										item.name === "umrah"
											? "/market-place-new/umrah/hotel"
											: "/market-place-new/global/hotel"
									);
									localStorage.setItem("market-palce-service", item.name);
								}}
							>
								<img
									src={item.name === "umrah" ? umrahSrc : globalSrc}
									className="img-fluid main-img"
									alt={item.name}
								/>
							</div>
							<h2 className="title">{item.title}</h2>
							{/* available services */}
							<div className="available-services">
								<h3>{inventory.availableServices}</h3>
								<ul className="services-list mt-3">
									<li
										className="service-item pointer pointer"
										onClick={() => {
											history.push(
												item.name === "umrah"
													? "/market-place-new/umrah/flight"
													: "/market-place-new/global/flight"
											);
											localStorage.setItem("market-palce-service", item.name);
										}}
									>
										<img
											src={FlightIcon}
											title={marketPlace.flights}
											alt={marketPlace.flights}
											width={24}
										/>
										<span className="mx-2">{marketPlace.flights}</span>
									</li>

									<li
										className="service-item pointer pointer"
										onClick={() => {
											history.push(
												item.name === "umrah"
													? "/market-place-new/umrah/hotel"
													: "/market-place-new/global/hotel"
											);
											localStorage.setItem("market-palce-service", item.name);
										}}
									>
										<img
											src={HotelIcon}
											title={marketPlace.hotels}
											alt={marketPlace.hotels}
											width={24}
										/>
										<span className="mx-2">{marketPlace.hotels}</span>
									</li>
									<li
										className="service-item pointer pointer"
										onClick={() => {
											history.push(
												item.name === "umrah"
													? "/market-place-new/umrah/visa"
													: "/market-place-new/global/visa"
											);
											localStorage.setItem("market-palce-service", item.name);
										}}
									>
										<img
											src={VisaIcon}
											title={marketPlace.onlineVisa}
											alt={marketPlace.onlineVisa}
											width={24}
										/>
										<span className="mx-2">{marketPlace.onlineVisa}</span>
									</li>

									<li
										className="service-item pointer"
										onClick={() => {
											history.push(
												item.name === "umrah"
													? "/market-place-new/umrah/transfer"
													: "/market-place-new/global/transfer"
											);
											localStorage.setItem("market-palce-service", item.name);
										}}
									>
										<img
											src={BusIcon}
											title={inventory.messages.transport}
											alt={inventory.messages.transport}
											width={24}
										/>
										<span className="mx-2">{inventory.messages.transport}</span>
									</li>

									<li
										className="service-item pointer"
										onClick={() => {
											history.push(
												item.name === "umrah"
													? "/market-place-new/umrah/mazarat"
													: "/market-place-new/global/tours"
											);
											localStorage.setItem("market-palce-service", item.name);
										}}
									>
										{/* <HotelIcon title="Flight" /> */}
										{item.name === "umrah" ? (
											<img
												src={MosqueIcon}
												title={marketPlace.mazarat}
												alt={marketPlace.mazarat}
												width={24}
											/>
										) : (
											<img
												src={ToursIcon}
												title={marketPlace.tours}
												alt={marketPlace.tours}
												width={24}
											/>
										)}
										{item.name === "umrah" ? (
											<span className="service-new-item">{visa.New}</span>
										) : null}
										<span className="mx-2">
											{item.name === "umrah"
												? marketPlace.mazarat
												: marketPlace.tours}
										</span>
									</li>
									{item.name === "umrah" ?
										<li
											className="service-item pointer"
											onClick={() => {
												history.push(
													item.name === "umrah"
														? "/market-place-new/umrah/packages"
														: "/market-place-new/global/packages"
												);
												localStorage.setItem("market-palce-service", item.name);
											}}
										>
											<img
												src={PackagesIcon}
												title={marketPlace.packages}
												alt={marketPlace.transfer}
												width={24}
											/>
											<span className="mx-2">{marketPlace.packages}</span>
										</li>
										:
										null
									}
								</ul>
							</div>
							<button
								className="btn btn-start-service"
								onClick={() => {
									history.push(
										item.name === "umrah"
											? "/market-place-new/umrah/hotel"
											: "/market-place-new/global/hotel"
									);
									localStorage.setItem("market-palce-service", item.name);
								}}
							>
								{marketPlace.start}
							</button>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
