import React, { useEffect, useState } from "react";
import Filterrequestes from "./Filterrequestes";
import Locale from "translations";
import ListRequestes from "./listRequestes";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import validate, { isFormValid } from "helpers/validate";
import NumberField from "components/Form/NumberField/NumberField";
import {
	FetchProductLookupd,
	addApplication,
	downloadPackageAmountDocuments,
	downloadPackagebranchDocuments,
	fetchApplicationReport,
	fetchApplicationReservationReport,
	productsListForApplication,
	requestsListReservations,
} from "services/productbuilder";
import moment from "moment";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useHistory } from "react-router-dom";
import ShowForPermission from "helpers/showForPermission";
import { useSBSState } from "context/global";
import PlusIcon from "../Pricing/IconsSVG/PlusIcon";
import { store } from "react-notifications-component";
import { ApllicationReservationReport } from "components/Printing/apllicationReservationReport";
import { ApplicationReport } from "components/Printing/apllicationReport";
import { ReactComponent as PAXIcon } from "assets/images/product-builder/profile.svg";
import ShowSubscription from 'helpers/ShowSubscription';

function Requestes() {
	const { payment, inventory, productsBuilder, marketPlace } = Locale;
	const { allCountries } = useSBSState();
	const countries = allCountries;
	const history = useHistory();
	const [errors, setErrors] = useState();
	const [requestsList, setRequestsList] = useState();
	const [applicationProducts, setApplicationProducts] = useState([]);
	const [filters, setFilters] = useState({
		trip_name: "",
		trip_type: "",
		departure_date: "",
		status: "",
	});
	const [meta, setMeta] = useState();
	// const [total, setTotal] = useState([]);
	const [newApplicationData, setNewApplicationData] = useState({
		tripname: "",
		ownername: "",
		owneremail: "",
		phoneCode: "",
		adultsPax: "",
		childPax: "",
		phoneNumber: "",
		clientemail: "",
		clientname: "",
		reservation_category: null
	});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const { locale } = useSBSState();

	const [createApplicationIsOpen, setCreateApplicationIsOpen] = useState(false);
	const toggleCreateApplicationModal = () => {
		setCreateApplicationIsOpen(!createApplicationIsOpen);
	};
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const checkFormErrors = () => {
		let submitError = {};
		Object.keys(newApplicationData).forEach((key) => {
			debugger
			if (key !== "childPax") {
				submitError = {
					...submitError,
					...validate(
						{ name: key, value: newApplicationData[key] },
						{ required: newApplicationData.reservation_category === "private" && key === "adultsPax" ? false : true }
					),
				};

			}
		});
		setErrors(() => submitError);
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	async function getRequestsList(filters) {
		const params = {
			...filters,
			trip_type: filters?.trip_type?.id,
			departure_date:
				filters.departure_date !== ""
					? moment(filters.departure_date).format("YYYY-MM-DD")
					: "",
			status: filters?.status?.title,
		};
		const requestsListRes = await requestsListReservations(params);
		if (requestsListRes?.status >= 200 && requestsListRes?.status < 300) {
			setRequestsList(requestsListRes?.data?.data);
			setMeta(requestsListRes?.data?.meta);
			// setTotal();
		}
	}

	useEffect(() => {
		getRequestsList(filters);
	}, [filters]);

	const goTo = (page = 1) => {
		setFilters({ ...filters, page: page });
	};

	// add new offline application
	async function createNewApplication() {
		let data = {
			product_uuid: newApplicationData?.tripname?.product_uuid,
			contact_info: {
				full_name: newApplicationData?.ownername,
				email: newApplicationData?.owneremail,
				phone: newApplicationData?.phoneNumber,
				phone_code: newApplicationData?.phoneCode?.id,
				client_name: newApplicationData?.clientname,
				client_email: newApplicationData?.clientemail,
			},
			reservation_category: newApplicationData?.reservation_category
		};
		if (newApplicationData.reservation_category === "shared") {
			data = {
				...data,
				adults_pax: +newApplicationData?.adultsPax,
				children_pax: +newApplicationData?.childPax,
			}
		}

		const createApplicationRes = await addApplication(data);
		if (
			createApplicationRes?.status >= 200 &&
			createApplicationRes?.status < 300
		) {
			history.push(
				`/products-builder/viewrequests/${createApplicationRes?.data?.data?.id}`
			);
		}
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			createNewApplication();
		} else {
		}
	}, [isErrorLoaded]);

	// get products list for new application
	async function getProductListForNewApplication(type, adultsPax = 0, childPax = 0) {
		let data;
		if (type === "private") {
			data = { reservation_category: type }
		} else {
			data = {
				adults_pax: adultsPax || 0,
				children_pax: childPax || 0,
			}
		}

		const productListRes = await productsListForApplication(data);
		if (productListRes?.status >= 200 && productListRes?.status < 300) {
			setApplicationProducts(productListRes?.data?.data);
		}
	}

	async function downloadPackageAmount(id) {
		const res = await downloadPackageAmountDocuments(id);
		if (res?.status === 200) {
			const blob = new Blob([res.data], {
				type: res.data.type,
			});
			const objectUrl = window.URL.createObjectURL(blob);
			var anchor = document.createElement("a");
			anchor.target = "_blank";
			anchor.href = objectUrl;
			anchor.setAttribute(
				"download",
				`${"Package Amount "}-${moment().format("DD-MM-YYYY")}`
			);
			anchor.click();
		} else {
			store.addNotification({
				title: "",
				message: "some thing Went Wrong",

				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 5000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}
	async function downloadPackagebranch() {
		const res = await downloadPackagebranchDocuments();
		if (res?.status === 200) {
			const blob = new Blob([res.data], {
				type: res.data.type,
			});
			const objectUrl = window.URL.createObjectURL(blob);
			var anchor = document.createElement("a");
			anchor.target = "_blank";
			anchor.href = objectUrl;
			anchor.setAttribute(
				"download",
				`${"Package Branch "}-${moment().format("DD-MM-YYYY")}`
			);
			anchor.click();
		} else {
			store.addNotification({
				title: "",
				message: "some thing Went Wrong",

				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 5000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	const roomTypesLookup = {
		1: marketPlace.x1,
		2: marketPlace.x2,
		3: marketPlace.x3,
		4: marketPlace.x4,
		5: marketPlace.x5,
		6: marketPlace.x6,
		7: marketPlace.x7,
		8: marketPlace.suite,
		9: marketPlace.flatRate,
	};
	/***Start Download Application */

	const dawnloadApplication = async (id) => {
		const { data } = await fetchApplicationReport(id);
		console.log(data?.data);
		const allTravelers =
			data?.data?.travellers?.length > 0
				? data?.data?.travellers.map((travel, index) => {
					return `<tr>
						<td>${index + 1}</td>
						
						<td >${travel.passport_number ? travel.passport_number : ""}</td>
						<td>${travel.name}</td>
					</tr>
								</tr>`;
				})
				: [];
		const hotelData = data.data.hotels?.map(
			(item, index) => `<div class="col-6">
				<label>${inventory.hotel} : </label>
				<span>${item.city} - ${item.name}</span>
			</div>`
		);
		// inventory.accommodationType
		const RoomTypeData = data.data.hotels?.map(
			(item, index) => `<div class="col-6">
			<label>${inventory.roomType}: </label>
			<span>${roomTypesLookup[item.room_type]}</span>
		</div>`
		);
		const dir = locale == "en" ? "ltr" : "rtl";
		if (data) {
			let respos = ApplicationReport;

			const keyValuePair = {
				"[dir]": dir,
				"[applicationData]": inventory.applicationData,
				"[appNum]": inventory.appNum,
				"[programName]": inventory.programName,
				"[tripNum]": inventory.tripNum,
				"[clientName]": inventory.clientName,
				"[hotel]": inventory.hotel,
				"[roomType]": inventory.roomType,
				"[accommodationType]": inventory.accommodationType,
				"[appOwnerData]": inventory.appOwnerData,
				"[fullName]": inventory.fullName,
				"[nationalId]": inventory.nationalId,
				"[email]": inventory.email,
				"[phone]": inventory.phone,
				"[num]": inventory.num,
				"[passport]": inventory.passport,
				"[name]": inventory.name,
				"[motamersTotal]": inventory.motamersTotal,
				"[adultNumber]": inventory.adultNumber,
				"[childrenNumber]": inventory.childrenNumber,

				"[ref]": data.data.ref,
				"[nameapp]": data.data.name,
				"[trip_number]": data.data.trip_number,
				"[client_name]": data.data.client_name,
				"[hotels]": hotelData.join(" "),
				"[allTravelers]": allTravelers.join(" "),
				"[full_name]": data.data.contact_info.name,
				"[email_address]": data.data.contact_info.email,
				"[phone_number]": `${data.data.contact_info.phone_code}${data.data.contact_info.phone}`,
				"[children_pax]": data.data.children_pax,
				"[adult_pax]": data.data.adult_pax,
				"[pax]": data.data.pax,
				"[RoomTypeData]": RoomTypeData.join(" "),
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
				<html>
				<head>
				<title> print Invoice</title>
				<style>

				</style>
				</head>
				<body onLoad="{()=>window.print()}">${respos}</body>
				</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	/***End Download Application*/

	/***Start Download Application Reservation*/

	const dawnloadApplicationReservation = async (id) => {
		const { data } = await fetchApplicationReservationReport(id);
		const allTravelers =
			data?.data?.travellers?.length > 0
				? data?.data?.travellers.map((travel, index) => {
					return `<tr>
							<td>${index + 1}</td>
							<td>${travel.name ? travel.name : ""}</td>
							<td >${travel.passport_number ? travel.passport_number : ""}</td>
						</tr>
									</tr>`;
				})
				: [];

		const allHotel =
			data?.data?.hotels?.length > 0
				? data?.data?.hotels.map((hotel, index) => {
					return `<tr>
								<td>${index + 1}</td>
								<td>${hotel.city}</td>
								<td>${hotel.name}</td>
								<td>${hotel.rating} ${inventory.Stars} </td>
								<td>${hotel.nights}</td>
								<td>${roomTypesLookup[hotel.room_type]}</td>
							</tr>`;
				})
				: [];
		const allOfferedServices =
			data?.data?.items?.length > 0
				? data?.data?.items.map((item, index) => {
					return `<tr>
								<td>${index + 1}</td>
								<td>${item.type}</td>
								<td >${item.price} ${data.data.currency}</td>
							</tr>
										</tr>`;
				})
				: [];

		const allExtraSevices =
			data?.data?.extra_services?.length > 0
				? data?.data?.extra_services.map((service, index) => {
					return `<tr>
									<td>${index + 1}</td>
									<td>${service.name}</td>
									<td>${service.quantity}</td>
									<td>${service.price} ${data.data.currency}</td>
								</tr>`;
				})
				: [];
		const dir = locale == "en" ? "ltr" : "rtl";
		if (data) {
			let respos = ApllicationReservationReport;

			const keyValuePair = {
				"[dir]": dir,
				"[appReservation]": inventory.appReservation,
				"[basicData]": inventory.basicData,
				"[date]": inventory.date,
				"[company]": inventory.company,
				"[clientName]": inventory.clientName,
				"[clientName1]": inventory.clientName,

				"[telephone]": inventory.telephone,
				"[phone]": inventory.phone,
				"[arrivalDate]": inventory.arrivalDate,
				"[departureDate]": inventory.departureDate,
				"[notes]": inventory.notes,
				"[city]": inventory.city,
				"[tripNum]": inventory.tripDate,
				"[hotelName]": inventory.hotelName,
				"[level]": inventory.level,
				"[nightsNumber]": inventory.nightsNumber,
				"[accommodationType]": inventory.accommodationType,
				"[offeredService]": inventory.offeredService,
				"[extraService]": inventory.extraService,
				"[motamersName]": inventory.motamersName,
				"[motamerName]": inventory.motamerName,
				"[Passport]": inventory.passport,
				"[age]": inventory.age,
				"[applicationTotal]": inventory.applicationTotal,
				"[conditions]": inventory.conditions,
				"[employee]": inventory.employee,
				"[signatureClient]": inventory.signatureClient,
				"[AccommodationDataHotel]": inventory.AccommodationDataHotel,
				"[num]": inventory.num,
				"[num1]": inventory.num,
				"[num2]": inventory.num,
				"[num3]": inventory.num,

				"[Service]": inventory.Service,
				"[Service1]": inventory.Service,

				"[price]": inventory.price,
				"[price1]": inventory.price,
				"[paxNum]": inventory.paxNum,
				"[email]": inventory.email,

				"[date_api]": data.data.date.slice(0, 10),
				"[company_api]": data.data.company.name[locale] ? data.data.company.name[locale] : "",
				"[client_name]": data.data.client?.name ? data.data.client?.name : "",
				"[trip_Date]": data.data.departure_date.slice(0, 10),
				"[departure_date]": data.data.return_date.slice(0, 10),
				"[return_date]": data.data.first_check_in.slice(0, 10),
				"[allHotel]": allHotel.join(" "),
				"[allExtraSevices]": allExtraSevices.join(" "),
				"[allTravelers]": allTravelers.join(" "),
				"[total_price]": `${data.data.total_price} ${data.data.currency}`,
				"[allOfferedServices]": allOfferedServices.join(" "),
				"[email_api]": data.data.client?.email ? data.data.client?.email : "",
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
	
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${respos}</body>
					</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	/***End Download Application Reservation*/
	const [TripOption, setTripOption] = useState([]);

	const [TripId, setTripId] = useState(null);
	useEffect(() => {
		const fetchLookups = async () => {
			const res = await FetchProductLookupd();
			const formatLookup = res?.data?.data?.map((lookup) => {
				return { ...lookup, label: lookup?.name, value: lookup?.id };
			});
			setTripOption([...formatLookup]);
		};
		fetchLookups();
	}, []);

	return (
		<ShowSubscription module="crm">
				<div className="container  bg-white rounded py-2 px-4">
					<div className="header-Filter">
						<p style={{ fontWeight: "bold" }} className="text-dark">
							{payment.messages.requests}({meta?.total})
						</p>
						<ShowForPermission permission="Manage-Trip-Management-Module">
							<button
								className="btn-Product-Filter bg-nxt"
								onClick={toggleCreateApplicationModal}
							>
								<PlusIcon />
								{payment.messages.addappliction}
							</button>
						</ShowForPermission>
					</div>
					<Filterrequestes
						filters={filters}
						setFilters={setFilters}
						getRequestsList={getRequestsList}
					/>
					<div className="btn-reports mt-1 mb-2 d-flex justify-content-end">
						<button className="btn bg-succ  mx-3" onClick={toggle}>
							<i class="fas fa-cloud-download-alt"></i>{" "}
							{payment.messages.TripStatement}
						</button>

						<button className="btn  bg-succ" onClick={downloadPackagebranch}>
							<i class="fas fa-cloud-download-alt"></i>{" "}
							{payment.messages.BranchReservationStatement}
						</button>
					</div>

					<ListRequestes
						requestsList={requestsList}
						meta={meta}
						goTo={goTo}
						dawnloadApplication={dawnloadApplication}
						dawnloadApplicationReservation={dawnloadApplicationReservation}
					/>

					<Modal
						isOpen={createApplicationIsOpen}
						size="md"
						centered={true}
						hasFooter={false}
						className='application-form'
					>
						<ModalHeader toggle={toggleCreateApplicationModal}>
							{payment.messages.addappliction}
						</ModalHeader>
						<ModalBody>
							<div className="container p-2 pb-4">
								<div className="row">
									<div className="col-12 row align-items-center mb-2">
										{/* private reservation */}
										<div className="col-md-4 col-6 d-flex align-items-center">
											<input
												class="mx-2 radio-input"
												type="radio"
												name="private"
												id="private"
												value="private"
												checked={newApplicationData.reservation_category === "private"}
												onChange={(e) => {
													setNewApplicationData({
														...newApplicationData,
														reservation_category: e.target.value,
														tripname: null,
														adultsPax: 0,
														childPax: 0,
													});
													getProductListForNewApplication(e.target.value);
												}}
											/>
											<label className="form-check-label pointer" for="private">
												{productsBuilder.private}
											</label>
										</div>
										{/* shared reservation  */}
										<div className="col-md-4 col-6 d-flex align-items-center">
											<input
												class="mx-2 radio-input"
												type="radio"
												name="shared"
												id="shared"
												value="shared"
												checked={newApplicationData.reservation_category === "shared"}
												onChange={(e) => {
													setNewApplicationData({
														...newApplicationData,
														reservation_category: e.target.value,
														tripname: null,
													});
													setApplicationProducts([]);
												}}
											/>
											<label className="form-check-label pointer" for="shared">
												{productsBuilder.shared}
											</label>
										</div>
									</div>


									{/* adult pax */}
									{newApplicationData.reservation_category === "shared" ?
										<>
											<div className="col-md-6 col-12 mb-1 application-pax">
												<span className="control-field__label">
													{productsBuilder.adultsPax}
												</span>
												<div className="box control-field py-2 px-1 mt-1">
													<p className="d-flex align-items-center">
														<PAXIcon />
														<span className="mx-1">{inventory.messages.adults}</span>
													</p>
													<div className="d-flex align-items-center">
														<button
															className={`pax-btn ${!newApplicationData?.adultsPax ||
																+newApplicationData?.adultsPax === 0
																? "disabled"
																: ""
																}`}
															onClick={() => {
																const newAdultValue = +newApplicationData?.adultsPax === 0 ? 0 : +newApplicationData?.adultsPax - 1;
																setNewApplicationData({ ...newApplicationData, tripname: "", adultsPax: newAdultValue });
																getProductListForNewApplication(
																	"shared",
																	newAdultValue,
																	newApplicationData.childPax
																);
															}}
															disabled={+newApplicationData?.adultsPax === 0}
														>
															<i className="fas fa-minus" />
														</button>
														<span className="mx-2">
															{newApplicationData.adultsPax || 0}
														</span>
														<button
															className="pax-btn"
															onClick={() => {
																const newAdultValue = +newApplicationData?.adultsPax + 1;
																setNewApplicationData({ ...newApplicationData, tripname: "", adultsPax: newAdultValue });
																getProductListForNewApplication(
																	"shared",
																	newAdultValue,
																	newApplicationData.childPax
																);
															}}
														>
															<i className="fas fa-plus" />
														</button>
													</div>
												</div>
											</div>
											{/* child pax */}
											<div className="col-md-6 col-12 mb-2 application-pax">
												<span className="control-field__label  ">
													{productsBuilder.childrenPax}
												</span>
												<div className="box control-field py-2 px-1 mt-1">
													<p className="d-flex align-items-center">
														<PAXIcon />
														<span className="mx-1">
															{inventory.messages.children}
														</span>
													</p>
													<div className="d-flex align-items-center">
														{/* decrease */}
														<button
															className={`pax-btn ${!newApplicationData?.childPax ||
																+newApplicationData?.childPax === 0
																? "disabled"
																: ""
																}`}
															onClick={() => {
																const newChildValue = +newApplicationData?.childPax === 0 ? 0 : +newApplicationData?.childPax - 1;
																setNewApplicationData({ ...newApplicationData, tripname: "", childPax: newChildValue });
																getProductListForNewApplication("shared", newApplicationData.adultsPax, newChildValue);
															}}
															disabled={+newApplicationData?.childPax === 0}
														>
															<i className="fas fa-minus" />
														</button>

														<span className="mx-2">
															{newApplicationData.childPax || 0}
														</span>
														{/* increase */}
														<button
															className="pax-btn"
															onClick={() => {
																const newChildValue = +newApplicationData?.childPax + 1;
																setNewApplicationData({ ...newApplicationData, tripname: "", childPax: newChildValue });
																getProductListForNewApplication("shared", newApplicationData.adultsPax, newChildValue);
															}}
														>
															<i className="fas fa-plus" />
														</button>
													</div>
												</div>
											</div>
										</>
										: null
									}
									{/* package name */}
									<div className="col-12 my-2">
										<SelectField
											label={`${inventory.messages.packageName} *`}
											placeholder={inventory.messages.select}
											name={"tripname"}
											id="tripname"
											value={newApplicationData.tripname?.label}
											onChange={(e) => {
												setNewApplicationData({
													...newApplicationData,
													tripname: e,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "tripname", value: e },
														{ required: true }
													),
												});
											}}
											options={applicationProducts}
											color={errors?.tripname?.required ? "danger" : ""}
											errors={errors?.tripname}
										/>
									</div>
									{/* owner name */}
									<div className="col-md-6 col-12 my-2">
										<TextField
											value={newApplicationData.ownername}
											label={`${inventory.messages.ownername} *`}
											type="text"
											name="ownername"
											id="ownername"
											placeholder={inventory.messages.ownername}
											onChange={(e) => {
												setNewApplicationData({
													...newApplicationData,
													ownername: e.target.value,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "ownername", value: e.target.value },
														{ required: true }
													),
												});
											}}
											color={errors?.ownername?.required ? "danger" : ""}
											errors={errors?.ownername}
										/>
									</div>
									{/* owner email */}
									<div className="col-md-6 col-12 my-2">
										<TextField
											label={`${inventory.messages.owneremail} *`}
											type="email"
											name="owneremail"
											id="owneremail"
											value={newApplicationData.owneremail}
											placeholder={inventory.messages.owneremail}
											min={3}
											onChange={(e) => {
												setNewApplicationData({
													...newApplicationData,
													owneremail: e.target.value,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "owneremail", value: e.target.value },
														{ required: true, email: true }
													),
												});
											}}
											color={errors?.owneremail?.required ? "danger" : ""}
											errors={errors?.owneremail}
										/>
									</div>
									{/* phone */}
									<div className="row w-100 mx-0 my-2">
										{/* phone code */}
										<div className="col-3">
											<SelectField
												label={`${inventory.messages.phoneCode} *`}
												placeholder={inventory.messages.select}
												name={"tripname"}
												id="tripname"
												value={newApplicationData.phoneCode?.label}
												onChange={(e) => {
													setNewApplicationData({
														...newApplicationData,
														phoneCode: e,
													});
													setErrors({
														...errors,
														...validate(
															{ name: "phoneCode", value: e },
															{ required: true }
														),
													});
												}}
												options={countries?.map((country) => {
													return {
														id: country?.phone_code,
														label: country?.phone_code,
														name: country?.phone_code,
														image: country?.image,
													};
												})}
												color={errors?.phoneCode?.required ? "danger" : ""}
												errors={errors?.phoneCode}
											/>
										</div>
										{/* phone */}
										<div className="col-9">
											<NumberField
												type={"phone"}
												name="phoneNumber"
												id="phoneNumber"
												label={`${inventory.messages.phoneNumber} *`}
												readOnly={true}
												removeArrow={true}
												value={newApplicationData.phoneNumber}
												placeholder={inventory.messages.phoneNumber}
												min={3}
												onChange={(e) => {
													setNewApplicationData({
														...newApplicationData,
														phoneNumber: e.target.value,
													});
													setErrors({
														...errors,
														...validate(
															{ name: "phoneNumber", value: e.target.value },
															{ required: true }
														),
													});
												}}
												color={
													errors?.phoneNumber?.required ||
														errors?.phoneNumber?.min
														? "danger"
														: ""
												}
												errors={errors?.phoneNumber}
											/>
										</div>
									</div>

									{/* client name */}
									<div className="col-md-6 col-12">
										<TextField
											value={newApplicationData.clientname}
											label={`${inventory.messages.clientname} *`}
											type="text"
											name="ownername"
											id="ownername"
											placeholder={inventory.messages.clientname}
											onChange={(e) => {
												setNewApplicationData({
													...newApplicationData,
													clientname: e.target.value,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "clientname", value: e.target.value },
														{ required: true }
													),
												});
											}}
											color={errors?.clientname?.required ? "danger" : ""}
											errors={errors?.clientname}
										/>
									</div>
									{/* client email */}
									<div className="col-md-6 col-12">
										<TextField
											label={`${inventory.messages.clientemail} *`}
											type="email"
											name="owneremail"
											id="owneremail"
											value={newApplicationData.clientemail}
											placeholder={inventory.messages.clientemail}
											min={3}
											onChange={(e) => {
												setNewApplicationData({
													...newApplicationData,
													clientemail: e.target.value,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "clientemail", value: e.target.value },
														{ required: true, email: true }
													),
												});
											}}
											color={errors?.clientemail?.required ? "danger" : ""}
											errors={errors?.clientemail}
										/>
									</div>

									<button
										className="btn mt-3  w-100 "
										onClick={checkFormErrors}
										style={{
											backgroundColor: "#DAA249",
											fontSize: "18px",
											color: "#FFFFFF",
										}}
										disabled={!newApplicationData.reservation_category || (newApplicationData.reservation_category === "shared" && !newApplicationData.adultsPax)}
									>
										{inventory.messages.create}
									</button>
								</div>
							</div>
						</ModalBody>
					</Modal>

					<Modal isOpen={isOpen} size="md" centered={true} hasFooter={false}>
						<ModalHeader toggle={toggle}>
							{payment.messages.TripStatement}
						</ModalHeader>
						<ModalBody>
							<div className="container p-2 pb-4 ">
								<div>
									<SelectField
										label={`${inventory.messages.tripname} *`}
										placeholder={inventory.messages.select}
										name={"tripname"}
										id="tripname"
										value={TripId?.label}
										onChange={(e) => {
											setTripId(e);
										}}
										options={TripOption}
									/>
								</div>

								<button
									className="btn mt-3  w-100 "
									style={{
										backgroundColor: "#DAA249",
										fontSize: "18px",
										color: "#FFFFFF",
									}}
									onClick={() => {
										downloadPackageAmount(TripId.id);
										toggle();
									}}
									disabled={TripId ? false : true}
								>
									Export
								</button>
							</div>
						</ModalBody>
					</Modal>
				</div>
		</ShowSubscription>
	);
}

export default Requestes;
