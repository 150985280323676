import React, { useEffect, useState } from 'react'
import Locale from 'translations';
import { ReactComponent as TrashIcon } from "assets/images/market-place/trash-trash.svg";
import SelectField from 'components/Form/SelectField/SelectField';
import { setPackageRoomAvailabilities } from 'services/productbuilder';
import { store } from 'react-notifications-component';
import { roomTypesLookup } from './helpers/localLookups';


export default function RequestsGuestsPerRoom({ reservationDetails, roomsData, setRoomsData, productItemsPrices, setProductItemsPrices, setReload }) {
  const { inventory, marketPlace, productsBuilder } = Locale;
  const reservationStatus = reservationDetails?.reservation_status;
  const [roomsAvailabilities, setRoomAvalibities] = useState(null);
  const [childrenRemaining, setChildrenRemaining] = useState(reservationDetails?.product?.remaining_children_pax);
  const maxChildrenCount = 2;
  const hotelsList = productItemsPrices?.filter((product) => product?.id === 6);
  const servicesWithoutHotels = productItemsPrices?.filter((product) => product?.id !== 6);



  function createAvailability() {
    let roomAvailabilitiesFormat = reservationDetails?.product?.roomsAvailabilities?.map((room) => {
      const roomCount = roomsData?.filter(r => r?.roomType?.room_type === room?.room_type)[0]?.roomCount || 0;
      const roomRemainingCount = room?.room_remaining_availability > 0 ? Math.floor((room?.room_remaining_availability / +room?.room_type) + roomCount) : roomCount;
      return {
        id: room?.id,
        room_type: room?.room_type,
        name: roomTypesLookup?.[room?.room_type],
        value: room?.room_type,
        label: roomTypesLookup?.[room?.room_type],

        roomRemainingAvailability:
          [...Array(roomRemainingCount || 0).keys()].map(i => (
            { id: i + 1, value: i + 1, label: `${i + 1}`, name: `${i + 1}` }
          )),
        disabled: roomsData?.find(r => r?.roomType?.room_type === room?.room_type) ? true : false,
      };
    });
    setRoomAvalibities(roomAvailabilitiesFormat);
  }

  function getRoomPriceForEachService(roomType, roomCount, childCount) {
    let servicesPrice = [];
    let productItemsPricesClone = [...productItemsPrices];
    let combinations = reservationDetails?.product_price_combinations;
    let hotelCombinationForRoomType = combinations?.find(comb => +comb?.combinations[0]?.room_type === roomType || +comb?.combinations[0]?.adults_count === roomType);
    productItemsPricesClone?.forEach(item => {
      let adultPrice = 0;
      let childPrice = 0;
      // handle hotels adult and child prices
      if (item?.id === 6) {
        hotelCombinationForRoomType?.combinations?.forEach(hotelCombination => {
          debugger
          if (hotelCombination?.product_hotel_id === item?.product_hotel_id) {
            adultPrice = +hotelCombination?.adults_count * +hotelCombination?.price_adult * +roomCount;
            childPrice = +hotelCombination?.price_child * +childCount;
          }
        })
      } else {
        adultPrice = +item?.price_adult * (+roomCount * roomType);
        childPrice = +item?.price_child * +childCount;
      }

      servicesPrice.push({
        ...item,
        pricePerRoom: adultPrice + (childPrice || 0),
        combination_id: hotelCombinationForRoomType?.id
      });
    })
    return servicesPrice;
  }


  function handleRoomType(roomIndex, value) {
    let roomAvailabilitiesClone;
    let prevRoom = roomsData[roomIndex];
    let roomsDataClone = [...roomsData];
    let currentRoom = roomsDataClone[roomIndex];
    let hotelPriceForRoom = getRoomPriceForEachService(value?.room_type, +currentRoom?.roomCount);
    currentRoom = {
      ...currentRoom,
      servicesPrices: hotelPriceForRoom,
      roomType: value,
      combination_id: hotelPriceForRoom[0]?.combination_id
    }
    roomsDataClone[roomIndex] = currentRoom;
    // reset Availabilities
    if (prevRoom?.roomType) {
      roomAvailabilitiesClone = handleAvailabilities(value?.room_type, prevRoom?.roomType?.room_type, true);
    } else {
      roomAvailabilitiesClone = handleAvailabilities(value?.room_type, null, true)
    }

    setRoomsData(roomsDataClone);
    setRoomAvalibities(roomAvailabilitiesClone);
  }

  function handleRoomsCount(roomIndex, value) {
    let roomsDataClone = [...roomsData];
    let currentRoom = roomsDataClone[roomIndex];
    let hotelPriceForRoom = getRoomPriceForEachService(currentRoom?.roomType?.room_type, +value, currentRoom?.childCount);
    currentRoom = {
      ...currentRoom,
      roomCount: value,
      servicesPrices: hotelPriceForRoom,
      combination_id: hotelPriceForRoom[0]?.combination_id,
      childrenAvailability: handleChildsAvailabilities(value)
    }
    roomsDataClone[roomIndex] = currentRoom;
    setRoomsData(roomsDataClone);
  }

  function handleRoomsChildCount(roomIndex, value) {
    let roomsDataClone = [...roomsData];
    let currentRoom = roomsDataClone[roomIndex];
    const oldChildCount = +currentRoom?.childCount || 0;
    let hotelPriceForRoom = getRoomPriceForEachService(currentRoom?.roomType?.room_type, currentRoom?.roomCount, +value);
    currentRoom = {
      ...currentRoom,
      childCount: value,
      servicesPrices: hotelPriceForRoom,
      combination_id: hotelPriceForRoom[0]?.combination_id
    }

    const newChildRemainig = (childrenRemaining + oldChildCount) - +value;

    roomsDataClone[roomIndex] = currentRoom;
    setRoomsData(roomsDataClone);
    setChildrenRemaining(newChildRemainig);
  }

  function handleChildsAvailabilities(roomCount) {
    const currentChildCount = +roomCount * maxChildrenCount > childrenRemaining ? childrenRemaining : +roomCount * maxChildrenCount;
    const childrenAvailability = [...Array(currentChildCount + 1).keys()].map(count => ({ name: `${count}`, id: `${count}`, value: `${count}` }));
    return childrenAvailability;
  }


  function handleAvailabilities(currentroomType, prevRoomType, disabled) {
    let roomAvailabilitiesClone = [...roomsAvailabilities];
    let prevRoomAvailibityIndex = roomAvailabilitiesClone?.findIndex(availability => availability?.room_type === prevRoomType);
    let currentRoomAvailibityIndex = roomAvailabilitiesClone?.findIndex(availability => availability?.room_type === currentroomType);
    let currentRoomAvailibity = roomAvailabilitiesClone[currentRoomAvailibityIndex];
    let pervRoomAvailibity = roomAvailabilitiesClone[prevRoomAvailibityIndex];
    currentRoomAvailibity = { ...currentRoomAvailibity, disabled: disabled };
    pervRoomAvailibity = { ...pervRoomAvailibity, disabled: false };
    roomAvailabilitiesClone[currentRoomAvailibityIndex] = currentRoomAvailibity;
    roomAvailabilitiesClone[prevRoomAvailibityIndex] = pervRoomAvailibity;
    return roomAvailabilitiesClone;
  }

  function deleteRoom(roomIndex) {
    const roomsClone = [...roomsData];
    let currentRoomType = roomsClone[roomIndex]?.roomType;
    let roomAvailabilitiesClone = handleAvailabilities(currentRoomType?.room_type, null, false);
    const oldChildCount = +roomsClone[roomIndex]?.childCount || 0;
    const newChildRemainig = childrenRemaining + oldChildCount;
    roomsClone.splice(roomIndex, 1);
    setRoomsData(roomsClone);
    setRoomAvalibities(roomAvailabilitiesClone);
    setChildrenRemaining(newChildRemainig);
  }

  function addNewRoom() {
    const emptyRoom = {
      roomType: null,
      roomCount: 0,
      childCount: 0,
    };
    setRoomsData([...roomsData, emptyRoom])
  }

  // calc prices foreach service
  function calculateServicesPrices() {
    let servicesPricesClone = productItemsPrices && [...productItemsPrices];
    servicesPricesClone = servicesPricesClone?.map(service => {
      let totalServicePrice = 0;
      roomsData?.forEach(room => {
        let currentService = room?.servicesPrices?.find(res => res?.serviceId === service?.serviceId);
        totalServicePrice = totalServicePrice + (currentService?.pricePerRoom || 0);
      })
      return {
        ...service,
        totalPrice: totalServicePrice,
      }
    })
    setProductItemsPrices(servicesPricesClone);
  }

  useEffect(() => {
    if (!roomsAvailabilities) {
      createAvailability();
    }
  }, [reservationDetails]);


  useEffect(() => {
    calculateServicesPrices();
  }, [roomsData, reservationDetails]);


  async function handlePackageRoomAvalibities() {
    const data = {
      reservation_num: reservationDetails?.reservation_num,
      rooms: roomsData?.filter(room=> room?.combination_id)?.map(room => {
        return {
          combination_id: room?.combination_id,
          rooms_count: +room?.roomCount,
          children_count: +room?.childCount
        }
      })
    }
    const res = await setPackageRoomAvailabilities(data);
    if (res?.status === 200) {
      store.addNotification({
        title: "info!",
        message: "Rooms updated Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          pauseOnHover: true,
        },
      });
      setReload(prev => !prev)
    }
  }


  return (
    <div className="package-details-container">
      <div className="package-item">
        <h2 className="package-title">{inventory.messages.guests}</h2>

        <div className='package-pricing'>
          {reservationStatus === "pending" ?
            <>
              {roomsData?.map((room, index) => {
                return (
                  <div className='d-flex align-items-center my-3' key={`room-${index}`}>
                    <div className='col-md-4'>
                      <SelectField
                        label={marketPlace.roomType}
                        value={room?.roomType?.label}
                        options={roomsAvailabilities}
                        onChange={(e) => handleRoomType(index, e)}
                      />
                    </div>
                    <div className='col-md-2'>
                      <SelectField
                        label={inventory.messages.RoomCount}
                        value={room?.roomCount}
                        options={roomsAvailabilities?.filter(r => r?.room_type === room?.roomType?.room_type)[0]?.roomRemainingAvailability}
                        onChange={(e) => handleRoomsCount(index, e.value)}
                        disabled={!room?.roomType}
                      />
                    </div>
                    <div className='col-md-2'>
                      <SelectField
                        label={inventory.messages.childPax}
                        value={room?.childCount}
                        options={room?.childrenAvailability}
                        onChange={(e) => handleRoomsChildCount(index, e.value)}
                        disabled={!room?.roomCount}
                      />
                    </div>
                    {index > 0 ?
                      <button className='btn mt-4' onClick={() => deleteRoom(index)}>
                        <TrashIcon />
                      </button>
                      :
                      null
                    }
                  </div>
                )
              })}
              <button className='btn add-room-btn p-1 shadow-none d-flex align-items-center'
                onClick={addNewRoom}
                disabled={roomsAvailabilities?.length === roomsData?.length}
              >
                <i className="fas fa-plus"></i>
                <span className='ms-3'>{productsBuilder.addNew}</span>
              </button>
            </>
            :
            null
          }

          <div className='hotels-pricing'>
            <div className="header">
              <span>{marketPlace.Price}</span>
            </div>
            <div className="content">
              <div className='d-flex align-items-center justify-content-between'>
                {/* hotels name */}
                <div className='hotel-names px-0'>
                  {hotelsList?.map((hotel, index) => {
                    return (
                      <div key={`${hotel?.id}-${index}`}>
                        <p className='d-flex align-items-center text-capitalize' title={hotel?.name}>
                          {hotel?.icon}
                          <span className='mx-2'> {hotel?.name}</span>
                        </p>
                        {index < hotelsList?.length - 1 ? <hr className=' w-100' /> : null}
                      </div>
                    )
                  })}
                </div>
                {/* hotels rooms count */}
                <div className='px-3 d-flex flex-column'>
                  {roomsData?.map((room, index) => {
                    return (
                      room?.roomCount && room?.roomCount > 0 ?
                        <p key={room?.roomType?.label} className='mb-2'>
                          <span>{room?.roomCount} {room?.roomType?.label}</span>

                          {room?.childCount > 0 &&
                            <span>{" "}({room?.childCount} Child/Children)</span>
                          }
                        </p>
                        :
                        null
                    )
                  })}
                </div>
                {/* hotel prices */}
                <div className='hotel-names text-center font-weight-bold col-3'>
                  {hotelsList?.map((hotel, index) => {
                    return (
                      <div key={`${hotel?.id}-${index}`}>
                        <p>{hotel?.totalPrice} {reservationDetails?.currency}</p>
                        {index < hotelsList?.length - 1 ? <hr className=' w-100' /> : null}
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className='serivces'>
                {servicesWithoutHotels?.map((service, index) => {
                  return (
                    <div key={service?.serviceId} className='service-item'>
                      <p className='d-flex align-items-center text-capitalize'>
                        {service?.icon}
                        <span className='mx-2'> {service?.name}</span>
                        {service?.destination_type &&
                          <span className='text-grey-500'>({service?.destination_type})</span>
                        }
                      </p>
                      <p className='col-3 font-weight-bold text-center'>
                        {service?.totalPrice} {reservationDetails?.currency}
                      </p>
                    </div>
                  )
                })}
              </div>

            </div>
          </div>


        </div>
        {reservationStatus === "pending" ?
          <div className='d-flex justify-content-end'>
            <button className='btn bg-nxt mt-3' onClick={handlePackageRoomAvalibities}>
              {inventory.messages.edit}
            </button>
          </div>
          : null
        }

      </div>
    </div>
  )
}
