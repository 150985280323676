import ShowForPermission from "helpers/showForPermission";
import { Redirect } from 'react-router-dom';
import Activity from "../Components/Activity";
import BookingList from "../Components/BookingList";
import RevenueStatistics from "../Components/RevenueStatistics";
import { useSBSState } from 'context/global';

export default function Index() {
	const { companyInfo } = useSBSState();
	// 
	return (
		
		<div className="container statistics-container">
			<div className="row m-0 pt-5  statistics">
				<Redirect to={companyInfo?.company_category === "umrah_company" ? "/umrah-operations/operation-statement" : "/market-place-new/"} />
				<div className="col-md-9">
						<RevenueStatistics />
					<BookingList />
				</div>
				<div className="col-md-3">
					<div className="col-md-12 mb-3 bg-white shadow-sm rounded">
					<ShowForPermission permission="View-Activity-Logs">
						<Activity />
					</ShowForPermission>
					</div>
				</div>
			</div>
		</div>
	);
}
