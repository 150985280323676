import React, { useEffect, useState } from 'react'
import { getGroupPassengers, OnlineVisaGroups } from 'services/VisaOnline';
import Locale from 'translations'
import PassportsHeader from './Header';
import TravelersTable from './TravelersTable';
import { updateCreatePassports } from 'services/operationStatement';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import { SaveIcon } from 'modules/UmrahOperations/shared/Icons';
import { formatViewOperationStatement } from 'modules/UmrahOperations/helper/formatUmrahOperations';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'



export default function OperationStatementPassports({ OperationStatement, setOperationStatement }) {
  const { onlineVisa, operationStatement } = Locale;
  const { reference, id } = useParams();
  const location = useLocation();
  const isPublic = location.pathname.includes("public");
  const statementId = isPublic ? id : reference;


  // ** states

  const [passportsData, setPassportsData] = useState({
    source: "safavisa-groups",
    selectedGroup: null,
    selectedTag: null,
    passportInserted: false,
  })

  const [passportsGroups, setPassportsGroups] = useState({
    list: null,
    tags: null
  });

  const [allTravelersList, setAllTravelersList] = useState([]);
  const [insertedTravelers, setInsertedTravelers] = useState([]);

  const [addPassportModalOpen, setAddPassportModalOpen] = useState(false);


  // ** function

  // toggle add passport modal
  function toggleAddPassportsModal() {
    setAddPassportModalOpen(!addPassportModalOpen);
    setPassportsData({
      ...passportsData,
      selectedGroup: null,
      selectedTag: null,
    });
    setAllTravelersList([]);
    if (!passportsGroups.list) {
      getSafaGroupsAndTags();
    }
  }

  function handleHeaderInputsChanges({ key, value }) {
    setPassportsData({ ...passportsData, [key]: value })
  }


  // handle select all available passport
  function handleSelectAllPassport(checked) {
    let passportsListClone = [];
    passportsListClone = allTravelersList.map(traveler => {
      return {
        ...traveler,
        selected: insertedTravelers.find(t => t?.passport_number === traveler?.passport_number) ? false : checked,
      }
    });
    setAllTravelersList(passportsListClone)
  }


  // select passport 
  function handleSelectPassport({ isPassportSelected, passportNumber }) {
    let passportsListClone = [...allTravelersList];
    let passportIndex = passportsListClone.findIndex(passport => passport?.passport_number === passportNumber)
    if (passportIndex > -1) {
      passportsListClone[passportIndex] = { ...passportsListClone[passportIndex], selected: !isPassportSelected }
    }
    setAllTravelersList(passportsListClone)
  }

  // // delete inserted passport by id
  function handleDeletePassport({ passportNumber }) {
    let passportsListClone = [...insertedTravelers];
    passportsListClone = passportsListClone.filter(passport => passport?.passport_number !== passportNumber);
    if (passportsListClone.length === 0) {
      setPassportsData({ ...passportsData, passportInserted: false });
      getSafaGroupsAndTags();
    }
    setInsertedTravelers(passportsListClone)
  }

  // // insert selected passpors to send it to backend
  function handleInsertSelectedPassprots(cb) {
    setInsertedTravelers([...insertedTravelers, ...allTravelersList?.filter(passport => passport?.selected)]);
    setPassportsData({ ...passportsData, passportInserted: true });
    setAllTravelersList([]);
    cb && cb()
  }

  // // get all possports from group 
  async function getPassportsListByGroupOrTag() {
    let groupOrTagId = passportsData.selectedGroup?.id || passportsData.selectedTag?.id;
    const res = await getGroupPassengers({}, groupOrTagId);
    if (res?.status === 200) {
      let formatSafaTravelers = res?.data?.data?.membersData?.map(member => {
        return {
          ...member,
          first_name: member?.FirstName,
          last_name: member?.LastName,
          passport_number: member?.PPNo,
          passport_photo: member?.passport_image,
          gender: +member?.Gender === 1 ? "M" : "F",
          birth_date: member?.BirthDate,
          age: member?.BirthDate ? moment(moment()).diff(member?.BirthDate, "years") : null,
          country_id: member?.CAddCountry,
          nationality: member?.nationality_name?.name,
          nationality_id: member?.CNationality,
          relationship: member?.RelationWithSponser || member?.RelationWithSponser || null,
          national_id: member?.IdNumber
        }
      })
      setAllTravelersList(formatSafaTravelers);
    }
  }


  // save inserted passports
  async function saveSelectedPassports() {
    let travelersData = {
      travellers: insertedTravelers.map(passport => ({
        first_name: passport?.first_name,
        last_name: passport?.last_name,
        passport_number: passport?.passport_number,
        passport_photo: passport?.passport_photo,
        gender: passport?.gender,
        birth_date: passport?.birth_date ? moment(passport?.birth_date).format("YYYY-MM-DD") : passport?.birth_date,
        age: passport?.birth_date ? moment(moment()).diff(passport?.birth_date, "years") : null,
        country_id: passport?.country_id,
        nationality_id: passport?.nationality_id,
        relationship: passport?.RelationWithSponser?.en || passport?.RelationWithSponser || null,
        national_id: passport?.national_id?.toString() || null
      }))
    }
    const res = await updateCreatePassports(statementId, travelersData)
    if (res?.status === 200) {
      handleResponesNotification({ alertType: "success", message: "Passports added succesfully", title: "Add Update Travelers" });
      let data = await formatViewOperationStatement(res.data.data, Locale);
      setOperationStatement(data);
    }
  }

  // get safa visa groups and tags
  async function getSafaGroupsAndTags() {
    const res = await OnlineVisaGroups({});
    if (res?.status === 200) {
      const formattedGroup = res.data.data.map((item) => {
        return {
          id: item.id,
          name: item.group_name,
        };
      });
      const formattedTags = res.data.data.map((item) => {
        return {
          id: item.id,
          name: item.tag,
        };
      });

      setPassportsGroups({ list: formattedGroup, tags: formattedTags })
    } else {
      setPassportsData({
        ...passportsData,
        selectedGroup: null,
        selectedTag: null,
      })
    }
  }

  useEffect(() => {
    if (!passportsGroups.list && OperationStatement?.travellers?.length === 0 && !isPublic) {
      getSafaGroupsAndTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OperationStatement?.travellers]);

  useEffect(() => {
    if (OperationStatement?.travellers?.length > 0) {
      setInsertedTravelers(OperationStatement.travellers)
      setPassportsData({ ...passportsData, passportInserted: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OperationStatement.travellers])


  return (
    <>
      {!passportsData.passportInserted ?
        <PassportsHeader
          passportsGroups={passportsGroups}
          passportsData={passportsData}
          handleHeaderInputsChanges={handleHeaderInputsChanges}
          getPassportsListByGroupOrTag={getPassportsListByGroupOrTag}
        />
        : null
      }

      {/* travelers list */}
      {(allTravelersList.length > 0 || insertedTravelers.length > 0) &&
        <>
          <div className="mt-3">
            <TravelersTable
              travelersList={passportsData.passportInserted ? insertedTravelers : allTravelersList}
              insertedTravelers={insertedTravelers}
              handleSelectAllPassport={handleSelectAllPassport}
              handleSelectPassport={handleSelectPassport}
              handleDeletePassport={handleDeletePassport}
              isPassportInserted={passportsData.passportInserted}
            />
          </div>

        </>
      }
      {/* action buttons */}
      <div className="d-flex justify-content-end mt-3">
        {passportsData.passportInserted ?
          <>
            {!isPublic &&
              <button className="btn bg-nxt px-5 mx-3 py-2" onClick={toggleAddPassportsModal}>
                {operationStatement.addNew}
              </button>
            }
            <button className="btn bg-nxt d-flex align-items-center px-5 py-2" onClick={saveSelectedPassports}>
              <SaveIcon color="#D4A655" />
              <span className="mx-1">{operationStatement.save}</span>
            </button>
          </>
          :
          null
        }

        {!passportsData.passportInserted && allTravelersList.length > 0 ?
          <button
            className="btn accept-btn px-5"
            disabled={!allTravelersList.find(passport => passport?.selected)}
            onClick={() => handleInsertSelectedPassprots()}
          >
            <span className="mx-2">{onlineVisa.INSERTSELECTED}</span>
          </button>
          :
          null
        }
      </div>



      <Modal isOpen={addPassportModalOpen} size="xl" centered className="operation-modal umrah-operations-wrapper">
        <ModalHeader toggle={toggleAddPassportsModal}>
          {operationStatement.chooseFromGroup}
        </ModalHeader>
        <ModalBody>
          <PassportsHeader
            passportsGroups={passportsGroups}
            passportsData={passportsData}
            handleHeaderInputsChanges={handleHeaderInputsChanges}
            getPassportsListByGroupOrTag={getPassportsListByGroupOrTag}
          />
          {allTravelersList.length > 0 &&
            <TravelersTable
              travelersList={allTravelersList}
              insertedTravelers={insertedTravelers}
              handleSelectAllPassport={handleSelectAllPassport}
              handleSelectPassport={handleSelectPassport}
              handleDeletePassport={handleDeletePassport}
              isPassportInserted={false}
            />
          }
          <button
            className="btn bg-nxt mt-3 px-5 w-100"
            disabled={!allTravelersList.find(passport => passport?.selected)}
            onClick={() => handleInsertSelectedPassprots(toggleAddPassportsModal)}
          >
            <span className="mx-2">{onlineVisa.INSERTSELECTED}</span>
          </button>
        </ModalBody>
      </Modal>



    </>
  )
}
