import React, { useEffect, useMemo, useState } from "react";
import PublicPageHeader from "./PublicPageHeader";
import { useParams } from "react-router-dom";
import {
	getPackage,
	getPageBuilder,
	getPageBuilderByCompany,
} from "services/webBuilder";
import { itineraryElementsIds } from "modules/market-place/NewPackageResponse/helpers/formatServicesItems";
import Locale from "translations";
import PackageAttractionsView from "modules/market-place/NewPackageResponse/Details/AttractionsView";
import PackageHotelView from "modules/market-place/NewPackageResponse/Details/HotelView";
import { useWebBuilderDispatch } from "context/webBuilder";
import initialData from "../../page.json";

import HeroHeader from 'modules/WebBuilder-V2/Components/Content/Hero/Header';
import { changeBorder, changeColorTheme, changeFont } from 'modules/WebBuilder-V2/helpers/styles';
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as CalenderIcon } from "assets/images/webBuilder/calendar-224.svg";
import { ReactComponent as CheckMarkCircleIcon } from "assets/images/market-place/tick-circle.svg";
import { ReactComponent as RequiredDocIcon } from "assets/images/market-place/required-document-text.svg";
import Carousel from 'react-multi-carousel';
import CustomRightArrow from 'modules/WebBuilder-V2/shared/CustomRightArrow';
import CustomLeftArrow from 'modules/WebBuilder-V2/shared/CustomLeftArrow';
import PackageNavTabs from './PackageNavTabs';
import FLightView from './Flights';
import ProductItinerary from 'modules/market-place/NewPackageResponse/Details/ProductItinerary';
import WebBuilderRequestForm from './RequestForm';
import { useSBSState } from 'context/global';
import moment from 'moment';



function PreviewServicesV2() {
	const { service, id, name } = useParams();
	const { locale } = useSBSState();
	const [packageData, setPackageData] = useState(null);
	const { productsBuilder } = Locale;
	const dispatch = useWebBuilderDispatch();
	const [selectedRoomType, setSelectedRoomType] = useState(null);

	const updateStyle = ({ name, value }) => {
		dispatch({
			type: "updateStyle",
			payload: {
				name,
				value,
			},
		});
	};
	const getPageDetails = async () => {
		let res;
		if (!name) {
			res = await getPageBuilder();
		} else {
			res = await getPageBuilderByCompany(name);
		}

		if (res.status === 200) {
			const pageBuilderData = res?.data?.page_builder_config ?? initialData;
			dispatch({
				type: "setInitialData",
				payload: pageBuilderData,
			});
			const style = pageBuilderData?.style;
			changeColorTheme(style?.color, updateStyle);
			changeBorder(style?.rounded, updateStyle);
			changeFont(style?.font, updateStyle);
		}
	};

	useEffect(() => {
		getPageDetails();
		window.scrollTo(0, 0);
		return () => { };
	}, []);

	useEffect(() => {
		if (service) {
			async function getDataView() {
				const res = await getPackage(id);
				let data = res?.data?.data;
				let nights_count = moment(moment(data?.return_date_at)).diff(moment(data?.departure_date_at), "days")
				setPackageData({ ...data, nights_count });
			}
			getDataView();
		}
	}, []);


	const productItemsTabs = useMemo(() => {
		let items = [];
		const productHasItinerary = packageData?.itinerary && packageData?.itinerary?.length > 0;
		packageData?.product_items?.forEach((element) => {
			const elemetID = element?.item?.id;
			switch (elemetID) {
				case itineraryElementsIds.hotel:
					items.push({ name: "Accommodation", id: elemetID });
					break;
				case itineraryElementsIds.external:
					items.push({ name: "Flights", id: elemetID, });
					break;
				case itineraryElementsIds.sightseeing:
					items.push({ name: "Attraction", id: elemetID });
					break;
				default:
					break;
			}
		});
		if (productHasItinerary) {
			items.push({ name: "Itinerary", id: 101 });
		}

		items = [
			...new Map(items.map((item) => [item["name"], item])).values(),
			// { name: "Package Pricing", id: "package-pricing" },
			{ name: "Include", id: "package-included-services" },
		];
		return items;
	}, [packageData?.itinerary, packageData?.product_items]);

	const packageContainHotel =
		productItemsTabs.filter((item) => item?.id === itineraryElementsIds.hotel)
			.length > 0;
	const packageContainAttraction =
		productItemsTabs.filter(
			(item) => item?.id === itineraryElementsIds.sightseeing
		).length > 0;

	const HotelElements = () => {
		return packageData?.product_items?.map((element, index) => {
			if (element?.item?.id === itineraryElementsIds.hotel) {
				return <PackageHotelView hotelData={element?.itemable} key={index} />;
			}
			return null;
		});
	};

	const AttractionElements = () => {
		return packageData?.product_items?.map((element, index) => {
			if (element?.item?.id === itineraryElementsIds.sightseeing) {
				return (
					<PackageAttractionsView
						attractionData={element?.itemable}
						key={index}
					/>
				);
			}
			return null;
		});
	};

	const calcTotalPrices = useMemo(() => {
		let adultTotalPrice = 0;
		let childTotalPrice = 0;
		let isFullPackagePrice = packageData?.product_price?.product_price_type?.id === 1;

		if (packageData) {
			// calc hotel
			if (packageContainHotel && selectedRoomType) {
				const combinations = packageData?.product_price_combinations;
				let hotelCombination = combinations?.find(comb => +comb?.combinations[0]?.room_type === selectedRoomType?.room_type);
				if (!isFullPackagePrice) {
					hotelCombination?.combinations?.forEach(hotel => {
						adultTotalPrice = adultTotalPrice + (hotel?.price_adult);
						childTotalPrice = childTotalPrice + (hotel?.price_child);
					});
					// calc other services
					packageData?.product_items?.forEach(service => {
						adultTotalPrice = adultTotalPrice + (+service?.itemable?.price_adult || 0);
						childTotalPrice = childTotalPrice + (+service?.itemable?.price_child || 0);
					})
				} else {
					adultTotalPrice = hotelCombination?.full_price_after_discount;
					childTotalPrice = hotelCombination?.child_price_after_discount;
				}
			} else if (!packageContainHotel) {
				// calc other services
				packageData?.product_items?.forEach(service => {
					adultTotalPrice = adultTotalPrice + (+service?.itemable?.price_adult || 0);
					childTotalPrice = childTotalPrice + (+service?.itemable?.price_child || 0);
				})
			}
		}
		return { adultTotalPrice, childTotalPrice };
	}, [packageContainHotel, packageData, selectedRoomType]);

	const hotelSlides = useMemo(() => {
		let hotels = packageData?.product_items?.filter(element => element?.item?.id === itineraryElementsIds.hotel);
		let slides = [];
		if (hotels?.length > 0) {
			for (let i = 0; i < hotels.length; i++) {
				let hotelImages = hotels[i]?.itemable?.hotel?.images;
				if (hotelImages?.length > 5) {
					hotelImages = hotelImages.slice(0, 5);
				}
				slides.push(hotelImages);
			}
		}
		return slides.flat();
	}, [packageData?.product_items])

	const minPakcagePrice = useMemo(() => {
		let minPrice = 0;
		let priceCombination = packageData?.product_price_combinations;
		let isFullPackagePrice = packageData?.product_price?.product_price_type?.id === 1;
		let priceKey = isFullPackagePrice ? "full_price_after_discount" : "breakdown_price_after_discount";

		if (packageData && priceCombination?.length > 0) {
			for (let i = 0; i < priceCombination.length; i++) {
				const element = priceCombination[i];
				if (i === 0) minPrice = element[priceKey];
				if (element[priceKey] < minPrice) {
					minPrice = element[priceKey]
				}
			}
		}
		minPrice = new Intl.NumberFormat("en").format(+minPrice || 0)
		return minPrice;
	}, [packageData])

	return (
		// 
		<>
			{packageData &&

				<div className="view-package-wrapper public-page-preview-services web-builder-preview" id='main-web-builder'>
					<HeroHeader />
					<PublicPageHeader service={service} packageData={packageData} />

					<div className="package-details-container w-85 mx-auto">

						<div className="d-flex flex-wrap justify-content-between align-items-center gap-10 py-3">
							<div className="">
								<div className="d-flex align-items-center gap-10">
									<h1 className="service-title">{packageData?.name?.[locale]}</h1>
									{packageData?.product_classification &&
										<span className="rate">
											{[...Array(5)].map((i, index) =>
												index < packageData?.product_classification?.id ? <GoldenStarIcon key={index} width="18px" /> : <StarIcon key={index} width="18px" />
											)}
										</span>
									}
								</div>
								<div className="d-flex align-items-center gap-24">
									<span className="location">
										<LocationIcon />
										{service !== "package" ?
											packageData?.product_items[0]?.itemable?.country?.name || ""
											:
											packageData?.product_items?.find(item => item?.item?.id === itineraryElementsIds.hotel)?.itemable?.country?.name
										}
									</span>
									{packageData?.nights_count && service !== "tours" ?
										<span className="duration">
											<CalenderIcon />
											{packageData?.nights_count + " Nights"}
										</span>
										:
										null
									}
								</div>
							</div>
							<div className="book-price">
								<div className="d-flex flex-column">
									<span>From</span>
									<p>
										<span className="min-price">{minPakcagePrice}</span>{" "}
										<span>{packageData?.product_price?.currency_code}</span>
									</p>
								</div>
								<button className="btn book-btn" onClick={() => document?.querySelector(".request-form ")?.scrollIntoView({ behavior: "smooth", block: "start" })}>Book Now</button>
							</div>
						</div>




						{/*  */}
						<div className="d-flex flex-wrap  gap-24 py-5">
							<div className="package-content">
								{/* carousel */}
								<div className="package-carousel">
									<Carousel
										responsive={{ desktop: { breakpoint: { max: 4000, min: 0 }, items: 1 } }}
										itemClass={"hotel-slide"}
										className=""
										slidesToSlide={1}
										keyBoardControl={true}
										customRightArrow={<CustomRightArrow />}
										customLeftArrow={<CustomLeftArrow />}
										renderButtonGroupOutside={true}
										shouldResetAutoplay={true}
										removeArrowOnDeviceType={["tablet", "mobile"]}
										infinite={true}
									>
										{hotelSlides.map((slide, index) => {
											return <img src={slide} alt="" key={slide || index} />
										})}
									</Carousel>
								</div>
								{/* package nav */}
								{service === "package"
									? <PackageNavTabs productItemsTabs={productItemsTabs} />
									: null
								}
								{/* flights */}
								{service === "package" || service === "flight" ? <div id={`tab-${itineraryElementsIds.external}`}>
									{packageData?.product_items?.filter((item) => item?.item?.id === itineraryElementsIds.external)
										?.map((item, index) => {
											return (
												<div
													className="public-page-preview-services mt-4"
													index={index}
													key={item?.itemable?.id}
												>
													<FLightView details={item} />
												</div>
											);
										})}
								</div> : null}


								{/* Hotels */}
								{service === "package" || service === "hotels" ? packageContainHotel ? (
									<div
										className="package-item"
										id={`tab-${itineraryElementsIds.hotel}`}
									>
										<h2 className="package-title">Package Accommodation</h2>
										{HotelElements()}
									</div>
								) : null : null}

								{/* Attraction */}
								{service === "package" || service === "tours" ? packageContainAttraction ? (
									<div
										className="package-item"
										id={`tab-${itineraryElementsIds.sightseeing}`}
									>
										<h2 className="package-title">
											Package Attraction
										</h2>
										{AttractionElements()}
									</div>
								) : null : null}


								{/* Itinerary */}
								{service === "package" ? packageData?.itinerary?.length > 0 ? (
									<div className="package-item" id="tab-101">
										<h2 className="package-title">Package {productsBuilder.itinerary}</h2>
										<ProductItinerary
											itineraryData={packageData?.itinerary}
											itineraryElementsIds={itineraryElementsIds}
										/>
									</div>
								) : null : null}

								{/* services */}
								{service === "package" ? <div className="package-item">
									<h2 className="package-title" id="tab-package-included-services">Package Include</h2>
									<div className="mt-4">
										<p className="d-flex align-items-center mb-2">
											{packageData?.product_items?.find((item) => item?.item?.id === itineraryElementsIds.internal) &&
												<>
													<CheckMarkCircleIcon />
													<span className="mx-1">
														{productsBuilder.internalTransportations}
													</span>
												</>
											}
										</p>
										<p className="d-flex align-items-center mb-2">
											{packageData?.product_items?.find((item) => item?.item?.id === itineraryElementsIds.visa && item?.itemable?.included) &&
												<>
													<CheckMarkCircleIcon />
													<span className="mx-1">{productsBuilder.visa}</span>
												</>
											}
										</p>
										<p className="d-flex align-items-center mb-2">
											{packageData?.product_items?.find((item) => item?.item?.id === itineraryElementsIds.insurance && item?.itemable?.included) &&
												<>
													<CheckMarkCircleIcon />
													<span className="mx-1">{productsBuilder.insurance}</span>
												</>
											}
										</p>
										<p className="d-flex align-items-center mb-2">
											{packageData?.product_items?.find((item) => item?.item?.id === itineraryElementsIds.otherServices) &&
												<>
													<CheckMarkCircleIcon />
													<span className="mx-1">{productsBuilder.other}</span>
												</>
											}
										</p>
									</div>
								</div> : null}
							</div>

							<div className="package-request">
								<WebBuilderRequestForm
									selectedRoomType={selectedRoomType}
									packageData={packageData}
									calcTotalPrices={calcTotalPrices}
									setSelectedRoomType={setSelectedRoomType}
									packageContainHotel={packageContainHotel}
								/>

								{/* Required Documents */}
								<div className="package-item required-docs">
									<div className="d-flex align-items-center">
										<RequiredDocIcon />
										<h2 className="package-title mx-2">
											{productsBuilder.requiredDocuments}
										</h2>
									</div>
									<div className="mt-4">
										{packageData?.share_required_documents ?? (
											<p className="text-muted text-center">Not Found Data</p>
										)}
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			}
		</>
	);
}

export default PreviewServicesV2;
