import React, { useEffect, useState } from "react";
import Locale from "translations";
import { CheckIcon } from "components/SideNav/icons";

import { FetchsubscriptionPackage, fetchsubscriptionActivePlan } from "services/subscription";
import moment from "moment";
import NewPaymentAction from 'components/NewPaymentAction';
import { payPackagePlan } from 'services/subscriptionPlan';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { store } from 'react-notifications-component';

export default function SubscriptionPackagesPlans() {
	const { productsBuilder, subscriptionModule, marketPlace } = Locale;
	const history = useHistory();

	// ** states
	const [packagePlanState, setPackagePlanState] = useState([]);
	const [actionType, setActionType] = useState("");
	const [formData, setFormData] = useState({});
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [paymentModalOpen, setPaymentModalOpen] = useState(false);


	// ** logic functions
	function togglePaymentModal() {
		setActionType("");
		setFormData({});
		setPaymentModalOpen((prev) => !prev);
	}
	async function getPackagePlan() {
		let res = await FetchsubscriptionPackage();
		return res?.data?.data;
		// setPackagePlanState(response?.data?.data);
	}
	async function getActivePlan() {
		let res = await fetchsubscriptionActivePlan();
		return res?.data?.data;
	}

	async function getPackagesAndActivePlan() {
		let [packagesList, activePlan] = await Promise.all([getPackagePlan(), getActivePlan()]);
		setPackagePlanState([{ ...activePlan, isSelectedPlan: true }, ...packagesList])
	}


	useEffect(() => {
		getPackagesAndActivePlan();
	}, []);

	async function pay(type, currency) {
		const data = {
			payment_method: type,
			currency,
		};
		const payRes = await payPackagePlan(selectedPlan?.id, data);
		if (payRes.status === 200 || payRes.status === 201) {
			store.addNotification({
				title: "info!",
				message: payRes?.data?.message ?? "Payment Successful",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => { }
			});
			togglePaymentModal();
			history.push("/subscription/plans");

		} else if (payRes?.status === 202) {
			setActionType(payRes?.data?.actionType);
			setFormData(payRes?.data?.data?.formData);
		}
	}

	const AllPackage =
		packagePlanState?.map((item, index) => {
			const isSpecialOffer = +item?.total_price !== item?.price && !item?.isSelectedPlan;
			return (
				<div className=" col-lg-4 col-md-6" key={item.id}>
					<div className={`planItem ${isSpecialOffer ? "special-card" : ""}`}>
						<div className="plan-item-header">
							<p className='text-black'>{item.title || subscriptionModule.currentPlan}</p>
							<h2 className='d-flex align-items-center'>
								{item?.total_price || "0"} <span className='mx-2'>{item?.currency}</span>
								{isSpecialOffer &&
									<span className='oldPrice ml-2'>{item?.price} </span>
								}
								<span>
									/ {item?.duration || moment(item?.end_date).diff(moment(item?.start_date), "d")} {productsBuilder.day}{" "}
								</span>
							</h2>
						</div>
						<div className="plan-item-body">
							<h3 className="title">{productsBuilder.WhatsIncluded}</h3>
							<ul className="list-items mt-2">
								{item?.modules?.length > 0 &&
									item.modules?.map((module, ind) => (
										module.status === "active" && module.type === "paid" ?
											<li key={module.id}>
												<span className="icon">
													<CheckIcon />
												</span>
												{module.name}
											</li>
											:
											null
									))}
							</ul>
						</div>
						<div className="plan-item-footer">
							<h4 className="title">{marketPlace.Available} : </h4>
							<div className="date-footer">
								<p className="desc">
									<span className='text-gray'>{subscriptionModule.from}</span> :
									<span>{moment(item?.start_date).format("DD/MM/YYYY")}</span>
								</p>
								<p className="desc">
									<span className='text-gray'>{subscriptionModule.to}:{" "}</span>
									<span> {moment(item?.end_date).format("DD/MM/YYYY")}</span>
								</p>
							</div>
							<div className="text-center">

								<div className="text-center">
									{!item?.isSelectedPlan ?

										<button className="btn btn-package-plan" onClick={() => {
											togglePaymentModal();
											setSelectedPlan(item);
										}}>
											{subscriptionModule.SubscriptionPlan}
										</button>
										:
										<button className="btn btn-current-plan">
											{subscriptionModule.currentPlan}
										</button>
									}
								</div>
							</div>
						</div>
						{isSpecialOffer &&
							<div className='special'>
								<span className='special-title' style={{fontSize: 16}}>
									{marketPlace.specialOffers}
								</span>
							</div>}
					</div>
				</div>
			)
		})

	return (
		<div className="subscription-packages-plans">
			<h1 className="main-title">{productsBuilder.packages}</h1>

			<div className="planCards">
				{packagePlanState.length > 0 ? <div className="row "> {AllPackage} </div> :
					<div className='notFound  '>
						<div className='imgErorr'>
							<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M37.5833 20.7166V12.9066C37.5833 5.51831 35.86 3.66663 28.93 3.66663H15.07C8.13996 3.66663 6.41663 5.51831 6.41663 12.9066V33.5499C6.41663 38.4266 9.09331 39.5816 12.3383 36.0983L12.3566 36.0799C13.8599 34.4849 16.1516 34.6133 17.4533 36.3549L19.305 38.8299" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M14.6666 12.8334H29.3333" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M16.5 20.1666H27.5" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M33.3869 27.0788L26.8969 33.5689C26.6403 33.8255 26.4019 34.3022 26.3469 34.6505L25.9986 37.1255C25.8702 38.0239 26.4936 38.6472 27.3919 38.5189L29.8669 38.1705C30.2152 38.1155 30.7102 37.8772 30.9486 37.6205L37.4386 31.1305C38.5569 30.0122 39.0886 28.7105 37.4386 27.0605C35.8069 25.4289 34.5052 25.9605 33.3869 27.0788Z" stroke="#707170" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M32.4486 28.0138C32.9986 29.9938 34.5386 31.5338 36.5186 32.0838" stroke="#707170" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</div>
						<div className='textErorr'>
							<h2 className='title'>“No Available Plan”</h2>
							<p className='desc'>For any requests you can contact our customer service</p>
						</div>
						<div className='back'>
							<Link to="/contact-us" className='btn   btn-package-plan '>
								Contact Us
							</Link>
						</div>
					</div>}
				{paymentModalOpen && selectedPlan ? (
					<NewPaymentAction
						isOpen={paymentModalOpen}
						toggleModal={togglePaymentModal}
						canPayLater={false}
						formData={formData}
						actionType={actionType}
						Price={selectedPlan?.total_price}
						currency={selectedPlan?.currency}
						onPayment={pay}
						setActionType={setActionType}
						setFormData={setFormData}
					//OTP={OTP_CHECK}
					/>
				) : null}
			</div>
		</div>
	);
} 
