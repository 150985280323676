import React from 'react'
import { Link } from 'react-router-dom';
import Locale from 'translations';

export default function BreadCrump({ title, children }) {
  const { operationStatement} = Locale;


  return (
    <div className='umrah-operation-breadcrump px-3'>
      <div className="text-capitalize">
        <Link to="/umrah-operations/operation-statement">
          <span className='link'>{operationStatement.umrahCompanyOperations}/</span>
        </Link>
        {" "} {title}
      </div>

      <div className='d-flex align-items-center flex-wrap'>
        {children}
      </div>
    </div>
  )
}
