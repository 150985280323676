import React, { useEffect, useState } from 'react'
import { onlineVisaLookups } from 'services/VisaOnline';
import VisaSearchTypes from './VisaSearchTypes';
import { useMarketplaceDispatch } from 'context/marketplace';
import { useHistory } from 'react-router-dom';
import Locale from 'translations';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function VisaSearch() {
  const { marketPlace } = Locale;
  const dispatch = useMarketplaceDispatch();
  const history = useHistory();
  const [destinations, setDestinations] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [lowestPackagePrice, setLowestPackagePrice] = useState(0);
  const { status } = useParams();
  function getLowestPriceInPacakges(data) {
    let packagesPrice = {};
    let lowestPrice = {};
    // get all prices and currencies
    data?.forEach(country => {
      packagesPrice = { ...packagesPrice, [country?.country_code]: { prices: [], currency: [] } };
      country?.visa_types?.forEach(visa => {
        visa?.residences?.forEach(residence => {
          residence?.package_plans?.forEach(plan => {
            packagesPrice[country?.country_code] = {
              ...packagesPrice[country?.country_code],
              prices: [...packagesPrice[country?.country_code]?.prices, +plan?.package_provider_price],
              currency: [...packagesPrice[country?.country_code]?.currency, residence?.provider_currency]
            }
          })
        })
      })
    });
    // get lowest price and currency
    data?.forEach(country => {
      let lowestPriceIndex = packagesPrice[country?.country_code].prices.indexOf(Math.min(...packagesPrice[country?.country_code].prices));
      lowestPrice = {
        ...lowestPrice,
        [country?.country_code]: {
          price: packagesPrice[country?.country_code].prices[lowestPriceIndex],
          currency: packagesPrice[country?.country_code].currency[lowestPriceIndex]
        }
      };
    });

    return lowestPrice;
  }

  const fetchDestinations = async () => {
    const response = await onlineVisaLookups({ type: status });
    if (response.status === 200) {

      if (response?.data?.data?.length > 0) {
        if (status === 'umrah') {
          setDestinations(response?.data?.data);
          setSelectedDestination(response?.data?.data[0])
        } else {
          let List = response?.data?.data
          if (List.length === 1) {

            const hasSaU = List?.filter(item => +item.country_code === 966).length > 0;
            if (!hasSaU) {

              List.push({
                country: "Saudi Arabia",
                country_code: 966,
                country_photo: "62638302-3c20-44c9-8ec2-4adaff8dcb19",
                service_name: "visa",
                visa_types: [],
              });
            } else {
              List.push({
                country: "UAE",
                country_code: 971,
                country_photo: "ac779974-0c5e-4932-911e-88fb3f48bda6",
                service_name: "visa",
                visa_types: [],
              });
            }
          }
          setDestinations(List);
          setSelectedDestination(List?.[0])
        }
       
      }else{
        setDestinations([]);
          setSelectedDestination({})
      }
      setLowestPackagePrice(() => getLowestPriceInPacakges(response?.data?.data))
    }
  };


  function selectVisa({ visaType, packagePlan, residence }) {
    dispatch({
      type: "onlineVisaSearch",
      payload: {
        destination: selectedDestination,
        visa_type: visaType,
        package_plan: packagePlan,
        residence
      }
    });
    history.push('/market-search/visa-details');
  }


  useEffect(() => {
    fetchDestinations();
  }, [status]);




  return (
    <div className='visa-container'>
      

      {!selectedDestination ?
        <div className='visa-destinations-container p-3 px-4'>

          {destinations ? destinations?.length > 0 ?
            <>
              {/* <h4 className="mb-2 terms-color h5">
                {marketPlace.selectVisaServices}
              </h4> */}
              <div className='visa-destinations visa-destinations-services row mx-0'>
                {destinations?.map((destination) => {
                  const countryImageURL = `${process.env.REACT_APP_FILEUPLOAD_URL}/fetch/${destination?.country_photo}`;
                  return (
                    <div key={`destination-${destination?.country_code}`} className="visa-destination-card visa-destination-services pointer"
                      //  style={{ backgroundImage: `linear-gradient(180deg, #00000000, #000000e3), url(${countryImageURL})`, backgroundRepeat: 'no-repeat', backgroundSize: "100% 100%" }}
                      onClick={() => {
                        setSelectedDestination({ ...destination, country_photo: countryImageURL });
                      }}
                    >

                      <h5 className='visa-card-header'>
                        {destination?.country}
                      </h5>

                      {/* <div className='visa-card-content mb-1'>
                        <span className='line-hi-1'> {marketPlace.startingFrom}</span>
                        <p>
                          <span className='visa-card-start-price line-hi-1'>
                            {lowestPackagePrice[destination?.country_code]?.price} {" "}
                            {lowestPackagePrice[destination?.country_code]?.currency}
                          </span>
                          <span> / {marketPlace.perVisa}</span>
                        </p>
                      </div> */}
                    </div>


                  )
                })}
              </div>
            </>
            :
            <div className='h4 w-100 text-center terms-color font-weight-bold'>
              {marketPlace.noPackagesAvailable}
            </div>
            :
            null
          }

        </div>
        :
        <VisaSearchTypes
          destinations={destinations}
          selectVisa={selectVisa}
          selectedDestination={selectedDestination}
          setSelectedDestination={setSelectedDestination}
          lowestPackagePrice={lowestPackagePrice}
        />
      }

    </div>
  )
}


