import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import { fetchAllServices } from 'services/VisaOnline';
import Locale from 'translations';
import HotelCard from './HotelCard';
import PackageCard from './PackageCard';
import VisaCard from './VisaCard';
// import TransferCard from './TransferCard';
// import FlightCard from './FlightCard';

export default function NewMarketSellingServices() {
	const { inventory, marketPlace } = Locale;
	const { status } = useParams();
	const [searchData, setSearchData] = useState(null)
	const [activeFilter, setActiveFilter] = useState('all');
	const [allFilters, setAllFilters] = useState(null);
	const [servicesData, setServicesData] = useState({
		allServices: [],
		filteredServices: []
	});
	// const [hotelSearchData, ]
	const getAllServices = async () => {
		const res = await fetchAllServices(status);
		if (res?.status === 200) {
			const filters = [...new Set(res?.data?.data?.map(item => item?.service_name))]
			setSearchData(res?.data?.search_data);
			setAllFilters(filters);
			setServicesData({ allServices: res?.data?.data, filteredServices: res?.data?.data });
		}
	};



	useEffect(() => {
		getAllServices();
		setActiveFilter("all")
	}, [status]);


	function handleFilters(filterName) {
		let allServicesClone = [...servicesData.allServices];
		let filteredServices;
		if (filterName !== "all") {
			filteredServices = allServicesClone.filter(service => service?.service_name === filterName);
		} else {
			filteredServices = allServicesClone;
		}
		setActiveFilter(filterName);
		setServicesData({ allServices: allServicesClone, filteredServices });
	}

	const viewServicesCards = useMemo(() => {
		return servicesData.filteredServices.length > 0 && servicesData.filteredServices?.map((service, index) => {
			switch (service?.service_name) {
				case "hotels":
					return (
						<HotelCard
							key={index}
							hotelData={service}
							searchData={searchData}
						/>
					)

				case "packages":
					return (
						<PackageCard
							key={index}
							packageData={service}
							searchData={searchData}
						/>
					)
				case "visa":
					return service?.visa_types?.map((visa, index) =>
						visa?.residences.map((residence, ind) =>
							residence?.package_plans?.map((plan, IN) => {
								const destinationData = {
									country_code: service?.country_code,
									country: service?.country,
									country_photo: service?.country_photo
								}
								return (
									<VisaCard
										key={`visa-${visa?.id}-${index}${ind}${IN}`}
										currency={residence?.currency}
										residence={{ ...residence, provider_currency: residence.currency }}
										packagePlan={{ ...plan, package_provider_price: plan?.package_price }}
										destination={destinationData}
										visa={visa}
									/>
								);
							})
						)
					);
				default:
					return null
			}
		})
	}, [searchData, servicesData.filteredServices]);


	return (
		<div className="col-md-10 mx-auto mt-4">
			{allFilters ?
				<div className={`selling-services-container ${allFilters?.length === 0 ? "text-center" : ""}`}>
					<h2 className='title'>{inventory.WhatWeSell}</h2>
					{allFilters.length > 0 ?
						<ul className='selling-services-filter'>
							<li className={`filter-item ${activeFilter === "all" ? "active" : ""}`}
								onClick={() => handleFilters("all")}
							>
								{inventory.AllServices}
							</li>

							{allFilters?.includes('hotels') ?
								<li className={`filter-item ${activeFilter === "hotels" ? "active" : ""}`}
									onClick={() => handleFilters("hotels")}
								>
									{marketPlace.hotels}
								</li>
								:
								null
							}

							{allFilters?.includes('visa') ?
								<li className={`filter-item ${activeFilter === "visa" ? "active" : ""}`}
									onClick={() => handleFilters("visa")}
								>
									{marketPlace.onlineVisa}
								</li>
								:
								null
							}

							{allFilters?.includes('transfer') ?
								<li className={`filter-item ${activeFilter === "transfer" ? "active" : ""}`}
									onClick={() => handleFilters("transfer")}
								>
									{marketPlace.transfer}
								</li>
								:
								null
							}

							{allFilters?.includes('flights') ?
								<li className={`filter-item ${activeFilter === "flights" ? "active" : ""}`}
									onClick={() => handleFilters("flights")}
								>
									{marketPlace.flights}
								</li>
								:
								null
							}

							{allFilters?.includes('packages') ?
								<li className={`filter-item ${activeFilter === "packages" ? "active" : ""}`}
									onClick={() => handleFilters("packages")}
								>
									{marketPlace.packages}
								</li>
								:
								null
							}
							{allFilters?.includes('tours') ?
								<li className={`filter-item ${activeFilter === "tours" ? "active" : ""}`}
									onClick={() => handleFilters("tours")}
								>
									{marketPlace.tours}
								</li>
								:
								null
							}

						</ul>
						:
						<p className="font-weight-bold text-capitalize mt-4">{marketPlace.noServicesAvailable}</p>
					}

					<div className="selling-services-cards-container my-3">
						{viewServicesCards}
						{/* <HotelCard />
						<PackageCard />
						<VisaCard />
						<TransferCard />
						<FlightCard /> */}
					</div>

				</div>
				:
				null
			}
		</div >
	)
}
