import React from 'react'
import Locale from 'translations';
import { ReactComponent as WarningIcon } from "assets/images/market-place/warning-outline-icon.svg";
import { ReactComponent as CheckMarkIcon } from "assets/images/market-place/checkmark-circle-icon.svg";
import { ReactComponent as OpenEyeIcon } from "assets/images/market-place/open-eye-icon.svg";
import CardEAar from "assets/images/UAE-AR.jpg";
import CardEAen from "assets/images/UAE-EN.jpg";
import CardSAar from "assets/images/SA-AR.jpg";
import CardSAen from "assets/images/SA-EN.jpg";
import { useSBSState } from 'context/global';
import { useMarketplaceDispatch } from 'context/marketplace';
import { useHistory } from 'react-router-dom';


export default function VisaCard({ visa, destination, residence, packagePlan }) {
  const { locale } = useSBSState();
  const { marketPlace, inventory } = Locale;
  const dispatch = useMarketplaceDispatch();
  const history = useHistory();

  function selectVisa() {
    dispatch({
      type: "onlineVisaSearch",
      payload: {
        destination: destination,
        visa_type: visa,
        package_plan: packagePlan,
        residence
      }
    });
    history.push('/market-search/visa-details');
  }


  return (
    <div className='selling-service-card'>
      <div className="card-img-container">
        {locale === "en" ?
          <img src={destination.country === "Saudi Arabia" ? CardSAen : CardEAen} alt={visa?.name} />
          :
          <img src={destination.country === "Saudi Arabia" ? CardSAar : CardEAar} alt={visa?.name} />
        }
        <button className="view-overlay" onClick={() => selectVisa()}>
          <OpenEyeIcon />
          <span className='mx-2'>{inventory.messages.view}</span>
        </button>
      </div>

      <div className="card-content">
        <h4 className='card-title'>{visa?.name}</h4>

        <span className='service-type'>Visa</span>

        <div className="my-1">
          <p className='my-2'>
            <CheckMarkIcon />
            {residence?.all_nationalities ?
              <span className="mx-2">{marketPlace.allNationalities}</span>
              :
              residence?.nationalities.length > 1 ?
                <span className="mx-1">{marketPlace.someNationalities}</span> :
                <span className="mx-2">{`${marketPlace.nationality} (${residence?.nationalities?.[0]?.name})`}</span>
            }
          </p>
          <p className='my-2'>
            {packagePlan?.visa_include?.length > 0 &&
              <>
                <CheckMarkIcon />
                <span className='mx-2'>{packagePlan?.visa_include[0]}</span>
              </>
            }
          </p>
        </div>
      </div>
      <div className="card-footer">
        <div className='additional-info visa-warning align-items-start'>
          <WarningIcon width="20px" />
          <span>{marketPlace.processingTime} {residence?.processing_time}</span>
        </div>

        <div className='price-container'>
          <span>{marketPlace.Price}</span>
          {/* <p>
            <span className='price'>{packagePlan.package_price}</span>
            <span>{residence.currency}/</span>
            <span className="price-for"> {inventory.messages.visa}</span>
          </p> */}
          <p>
            <span className='price'>{packagePlan.package_price} </span>
            <span>{residence?.currency}/</span>
            <span className="price-for">  {inventory.messages.visa}</span>
          </p>
        </div>
      </div>
    </div>

  )
}
