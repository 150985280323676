import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import ToursCarouselOverLayView from 'modules/WebBuilder-V2/Components/Content/ToursService/ToursCarouselOverLayView';
import ToursCarouselView from 'modules/WebBuilder-V2/Components/Content/ToursService/ToursCarouselView';
import { AddElement } from 'modules/WebBuilder-V2/shared/AddElement';
import AddService from 'modules/WebBuilder-V2/shared/AddService';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import React, { useState } from 'react'
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import ToursTheme3 from 'modules/WebBuilder-V2/Components/Content/ToursService/ToursTheme3';


export default function CustomPageTours({ details, pageId, focusContainer, onFocus, title, preview }) {
  const { style } = useWebBuilderState();
  const { id, type, items, tours_type } = details;

  const dispatch = useWebBuilderDispatch();

  const [openAdd, setOpenAdd] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);

  function handleOpenAddModal() {
    setOpenAdd((prev) => !prev);
  };
  function openEditModal(item) {
    handleOpenAddModal();
    setUpdateItem(item);
  };

  function editItem(item, index) {
    return [
      <span className="pointer-event" key={`${item.id}-${index}`}
        onClick={() => openEditModal({ ...item, index })}
      >
        <SettingIcon />
      </span>,
    ];
  };

  const deleteBodyContent = (itemId) => {
    dispatch({
      type: "deleteItemFromCustomPage",
      payload: {
        id,
        itemId,
        pageId
      },
    });
  };

  function itemsBoxActions(itemId) {
    return [
      <span className="pointer-event" key={itemId}
        onClick={() => deleteBodyContent(itemId)}
      >
        <TrashIcon />
      </span>
    ];
  };

  function addItemToTours(item) {
    updateItem
      ? dispatch({
        type: "editItemToCustomPage",
        payload: {
          id,
          item,
          itemIndex: updateItem.index,
          pageId
        },
      })
      : dispatch({
        type: "addItemToCustomPage",
        payload: {
          id,
          item,
          pageId
        },
      });
    setUpdateItem(null);
  };

  function renderToursViews() {
    return {
      "theme-1": <ToursCarouselView
        items={items}
        // sortToursItems={sortToursItems}
        focusContainer={focusContainer}
        editItem={editItem}
        itemsBoxActions={itemsBoxActions}
        openEditModal={openEditModal}
      />,
      "theme-2": <ToursCarouselOverLayView
        items={items}
        // sortToursItems={sortToursItems}
        focusContainer={focusContainer}
        editItem={editItem}
        itemsBoxActions={itemsBoxActions}
        openEditModal={openEditModal}
      />,
      "theme-3": <ToursTheme3
        items={items}
        focusContainer={focusContainer}
        editItem={editItem}
        itemsBoxActions={itemsBoxActions}
        openEditModal={openEditModal}
      />
    }
  }
  const selectedIds = items ? items.map((i) => i.id) : [];


  return (
    <>
      <h3 className="section-title">
        {title}
      </h3>
      <EditBox editMode={focusContainer === type} defaultIcon={""}>
        <div className="web-builder-content-tours" onClick={() => onFocus(type)} id={id}>

          {!preview &&
            <div className="service-top-section">
              <AddElement onClick={handleOpenAddModal} title="Add Tours" />
            </div>
          }

          <div className="hotels-detials services-carousel-container">
            {items && renderToursViews()?.[style?.theme || tours_type]}
          </div>

          {openAdd && <AddService
            handleModalState={handleOpenAddModal}
            open={openAdd}
            item_id={7}
            label={"Service"}
            header={"Tour"}
            onAdd={addItemToTours}
            details={updateItem}
            selectedIds={selectedIds}
          />}
        </div>
      </EditBox>
    </>
  )
}
