import TextField from "components/Form/TextField/TextField";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { deletePayment } from "services/productbuilder";
import Locale from "translations";

export default function Listpayemnts({
	toggle,
	reservationDetails,
	Reload,
	setReload,
}) {
	const { payment, inventory } = Locale;
	const [payments, setPayments] = useState(reservationDetails?.payments);
	useEffect(() => {
		setPayments(reservationDetails?.payments);
	}, [reservationDetails]);
	const deleteItem = async (id) => {
		const res = await deletePayment(id);
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setReload((prev) => !prev);
				},
			});
		}
	};
	return (
		<>
			<div className="d-flex  align-items-center py-2 px-2 border bg-white">
				<p style={{ fontWeight: "bold" }} className="text-dark">
					{payment.messages.Payments}
				</p>
			</div>
			<div className="filter-update bg-filter-gray border">
				<div className="product-buttons col-md-12">
					<div className="main-filter-payment d-flex justify-content-between">
						<div className="main-label controls-field col-6 col-md-3">
							<TextField
								label={payment.messages.search}
								type="text"
								placeholder={payment.messages.search}
							/>
						</div>

						<div className="filter-btn col-6 col-md-3 d-flex align-items-center justify-content-end">
							<div className="add-pass pointer">
								<button
									className="btn text-white  px-3 text-capitalize"
									onClick={toggle}
									disabled={
										reservationDetails?.reservation_status === "rejected"
									}
								>
									{inventory.messages.addpayment}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="border table-responsive">
				<table
					striped
					className="table-update p-2 bg-white m-0 table table-striped"
				>
					<thead>
						<tr>
							<th>{inventory.messages.invoiceid}</th>
							<th>{inventory.messages.Amount}</th>
							<th>{inventory.messages.paydate}</th>
							<th>{inventory.messages.online}</th>
							<th>{inventory.messages.actions}</th>
						</tr>
					</thead>
					<tbody>
						{payments && payments?.length > 0 ? (
							payments?.map((item, index) => (
								<tr>
									<td>{item?.id}</td>
									<td>
										{item?.amount} {reservationDetails?.currency}
									</td>
									<td> {moment(item?.pay_date).format("DD-MMM-YYYY")}</td>
									<td>{item?.is_online === 0 ? "No" : "Yes"}</td>
									<td>
										{item?.is_online === 0 &&
											reservationDetails?.reservation_status !== "rejected" && (
												<i
													className="fas fa-trash-alt fa-fw text-danger pointer"
													onClick={() => deleteItem(item?.id)}
												></i>
											)}
									</td>
								</tr>
							))
						) : (
							<td colSpan="5">
								<div className="product-build__product-no-data">
									<h4 className="no-data-color">{"No Data Found"}</h4>
								</div>
							</td>
						)}
					</tbody>
				</table>
				<div className="totalBox">
					<div className="total darknum">
						<p>{inventory.messages.totalPrice}</p>
						<p>
							{reservationDetails?.total_price} {reservationDetails?.currency}{" "}
						</p>
					</div>
					<div className="total greennum">
						<p>{inventory.messages.totalpaid}</p>
						<p>
							{reservationDetails?.payments_total_paid}{" "}
							{reservationDetails?.currency}{" "}
						</p>
					</div>
					<div className="total yellownum">
						<p>{payment.messages.Outstanding}</p>
						<p>
							{reservationDetails?.payments_outstanding}{" "}
							{reservationDetails?.currency}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
