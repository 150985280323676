export const ApllicationReservationReport = `
<style>
			html,
			body,
			div,
			dl,
			dt,
			dd,
			ul,
			ol,
			li,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			pre,
			code,
			form,
			fieldset,
			legend,
			input,
			textarea,
			p,
			blockquote,
			th,
			td {
				margin: 0;
				padding: 0;
			}

			a {
				text-decoration: none !important;
				cursor: pointer;
			}

			a:hover {
				text-decoration: none !important;
			}

			fieldset,
			img {
				border: 0;
			}

			address,
			caption,
			cite,
			code,
			dfn,
			em,
			strong,
			th,
			var {
				font-style: normal;
				font-weight: normal;
			}

			ol,
			ul {
				list-style: none;
			}

			caption,
			th {
				text-align: var(--left);
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: 100%;
				font-weight: normal;
			}

			q:before,
			q:after {
				content: "";
			}

			abbr,
			acronym {
				border: 0;
				font-variant: normal;
			}

			sup {
				vertical-align: text-top;
			}

			sub {
				vertical-align: text-bottom;
			}

			input,
			textarea,
			select {
				font-family: inherit;
				font-size: inherit;
				font-weight: inherit;
			}

			legend {
				color: #000;
			}

			input:focus,
			select:focus,
			textarea:focus,
			button:focus {
				outline: none;
			}

			* {
				font-family: "Cairo", sans-serif;
				box-sizing: border-box;
			}

			.container,
			.container-fluid,
			.container-xl,
			.container-lg,
			.container-md,
			.container-sm {
				width: 100%;
				padding-right: 10px;
				padding-left: 10px;
				margin-right: auto;
				margin-left: auto;
			}

			/* .container {
				max-width: 1090px;
			} */

			.py-4 {
				padding-bottom: 1.5rem !important;
				padding-top: 1.5rem !important;
			}

			.mx-3 {
				margin-left: 1rem !important;
				margin-right: 1rem !important;
			}

			.mt-2 {
				margin-top: 0.5rem !important;
			}

			.mt-3 {
				margin-top: 1rem !important;
			}

			.bg-gray {
				background: #e8e8e8 !important;
			}

			.p-4 {
				padding: 1.5rem !important;
			}

			.align-items-center {
				align-items: center !important;
			}

			.justify-content-around {
				justify-content: space-around !important;
			}

			.font-weight-bold {
				font-weight: 700 !important;
			}

			.mb-1,
			.my-1 {
				margin-bottom: 0.25rem !important;
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			element.style {
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.col-2 {
				flex: 0 0 16.6666666667%;
				max-width: 16.6666666667%;
			}

			.col-8 {
				flex: 0 0 66.6666666667%;
				max-width: 66.6666666667%;
			}

			.col-1 {
				flex: 0 0 8.3333333333%;
				max-width: 8.3333333333%;
			}

			.col-3 {
				flex: 0 0 25%;
				max-width: 25%;
			}

			.col-6 {
				flex: 0 0 50%;
				max-width: 50%;
			}

			.col-4 {
				flex: 0 0 33.3333333333%;
				max-width: 33.3333333333%;
			}

			.img-fluid {
				max-width: 100%;
				height: auto;
			}

			.w-50 {
				width: 50%;
			}

			element.style {
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.txt-blue {
				color: #0c3b5c;
				font-weight: 700;
			}

			.container-wrapper {
				border: 1px solid #0c3b5c;
			}

			.justify-content-between {
				justify-content: space-between !important;
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				/* margin-right: -15px;
				margin-left: -15px; */
			}
			.col-6 {
				display: inline-block;
				width: 48%;
			}
			.border-header {
				border-bottom: 1px solid #2d2d2d;
			}

			.f-12 {
				font-size: 12px;
			}

			table {
				/* border-collapse: collapse; */
				border-spacing: 0px 2px !important;
			}
		
			td {
				border-inline: 1px solid #222;
				border-block: 2px solid #222;
				padding-inline: 7px;
				padding-block: 4px;

			}

			th {
				border-inline: 1px solid #222;
				border-block: 2px solid #222;
				padding-inline: 7px;
        padding-block: 4px;

			}

			thead th {
				border-inline: 1px solid #222;
				border-block: 2px solid #222;
				padding-inline: 7px;
        padding-block: 4px;

			}

			/* tr:first-child td {
				border-top: 0;
			}

			tr td:first-child {
				border-left: 0;
			}

			tr td:last-child {
				border-right: 0;
			}

			tr th:first-child {
				border-left: 0;
			}

			tr th:last-child {
				border-right: 0;
			} */

			.width-10 {
				width: 11% !important;
			}

			.container-wrapper {
				height: 95vh !important;
			}

			.p-5 {
				padding: 2rem;
			}

			.d-flex {
				display: flex !important;
			}

			.justify-content-center {
				justify-content: center !important;
			}

			.flex-column {
				flex-direction: column;
			}

			.border {
				border: 1px solid #dfdfdf;
			}

			.fw-600 {
				font-weight: 600;
			}

			.fw-700 {
				font-weight: 700;
			}

			.hr-lg {
				height: 4px;
				background-color: #f3f5f5;
				border: none !important;
			}
			.container {
				max-width: 1250px;
			}

			.font-bold {
				font-weight: 700;
			}
			.table_data h3{
				font-weight: 700;
				text-decoration: underline;
				margin-bottom: 5px;
				margin-top: 7px;

			}
			.application_reservation h2{
				font-weight: 600;
				/* text-decoration: underline; */
				text-align: center;
				border-bottom: 1px solid #222;
				width: 40%;
   			margin-inline: auto;
				margin-block: 30px;
        font-size:17px
			}
			.basic_data .row {
				margin-bottom: 10px !important;
			}

			.client_name{
				display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
			}
			.client_name p{
				width: 300px;
			}
     .basic_data label{
      font-size:15px;
      font-weight:700
     }
		</style>
    <div class="application_reservation" style="direction:[dir]">
			<div class="container">
				<h2>[appReservation]</h2>
				<div class="basic_data table_data">
					<h3> [basicData]</h3>
					<div class="row">
						<div class="col-6">
							<label> [date] : </label>
							<span>[date_api]</span>
						</div>
						<div class="col-6">
							<label> [company] : </label>
							<span>[company_api]</span>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<label> [clientName] : </label>
							<span>[client_name]</span>
						</div>
					
					</div>
					<div class="row">
						<div class="col-12">
							<label>[email] : </label>
							<span>[email_api]</span>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<label>[tripNum] : </label>
							<span>[trip_Date]</span>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<label> [arrivalDate]: </label>
							<span>[return_date]</span>
						</div>
						<div class="col-6">
							<label> [departureDate] : </label>
							<span>[departure_date]</span>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<label>[notes] </label>
						</div>
					</div>
				</div>

				<div class="hotel_data table_data">
					<h3>[AccommodationDataHotel]</h3>
					<table class="table" style="width: 100%">
						<thead>
							<tr>
								<th class="th font-bold" width="3%">[num]</th>
								<th class="th font-bold">[city]</th>
								<th class="th font-bold">[hotelName]</th>
								<th class="th font-bold">[level]</th>
								<th class="th font-bold">[nightsNumber]</th>
								<th class="th font-bold">[accommodationType]</th>
							</tr>
						</thead>
						<tbody>
							
							[allHotel]
						</tbody>
					</table>
				</div>

				<div class="service_data table_data">
					<h3>[offeredService]</h3>
					<table class="table" style="width: 50%">
						<thead>
							<tr>
								<th class="th font-bold">[num1]</th>
								<th class="th font-bold">[Service]</th>
								<th class="th font-bold">[price]</th>

							</tr>
						</thead>
						<tbody>
            [allOfferedServices]
							
						</tbody>
					</table>
				</div>

				<div class="extraService_data table_data">
					<h3>[extraService]</h3>
					<table class="table" style="width: 50%">
						<thead>
							<tr>
								<th class="th font-bold">[num2]</th>
								<th class="th font-bold">[Service1]</th>
								<th class="th font-bold">[paxNum] </th>
								<th class="th font-bold">[price1]</th>
								
							</tr>
						</thead>
						<tbody>
						[allExtraSevices]
						</tbody>
					</table>
				</div>
				<div class="motamer_data table_data">
					<h3>[motamersName]</h3>
					<table class="table" style="width: 100%">
						<thead>
							<tr>
								<th class="th font-bold">[num3]</th>
								<th class="th font-bold">[motamerName]</th>

								<th class="th font-bold">[Passport]</th>
								
							</tr>
						</thead>
						<tbody>
            [allTravelers]
						</tbody>
					</table>
				</div>
				<div class="table_data">
					<table class="table " style="width: 50%">
						<thead>
							<tr>
								<th class="th font-bold" style="font-size:18px;padding:13px">[applicationTotal]</th>
								<th class="th font-bold">[total_price]</th>
								
								
							</tr>
						</thead>
						
					</table>
				</div>
				<div class="condition table_data">
					<h3>[conditions]</h3>
					<span>[employee] : </span>
				</div>

				<div class="client_name">
					<p>[clientName1] :</p>
					<p>[signatureClient] :</p>

				</div>


			</div>
		</div>
`;
