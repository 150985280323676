import React, { useEffect, useState } from "react";
import BreadCrump from "./BreadCrump";
import Locale from "translations";
import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { PlusIcon } from "../shared/Icons";
import { fetchOrdersViolations, violationsExportToExcel } from "services/operationStatement";
import { useHistory } from "react-router-dom";
import TextField from "components/Form/TextField/TextField";
import useDebounce from "hooks/useDebounce";
import moment from "moment";

import SelectField from "components/Form/SelectField/SelectField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";
import OrdersViolationsList from './List';
import Pagination from 'components/Pagination';
import ShowSubscription from 'helpers/ShowSubscription';
import ShowForPermission from 'helpers/showForPermission';




const VIOLATION_TYPES = ["flight", "transportation", "hotel"];
const mapViolationTypesToOptions = (data) =>
	data.map((type, index) => ({ name: type, id: index }));

export default function UmrahOperationsOrdersViolations() {
	const { operationStatement, marketPlace, payment } = Locale;
	const history = useHistory();

	const [searchTerm, setSearchTerm] = useState("");
	const [ordersViolations, setOrdersViolations] = useState({
		list: [],
		meta: {},
	});
	const [filters, setFilters] = useState({
		violation_type: null,
		date: null,
	});

	const handleSearch = async (searchTerm) => {
		let formatedFilters = {
			violation_type: filters?.violation_type?.name || null,
			date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : null,
			search: searchTerm,
		};

		getOrderViolations(formatedFilters);
	};

	const debouncedSearch = useDebounce(handleSearch, 500);

	const handleAddNew = () => {
		history.push("orders-violations/add");
	};

	const handleExport = async () => {
		let formatedFilters = {
			violation_type: filters?.violation_type?.name || null,
			date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : null,
			search: searchTerm,
		};
		const res = await violationsExportToExcel(formatedFilters);
		if (res?.status === 200 && res?.data) {
			const blob = new Blob([res.data], {
				type: res.data.type,
			});

			const objectUrl = window.URL.createObjectURL(blob);
			const anchor = document.createElement("a");
			anchor.target = "_blank";
			anchor.href = objectUrl;
			anchor.setAttribute(
				"download",
				`OrderViolation-${moment().format("DD-MM-YYYY")}`
			);
			anchor.click();
		}
	};


	const handleInputChange = (e) => {
		setSearchTerm(e.target.value);
		debouncedSearch(e.target.value);
	};

	const goTo = (page) => {
		getOrderViolations({ page });
	};

	const getOrderViolations = async (params) => {
		let formatedFilters = {
			violation_type: filters?.violation_type?.name,
			date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : null,
			search: searchTerm,
		};
		const res = await fetchOrdersViolations(params || formatedFilters);
		setOrdersViolations({ list: res.data?.data, meta: res?.data?.meta });
	};
	useEffect(() => {
		getOrderViolations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const breadCrumbLinks = [
		{
			name: operationStatement.umrahCompanyOperations,
			url: "/umrah-operations/operation-statement",
		},
		{
			name: Locale.ordersViolations.ordersViolations,
			url: "/umrah-operations/orders-violations",
		},
	];

	return (
		<ShowSubscription module="order_violation">

			<div className="orders-violations-container px-new-26">
				<BreadCrump links={breadCrumbLinks} />

				<div className="orders-violations-wrapper">
					<div className="row align-items-end px-2">
						<div className="d-flex mb-1 align-items-end col-12 justify-content-end mb-4 px-0 gap-10">
							{/* export violations */}
							<ShowForPermission permission="Manage-Order-Violation">
								<button
									className="btn export-excel-btn py-2 "
									onClick={handleExport}
								>
									<ExportExcelIcon />
									<span className="ml-2">{operationStatement.exportExcel}</span>
								</button>
							</ShowForPermission>
							{/* add new  violation */}
							<ShowForPermission permission="Manage-Order-Violation">
								<button
									className="orders-violations-content-addNewButton ml-2"
									onClick={handleAddNew}
								>
									<PlusIcon color="white" />
									{operationStatement.addNew}
								</button>
							</ShowForPermission>
						</div>

						<div className="col-6 col-md-3 ">
							<TextField
								// hasLabel={false}
								label={marketPlace.messages.search}
								type="text"
								id="group-search"
								name="group-search"
								placeholder={marketPlace.messages.search}
								isImage={true}
								image={SeachIcon}
								prependImage={true}
								onChange={handleInputChange}
								value={searchTerm}
							/>
						</div>

						<div className="col-6 col-md-3 ">
							<SelectField
								label={operationStatement.violationType}
								placeholder={operationStatement.selectType}
								id="violationType"
								name="violationType"
								value={filters.violation_type?.name}
								options={mapViolationTypesToOptions(VIOLATION_TYPES)}
								onChange={(selectedType) => setFilters({ ...filters, violation_type: selectedType })}
							/>
						</div>

						<div className="col-6 col-md-3 ">
							<DatePickerField
								label={operationStatement.date}
								placeholder={operationStatement.selectDate}
								date={filters.date}
								minDate={false}
								onChangeDate={(date) => setFilters({ ...filters, date: date })}
							/>
						</div>

						<div className="col-6 col-md-3">
							<button
								className="btn reset-btn w-100 mb-1"
								onClick={() => {
									setFilters({ date: null, violation_type: null });
									setSearchTerm("");
								}}
							>
								<ResetFilterIcon />
								<span className="mx-2">{payment.messages.clear}</span>
							</button>
						</div>
					</div>

					<ShowForPermission permission="View-Order-Violation">
						<OrdersViolationsList
							violationsList={ordersViolations.list}
							showAgentViolations={false}
							isNoData={(ordersViolations.list.length === 0 && searchTerm.length > 0)}
						/>


						<div className="mt-3">
							<Pagination info={ordersViolations.meta} goTo={goTo} />
						</div>
					</ShowForPermission>

				</div>
			</div>
		</ShowSubscription>

	);
}

