import { NoDataIcon } from "modules/WebBuilder-V2/Components/Content/OurServices/icons";
import React from "react";

import ResultItem from "./resultItem";

export default function SearchResultData({
	resultData,
	productType,
	filter,
	setFilter,
	handleSort
}) {

	return (
		<div className="search-result">
			<div className="header">
				<p>
					{resultData?.length > 0
						? `${resultData[0].locationName} - ${productType}`
						: null}
				</p>
				<div className="select-box">
					<p>sort by</p>
					<select
						className="sort-price-select"
						placeholder="Best Price"
						onChange={(e) => {
							setFilter({...filter, sortBy: e.target.value})
							handleSort(e.target.value)
						}}
					>
						{/* <option value="null">Best Price</option> */}
						<option value="asc" selected={filter?.sortBy === "asc"}>Lowest Price</option>
						<option value="desc" selected={filter?.sortBy === "desc"}>Highest Price</option>
					</select>
				</div>
			</div>
			<div className="result">
				{resultData?.length > 0 ? (
					resultData?.map((item, index) => {
						return (
							<ResultItem
								key={item?.id || index}
								item={item}
								proudctType={productType}
							/>
						);
					})
				) : (
					<div className="no-result">
						<NoDataIcon />
						<h6>No Results Found!</h6>
						<p>You can try another search option or try again later.</p>
						<button className="btn">Back To Search</button>
					</div>
				)}
			</div>
		</div>
	);
}
