import moment from "moment";
import { useState } from "react";
import { store } from "react-notifications-component";
import { addCallLog } from "services/productbuilder";
import Locale from "translations";
import { InboundIcon, OutboundIcon } from "./logIcon";

export default function ColLogs({ reservationDetails, getViewReservation }) {
	const { id, call_logs } = reservationDetails;
	const { inventory } = Locale;
	const [log, setLog] = useState({
		call_type: "inbound",
		note: "",
		package_reservation_id: id,
	});

	async function addLog() {
		const res = await addCallLog(log);
		if (res.status === 200 || res.status === 201) {
      getViewReservation()
			setLog({
				...log,
				note: "",
			})
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1100,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	return (
		<>
			<div className="d-flex d-flex align-items-center justify-content-center">
				<button
					className={`btn Inbound  ${
						log.call_type === "inbound" ? "" : "opacity-5"
					}`}
					onClick={() => setLog({ ...log, call_type: "inbound" })}
				>
					<i class="fas fa-phone-alt me-1"></i>

					{inventory.messages.callInbound}
				</button>
				<button
					className={`btn Outbound ms-2  ${
						log.call_type === "outbound" ? "" : "opacity-5"
					}`}
					onClick={() => setLog({ ...log, call_type: "outbound" })}
				>
					{/* <i class="fas fa-phone-alt me-1"></i> */}
					<i class="fas fa-phone-slash me-1"></i>
					{inventory.messages.callOutbound}
				</button>
			</div>
			<div className="mt-2 px-4 pb-4">
				<textarea
					className=" w-100 p-3"
					placeholder={inventory.messages.notecall} 
					value={log.note}
					onChange={(e) => setLog({ ...log, note: e.target.value })}
				/>
				<button
					onClick={() => addLog()}
					className="btn btn-color text-white  px-3"
					// disabled
				>
					{inventory.messages.save}
				</button>
			</div>
			{call_logs && call_logs.length > 0
						? 	<div className="history p-4">
				<h1 className="mb-4">{inventory.messages.CallsLog}</h1>
				<div>
				{call_logs.map((res) => {
								return (
									<div className="mb-3">
										<div className="head">
											<p>
												{/* <i class="fas fa-phone-alt "></i> */}
												<span className="mx-1">
													{res?.call_type === "inbound" ? (
														<InboundIcon />
													) : (
														<OutboundIcon />
													)}
												</span>
												Logged by {res.by_user}
											</p>

											<p>
												{moment(res.call_date).format("YYYY-MM-DD | hh:mm")}
											</p>
										</div>
										<span className="descrip">{res.note}</span>
									</div>
								);
						  })}
					

				
				</div>
			</div>
			: ""}
		</>
	);
}
