import { ReactComponent as PlaneIcon } from "assets/images/product-builder/plane.svg";
import { ReactComponent as HotelIcon } from "assets/images/product-builder/hotel.svg";
import { ReactComponent as TransportationIcon } from "assets/images/product-builder/transportation.svg";
import { ReactComponent as TrashIcon } from "assets/images/product-builder/trash.svg";
import { ReactComponent as MoreSquare } from "assets/images/product-builder/more-square.svg";
import React, { useState } from "react";
import Locale from "translations";
import { useDetectClickOutside } from "react-detect-click-outside";
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import { ExportToPdf } from "modules/UmrahOperations/shared/Icons";
import moment from "moment";
import Pagination from "components/Pagination";
import { deleteTripPlannerPackage } from "services/tripPlanner";
import { successfulResponse } from "../helpers/formatTripPlannerData";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { VisaIcon } from 'modules/WebBuilder-V2/Components/Content/OurServices/icons';
import PublishAllTripsModal from './PublishAllTripsModal';
import { CameraFlashIcon } from '../ViewEdit/Icons';

export default function TripPlannerList({
	toggle,
	tripData,
	goTo,
	meta,
	setReloadData,
}) {
	const { productsBuilder, backOffice, packagePlanner ,inventory } = Locale;
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(false); 
	const [selectedId, setSelectedId] = useState(null);
	const [publishModal, setPublishModal] = useState({ isOpen: false, trips: [], packageId: null });
	function closeActionMenu() {
		setIsOpen(false);
		// setSelectedId(null)
	}

	function togglePublishModal(trips, packageId) {
		setPublishModal({ isOpen: !publishModal.isOpen, trips, packageId })
	}

	const detectClickOutsideRef = useDetectClickOutside({
		onTriggered: () => {
			if (isOpen && selectedId) {
				closeActionMenu()
				setSelectedId(null)
			}
		},
	});

	async function handleDeletePackage(packageId) {
		const res = await deleteTripPlannerPackage(packageId);
		if (res?.status === 200) {
			successfulResponse("package deleted successfuly");
			closeActionMenu();
			setReloadData((prev) => !prev);
		}
	}

	return (
		<>
			<div
				className="table-responsive table-bordered"
			// style={{ borderRadius: "10px" }}
			>
				<table className="table mb-0 trip-planner-table">
					<thead className="thead-bg ">
						<tr>

							<th className="scope">
								{/* <ArrowsIcon />  */}
								{packagePlanner.packageRef}
							</th>
							<th className="scope">
								{/* <ArrowsIcon /> */}
								{productsBuilder.packageName}
							</th>
							<th className="scope">
								{/* <ArrowsIcon /> */}
								{productsBuilder.services}
							</th>
							<th className="scope">
								{/* <ArrowsIcon />  */}
								{productsBuilder.createdAt}
							</th>
							<th className="scope">
								{/* <ArrowsIcon />  */}
								{productsBuilder.duration}
							</th>
							<th className="scope">
								{/* <ArrowsIcon /> */}
								{productsBuilder.rate}
							</th>
							<th className="scope">{productsBuilder.actions}</th>
						</tr>
					</thead>

					<tbody>
						{tripData?.length > 0 ? (
							tripData.map((trip, index) => {
								const tripsCanShare = trip?.trips?.filter(t => t?.has_price && !t?.is_published && moment(t?.departure_date_at).isAfter(moment()));
								return (
									<tr key={trip?.id}>


										<td
											style={{
												fontSize: "16px",
												fontWeight: "600",
												color: "#2190F5",
											}}
										>
											{trip?.id}
										</td>
										<td>
											<strong
												style={{
													fontSize: "16px",
													fontWeight: "600",
													color: "#050001",
												}}
											>
												{trip?.name}
											</strong>
											<pre
												style={{
													size: "13px",
													fontWeight: "400",
													color: "#707170",
												}}
											>
												{trip?.product_type?.name}
											</pre>
										</td>

										<td
											style={{
												display: "flex",
												alignItems: "center",
												gap: "7px",
											}}
										>
											{trip?.products?.flights && <PlaneIcon />}
											{trip?.products?.hotels && <HotelIcon />}
											{trip?.products?.transportations && <TransportationIcon />}
											{trip?.products?.attractions && <CameraFlashIcon color="#7367F0" />}
											{trip?.products?.visa && <VisaIcon />}
										</td>

										<td>
											{trip?.created_at
												? moment(trip?.created_at).format("DD MMM YYYY")
												: ""}
										</td>

										<td>{trip?.duration - 1} {productsBuilder.nights}</td>

										<td>{trip?.rate}  {inventory.Stars}</td>

										<td>
											<div className="d-flex align-items-center" ref={detectClickOutsideRef}>
												<div className="position-relative">
													<button
														className="btn p-0 mx-2"
														onClick={() => {
															setIsOpen(true);
															setSelectedId((prev) =>
																prev === null ? trip?.id : null
															);
														}}
													>
														<MoreSquare />
													</button>

													{trip?.id === selectedId ? (
														<ul
															style={{ gap: "5px" }}
															className={`actions-list p-3 ${isOpen ? "show" : ""
																}`}
														>
															<li
																className="item stroke payment-row"
																role="button"
																onClick={() => {
																	closeActionMenu();
																	setSelectedId(null);
																	toggle(trip?.id);
																}}
															>
																<ExportToPdf color="#292D32" />
																<span className="payment-text-label">
																	{productsBuilder.marketingPdf}
																</span>
															</li>

															<hr className="hr-horizontal" />

															<li
																className="item stroke payment-row"
																role="button"
																onClick={() => {
																	closeActionMenu();
																	handleDeletePackage(trip?.id);
																}}
															>
																<TrashIcon color="#292D32" />
																<span className="payment-text-label">
																	{productsBuilder.delete}
																</span>
															</li>

															{trip?.trips?.length > 0 && tripsCanShare.length > 0 ?
																<>
																	<hr className="hr-horizontal" />
																	<li className="item stroke payment-row" role="button"
																		onClick={() => togglePublishModal(tripsCanShare, trip?.id)}
																	>
																		<ExportToPdf color="#292D32" />
																		<span className="payment-text-label">
																			{"Publish all trips"}
																		</span>
																	</li>
																</>
																:
																null
															}
														</ul>
													) : null}
												</div>

												<button className="btn hero-content-layout" onClick={() => history.push(`/trip-planner/edit/${trip?.id}`)}>
													<EditIcon />
												</button>
											</div>
										</td>
									</tr>
								)
							})
						) : (
							<tr>
								<td colSpan="7">
									<div className="product-build__product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>


			{publishModal.isOpen ?
				<PublishAllTripsModal
					toggleShareModel={() => togglePublishModal([])}
					shareModelOpen={publishModal.isOpen}
					tripsList={publishModal.trips}
					packageId={publishModal.packageId}
				/>
				:
				null
			}

			{tripData?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</>
	);
}
