import React, { useState } from 'react'
import PreAuthLayoutV2 from './Components/Layout'
import TextField from 'components/Form/TextField/TextField';
import PasswordField from 'components/Form/PasswordField/PasswordField';
import Locale from 'translations';
import validate, { isFormValid } from 'helpers/validate';
import { Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { loginWithSafaKey } from 'services/auth';
import { useSBSDispatch } from 'context/global';
import { store } from 'react-notifications-component';


const userDontHaveSBSAccountCode = "AUTH-010";

export default function LoginWithSafaV2() {
  const { login, commons } = Locale;
  const dispatch = useSBSDispatch();
  const history = useHistory();

  // ** states
  const [loginData, setLoginData] = useState({
    safa_key: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [spin, setSpin] = useState(false);


  function checkFormErrors() {
    let submitError = {};
    Object.keys(loginData).forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: loginData[key] },
          {
            required: true,
            email: key === "email",
          }
        ),
      };
    });
    setErrors(() => submitError);
    return submitError;
  }

  async function submit(e) {
    e.preventDefault();

    let formErrors = checkFormErrors();

    if (isFormValid(formErrors)) {
      setSpin(true);

      const data = {
        safa_key: loginData.safa_key,
        email: loginData.email,
        password: loginData.password,
      };


      const loginWithSafaRes = await loginWithSafaKey(data);

      if (loginWithSafaRes?.status === 200) {
        setSpin(false);
        dispatch({
          type: "login",
          isAuth: true,
          token: loginWithSafaRes.data.data.token,
          userInfo: {
            ...loginWithSafaRes.data.data.user,
            is_first_login: loginWithSafaRes?.data?.data?.user?.is_first_login,
          },
          companyInfo: loginWithSafaRes.data.data.company,
        });
        history.push("/market-place-new");
      } else if (
        loginWithSafaRes?.status === 400 &&
        loginWithSafaRes?.data?.err_code === userDontHaveSBSAccountCode
      ) {
        store.addNotification({
          title: commons.somethingWentWrong,
          message: loginWithSafaRes?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
          // onRemoval: () => history.push("/auth/link_safa_account"),
        });
        setSpin(false);
      } else {
        dispatch({
          type: "setError",
          payload: true,
          message: {
            title: commons.somethingWentWrong,
            body: loginWithSafaRes?.data?.message,
          },
        });
        setSpin(false);
      }
    }
  }

  return (
    <PreAuthLayoutV2>
      <div className="form-header">
        <h2>{login.welcomeBack} </h2>
        <h3>{login.loginMessage}</h3>
      </div>
      <form onSubmit={submit} className="mt-4">
        {/* safa key */}
        <div className="col-12 mt-3">
          <TextField
            label={login.safa_key}
            placeholder={login.safaPlaceholder}
            name="safa_key"
            id="safa_key"
            value={loginData.safa_key}
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(e.target, {
                  required: true,
                }),
              });
              setLoginData({
                ...loginData,
                safa_key: e.target.value,
              });
            }}
            color={errors?.safa_key?.required ? "danger" : ""}
            errors={errors?.safa_key}
          />
        </div>

        {/* username */}
        <div className="col-12 mt-3">
          <TextField
            placeholder={login.usernamePlaceholder}
            label={login.username}
            value={loginData.email}
            name="email"
            id="email"
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(e.target, {
                  required: true,
                  email: true,
                }),
              });
              setLoginData({
                ...loginData,
                email: e.target.value,
              });
            }}
            color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
            errors={errors?.email}
          />
        </div>

        {/* password */}
        <div className="col-12 mt-3">
          <PasswordField
            placeholder={login.passwordPlaceholder}
            hasLabel={true}
            label={login.password}
            name="safa_password"
            id="safa_password"
            value={loginData.password}
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "password", value: e.target.value },
                  { required: true }
                ),
              });
              setLoginData({
                ...loginData,
                password: e.target.value,
              });
            }}
            color={errors?.password?.required ? "danger" : ""}
            errors={errors?.password}
          />
        </div>

        <div className="col-12 d-flex flex-column justify-content-center gap-10 mt-3">
          <button type="submit" className="btn main-btn"
            disabled={spin}
          >
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {spin ? <Spinner color="light" size="sm" /> : null}
              {login.loginTitle}
            </span>
          </button>

          <Link to="/auth/login" className="btn secondary-btn">
            <span className="btn-overlay"></span>
            <span className="text">{login.loginWihtEmail}</span>
          </Link>

          <span className="text-center">
            {login.newCompanySignup} | {" "}
            <Link to="/auth/register">
              {login.signup}
            </Link>
          </span>
        </div>

      </form>
    </PreAuthLayoutV2>
  )
}
