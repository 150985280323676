import Pagination from 'components/Pagination'
import ShowForPermission from 'helpers/showForPermission'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import Locale from 'translations'

function WebPageRequestsList({ requestsList, meta, goTo }) {
	const { inventory, backOffice } = Locale
	return (
		<div className='border table-responsive'>
			<table
				striped
				className='table-update p-2 bg-white m-0 table table-striped'
			>
				<thead>
					<tr>
						<th>{inventory.messages.packageName}</th>
						<th>{inventory.messages.firstName}</th>
						<th>{inventory.messages.lastName}</th>
						<th>{inventory.messages.adultCount}</th>
						<th>{inventory.messages.childCount}</th>
						<th>{inventory.messages.nationality}</th>
						<th>{inventory.messages.email}</th>
						<th>{inventory.messages.phoneNumber}</th>
						<th>{inventory.messages.whatsAppNumber}</th>
						<th>{inventory.messages.note}</th>
					</tr>
				</thead>
				<tbody>
					{requestsList?.length > 0 ? (
						requestsList?.map((request, index) => {
							return (
								<tr key={request?.id}>
									<td>{request?.package_name}</td>
									<td>{request?.first_name}</td>
									<td>{request?.last_name}</td>
									<td>{request?.adults}</td>
									<td>{request?.children}</td>
									<td>{request?.nationality}</td>
									<td>{request?.email}</td>
									<td>{request?.phone}</td>
									<td>{request?.whatsapp_number}</td>
									<td>{request?.message}</td>
								</tr>
							)
						})
					) : (
						<tr>
							<td colSpan='12'>
								<div className='product-build__product-no-data fullHeight'>
									<i className='icx icx-eye-slash' aria-hidden='true'></i>
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<div className='mt-4 pb-3'>
				<Pagination info={meta} goTo={goTo} />
			</div>
		</div>
	)
}

export default WebPageRequestsList
