import React, { useEffect, useState } from 'react'
import FlightsFilter from './FilterFlights'
import Locale from 'translations'
import { Table } from 'reactstrap'
import { Fetchflightlist } from 'services/inventory'
import moment from 'moment'
import walletImgSrc from "assets/images/inventory/walletSm.svg";
import Arrow from "assets/images/icon-arrow-horizontal.svg";
import { Link } from "react-router-dom";
import Pagination from 'components/Pagination'
import { useSBSState } from 'context/global'



function FlightsList() {
  const { backOffice, inventory } = Locale;
  const [searchstatus, setsearchstatus] = useState(false)
  const [Meta, setMeta] = useState({})
  const [Page, setPage] = useState(1)
  const [listdata, setlistdata] = useState([])
  const [total, setTotal] = useState()
  const [filters, setFilters] = useState({
    search: "",
    bookingDate: "",
    tourDate: "",
    status: "",
  })
  	const { locale } = useSBSState();

  useEffect(() => {
    const apply = async () => {
      const data = await Fetchflightlist({
        search: filters.search,
        created_at: filters.bookingDate
          ? moment(filters.bookingDate).format("YYYY-MM-DD")
          : "",
        departure_date: filters.tourDate
          ? moment(filters.tourDate).format("YYYY-MM-DD")
          : "",
        status: filters.status,
      },Page);
      if (data.status === 200) {
        setlistdata(data.data.data)
        setMeta(data.data.meta)
        setTotal(data.data.data.length)
      }
    }
    apply()
  }, [searchstatus, Page])
  const statusLocale = (status) => {
      switch (status) {
        case "pending":
          return "قيدالانتظار";
        case "canceled":
          return "ملغئ";
        case "issued":
          return "مؤكد";
        case "expired":
          return "غير متاح";
        default:
          return status;
      }
 
	};
  const search = () => {
    setsearchstatus(!searchstatus)
  }
  const goTo =(page)=>{
    setPage(page)
  }
  return (
    <div className='transfer-container bg-white'>
      <div className="product-header-list p-4 inventory-header-totals">
        <h3 className="font-weight-bold text-capitalize">
          {inventory.messages.flightsBookings}({total})
        </h3>
      </div>
      <FlightsFilter filters={filters} setFilters={setFilters} search={search} />
      <div className='table-container'>
        <Table striped className="table-update p-2 bg-white table table-striped" style={{ verticalAlign: 'center' }}>
          <thead>
            <tr>
              <th>{inventory.messages.refNumber}</th>
              <th>{inventory.messages.name}</th>
              <th>{inventory.messages.FlightOrder}</th>
              <th>{inventory.messages.bookingDate}</th>
              <th>{inventory.messages.Traveldate}</th>
              <th>{inventory.messages.pax}</th>
              <th>{inventory.messages.source}</th>
              <th>{inventory.messages.actions}</th>
              <th>{inventory.messages.status}</th>
            </tr>
          </thead>
          <tbody>
            {listdata.length > 0 ? listdata?.map((res) => {
              return (
                <tr key={res?.id}>
                  <td>{res?.reference_number}</td>
                  <td>{res?.user_name}</td>
                  <td>{res?.order_number}</td>
                  <td>{res?.booking_date}</td>
                  <td>{res?.travel_date}</td>
                  <td>{res?.pax}</td>
                  <td>{res?.source}</td>

                  <td>
                    {res.payment_status === "confirmed" ? (
                      "-"
                    ) : (
                      <>
                        <img src={walletImgSrc} alt="wallet" className="mx-1" />
                        <Link
                          to={`/inventory/flight/reservation/${res?.id}/booking-details`}
                          className="text-underline h6"
                          style={{
                            color: "#CB9A51",
                          }}
                        >
                          {inventory.messages.payNow}
                        </Link>
                      </>
                    )}
                  </td>
                  <td>
                    <span 
                    
                    className={`text-white text-capitalize rounded px-1 text-center 
                    ${res?.status === "issued" ? "bg-success"
                      : res?.status === "pending" ? " pending"
                        : res?.status === "canceled" || res?.status === "expired" ? "bg-danger" : ""
                      }`}
                    >
                      {locale === "ar" ?
                        statusLocale(res.status) :
                        res.status}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`/inventory/flight/reservation/${res?.id}/booking-details`}
                      className="transfer-arrow"
                    >
                      <img src={Arrow} alt="arrow" />
                    </Link>
                  </td>
                </tr>
              );
            }) : 	
            
            <tr>
							<td colSpan="9">
								<div className="product-build__product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>{" "}
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
            }
          <Pagination info={Meta} goTo={goTo} />
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default FlightsList