import Pagination from "components/Pagination";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Locale from "translations";
import ViewIcon from "../Pricing/IconsSVG/ViewIcon";
import { Tooltip } from "reactstrap";

function ListRequestes({
	requestsList,
	meta,
	goTo,
	dawnloadApplication,
	dawnloadApplicationReservation,
}) {
	const { inventory, backOffice } = Locale;
	const statusClasses = (status) => {
		switch (status) {
			case "pending":
				return "bg-yellow-request";
			case "rejected":
			case "expired":
			case "canceled":
				return "bg-danger";
			default:
				return "bg-green";
		}
	};
	const [tooltip, setTooltip] = useState({
		application: false,
		res: false,
	});
	return (
		<div className="table-responsive border rounded">
			<table striped className="bg-white m-0 table">
				<thead className="th-trip-mang ">
					<tr>
						<th>{inventory.messages.refNumber}</th>
						<th>{inventory.messages.tripname}</th>
						{/* <th>{inventory.messages.tripType}</th> */}
						<th>{inventory.messages.owner}</th>
						<th>{"Source"}</th>
						<th>{inventory.messages.requestDate}</th>
						<th>{inventory.messages.totalpaid}</th>
						{/* <th>{inventory.messages.departure}</th>
						<th>{inventory.messages.nights}</th>
						<th>{inventory.messages.pax}</th>
						<th>{inventory.messages.TotalPrice}</th>
						 <th>{inventory.messages.paymentStatus}</th> */}
						<th>{inventory.messages.Status}</th>
						<th>{inventory.messages.actions}</th>
					</tr>
				</thead>
				<tbody>
					{requestsList?.length > 0 ? (
						requestsList?.map((request, index) => {
							return (
								<tr className="td-TB" key={request?.reservation_num}>
									<td className="text-primary">{request?.reference_num}</td>
									<td>
										{" "}
										<p className="tb-date">{request?.trip_name}</p>
										<p className="tb-date-s">{request?.trip_type}</p>
									</td>
									{/* <td>{request?.trip_type}</td> */}
									<td>{request?.owner?.full_name}</td>
									<td>
										<p className="ele-round">{request?.reservation_from}</p>
									</td>

									<td>
										<p className="tb-date">
											{moment(request?.created_at).format("ll")}
										</p>
									</td>
									<td>
										<p className="tb-date">
											{`
										${
											request?.reservation_from === "online" &&
											request?.reservation_type === "auto" &&
											request?.reservation_status === "paid"
												? request?.total_paid
												: request?.payments_total_paid
										} 
										${request?.currency}
										`}
										</p>
										<p className="tb-date-s">
											{`${inventory.messages.TotalPrice} : 
										 ${request?.total_price}`}
										</p>
										{/* {request?.payments_total_paid} {request?.currency} */}
									</td>
									{/* <td>
										{moment(request?.departure_date).format("DD-MM-YYYY")}
									</td>
									<td>{request?.nights}</td>
									<td>{request?.pax}</td>
									<td>
										{request?.total_price} {request?.currency}
									</td> 
						
								
									<td>
										<span
											className={`px-2 text-white rounded text-capitalize ${
												request?.payment_status === "pending"
													? "bg-yellow-request"
													: "bg-green"
											}`}
										>
											{request?.payment_status === "pending"
												? inventory.messages.unPaid
												: inventory.messages.paid}
										</span>
										</td> */}

									<td>
										<span
											className={`status-badge-${request?.reservation_status}`}
										>
											{request?.reservation_status === "paid"
												? inventory.messages.confirmed
												: request?.reservation_status}
										</span>
									</td>
									<ShowForPermission permission="Manage-Trip-Management-Module">
										<td className="d-flex">
											<Link
												to={`/products-builder/viewrequests/${request?.id}`}
											>
												<ViewIcon />
											</Link>

											<button
												className="btn btn-link"
												onClick={() => dawnloadApplication(request.id)}
											>
												<i
													class="fas fa-file-alt text-success mx-1"
													style={{ fontSize: "19px" }}
													id="Tooltip1"
												></i>
												<Tooltip
													placement="top"
													isOpen={tooltip.application}
													// autohide={false}
													toggle={() =>
														setTooltip({
															...tooltip,
															application: !tooltip.application,
														})
													}
													target="Tooltip1"
												>
													{inventory.ApplicationReport}
												</Tooltip>
											</button>

											<button
												className="btn btn-link"
												onClick={() =>
													dawnloadApplicationReservation(request.id)
												}
											>
												<i
													class="fas fa-file-alt text-success mx-1"
													style={{ fontSize: "19px" }}
													id="Tooltip2"
												></i>
												<Tooltip
													placement="top"
													isOpen={tooltip.res}
													// autohide={false}
													toggle={() =>
														setTooltip({ ...tooltip, res: !tooltip.res })
													}
													target="Tooltip2"
												>
													{inventory.InitialReservation}
												</Tooltip>
											</button>
										</td>
									</ShowForPermission>
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan="12">
								<div className="product-build__product-no-data fullHeight">
									<i className="icx icx-eye-slash" aria-hidden="true"></i>
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{requestsList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</div>
	);
}

export default ListRequestes;
