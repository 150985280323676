import visaColored from "assets/images/1Online.svg";
import visaCray from "assets/images/2Online.svg";
// import groundService from "assets/images/groundService.svg";
// import groundServiceAcive from "assets/images/groundServiceAcive.svg";
import carColored from "assets/images/inventory/carColored.svg";
import carCray from "assets/images/inventory/carCray.svg";
import toursImg from "assets/images/inventory/tours.svg";
import flightImg from "assets/images/flights.svg";

import HotelGray from "assets/images/inventory/HotelGray.svg";
import HotelColored from "assets/images/inventory/HotelsColored.svg";
import classnames from "classnames";
import { useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Locale from "translations";
import GroundServices from "./GroundServices";
import ToursRequests from "./Tour";
import TransferRequests from "./Transfer";
// import TransportationRequests from "./Transportation";
import CustomTableInventoryHotel from "./Hotels";
import VisaRequests from "./VisaRequests";
import useShowPermission from "hooks/useShowPermission";
import FlightsList from './Flights';
import packageImg from "assets/images/packages.svg";
import PackagesRequests from './Packages';
import ShowSubscription from 'helpers/ShowSubscription';
// import TransportationRequests from './Transportation';

export default function InventoryList() {
	const history = useHistory();
	const { permissions, role } = useSBSState();
	const { inventory } = Locale;
	const transferPermission = useShowPermission({
		permission: ["View-Inventory-Transfer", "Manage-Inventory-Transfer"],
	});
	const toursPermission = useShowPermission({
		permission: ["View-Inventory-Tours", "Manage-Inventory-Tours"],
	});
	const hotalsPermission = useShowPermission({
		permission: ["View-Inventory-Hotels", "Manage-Inventory-Hotels"],
	});
	const visasPermission = useShowPermission({
		permission: ["View-Inventory-Visa", "Manage-Inventory-Visa"],
	});
	const flightPermission = useShowPermission({
		permission: ["View-Inventory-Flights", "Manage-Inventory-Flights"],
	});
	// const groundWorkPermission = useShowPermission({
	// 	permission: [
	// 		"View-Inventory-Ground-Service,"
	// 		"Manage-Inventory-Ground-Service",
	// 	],
	// });
	const [activeTab, setActiveTab] = useState(0);
	const { tab } = useParams();

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	useEffect(() => {
		switch (tab) {
			case "hotels":
				setActiveTab("1");
				break;
			case "transfer":
				setActiveTab("2");
				break;
			case "visa-requests":
				setActiveTab("3");
				break;
			case "ground-services":
				setActiveTab("4");
				break;
			case "tour":
				setActiveTab("5");
				break;
			case "flights":
				setActiveTab("6");
				break;
			case "packages":
				setActiveTab("8");
				break;
			// case "transportations":
			// 	setActiveTab("7");
			// 	break;
			default:
				setActiveTab("1");
				history.push("/inventory/hotels");
				break;
		}
	}, [tab, activeTab]);

	const redirect = () =>
		history.push(
			hotalsPermission
				? "/inventory/hotels"
				: transferPermission
					? "/inventory/transfer"
					: visasPermission
						? "/inventory/visa-requests"
						: toursPermission
							? "/inventory/tour"
							: flightPermission
								? "/inventory/flights"
								: null
		);
	useEffect(() => {
		if ((permissions && permissions.length > 0) || role) {
			if (!hotalsPermission && tab === "hotels") {
				redirect();
				return;
			}
			if (!transferPermission && tab === "transfer") {
				redirect();
				return;
			}
			if (!visasPermission && tab === "visa-requests") {
				redirect();
				return;
			}
			// if (!groundWorkPermission && tab === "ground-services") {
			// 	redirect();
			// 	return;
			// }
			if (!toursPermission && tab === "tour") {
				redirect();
				return;
			}
			if (!flightPermission && tab === "flights") {
				redirect();
				return;
			}
		}
	}, [permissions, role]);

	return (
		<>
			{/* <FilterInventory /> */}
			<ShowSubscription module={"marketplace"}>
				<div className="list-container  inventory">
					<div className="bg-white rounded main-nav-sbs">
						<div className="tab-icons">
									<Nav tabs>
										<ShowForPermission
											permission={[
												"View-Inventory-Hotels",
												"Manage-Inventory-Hotels",
											]}
										>
											
											<NavItem className="">
												<NavLink
													className={`border-0  ${classnames({
														active: activeTab === "1",
													})}`}
													onClick={() => {
														toggle("1");
														history.push("/inventory/hotels");
													}}
												>
													<div className="product-build-list-item">
														<img
															src={activeTab === "1" ? HotelColored : HotelGray}
															alt=""
														/>
														<span
															className={`text  mx-1 ${activeTab === "1" ? "title-Filter" : "txt-dis-tabs"
																} `}
														>
															{inventory.messages.hotels}
														</span>
													</div>
												</NavLink>
											</NavItem>
										</ShowForPermission>

										<ShowForPermission
											permission={[
												"View-Inventory-Transfer",
												"Manage-Inventory-Transfer",
											]}
										>
											<NavItem className="">
												<NavLink
													className={`border-0  ${classnames({
														active: activeTab === "2",
													})}`}
													onClick={() => {
														toggle("2");
														history.push("/inventory/transfer");
													}}
												>
													<div className="product-build-list-item">
														<img
															src={activeTab === "2" ? carColored : carCray}
															alt=""
														/>
														<span
															className={`text  mx-1 ${activeTab === "2" ? "title-Filter" : "txt-dis-tabs"
																} `}
														>
															{inventory.messages.transfer}
														</span>
													</div>
												</NavLink>
											</NavItem>
										</ShowForPermission>
										{/* visa requests */}
										<ShowForPermission
											permission={["View-Inventory-Visa", "Manage-Inventory-Visa"]}
										>
											<NavItem className="position-relative">
												<NavLink
													className={classnames({ active: activeTab === "3" })}
													onClick={() => {
														toggle("3");
														history.push("/inventory/visa-requests");
													}}
												>
													{/* <span className="Soon-span">{inventory.messages.soon}</span> */}
													<div className="product-build-list-item">
														<img
															src={activeTab === "3" ? visaColored : visaCray}
															alt=""
															width={25}
															style={{ marginTop: "-2px" }}
														/>
														<span
															className={`text  mx-1 ${activeTab === "3" ? "title-Filter" : "txt-dis-tabs"
																} `}
														>
															{inventory.messages.visaRequests}
														</span>
													</div>
												</NavLink>
											</NavItem>
										</ShowForPermission>


										{/* transportations */}
										{/* <ShowForPermission permission={["manage-transportations"]}> */}
										{/* <NavItem className="position-relative">
											<NavLink
												className={classnames({ active: activeTab === "7" })}
												onClick={() => {
													toggle("7");
													history.push("/inventory/transportations");
												}}
											// disableds	
											>
												<div className="product-build-list-item">
													<img
														src={activeTab === "7" ? carColored : carCray}
														alt=""
													/>
													<span
														className={`text  mx-1 ${activeTab === "7" ? "title-Filter" : "txt-dis-tabs"
															} `}
													>
														{inventory.messages.transportation}
													</span>
												</div>
											</NavLink>
										</NavItem> */}
										{/* </ShowForPermission> */}

										{/******** start Tours ********/}
										<ShowForPermission
											permission={["View-Inventory-Tours", "Manage-Inventory-Tours"]}
										>
											<NavItem className="">
												<NavLink
													className={`border-0  ${classnames({
														active: activeTab === "5",
													})}`}
													onClick={() => {
														toggle("5");
														history.push("/inventory/tour");
													}}
												>
													<div className="product-build-list-item">
														<img src={toursImg} alt="" />
														<span
															className={`text  mx-1 ${activeTab === "5" ? "title-Filter" : "txt-dis-tabs"
																} `}
														>
															{inventory.messages.tours}
														</span>
													</div>
												</NavLink>
											</NavItem>
										</ShowForPermission>

										<ShowForPermission permission={["View-Inventory-Flights", "Manage-Inventory-Flights"]}>
											<NavItem className="">
												<NavLink
													className={`border-0  ${classnames({
														active: activeTab === "6",
													})}`}
													onClick={() => {
														toggle("6");
														history.push("/inventory/flights");
													}}
													// disabled
												>
													<div className="product-build-list-item">
														{/* <span className="Soon-span mt-3">{inventory.messages.soon}</span> */}
														<img src={flightImg} alt="" style={{ width: "25px", height: "25px" }} />
														<span
															className={`text  mx-1 ${activeTab === "6" ? "title-Filter" : "txt-dis-tabs"
																} `}
														>
															{inventory.messages.flights}
														</span>
													</div>
												</NavLink>
											</NavItem>
										</ShowForPermission>

										{/******** End Tours **********/}

										{/* <ShowForPermission permission={["manage-ground-work"]}>
											<NavItem className="position-relative">
												<NavLink
													className={classnames({ active: activeTab === "4" })}
													onClick={() => {
														toggle("4");
														history.push("/inventory/ground-services");
													}}
													disabled
												>
													<span className="Soon-span">{inventory.messages.soon}</span>

													<div className="product-build-list-item">
														<img
															src={
																activeTab === "4" ? groundServiceAcive : groundService
															}
															alt=""
															width={25}
															style={{ marginTop: "-2px" }}
														/>
														<span
															className={`text  mx-1 ${
																activeTab === "4" ? "title-Filter" : "txt-dis-tabs"
															} `}
														>
															{inventory.messages.GroundServices}
														</span>
													</div>
												</NavLink>
											</NavItem>
										</ShowForPermission> */}


										{/* package */}
										<NavItem className="">
											<NavLink
												className={`border-0  ${classnames({
													active: activeTab === "8",
												})}`}
												onClick={() => {
													toggle("8");
													history.push("/inventory/packages");
												}}
												// disabled
											>
												<div className="product-build-list-item">
												{/* <span className="Soon-span mt-3" >{inventory.messages.soon}</span> */}
													<img
														style={{ width: "25px", height: "25px" }}
														src={packageImg}
														alt=""
														srcset=""
													/>
													<span
														className={`text  mx-1 ${activeTab === "6" ? "title-Filter" : "txt-dis-tabs"
															} `}
													>
														{inventory.messages.packages}
													</span>
												</div>
											</NavLink>
										</NavItem>
									</Nav>
								
						</div>

						<TabContent activeTab={activeTab} style={{ minHeight: "100%" }}>
							{/* hotels tab */}
							<TabPane tabId="1">
								{activeTab === "1" ? <CustomTableInventoryHotel /> : null}
							</TabPane>
							{/* hotels tab */}
							<TabPane tabId="2">
								{activeTab === "2" ? <TransferRequests /> : null}
							</TabPane>

							{/* Transportation Tab */}
							{/* <TabPane tabId="7">
								{activeTab === "7" ? <TransportationRequests /> : null}
							</TabPane> */}
							{/* Visa Requests Tab */}
							<TabPane tabId="3">
								{activeTab === "3" ? <VisaRequests /> : null}
							</TabPane>

							<TabPane tabId="4">
								{activeTab === "4" ? <GroundServices /> : null}
							</TabPane>

							<TabPane tabId="5">
								{activeTab === "5" ? <ToursRequests /> : null}
							</TabPane>

							<TabPane tabId="6">
								{activeTab === "6" ? <FlightsList /> : null}
							</TabPane>
							<TabPane tabId="8">
								{activeTab === "8" ? <PackagesRequests /> : null}
							</TabPane>
						</TabContent>
					</div>
				</div>
			</ShowSubscription>
		</>
	);
}
