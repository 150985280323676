import React, { useEffect, useState } from "react";
import OperationStatementHeader from "./StatementHeader";
import Hotels from "./OperationElements/Hotels";
import OperationDetails from "./OperationElements/OperationDetails";
import OperationTabs from "modules/UmrahOperations/shared/Tabs";
import { ReactComponent as OperatingDetailsIcon } from "assets/images/umrah-operations/details.svg";
import { ReactComponent as OperatingHotelsIcon } from "assets/images/umrah-operations/hotels.svg";
import { ReactComponent as OperatingArrivalDepartureIcon } from "assets/images/umrah-operations/arrival-departure.svg";
import { ReactComponent as OperatingAttractionIcon } from "assets/images/umrah-operations/attraction-icon.svg";
import { ReactComponent as OperatingTransportationIcon } from "assets/images/umrah-operations/car-icon.svg";
import { ReactComponent as OperatingVisaIcon } from "assets/images/umrah-operations/visa-icon.svg";
import { ReactComponent as OperatingOtherIcon } from "assets/images/umrah-operations/other-services-icon.svg";
import { ReactComponent as OperatingCateringIcon } from "assets/images/umrah-operations/catering.svg";
import { PassportIcon } from 'modules/UmrahOperations/shared/Icons';
import Locale from "translations";
import BreadCrump from "modules/UmrahOperations/shared/BreadCrump";
import TextField from "components/Form/TextField/TextField";
import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import ArrivalDeparture from "./OperationElements/ArrivalDeparture";
import { getOperationStatement } from "services/operationStatement";
import { useParams } from "react-router-dom";
import { formatViewOperationStatement } from "modules/UmrahOperations/helper/formatUmrahOperations";
import OperationStatementVisa from "./OperationElements/Visa";
import OperationStatementAttractions from "./OperationElements/Attractions";
import OperationStatementOtherServices from "./OperationElements/OtherServices";
import OperationStatementTransportation from "./OperationElements/Transportation";
import OperationStatementCatering from "./OperationElements/Catering";
import OperationStatementPassports from './OperationElements/Passports';

export default function PublicOperationStatementView() {
	const { operationStatement, companyDetails } = Locale;
	const {  id } = useParams();

	const [OperationStatement, setOperationStatement] = useState({
		operation_details: {
			adults: 0,
			children: 0,
			infants: 0,
			agent_id: null,
		},
		arrival_departure: {},
		hotels: [],
		visas: [],
		attractions: [],
		other_services: [],
		transportation: {
			cycle_type: null,
			request_type: null,
			transporter_id: null,
			credential_number: "",
			operation_id: "",
			vehicles: [],
			movements: [],
		},
	});

	const [activeTab, setActiveTab] = useState("operation-details");

	async function getStatementMain() {
		const response = await getOperationStatement(id);
		if (response.status === 200 || response.status === 201) {
			let data = await formatViewOperationStatement(response.data.data, Locale);
			setOperationStatement(data);
		}
	}

	useEffect(() => {
		if (id) {
			getStatementMain();
		}
	}, []);

	const operationTabsItems = [
		{
			title: operationStatement.details,
			icon: <OperatingDetailsIcon />,
			url: "operation-details",
		},

		{
			title: operationStatement.ArrivalDeparture,
			icon: <OperatingArrivalDepartureIcon />,
			url: "operation-ArrivalDeparture",
			disabled: id ? false : true,
		},
		{
			title: operationStatement.hotel,
			icon: <OperatingHotelsIcon />,
			url: "operation-hotels",
			disabled: id ? false : true,
		},
		{
			title: operationStatement.catering,
			icon: <OperatingCateringIcon />,
			url: "operation-catering",
			disabled: id && OperationStatement.hotels.length > 0 ? false : true,
		},
		{
			title: operationStatement.transportation,
			icon: <OperatingTransportationIcon />,
			url: "operation-transportation",
			disabled: id ? false : true,
		},
		{
			title: operationStatement.attractions,
			icon: <OperatingAttractionIcon />,
			url: "operation-attraction",
			disabled: id ? false : true,
		},
		{
			title: operationStatement.visa,
			icon: <OperatingVisaIcon />,
			url: "operation-visa",
			disabled: id ? false : true,
		},
		{
			title: operationStatement.otherServices,
			icon: <OperatingOtherIcon />,
			url: "operation-other",
			disabled: id ? false : true,
		},
		{
			title: "Passport",
			icon: <PassportIcon />,
			url: "operation-passports",
			disabled: id ? false : true,
		},
	];

	const currentTap = operationTabsItems.findIndex(
		(res) => res.url === activeTab
	);

	const isDisabled =	OperationStatement?.status !== "draft"  && OperationStatement?.status !== "new" 

	return (
		<div className="operation-statement-view">
			<div className="container-fluid px-5">
				<BreadCrump title={operationStatement.operatingStatement}>
					<div className="">
						<TextField
				
							type="text"
							id="statement-operation-no"
							name="statement-operation-no"
							hasLabel={false}
							placeholder={operationStatement.quickOperationNo}
							isImage={true}
							image={SeachIcon}
							prependImage={true}
							// onChange={(e) => {
							//   setFilters({
							//     ...filters,
							//     search: e.target.value,
							//   });
							// }}
							// value={filters?.search}
						/>
					</div>
					<button className="btn export-excel-btn mx-2">
						<ExportExcelIcon />
						<span className="mx-1">{operationStatement.exportExcel}</span>
					</button>
				</BreadCrump>

				<OperationStatementHeader OperationStatement={OperationStatement} />

				<div className="operating-view-container mt-4">
					<div className="w-100">
						<div className="w-100 d-flex justify-content-between align-items-center flex-wrap my-3">
							<p className="operating-title-view">
								{operationStatement.AddNewOperatingStatement}
								
							</p>
							<div className="d-flex align-content-center">
								<button
									disabled={currentTap === 0 || !id}
									onClick={() => {
										let tap =
											currentTap > 0
												? operationTabsItems[currentTap - 1].url
												: operationTabsItems[0].url;
										setActiveTab(tap);
									}}
									className={`operating-btn-back mx-1
								${currentTap === 0 || !id ? "disabled" : ""}
								`}
								>
									<i class="fas fa-arrow-left mx-2"></i>
									{companyDetails.back}
								</button>

								<button
									disabled={currentTap === 7 || !id}
									onClick={() => {
										let tap =
											currentTap < 7
												? operationTabsItems[currentTap + 1].url
												: operationTabsItems[0].url;
										setActiveTab(tap);
									}}
									className={`operating-btn-next mx-1
								${currentTap === 7 || !id ? "disabled" : ""}
								`}
								>
									{companyDetails.next}
									<i class="fas fa-arrow-right mx-2"></i>
								</button>
							</div>
						</div>
						<OperationTabs
							tabs={operationTabsItems}
							activeTab={activeTab}
							IsNotPushUrl={true}
							setActiveTab={setActiveTab}
							classNames=" col-md-12 col-12  mx-auto"
						/>
					</div>

					<div className="col-md-12 mt-4">
						{activeTab === "operation-details" ? (
							<OperationDetails
								isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}

						{activeTab === "operation-hotels" ? (
							<Hotels
							isDisabled={isDisabled}
								getStatementMain={getStatementMain}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}

						{activeTab === "operation-ArrivalDeparture" ? (
							<ArrivalDeparture
								isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}
						{/* attractions */}
						{activeTab === "operation-attraction" ? (
							<OperationStatementAttractions
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}
						{/* Transportation */}
						{activeTab === "operation-transportation" ? (
							<OperationStatementTransportation
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}
						{/* visa */}
						{activeTab === "operation-visa" ? (
							<OperationStatementVisa
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}
						{/* other services */}
						{activeTab === "operation-other" ? (
							<OperationStatementOtherServices
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}
						{/* Catering */}
						{activeTab === "operation-catering" ? (
							<OperationStatementCatering
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}

						{/* passport */}
						{activeTab === "operation-passports" ? (
							<OperationStatementPassports
								isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
}
