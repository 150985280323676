import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder'
import { EditBox } from 'modules/WebBuilder/shared/EditBox'
import React, { useState } from 'react'
import { ReactComponent as SettingIcon } from 'assets/images/webBuilder/setting.svg'
import SocialMediaModal from './SocialMediaModal'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import UploadFileComponent from 'modules/WebBuilder/shared/UploadFile'
import { ReactComponent as LogoPlaceholder } from 'assets/images/webBuilder/logo-placeholder.svg'
import Locale from 'translations'

const currentYear = new Date().getFullYear()

export default function CenteredFooter({ focusContainer, handleFocus, socialMediaIcons }) {
	const { webBuilder } = Locale;

	const location = useLocation()
	const history = useHistory()
	const params = useParams()
	const preview = !location.pathname.includes('edit')

	const { footer: footerDetails, hero } = useWebBuilderState()
	const header = hero?.header
	const hasLogo = header?.layout?.hasLogo

	const updateHeroHeader = ({ name, value, type }) => {
		dispatch({
			type: 'updateHeroHeader',
			payload: {
				name,
				value,
				type
			}
		})
	}

	// const [editHeader, setEditHeader] = useState(false)

	// const editFooter = (e) => {
	// 	e.stopPropagation()
	// 	setEditHeader(true)
	// }

	const dispatch = useWebBuilderDispatch()
	const type = 'footer'
	const [socialMediaModal, setSocialMediaModal] = useState(false)
	function toggleSocialMediaModal() {
		setSocialMediaModal(!socialMediaModal)
	}

	const containerBoxActions = [
		<span onClick={toggleSocialMediaModal} className='pointer'>
			<SettingIcon />
		</span>
	]
	// function updateFooterContent(keyName, value, index) {
	// 	dispatch({
	// 		type: 'updateFooter',
	// 		payload: {
	// 			type: keyName,
	// 			value,
	// 			name: index
	// 		}
	// 	})
	// }

	const scrollToSection = (id) => {
		if (id) {
			const element = document.getElementById(id)
			element?.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const nav = (
		<nav className='nav-list'>
			<ul className='links-list'>
				<li
					onClick={() => {
						if (preview) {
							if (params.name) {
								history.push(`/public-page-v2/${params.name}`)
							} else {
								history.push(`/web-builder-v2/preview`)
							}
							scrollToSection('main-web-builder')
						}
					}}
					className={'text-secondary-color'}
				>
					{webBuilder.home}
				</li>

				<li
					onClick={() => {
						let url = params.name
							? `/public-page-v2/${params.name}`
							: '/web-builder-v2/preview'
						if (params.id) {
							history.push({ pathname: `${url}`, state: 'contact-us-section' })
						} else {
							scrollToSection('contact-us-section')
						}
					}}
					className={'text-secondary-color'}
				>
					{webBuilder.getHelp}
				</li>

				<li
					onClick={() => {
						let url = params.name
							? `/public-page-v2/${params.name}`
							: '/web-builder-v2/preview'
						if (params.id) {
							history.push({
								pathname: `${url}`,
								state: 'web-builder-our-service'
							})
						} else {
							scrollToSection('web-builder-our-service')
						}
					}}
					className={'text-secondary-color'}
				>
					{webBuilder.ourServices}
				</li>
			</ul>
		</nav>
	)

	const logoImage = header?.logo ? (
		<img className='footer-photo' src={header?.logo} alt='Logo' />
	) : null
	const logo = preview ? (
		logoImage
	) : (
		<UploadFileComponent
			icon={LogoPlaceholder}
			id='hero-logo'
			label=''
			// label="Change image"
			onUpload={(value) => updateHeroHeader({ name: 'logo', value })}
			image={logoImage}
		/>
	)

	return (
		<>
			<div className='container'>
				<div className='web-builder-footer'>
					<div className='d-flex align-items-center footer-icon footer-logo justify-content-center'>
						{hasLogo && header?.layout?.hasLogo ? logo : null}
					</div>

					<div className='footer-links'>{nav}</div>

					{/* social icons */}
					<div className='footer-socialIcons' onClick={() => handleFocus(type)}>
						<p className='footer-text'>{webBuilder.followUsOn}</p>
						<EditBox
							editMode={focusContainer === type}
							defaultIcon={containerBoxActions}
						>
							<div className='d-flex align-items-center gap-10 py-2'>
								{footerDetails?.links?.map((link) => {
									if (link?.visible) {
										return (
											<div key={link?.id}>
												<a href={link?.url} target='_blank' rel='noreferrer'>
													{socialMediaIcons[link?.id]}
												</a>
											</div>
										)
									} else {
										return null
									}
								})}
							</div>
						</EditBox>
					</div>

					<div className='footer-copyrights align-items-center'>
						<p>
							{webBuilder.allRightsReserved} {" - "}
							<a href="https://safasoft.com/" target="_blank" rel="noreferrer" className="text-white">{webBuilder.safasoft}</a>
							{" "} © {currentYear}
						</p>
					</div>
				</div>
			</div>
			{socialMediaModal && footerDetails?.links ? (
				<SocialMediaModal
					isOpen={socialMediaModal}
					toggle={toggleSocialMediaModal}
					header={'Social links'}
					socialMediaLinks={footerDetails?.links}
				/>
			) : null}
		</>
	)
}
