import ApplicationRoute from 'components/Routes/ApplicationRouter'
import PrivateRoute from 'components/Routes/PrivateRoute'
import ProductsBuilderRoute from 'components/Routes/ProductsBuliderRouter'
import ProgressCircular from 'components/ProgressCircular/ProgressCircular'
import SuccessfullyPayment from 'components/successfullyPayment'
// import Home from "modules/main/Home";
import TeamManagementRoute from 'components/Routes/TeamManagementRoute'
import verificationGoogle from 'components/verificationGoogle'
import VisaRoute from 'components/Routes/VisaRouter'
import WalletRoute from 'components/Routes/WalletRouter'
// import DashboardLayout from "layouts/DashboardLayout";
import ActivityLogs from 'modules/activityLogs'
import Application from 'modules/application'
import Edit from 'modules/application/edit'
import Travelers from 'modules/application/edit/Travelers'
import ChangePassword from 'modules/change-password/ChangePassword'
import CompanyAccountsEdit from 'modules/company-profile/indexEdit'
import CompanyAccountsView from 'modules/company-profile/indexView'
import InventoryList from 'modules/Inventory'
import Voucher from 'modules/Inventory/Hotels/shared/Voucher'
// import BookingDetails from "modules/Inventory/BookingDetails";
// import InventoryList from "modules/Inventory/UI/InventoryList";
import InventoryUmrahHolidays from 'modules/Inventory/Hotels/Reservation'
import MarketInvoice from 'modules/Inventory/Hotels/invoices'
import ViewBooking from 'modules/Inventory/Hotels/Reservation/Individuals/viewBooking'
import Details from 'modules/market-place/HotelResponse/Details'
import List from 'modules/market-place/HotelResponse/HotelList'
import HotelListMap from 'modules/market-place/HotelResponse/HotelList/HotelListMap'
// import Confirm from "modules/market-place/containers/MarketSearch/Confirm";
// import MarketAddHotel from "modules/market-place/HotelResponse/MarketAddHotel";
// import MarketItems from "modules/market-place/HotelResponse/MarketItems";
// import MarketList from "modules/market-place/HotelResponse/MarketList";
// import PaymentMode from "modules/market-place/HotelResponse/PaymentMode";
import Summary from 'modules/market-place/HotelResponse/Summary'
import UmrahHolidaysHotel from 'modules/market-place/HotelResponse/Summary/Umrah-holidays-hotel'
import SearchContainer from 'modules/market-place/Search'
import GroundEditPassangerProfile from 'modules/market-place/Search/GroundService/GroundEditPassangerProfile'
import GroundListPassengers from 'modules/market-place/Search/GroundService/GroundListPassengers'
import GroundPassangerProfile from 'modules/market-place/Search/GroundService/GroundPassangerProfile'
import EditPassangerProfile from 'modules/market-place/Search/OnlineVisa/EditPassangerProfile'
import ListPassengers from 'modules/market-place/Search/OnlineVisa/ListPassengers'
import PassangerProfile from 'modules/market-place/Search/OnlineVisa/PassangerProfile'
import TransportationEditPassangerProfile from 'modules/market-place/Search/Transportation/EditPassangerProfile'
import TransportationListPassengers from 'modules/market-place/Search/Transportation/TransportationListPassengers'
import TransportationPassangerProfile from 'modules/market-place/Search/Transportation/TransportationPassangerProfile'
import Notification from 'modules/Notification'
import PaymentAction from 'modules/payment/paymentAction'
import PaymentHyperpay from 'modules/payment/PaymentHyperpay'
import AddBankTransfer from 'modules/payment/UI/PaymentAdmin/AdminBankTransfer/AddBankTransfer'
import AdminBankTransfer from 'modules/payment/UI/PaymentAdmin/AdminBankTransfer/AdminBankTransfer'
import AddNewCurrency from 'modules/payment/UI/PaymentAdmin/AdminWalletCurrencies/AddNewCurrency'
import PaymentAdmin from 'modules/payment/UI/PaymentAdmin/AdminWalletCurrencies/PaymentAdmin'
import AddNewBank from 'modules/payment/UI/PaymentAdmin/OnlineBanking/AddNewBank'
import OnlineBanking from 'modules/payment/UI/PaymentAdmin/OnlineBanking/OnlineBanking'
import BankTransfer from 'modules/payment/UI/RequestBank/BankTransfer'
import CreditCard from 'modules/payment/UI/RequestBank/CreditCard'
import PaymentFailed from 'modules/payment/UI/RequestBank/PaymentFailed'
import PaymentSuccessful from 'modules/payment/UI/RequestBank/PaymentSuccessful'
// import Reservation from "modules/reservation/UI/Reservation";
import ReservationList from 'modules/reservation/UI/ReservationList'
import ReservationTabs from 'modules/reservation/UI/ReservationTabs'
import Index from 'modules/Statistics/Containers'
// import TeamManagementList from "modules/team-management/UI/TeamManagementList";
import UserProfile from 'modules/user-profile/UserProfile'
import ViewProfile from 'modules/user-profile/viewProfile'
import Visa from 'modules/visa'
import LoginVisa from 'modules/visa/Login'
import ManageContract from 'modules/visa/manage-contract'
import ViewGroup from 'modules/visa/view-group'
import PrintVisa from 'modules/visa/view-group/print-visa'
import ViewMember from 'modules/visa/view-member'
import NewWallet from 'modules/wallet'
import Requests from 'modules/wallet/lists/requests/requests'
import Transaction from 'modules/wallet/lists/transaction/transaction'
import { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import InterestsRoute from './components/Routes/InterestsRoute'
import MarketPlaceRoute from './components/Routes/MarketPlaceRoute'
import ReservationRoute from './components/Routes/ReservationRoute'
import Reservation from './modules/reservation/UI/reservation'
import TransferReservation from 'modules/Inventory/Transfer/Reservation'
import ToursList from 'modules/market-place/ToursResponse/ToursList'
import TourDetails from 'modules/market-place/ToursResponse/Details'
import ToursSummary from 'modules/market-place/ToursResponse/Summary'
import TransferReservations from 'modules/market-place/TransferResponse/Reservation'
import ToursRequests from 'modules/Inventory/Tour'
import TourReservation from 'modules/Inventory/Tour/Reservation'
import Requestes from 'modules/products-builder/Requestes'
import TransferRequests from 'modules/Inventory/Transfer'
import TransferList from 'modules/market-place/TransferResponse/TransferList/index.js'
import requestview from 'modules/products-builder/Requestes/RequestView'
import SuccessfullyTransferPayment from 'modules/market-place/TransferResponse/Reservation/successfullyTransferPayment'
import Outbound from 'modules/market-place/FlightResponse/FlightsList/Outbound'
import Inbound from 'modules/market-place/FlightResponse/FlightsList/Inbound'
import FlightSummray from 'modules/market-place/FlightResponse/FlightSummary'
import FlightsList from 'modules/Inventory/Flights'
import FlightReservation from 'modules/Inventory/Flights/Reservation'
import FlightRefund from 'modules/Inventory/Flights/Reservation/BookingDetails/Refund'
import FlightVoid from 'modules/Inventory/Flights/Reservation/BookingDetails/Void'
import FlightChange from 'modules/Inventory/Flights/Reservation/BookingDetails/Change'
import Products from 'modules/products-builder/pages/Products'
import Subscription from 'modules/subscription'
import PrivacyPolicy from 'modules/auth/pages/PrivacyPolicy'
import AboutUs from 'modules/about-us'
import ContactUsPage from 'modules/ContactUs'
import PackageDetails from 'modules/market-place/NewPackageResponse/Details'
import Packages from 'modules/market-place/Packages'
import CityPackages from 'modules/market-place/NewMarketplace/ViewServices/ServicesSearch/Packages/List'
import CheckoutPackage from 'modules/market-place/NewPackageResponse/CheckoutPackage'
import PackagesRequests from 'modules/Inventory/Packages'
import PackageReservation from 'modules/Inventory/Packages/Reservation'
import B2bRequestes from 'modules/B2bRequests'
import B2brequestview from 'modules/B2bRequests/RequestView'
import ifrem from 'components/ifrem'
import VisaSearchDetails from 'modules/market-place/Search/OnlineVisa/VisaSearchDetails'
import LoginWithSafa from 'modules/auth/pages/LoginWithSafa'
// import AuthRoute from 'components/Routes/AuthRoute'
import HotelAccommodation from 'modules/products-builder/pages/HotelAccommodation'
import BusesAccommodations from 'modules/products-builder/Accommodations/Buses'
// import NewUI from 'modules/market-place/NewUI'
// import ViewBookingService from 'modules/market-place/NewUI/viewBookingService'
import WebBuilder from 'modules/WebBuilder'
import WebBuilderRoute from 'components/Routes/WebBuilderRoute'
// import TDSVisaReservation from 'modules/TDSVisa/Reservation'
import TDSPackagesList from 'modules/TDSVisa/PackagesList'
import TDSVisaInfo from 'modules/TDSVisa/VisaInfo'
import NewMarketPlaceUI from 'modules/market-place/NewMarketplace'
import NewMarketViewServices from 'modules/market-place/NewMarketplace/ViewServices'
import SummaryBooking from 'modules/market-place/NewMarketplace/ViewServices/SpecialAllotment/Summary'
import TDSVisaIntro from 'modules/TDSVisa/PackagesList/Intro'
import UmrahOperations from 'modules/UmrahOperations'
// import OperationAddEditAgent from 'modules/UmrahOperations/Agents/AddEditAgent'
import PublicOperationStatementView from 'modules/UmrahOperations/OperationStatement/View/PublicOperationStatementView'
import GlobalVisaDeatails from 'modules/market-place/NewMarketplace/ViewServices/ServicesSearch/OnlineVisa/GlobalVisaDeatails'
//import FlightsView from "modules/market-place/Search/FlightList";
//import TransferPrint from "assets/TransferPrintCopy";
import PreviewServices from 'modules/WebBuilder/PreviewServices'
import WebPageRequests from 'modules/WebPageRequests'
// web builder version 2
import ChooseTemplate from 'modules/WebBuilder-V2/ChooseTemplate'
import WebBuilderV2 from 'modules/WebBuilder-V2'
import PackageSearch from 'modules/WebBuilder-V2/PackageSearch'
import PreviewServicesV2 from 'modules/WebBuilder-V2/Pages/PreviewServices'
import ErroLoginAuth from 'components/Errors/Error404/ErroLoginAuth'
// import CRM from 'modules/CRM'
// import CRMDetails from 'modules/CRM/Main/views/CRMDetails'
// import ViewCrmCustomer from 'modules/CRM/Customers/View'
// import CsRequestDetails from 'modules/CRM/CSRequests/views'
import SubscriptionPlan from 'modules/SubscriptionPlan/'
import TripPlanner from 'modules/TripPlanner/List'
import TripPlannerViewEdit from 'modules/TripPlanner/ViewEdit'
import CRM from 'modules/CRM'
import CRMDetails from 'modules/CRM/Main/views/CRMDetails'
import ViewCrmCustomer from 'modules/CRM/Customers/View'
import CsRequestDetails from 'modules/CRM/CSRequests/views'
import SubscriptionPackagesPlans from 'modules/SubscriptionPlan/PackagesPlans'

// Lazy Components
const Error404 = lazy(() => import('./components/Errors/Error404/Error404'))
// Products Builder
// const Products = lazy(() => import("modules/products-builder/pages/Products"));
const CreateProduct = lazy(() =>
	import('modules/products-builder/pages/CreateProduct')
)

const TeamManagement = lazy(() => import('modules/team-management'))

// const Interests = lazy(() => import("modules/Interests/Containers/Interests"));

//InterestModuleRefactor
const InterestModule = lazy(() =>
	import('modules/interest-module/Containers/InterestModule')
)

// const BuildProduct = lazy(() => import('modules/products-builder/UI/BuildProduct/BuildProduct'));

// Application Routes
const Routes = (
	<Switch>
		{/* UI Static Routes */}

		{/* ****************** Start Reservation Route ******************** */}
		<Route path="/PrivacyPolicy" exact component={PrivacyPolicy} />
		<Route path="/about-us" exact component={AboutUs} />
		<Route path="/contact-us" exact component={ContactUsPage} />
		<Route path="/auth/login" exact component={ErroLoginAuth} />

		<ReservationRoute
			path='/reservation-traveler'
			component={ReservationTabs}
		/>
		<ReservationRoute path='/reservation' component={Reservation} />
		<ReservationRoute path='/reservation-list' component={ReservationList} />
		{/* ****************** End Reservation Route ******************** */}

		{/* Team Management Routes */}
		<TeamManagementRoute
			path='/team-managementlist/:tab'
			exact
			component={TeamManagement}
		/>
		<TeamManagementRoute
			path='/team-managementlist'
			exact
			component={TeamManagement}
		/>

		{/* ****************** Start Market Place Route ******************** */}

		{/* Market Place Routes */}
		{/* <MarketPlaceRoute path="/market-search" component={SearchContainer} /> */}

		<MarketPlaceRoute path="/market-search/visa-details" exact component={VisaSearchDetails} />
		{/* <MarketPlaceRoute path="/market-search/:tab" exact component={SearchContainer} /> */}		
		<MarketPlaceRoute
			path='/market-search/visa-details'
			exact
			component={VisaSearchDetails}
		/>
		<MarketPlaceRoute
			path='/market-search/global-visa-details'
			exact
			component={GlobalVisaDeatails}
		/>
		<MarketPlaceRoute path="/:status/market-view" exact component={List} />
		<MarketPlaceRoute path="/market-view" component={List} />

		<MarketPlaceRoute
			path='/market-package/package-details/:uuid'
			exact
			component={PackageDetails}
		/>
		<MarketPlaceRoute
			path='/market-package/checkout-package/:uuid'
			exact
			component={CheckoutPackage}
		/>
		{/* <MarketPlaceRoute
			path='/market-search/:tab'
			exact
			component={SearchContainer}
		/>



		{/* TODO flight need more naming *******************************************/}

		<MarketPlaceRoute path='/outbound-view' component={Outbound} />
		<MarketPlaceRoute path='/inbound-view' component={Inbound} />
		<MarketPlaceRoute path='/flight-summray' component={FlightSummray} />

		<MarketPlaceRoute path='/market-view-map' component={HotelListMap} />
		<MarketPlaceRoute path='/market-details/:id' component={Details} />
		<MarketPlaceRoute
			path='/successfullyPayment'
			component={SuccessfullyPayment}
		/>
			<MarketPlaceRoute
			path='/successfullyPayment/:type'
			component={SuccessfullyPayment}
		/>
		<MarketPlaceRoute
			path='/successfullyTransferPayment'
			component={SuccessfullyTransferPayment}
		/>
		<MarketPlaceRoute path='/:searchBy/confirm' component={Summary} />
		<MarketPlaceRoute
			path='/Umrah/confirm-hotel'
			component={UmrahHolidaysHotel}
		/>

		<MarketPlaceRoute
			path='/inventory/visa-requests/:status/:id'
			component={ListPassengers}
		/>
		{/* <MarketPlaceRoute
			path="/tds/visa-reservation/:status/:id"
			component={TDSVisaReservation}
		/> */}
		<MarketPlaceRoute path='/Online-Visa/Search' component={ListPassengers} />
		<MarketPlaceRoute
			path='/ground-service/Search'
			component={GroundListPassengers}
		/>
		<MarketPlaceRoute
			path='/ground-service/Add/Passenger'
			component={GroundPassangerProfile}
		/>
		<MarketPlaceRoute
			path='/ground-services/Edit/Passenger'
			component={GroundEditPassangerProfile}
		/>
		<MarketPlaceRoute
			path='/inventory/ground-services/:status/:id'
			exact
			component={GroundListPassengers}
		/>

		<MarketPlaceRoute
			path='/transportation/Search'
			component={TransportationListPassengers}
		/>

		<MarketPlaceRoute path='/transfer' exact component={TransferList} />
		<MarketPlaceRoute
			path='/transfer/reservations'
			component={TransferReservations}
			exact
		/>
		<MarketPlaceRoute
			path='/transportation/Add/Passenger'
			component={TransportationPassangerProfile}
		/>
		<MarketPlaceRoute
			path='/transportation/Edit/Passenger'
			component={TransportationEditPassangerProfile}
		/>

		<MarketPlaceRoute
			path='/Online-Visa/Add/Passenger/manual'
			component={PassangerProfile}
		/>
		<MarketPlaceRoute
			path='/Online-Visa/Add/Passenger'
			component={PassangerProfile}
		/>

		<MarketPlaceRoute
			path='/visa-requests/Edit/Passenger'
			component={EditPassangerProfile}
		/>

		<MarketPlaceRoute path='/Print/invoice/:brn' component={MarketInvoice} />

		<MarketPlaceRoute path='/Print/invoice/:brn' component={MarketInvoice} />

		<MarketPlaceRoute path='/inventory/:tab' exact component={InventoryList} />
		<MarketPlaceRoute
			path='/inventory/transportations/:status/:id'
			exact
			component={TransportationListPassengers}
		/>
		<MarketPlaceRoute
			path='/inventory/transfer'
			exact
			component={TransferRequests}
		/>

		<MarketPlaceRoute
			path='/inventory/transfer/reservation/:id'
			exact
			component={TransferReservation}
		/>
				<MarketPlaceRoute path="/:status/transfer" exact component={TransferList} />


		{/* <MarketPlaceRoute
			path="/inventory/flight/reservation/:id"
			exact
			component={FlightReservation}
		/> */}
		<MarketPlaceRoute
			path='/inventory/flight/reservation/:id/refund'
			exact
			component={FlightRefund}
		/>
		<MarketPlaceRoute
			path='/inventory/flight/reservation/:id/void'
			exact
			component={FlightVoid}
		/>

		<MarketPlaceRoute
			path='/inventory/flight/reservation/:id/change'
			exact
			component={FlightChange}
		/>
		<MarketPlaceRoute
			path='/inventory/flight/reservation/:id/:tab'
			// exact
			component={FlightReservation}
		/>
		<MarketPlaceRoute path='/:status/transfer' exact component={TransferList} />

		{/********Start Tours ***********/}
		<MarketPlaceRoute path='/inventory/tour' exact component={ToursRequests} />

		<MarketPlaceRoute path='/inventory/flights' exact component={FlightsList} />

		<MarketPlaceRoute
			path='/inventory/tour/reservation/:id'
			exact
			component={TourReservation}
		/>
		{/********End Tours ***********/}
		<MarketPlaceRoute path="/:status/transfer" exact component={TransferList} />


		<MarketPlaceRoute path="/:status/tours-result" exact component={ToursList} />
		<MarketPlaceRoute path="/tours-result" component={ToursList} />
		<MarketPlaceRoute path="/tour-details/:id" component={TourDetails} />
		<MarketPlaceRoute path="/tour-summary" component={ToursSummary} />
		<MarketPlaceRoute path="/packages" component={Packages} />
		<MarketPlaceRoute path="/city-packages/:id" component={CityPackages} />
		<MarketPlaceRoute exact path="/" component={NewMarketPlaceUI} />



		<MarketPlaceRoute path="/market-place-new/:status" exact  component={NewMarketViewServices} />
		<MarketPlaceRoute path="/market-place-new/:status/:tab"  exact component={NewMarketViewServices} />
		<MarketPlaceRoute path="/market-place-new/umrah/special-allotment/summary"  exact component={SummaryBooking} />


		{/* ****************** package routes ******************** */}
		<MarketPlaceRoute path="/inventory/packages" exact component={PackagesRequests} />

		<MarketPlaceRoute
			path='/market-place-new'
			exact
			component={NewMarketPlaceUI}
		/>
		<MarketPlaceRoute
			path='/market-place-new/:status'
			exact
			component={NewMarketViewServices}
		/>
		<MarketPlaceRoute
			path='/market-place-new/:status/:tab'
			exact
			component={NewMarketViewServices}
		/>
		<MarketPlaceRoute
			path='/market-place-new/umrah/special-allotment/summary'
			exact
			component={SummaryBooking}
		/>

		{/* ****************** package routes ******************** */}
		<MarketPlaceRoute
			path='/inventory/packages'
			exact
			component={PackagesRequests}
		/>

		<MarketPlaceRoute
			path='/inventory/packages/:id'
			exact
			component={PackageReservation}
		/>

		{/* ****************** End Market Place Route ******************** */}

		{/* Payment Routes */}

		<Route path='/google1ddf08c5d66e9bbf.html' component={verificationGoogle} />

		{/* ****************** Start Wallet Route ******************** */}

		{/* Payment Routes */}
		<WalletRoute path='/credit-card' component={CreditCard} />
		<WalletRoute path='/bank-transfer' component={BankTransfer} />
		<WalletRoute path='/payment-successful' component={PaymentSuccessful} />
		<WalletRoute path='/payment-failed' component={PaymentFailed} />
		<WalletRoute path='/payment-admin' component={PaymentAdmin} />
		<WalletRoute path='/add-currency' component={AddNewCurrency} />
		<WalletRoute path='/add-bank-transfer' component={AddBankTransfer} />
		<WalletRoute path='/admin-bank-transfer' component={AdminBankTransfer} />
		<WalletRoute path='/online-banking' component={OnlineBanking} />
		<WalletRoute path='/add-new-bank' component={AddNewBank} />
		{/* New Wallet Routes*/}
		<WalletRoute path='/crm/:tab' exact component={CRM} />
		{/** /crm/:tab/:id */}
		<WalletRoute path='/crm/main/:id'  component={CRMDetails} />
		{/* <WalletRoute path='/crm/:tab/:id' component={CRMDetails} /> */}
		<WalletRoute path='/crm/customers/:id' component={ViewCrmCustomer} />

		<WalletRoute path='/crm/cs-requests/:id' component={CsRequestDetails} />

		<WalletRoute path='/subscription/plans' component={SubscriptionPlan} />
		<WalletRoute path='/subscription/packages-plans' component={SubscriptionPackagesPlans} />


		<WalletRoute path='/wallet/:tab' exact component={NewWallet} />
		<WalletRoute path='/wallet' exact component={NewWallet} />
		<WalletRoute path='/wallet/transaction/:id' component={Transaction} />
		<WalletRoute path='/wallet/requests/:id' component={Requests} />
		{/* /wallet/requests/ */}
		{/* ****************** End Wallet Route ******************** */}

		{/* ****************** Start Products Builder Route ******************** */}

		{/* Products Builder */}
		<ProductsBuilderRoute
			path='/products-builder/hotel-accommodation/:id'
			component={HotelAccommodation}
		/>
		<ProductsBuilderRoute
			path='/products-builder/products'
			component={Products}
		/>
		<ProductsBuilderRoute
			path='/products-builder/requests'
			component={Requestes}
		/>
		<ProductsBuilderRoute path='/b2b-requests' component={B2bRequestes} exact />

		<ProductsBuilderRoute
			path='/web-page-requests'
			component={WebPageRequests}
			exact
		/>

		<ProductsBuilderRoute
			path='/b2b-requests/:id'
			component={B2brequestview}
			exact
		/>
		<ProductsBuilderRoute path='/subscription' component={Subscription} />
		<ProductsBuilderRoute
			path='/products-builder/viewrequests/:id'
			component={requestview}
		/>
		<ProductsBuilderRoute path='/activity-logs' component={ActivityLogs} />
		{/*New Products Builder refactor*/}
		<ProductsBuilderRoute
			path='/products-builder/create'
			component={CreateProduct}
		/>

		<ProductsBuilderRoute
			path='/products-builder/show'
			component={CreateProduct}
		/>
		<Route path='/auth/login_safa' exact component={LoginWithSafa} />
		<ProductsBuilderRoute
			path='/products-builder/:id/bus-accommodation'
			component={BusesAccommodations}
		/>

		<ProductsBuilderRoute
			exact
			path='/trip-planner/edit/:id'
			component={TripPlannerViewEdit}
		/>
		<ProductsBuilderRoute
			exact
			path='/trip-planner/add'
			component={TripPlannerViewEdit}
		/>
		<ProductsBuilderRoute path='/trip-planner' component={TripPlanner} />


		{/* ****************** End Products Builder Route ******************** */}

		{/* ****************** Start Application Route ******************** */}
		{/* Application Module */}
		<ApplicationRoute path='/application' component={Application} />
		<ApplicationRoute path='/aplication/edit' component={Edit} />
		<ApplicationRoute path='/aplication/add-traveler' component={Travelers} />

		{/* ****************** End Application Route ******************** */}

		{/* ****************** Start Visa Route ******************** */}

		{/* Visa Module */}
		<VisaRoute exact path="/visa" component={Visa} />
		<VisaRoute path="/visa/login" component={LoginVisa} />
		<VisaRoute path="/visa/manage-contract" component={ManageContract} />
		
		<VisaRoute path="/visa/view-group/:id" component={ViewGroup} />
		<VisaRoute path='/visa/view-member/:onlineId/:groupId' component={ViewMember} />
		<VisaRoute exact path='/visa/print-visa' component={PrintVisa} />
		{/* ****************** End Visa Route ******************** */}

		<InterestsRoute path='/interest-module' component={InterestModule} />

		{/* ****************** Start Private Route ******************** */}

		{/* User Profile Routes */}
		<PrivateRoute path='/edit-user-profile' component={UserProfile} />
		<PrivateRoute path='/user-profile' component={ViewProfile} />
		<PrivateRoute path='/change-password' component={ChangePassword} />
		{/* Company Profile Routes */}
		<PrivateRoute
			path='/edit-company-profile'
			component={CompanyAccountsEdit}
		/>
		<PrivateRoute path='/company-profile' component={CompanyAccountsView} />
		{/* version 2 */}
		<WebBuilderRoute path='/web-builder/select' component={ChooseTemplate} />
		<WebBuilderRoute
			path='/web-builder-v2/preview/packages-results'
			component={PackageSearch}
		/>
		<WebBuilderRoute
			path='/web-builder-v2/preview/:service/:id'
			component={PreviewServicesV2}
		/>
		<WebBuilderRoute
			path='/web-builder-v2/preview/:pageName'
			component={WebBuilderV2}
		/>
		<WebBuilderRoute path='/web-builder-v2/preview' component={WebBuilderV2} />
		<WebBuilderRoute
			path='/web-v2/:service/:id'
			component={PreviewServicesV2}
		/>
		<WebBuilderRoute
			path='/web-builder-v2/edit/:pageName'
			component={WebBuilderV2}
		/>
		<WebBuilderRoute path='/web-builder-v2/edit' component={WebBuilderV2} />

		{/* version 1 */}
		<WebBuilderRoute path='/web-builder-v1' component={WebBuilder} />
		<WebBuilderRoute path='/web-builder-v1/preview' component={WebBuilder} />
		<WebBuilderRoute path='/web-builder-v1/edit' component={WebBuilder} />
		<WebBuilderRoute path='/web-v1/:service/:id' component={PreviewServices} />

		<Route path='/tds-visa-packages' component={TDSPackagesList} />
		<Route path='/tds-visa/details' component={TDSVisaInfo} />
		<Route path='/tds-visa' component={TDSVisaIntro} />

		{/* umrah operations routes */}
		{/* <Route path="/umrah-operations/agents/:status" exact component={OperationAddEditAgent} /> */}
		{/* <Route path="/umrah-operations/agents/:status/:id" component={OperationAddEditAgent} /> */}
		{/* <Route path="/operation-statement" component={OperationStatementList} /> */}
		{/* <Route path="/umrah-operations/:tab/:id" component={UmrahOperations} /> */}
		{/* <Route path='/umrah-operations/:tab/:reference' component={UmrahOperations} /> */}
		<Route path='/umrah-operations/:tab/:reference/:id' component={UmrahOperations} />
		<Route path='/umrah-operations/:tab/:reference' component={UmrahOperations} />
		<Route
			path='/umrah-operations/:tab/:id/:reference'
			component={UmrahOperations}
		/>
		<Route path='/umrah-operations/:tab' component={UmrahOperations} />

		{/* <Route path="/umrah-operations/:tab" component={UmrahOperations} /> */}
		<Route
			path='/public/umrah-operations/:id'
			component={PublicOperationStatementView}
		/>

		{/* Inventory Routes */}
		<PrivateRoute path='/inventory' exact component={InventoryList} />

		<PrivateRoute
			path='/Hotels/inventory/:brn'
			component={InventoryUmrahHolidays}
		/>
		<PrivateRoute
			path='/Hotels/inventory-group/:brn'
			component={InventoryUmrahHolidays}
		/>

		<PrivateRoute path='/Hotels/view-booking' component={ViewBooking} />
		<PrivateRoute path='/PayOnline/:tap' component={PaymentAction} />

		<PrivateRoute path="/PayOnline" component={PaymentAction} />
		
		<PrivateRoute path="/hyperpay" component={PaymentHyperpay} />
		<PrivateRoute exact path="/inventory-voucher" component={Voucher} />
		{/* Dashboard Module */}
		<PrivateRoute exact path="/survey" component={ifrem} />
		{/* Dashboard Module */}
		<PrivateRoute exact path='/' component={Index} />
		{/* BackOffice */}
		<PrivateRoute path='/progress-circular' component={ProgressCircular} />
		<PrivateRoute path='/statistics' exact component={Index} />
		{/* General Routes */}
		<PrivateRoute path='/notification' component={Notification} />
		{/* ****************** End Private Route ******************** */}
		<Route path='*' component={Error404} />
	</Switch>
)

export default Routes
