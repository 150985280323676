import { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader, TabPane } from "reactstrap";

import Locale from "translations";

import {
	changeRequestStatus,
	// requestViewReservation,
	requestViewReservationB2b,
	setPackageRoomAvailabilities,
} from "services/productbuilder";
import { useParams } from "react-router-dom";
import SummaryTab from "./SummaryTab";
import Listpax from "./paxDetails/ListPax";
import Listpayemnts from "./payments/ListPayments";
// import GuestsBox from "./GuestsBox";
import ColLogs from "./CallLogs";
import History from "./History";
// import ItineraryRequest from "./ItineraryRequest";
import PassangerModal from "./paxDetails/passangerModal";
import PaymentsModal from "./payments/paymentsModal";
import { store } from "react-notifications-component";
import { useSBSState } from 'context/global';
import ProductItinerary from 'modules/market-place/NewPackageResponse/Details/ProductItinerary';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import moment from 'moment';
import GuestsBox from './GuestsBox';
import { formatRoomsData } from './helpers/formatRoomsData';
import { formatProductItems } from 'modules/market-place/NewPackageResponse/helpers/formatServicesItems';
import GuestsRooms from './GuestsRooms';

export default function B2brequestview() {
	const { payment, inventory } = Locale;
	const { allLookupsBuilder, locale } = useSBSState();
	const { id } = useParams();
	const [activeTap, setActiveTap] = useState(1);
	const [activeTapSide, setActiveTapSide] = useState(1);
	const [reservationDetails, setReservationDetails] = useState(null);
	const [totalPrice, setTotalPrice] = useState();
	const [actionModal, setActionModal] = useState({
		isOpen: false,
		statusType: "",
		reject_reason: "",
		expire_at: null
	});

	const reservationType = reservationDetails?.reservation_type;
	const [guests, setGuests] = useState(null);
	const [modalPass, setmodalPass] = useState({
		isOpen: false,
		title: "add Passport",
	});
	const [spasificPassenger, setSpasificPassenger] = useState({});
	const [Reload, setReload] = useState(false);
	const togglepass = (type = null, passanger) => {
		setmodalPass({ isOpen: !modalPass.isOpen, title: type });
		setSpasificPassenger(passanger);
	};
	const [modalPax, setmodalPax] = useState({
		isOpen: false,
		title: "add Payments",
	});
	const [roomsData, setRoomsData] = useState([
		{ roomType: null, roomCount: 0, childCount: 0, },
	]);
	const [productItemsPrices, setProductItemsPrices] = useState(null);

	function toggleActionModal(statusType) {
		setActionModal((prev) => ({
			isOpen: !prev.isOpen,
			statusType,
			reject_reason: "",
			expire_at: null,
		}))
	}
	const togglepax = () => {
		setmodalPax({ isOpen: !modalPax.isOpen });
	};
	const roomTypesLookup = {
		"1": "Single",
		"2": "Double",
		"3": "Triple",
		"4": "Quadruple",
		"5": "Quintuple",
		"6": "Hexagonal",
		"7": "heptathlon",
		"8": "suite",
		"9": "flat"
	}



	async function getViewReservation() {
		// to can catch change Request id guest 
		setGuests(null)
		const viewReservationRes = await requestViewReservationB2b(id);
		if (viewReservationRes?.status >= 200 && viewReservationRes?.status < 300) {
			let adults = [];
			let children = [];
			const guestsList =
				viewReservationRes?.data?.data?.roomAvailabilities?.map((room) => {
					children = [...Array(+room?.children_count).keys()].map(() => {
						return {
							...room,
							type: { value: 2, name: "Child", id: 2 },
							price: room?.children_price,
						};
					});

					adults = [...Array(+room?.adults_count).keys()].map(() => {
						return {
							...room,
							type: { value: 1, name: "Adult", id: 1 },
							price: room?.adults_price,
						};
					});
					return [...adults, ...children];
				});
			const servicesList = formatProductItems(viewReservationRes?.data?.data?.product?.product_items, locale);
			const roomsList = formatRoomsData(viewReservationRes?.data?.data, roomTypesLookup);
			!productItemsPrices && setProductItemsPrices(servicesList);
			setRoomsData(roomsList);
			setReservationDetails(viewReservationRes?.data?.data);
			setGuests(guestsList?.flat());
			setTotalPrice(viewReservationRes?.data?.data?.total_price);

		} else {
			// history.push('/products-builder/requests');
		}
	}

	useEffect(() => {
		if (id) {
			getViewReservation();
		} else {
			// history.push('/products-builder/requests');
		}
	}, [id, Reload]);

	async function changePackageStatus(status) {
		const data = {
			reservation_num: reservationDetails?.reservation_num,
			status: status,
			expire_at: actionModal.expire_at ? moment(actionModal.expire_at).format("DD-MM-YYYY") : null,
			reject_reason: actionModal.reject_reason,
		};
		const res = await changeRequestStatus(data);

		if (res?.status === 200 || res?.status === 201) {
			store.addNotification({
				title: "info!",
				message: status === "accept" ? "Request Accepted Successfully" : "Request Rejected Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					pauseOnHover: true,
				},
			});
			toggleActionModal(null);
			getViewReservation();
		}
	}

	async function handlePackageRoomAvalibities() {
		const data = {
			reservation_num: reservationDetails?.reservation_num,
			rooms: roomsData?.filter(room => room?.combination_id)?.map(room => {
				return {
					combination_id: room?.combination_id,
					rooms_count: +room?.roomCount,
					children_count: +room?.childCount
				}
			})
		}
		const res = await setPackageRoomAvailabilities(data);
		if (res?.status === 200) {
			store.addNotification({
				title: "info!",
				message: "Rooms updated Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					pauseOnHover: true,
				},
			});
			setReload(prev => !prev)
		}
	}

	console.log(productItemsPrices);



	const calcTotalPrices = useMemo(() => {
		let total = 0;
		let adultPaxCount = 0;
		let childPaxCount = 0;

		productItemsPrices?.forEach(service => {
			if (service?.totalPrice) {
				total = total + service?.totalPrice;
			} else {
				let adultPrice = +service?.price_adult ? (+service?.price_adult * adultPaxCount) : 0;
				let childPrice = +service?.price_child ? (+service?.price_child * childPaxCount) : 0;
				total = total + (adultPrice + childPrice);
			}
		})
		return total;
	}, [productItemsPrices]);


	return (
		<div className="container">
			<div className="row">
				<div className="col-md-8 col-12">
					<div>
						<div className="requestTaps">
							<TabPane
								className={`tap ${activeTap === 1 && `active`}`}
								onClick={() => {
									setActiveTap(1);
								}}
								tabId={1}
							>
								{payment.messages.summardetails}
							</TabPane>
							<TabPane
								className={`tap ${activeTap === 2 && `active`}`}
								onClick={() => {
									setActiveTap(2);
								}}
								tabId={2}
							>
								{payment.messages.paxdetails}
							</TabPane>
							{/* {reservationDetails?.reservation_from === "online" &&
								reservationDetails?.reservation_type === "auto" ? null : (
								<TabPane
									className={`tap ${activeTap === 3 && `active`}`}
									onClick={() => {
										setActiveTap(3);
									}}
									tabId={3}
								>
									{payment.messages.Payments}
								</TabPane>
							)} */}
						</div>
						{activeTap === 1 && reservationDetails && (
							<SummaryTab reservationDetails={reservationDetails} />
						)}
						{activeTap === 2 && (
							<Listpax
								togglepass={togglepass}
								setReload={setReload}
								Reload={Reload}
								reservationDetails={reservationDetails}
							/>
						)}
						{activeTap === 3 && (
							<Listpayemnts
								toggle={togglepax}
								setReload={setReload}
								Reload={Reload}
								reservationDetails={reservationDetails}
							/>
						)}
					</div>

					{guests && Object.keys(allLookupsBuilder).length > 0 ? (
						<div>
							{reservationDetails?.type === "room" &&
								<GuestsRooms
									reservationDetails={reservationDetails}
									activeTap={activeTap}
									roomsData={roomsData}
									setRoomsData={setRoomsData}
									productItemsPrices={productItemsPrices}
									setProductItemsPrices={setProductItemsPrices}
									roomTypesLookup={roomTypesLookup}
									handlePackageRoomAvalibities={handlePackageRoomAvalibities}
								/>
							}
							{reservationDetails?.type === "pax" &&
								<GuestsBox
									reservationDetails={reservationDetails}
									guests={guests}
									setGuests={setGuests}
									activeTap={activeTap}
									setTotalPrice={setTotalPrice}
									getViewReservation={getViewReservation}
									allLookupsBuilder={allLookupsBuilder}
								/>
							}

						</div>
					) : null}


					{reservationDetails ? (
						<ProductItinerary itineraryData={reservationDetails?.product_itinerary} />
					) : null}

					{/* accept or reject application */}
					<div className="d-flex justify-content-between align-items-center my-4">
						<div style={{ fontSize: "18px" }}>
							<p>{inventory.messages.TotalPackagePrice}</p>
							<p style={{ color: "#10A711" }}>
								<strong style={{ fontWeight: "bold" }}>
									{calcTotalPrices} {" "}
									{reservationDetails?.currency}
									{/* {reservationDetails?.total_price} */}
								</strong>
							</p>
						</div>
						{reservationType === "manual" &&
							reservationDetails?.reservation_status === "pending" &&
							reservationDetails?.reservation_from === "online" ? (
							<div>
								<button
									className="btn btn-guests me-2"
									style={{ color: "#fff", backgroundColor: "#C30101" }}
									onClick={() => {
										toggleActionModal("reject")
									}}
								// onClick={() => changePackageStatus("reject")}
								>
									{inventory.messages.reject}
								</button>
								<button
									style={{ color: "#fff", backgroundColor: "#027B30" }}
									className="btn btn-guests"
									onClick={() => {
										toggleActionModal("accept")
									}}
								// onClick={() => changePackageStatus("accept")}
								>
									{inventory.messages.accept}
								</button>
							</div>
						) : null}
					</div>
				</div>
				{/* calls log and history tabs */}
				<div className="col-md-4 col-12 ">
					<div className="side-col">
						<div className="requestTaps">
							<TabPane
								className={`tap ${activeTapSide === 1 && `active`}`}
								onClick={() => {
									setActiveTapSide(1);
								}}
								tabId={1}
							>
								{inventory.messages.CallsLog}
							</TabPane>
							<TabPane
								className={`tap ${activeTapSide === 2 && `active`}`}
								onClick={() => {
									setActiveTapSide(2);
								}}
								tabId={2}
							>
								{inventory.messages.History}
							</TabPane>
						</div>
						{activeTapSide === 1 && reservationDetails && (
							<ColLogs
								reservationDetails={reservationDetails}
								getViewReservation={getViewReservation}
							/>
						)}
						{activeTapSide === 2 && <History />}
					</div>
				</div>
			</div>
			{/* add passport modal */}
			<PassangerModal
				modalPass={modalPass}
				toggle={togglepass}
				setReload={setReload}
				Reload={Reload}
				reservationDetails={reservationDetails}
				spasificPassenger={spasificPassenger}
			/>
			{/* add payments modal */}
			<PaymentsModal
				modal={modalPax}
				toggle={togglepax}
				setReload={setReload}
				Reload={Reload}
				reservationDetails={reservationDetails}
			/>
			{actionModal.isOpen ?
				<Modal isOpen={actionModal.isOpen}>
					<ModalHeader toggle={toggleActionModal}>
						<span className='text-capitalize'>{actionModal.statusType}</span>
					</ModalHeader>
					<ModalBody>
						{actionModal.statusType === "accept" ?
							<DatePickerField
								label={"Optional Date"}
								placeholder={"DD/MM/YYYY"}
								date={actionModal.expire_at}
								name="birth_date"
								hasYears={true}
								onChangeDate={(date) => {
									setActionModal({ ...actionModal, expire_at: date });
								}}
								isOutsideRange={(day) => {
									return !day.isAfter(moment(), "day") || day.isAfter(moment(reservationDetails?.departure_date), "day");
								}}
							/>
							:
							<textarea
								className=" w-100 p-3 border rounded-lg"
								placeholder={inventory.messages.rejectReason}
								value={actionModal.reject_reason}
								onChange={(e) => setActionModal({ ...actionModal, reject_reason: e.target.value })}
							/>
						}
						{/* action button */}
						<div className='mt-2'>
							{actionModal.statusType === "reject" &&
								<button
									className="btn btn-guests me-2  w-100"
									style={{ color: "#fff", backgroundColor: "#C30101" }}
									onClick={() => changePackageStatus("reject")}
									disabled={!actionModal.reject_reason || actionModal.reject_reason?.length < 3}
								>
									{inventory.messages.reject}
								</button>
							}
							{actionModal.statusType === "accept" &&
								<button
									style={{ color: "#fff", backgroundColor: "#027B30" }}
									className="btn btn-guests  w-100"
									onClick={() => changePackageStatus("accept")}
									disabled={!actionModal.expire_at}
								>
									{inventory.messages.accept}
								</button>
							}
						</div>
					</ModalBody>
				</Modal>
				:
				null
			}

		</div>
	);
}
