import React, { useEffect, useState } from 'react';
import usersIconSrc from 'assets/images/product-builder/profile-2-users.svg'
import userIconSrc from 'assets/images/product-builder/profile.svg'
import hotelIconSrc from 'assets/images/product-builder/hotel-icon.svg'
import Locale from 'translations';
import SelectField from 'components/Form/SelectField/SelectField';
import { setPackageAvailabilities } from 'services/productbuilder';
import { store } from 'react-notifications-component';
import { roomTypesLookup } from './helpers/localLookups';

export default function RequestGuestsPerPax({
  reservationDetails,
  allPaxArray,
  setAllPaxArray,
  setReload,
  productPriceFullPackage,
  productPriceBreakDown
}) {

  const { marketPlace, inventory } = Locale;
  const hotels = reservationDetails?.product_details?.filter(product => product?.item?.id === 6)
  const [roomsAvailabilities, setRoomsAvalibities] = useState(null);
  const [childrenRoomTypes, setChildrenRoomTypes] = useState(null);
  

  // format room and children availablities  
  useEffect(() => {
    if (!roomsAvailabilities) {
      let roomAvailabilitiesFormat = reservationDetails?.product?.roomsAvailabilities?.map(room => {
        return {
          ...room,
          name: roomTypesLookup?.[room?.room_type],
          value: room?.room_type,
          label: roomTypesLookup?.[room?.room_type],
        }
      });
      setRoomsAvalibities(roomAvailabilitiesFormat);
    }
    if (!childrenRoomTypes) {
      let childrenAvailableRooms = allPaxArray?.adults?.filter(adult => adult?.roomType?.room_type)
      childrenAvailableRooms = [...new Set(childrenAvailableRooms?.map(room => room?.roomType?.room_type))].map(room => {
        return {
          id: room,
          name: roomTypesLookup?.[room],
          value: room,
          label: roomTypesLookup?.[room],
          room_type: room
        }
      });
      setChildrenRoomTypes(childrenAvailableRooms);
    }
  }, [allPaxArray]);


  // get hotels combincations and prices
  function calcPriceFromHotelCombintaions(roomType, paxType) {
    let totalPrice = 0;
    const hotelsCombibation = reservationDetails?.product?.product_price_combinations;
    let selectedCombination = hotelsCombibation?.find(comb => comb?.combinations[0]?.adults_count === roomType );
    if (productPriceBreakDown) {
      selectedCombination?.combinations?.forEach(combination => {
        let paxPrice = paxType === "adults" ? combination?.price_adult : paxType === "childs" ? combination?.price_child : 0
        totalPrice = totalPrice + paxPrice;
      });
    } else if (productPriceFullPackage) {
      totalPrice =
        paxType === "adults" ? selectedCombination?.full_price_after_discount :
          paxType === "childs" ? selectedCombination?.child_price_after_discount : 0
    }
    return { totalPrice, combination_id: selectedCombination?.id };
  }
  // update pax rooms and room Avalibities
  function handleRoomsAvalibities(roomType, paxType, paxIndex) {
    let roomsAvailabilitiesClone = [...roomsAvailabilities];
    // let childrenRoomsAvailabilitiesClone = [...childrenRoomsAvailabilities];
    let allPaxClone = { ...allPaxArray };
    let currentPax = allPaxClone[paxType][paxIndex];

    // update rooms avalibity     
    let selectedIndex = roomsAvailabilitiesClone.findIndex(r => r?.room_type === roomType?.room_type);
    let prevRoomIndex = roomsAvailabilitiesClone.findIndex(r => r?.room_type === allPaxArray[paxType][paxIndex]?.roomType?.room_type);
    let selectedRoom = roomsAvailabilitiesClone[selectedIndex];
    let prevRoom = roomsAvailabilitiesClone[prevRoomIndex];
    let childrenAvailableRooms = [];
    if (paxType === "adults" && prevRoom?.room_type !== selectedRoom?.room_type) {
      selectedRoom = {
        ...selectedRoom,
        room_remaining_availability: selectedRoom?.room_remaining_availability - 1,
        disabled: selectedRoom?.room_remaining_availability - 1 === 0
      };
      roomsAvailabilitiesClone[selectedIndex] = selectedRoom;
      // updated Availabilities for prev room
      if (prevRoom) {
        prevRoom = {
          ...prevRoom,
          room_remaining_availability: prevRoom?.room_remaining_availability + 1,
          disabled: false,
        };
        roomsAvailabilitiesClone[prevRoomIndex] = prevRoom;
      }
    }

    // get pax price in all hotels
    const paxPrice = calcPriceFromHotelCombintaions(roomType?.room_type, paxType);

    // assign room to pax type
    currentPax = {...currentPax, roomType, price: paxPrice.totalPrice, combination_id: paxPrice.combination_id }
    allPaxClone[paxType][paxIndex] = currentPax;
    // update the children rooms avalibity options
    childrenAvailableRooms = allPaxClone?.adults?.filter(adult => adult?.roomType?.room_type)
    childrenAvailableRooms = [...new Set(childrenAvailableRooms?.map(room => room?.roomType?.room_type))].map(room => {
      return {
        id: room,
        name: roomTypesLookup?.[room],
        value: room,
        label: roomTypesLookup?.[room],
        room_type: room
      }
    });
    // set new pax array
    setAllPaxArray(allPaxClone);
    setRoomsAvalibities(roomsAvailabilitiesClone);
    setChildrenRoomTypes(childrenAvailableRooms);
  }

  // set Avalibities with backend
  async function setPackagesRoomsAvailabilities() {
    const adultsRooms = allPaxArray?.adults?.map(adult => {
      return {
        combination_id: adult?.combination_id,
        is_adult: 1,
        traveler_id: adult?.id
      }
    });
    const childRooms = allPaxArray?.childs?.map(child => {
      return {
        combination_id: child?.combination_id,
        is_adult: 0,
        traveler_id: child?.id
      }
    });
    const roomsAvailability = [...adultsRooms, ...childRooms].filter(room => room?.combination_id);
    const data = {
      reservation_num: reservationDetails?.reservation_num,
      rooms_availability: roomsAvailability
    }
    const res = await setPackageAvailabilities(data);
    if (res?.status === 200) {
      store.addNotification({
        title: "info!",
        message: "Traveler added Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true,
        },
      })
      setReload((prev) => !prev);
    }
  }

  return (
    <div className='d-flex flex-column mt-4'>
      <h3 className='font-weight-bold mb-3 h4'>Guests</h3>
      <div className='request-guest-info mb-3'>
        <img src={usersIconSrc} alt="users icon" />
        <span className='mx-2'>
          {reservationDetails?.adults_pax} Adult,{" "}
          {reservationDetails?.children_pax} Child{" "}
          {/* {reservationDetails?.product?.roomsAvailabilities?.length} rooms */}
        </span>
      </div>
      <table className="table table-striped guest-room-prices m-auto" cellSpacing="12px">
        <thead>
          <tr>
            <th className='border-0'></th>
            {hotels?.map(hotel => {
              return (
                <td key={hotel?.itemable?.id}>
                  <div className="room-head">
                    <img src={hotelIconSrc} alt="hotel icon" />
                    <span className='mx-1'>{hotel?.itemable?.hotel?.name}</span>
                  </div>
                </td>
              )
            })}
            <td>
              <div className="room-head total">
                Total
              </div>
            </td>
          </tr>
        </thead>
        <tbody className='border mb-3'>
          {allPaxArray?.adults?.map((adult, paxIndex) => {
            return (
              <tr key={paxIndex}>
                <td className='align-middle' width="20%">
                  <img src={userIconSrc} alt="user icon" width="20px" />
                  <span className='mx-1'>
                    {marketPlace.messages.adult} {paxIndex + 1}
                  </span>
                </td>
                {/* hotels list with rooms types  */}

                <td colSpan={hotels?.length} className='align-middle'>
                  <div className='d-flex justify-content-center'>
                    <div className="w-50">
                      <SelectField
                        hasLabel={false}
                        value={adult?.roomType?.label}
                        options={roomsAvailabilities}
                        onChange={(e) => handleRoomsAvalibities(e, "adults", paxIndex)}
                      // disabled={adult?.rooms?.[hotel?.itemable?.id]?.isDisabled}
                      />
                    </div>
                  </div>
                </td>
                <td className='text-center align-middle font-weight-bold'>
                  {adult?.price ? `${adult?.price} ${reservationDetails?.currency}` : ""}
                </td>
              </tr>
            )
          })}
          {allPaxArray?.childs?.map((child, paxIndex) => {
            return (
              <tr key={`child-${paxIndex}`}>
                <td className='align-middle' width="20%">
                  <img src={userIconSrc} alt="user icon" width="20px" />
                  <span className='mx-1'>
                    {inventory.messages.Child} {paxIndex + 1}
                  </span>
                </td>
                {/* hotels list with rooms types  */}
                <td colSpan={hotels?.length} className='align-middle'>
                  <div className='d-flex justify-content-center'>
                    <div className="w-50">
                      <SelectField
                        hasLabel={false}
                        value={child?.roomType?.label}
                        options={childrenRoomTypes || []}
                        onChange={(e) => handleRoomsAvalibities(e, "childs", paxIndex)}

                      // disabled={child?.rooms?.[hotel?.itemable?.id]?.isDisabled}
                      />
                    </div>
                  </div>
                </td>
                <td className='text-center align-middle font-weight-bold'>
                  {child?.price ? `${child?.price} ${reservationDetails?.currency}` : ""}
                </td>
              </tr>
            )
          })}


        </tbody>
      </table>
      <button
        style={{ color: "#fff", backgroundColor: "#027B30" }}
        className="btn btn-guests mt-3 d-flex align-self-end justify-content-center"
        onClick={() => {
          setPackagesRoomsAvailabilities()
        }}
      // disabled={savedGuests?.length === 0}
      >
        {inventory.messages.savechanges}
      </button>
    </div>
  )
}
