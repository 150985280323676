import classnames from "classnames";
import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import Pagination from "components/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Table,
} from "reactstrap";
import {
	cancelRoom,
	fetchGroupReservation,
	fetchHotelBrns,
	getHotelReceipt,
	getHotelVoucher,
	payFees,
} from "services/marketplace";
import Locale from "translations";
import PayModal from "./shared/payModal";
import TableHotelContent from "./shared/TableHotelContent";
import TableHotelGroupContent from "./shared/TableHotelGroupContent";
import Filter from "./Filter";
import NewPaymentAction from 'components/NewPaymentAction';

export default function CustomTableInventoryHotel(props) {
	const [activeTab, setActiveTab] = useState("1");
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK? JSON.parse(process.env.REACT_APP_OTP_CHECK):false

	const { inventory, productsBuilder } = Locale;
	const [ProductList, setProductList] = useState([]);
	const [GroupsList, setGroupsList] = useState([]);

	const [meta, setMeta] = useState(null);
	const [metaGroup, setMetaGroup] = useState(null);
	const [cancellationReason, setCancellationReason] = useState(null);
	const [brn, setBrn] = useState(null);
	const [productItemState, setProductItemState] = useState({});
	const [modal, setModal] = useState(false);
	const toggleModal = () => setModal(!modal);

	const [modalPay, setModalPay] = useState(false);
	const togglePayModal = () =>{ 
		  setActionType('')
		setFormData({})
		setModalPay(!modalPay)};

	const [modalPromocode, setModalPromocode] = useState({
		toggle: false,
		title: "promo Code",
	});
	const togglePromocodeModal = (titleModal = "Promo Code") =>
		setModalPromocode({
			toggle: !modalPromocode.toggle,
			title: titleModal,
		});

	const [payMethod, setPayMethod] = useState(null);
	const [cancel, setCancel] = useState(false);
	const [filter, setFilter] = useState({
		name: "",
		book_date: "",
		checkin: "",
		status: "",
		page: 1,
	});

	const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});
	const fetchData = async (page = 1) => {
		const { data } = await fetchHotelBrns({
			...filter,
			book_date: filter.book_date
				? moment(filter.book_date).format("YYYY-MM-DD")
				: "",
			checkin: filter.checkin
				? moment(filter.checkin).format("YYYY-MM-DD")
				: "",
			status: filter?.status?.title,
			page: page,
		});
		setMeta(data?.meta);
		setProductList(data?.data);
	};

	const fetchGroupReservationList = async (page = 1) => {
		const { data } = await fetchGroupReservation({
			//...filter,
			hotel_name: filter.name,
			book_date: filter.book_date
				? moment(filter.book_date).format("YYYY-MM-DD")
				: "",
			checkin: filter.checkin
				? moment(filter.checkin).format("YYYY-MM-DD")
				: "",
			status: filter?.status?.id,
			page: page,
		});
		setMetaGroup(data?.meta);
		setGroupsList(data?.data);
	};
	const [searchStatus, setSearchStatus] = useState("");

	const getSpacialBrb = ProductList.filter((item, i) => item.brn == brn);
	const cancelBooking = async () => {
		const data = {
			brn,
			cancellationReason,
		};
		const res = await cancelRoom(data);
		if (res.status === 200) {
			toggleModal();
			store.addNotification({
				title: "Info!",
				message: "Booking Canceled successfully!",
				type: "info",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					window.location.reload();
					//history.push("/inventory");
				},
			});
		}
	};

	useEffect(() => {
		if (activeTab.toString() === "1") {
			fetchData();
		} else {
			fetchGroupReservationList();
		}
	}, [searchStatus, activeTab]);

	const goTo = (page) => {
		if (activeTab.toString() === "1") {
			fetchData(page);
		} else {
			fetchGroupReservationList(page);
		}
		setFilter({ ...filter, page: page });
	};

	const getReceipt = async (brn) => {
		const receipt = await getHotelReceipt({
			brn,
		});
		if (receipt) {
			const blob = new Blob([receipt.data], {
				type: "application/pdf",
			});
			const objectUrl = window.URL.createObjectURL(blob);
			window.open(objectUrl);
		}
	};
	const getVoucher = async (brn) => {
		const voucher = await getHotelVoucher({
			brn,
		});
		if (voucher) {
			const blob = new Blob([voucher.data], {
				type: "application/pdf",
			});
			const objectUrl = window.URL.createObjectURL(blob);
			window.open(objectUrl);
		}
	};
	//getPromoVoucher
	const [promoOrVoucherState, setPromoOrVoucherState] = useState();

	// const fetchPromoOrVoucher = async (brn) => {
	// 	const res = await getPromoVoucher({
	// 		brn: brn,
	// 	});
	// 	setPromoOrVoucherState(res?.data?.data);
	// };

	const AllProducts =
		ProductList?.length > 0 ? (
			ProductList.map((productItem, index) => (
				<TableHotelContent
					key={index}
					index={index}
					productItem={productItem}
					getReceipt={getReceipt}
					getVoucher={getVoucher}
					toggleModal={toggleModal}
					brn={brn}
					setBrn={setBrn}
					modalPay={modalPay}
					togglePayModal={togglePayModal}
					setPayMethod={setPayMethod}
					promoOrVoucherState={promoOrVoucherState}
					togglePromocodeModal={togglePromocodeModal}
					cancel={cancel}
					setCancel={setCancel}
					payMethod={payMethod}
					modalPromocode={modalPromocode}
					setProductItemState={setProductItemState}
				/>
			))
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result"}</h4>
					</div>
				</td>
			</tr>
		);

	const AllGroups =
		GroupsList?.length > 0 ? (
			GroupsList.map((item, index) => (
				<TableHotelGroupContent key={index} index={index} groupItem={item} />
			))
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result"}</h4>
					</div>
				</td>
			</tr>
		);
	const search = () => {
		setSearchStatus(!searchStatus);
	};


	const payment = async (type,currency,otp) => {
		const res = await payFees(productItemState?.brn, { payment_method: type,currency:currency,otp:otp });
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "info!",
				message: "res?.data?.message",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});

			togglePayModal(null);
			window.location.reload();
		}else if (res?.status === 202){
			setActionType(res?.data?.actionType)
			setFormData(res?.data?.data?.formData)

		}
	};

	return (
		<div className=" bg-white">
			<div className="product-header-list p-0">
				<p className="font-weight-bolder title-Filter p-3 ml-2">
					{activeTab.toString() === "1" ? (
						<>
							{inventory.messages.hotelBookings} ({meta?.total})
						</>
					) : (
						<>
							{inventory.messages.hotelBookings} ({metaGroup?.total})
						</>
					)}
				</p>
				<div className="sm-taps">
					<Nav tabs>
						<NavItem style={{ width: "50%" }}>
							<NavLink
								className={`border-0  ${classnames({
									active: activeTab === "1",
								})}`}
								onClick={() => {
									toggle("1");
								}}
							>
								<div className="product-build-list-item">
									<span
										className={`text text-mobileNew  mx-1 ${
											activeTab === "1" ? "title-Filter" : "txt-dis-tabs"
										} `}
									>
										{inventory.messages.hotels}
									</span>
								</div>
							</NavLink>
						</NavItem>
						<NavItem style={{ width: "50%" }}>
							<NavLink
								className={`border-0  ${classnames({
									active: activeTab === "2",
								})}`}
								onClick={() => {
									toggle("2");
								}}
							>
								<div className="product-build-list-item">
									<span
										className={`text  text-mobileNew mx-1 ${
											activeTab === "2" ? "title-Filter" : "txt-dis-tabs"
										} `}
									>
										{inventory.messages.groups}
									</span>
								</div>
							</NavLink>
						</NavItem>
					</Nav>
				</div>
				<div></div>
			</div>

			<Filter
				filter={filter}
				setFilter={setFilter}
				search={search}
				activeTab={activeTab}
			/>
			<div className="table-container">
				<Table striped className="table-update p-2 bg-white border">
					<thead className="th-trip-mang">
						<tr>
							<th className="pl-4">{inventory.messages.brn}</th>
							<th>{inventory.messages.referenceNumber}</th>
							<th>{inventory.messages.hotelName}</th>
							{/* 		<th>{inventory.messages.roomType}</th> */}
							<th>
								{/* <span class="up-down">
								<i class="fas fa-sort"></i>
							</span> */}
								<span>{inventory.messages.noOfRooms}</span>
							</th>
							{/* 						<th>{inventory.messages.availability}</th> */}
							<th>
								{/* <span class="up-down">
								<i class="fas fa-sort"></i>
							</span> */}
								{inventory.messages.bookingDate}
							</th>
							<th>
								{/* <span class="up-down">
								<i class="fas fa-sort"></i>
							</span> */}
								{inventory.messages.checkIn}
							</th>
							<th>
								{/* <span class="up-down">
								<i class="fas fa-sort"></i>
							</span> */}
								{inventory.messages.noOfNights}
							</th>
							<th>
								{/* <span class="up-down">
								<i class="fas fa-sort"></i>
							</span> */}
								{inventory.messages.totalPrice}
							</th>
							{/* 						<th>{inventory.messages.groups}</th> */}
							<th>
								{/* <span class="up-down">
								<i class="fas fa-sort"></i>
							</span> */}
								{inventory.messages.pax}
							</th>
							<th>{inventory.messages.status}</th>
							{activeTab.toString() === "1" ?<th>{inventory.messages.source}</th> : null }
						 

							<th>{inventory.messages.actions}</th>
						</tr>
					</thead>
					<tbody>
						{/* {AllProducts} */}
						{activeTab.toString() === "1" ? AllProducts : AllGroups}
					</tbody>
				</Table>
			</div>

			{activeTab.toString() === "1" && ProductList.length > 0 ? (
				<Pagination info={meta} goTo={goTo} />
			) : (
				<Pagination info={metaGroup} goTo={goTo} />
			)}

			<Modal size="lg" isOpen={modal} toggle={toggleModal}>
				<ModalHeader className="align-items-center" toggle={toggleModal}>
					{getSpacialBrb[0]?.payment_status === "confirmed"
						? `${inventory.messages.cancelReservation} ${inventory.messages.afterFullPayment}`
						: `${inventory.messages.cancelReservation}`}
				</ModalHeader>
				<ModalBody className="mx-3">
					<div className="row">
						<div className="col-md-12">
							<TextAreaField
								label={inventory.messages.cancellationReason}
								placeholder={inventory.messages.cancellationReason}
								value={cancellationReason}
								onChange={(e) => setCancellationReason(e.target.value)}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-gray-150 text-dark border-0 px-4"
						onClick={toggleModal}
					>
						{productsBuilder.cancel}
					</Button>
					<Button
						// disabled={["account", "otp"].some((el) => el === null || el === "")}
						className="px-5 w-30"
						color="danger"
						onClick={() => cancelBooking()}
					>
						{inventory.messages.cancelReservation}
					</Button>{" "}
				</ModalFooter>
			</Modal>
			

{modalPay && (
				<NewPaymentAction
					isOpen={modalPay}
					toggleModal={togglePayModal}
					canPayLater={false}
					formData={formData} 
					actionType={actionType}
					Price={Number(productItemState?.original_provider_price)}
					currency={productItemState?.original_provider_currency}
					secondCurrency={productItemState?.currency}
					onPayment={payment}
					OTP={OTP_CHECK}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}



		</div>
	);
}
