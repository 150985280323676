import React from 'react'
import clearIcon from 'assets/images/wallet/clearIcon.svg'
import correctIcon from 'assets/images/wallet/correctIcon.svg'
import Locale from 'translations'
import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
import DateRangeField from 'components/Form/DateRangeField/DateRangeField'
import DatePickerField from 'components/Form/DatePickerField/DatePickerField'

function Filterrequestes({ filters, setFilters, getRequestsList }) {
	const { payment, inventory } = Locale

	function resetFilters() {
		setFilters({
			search: '',
			request_date: '',
			nationality: '',
			page:1
		})
		getRequestsList({
			search: '',
			request_date: '',
			nationality: '',
			page:1
		})
	}

	return (
		<>
			<div className='filter-update bg-filter-gray border'>
				<div className='product-buttons col-md-12'>
					<div className='main-filter-payment d-flex justify-contain-between'>
						<div className='main-label controls-field col-6 col-md-2'>
							<TextField
								label={inventory.messages.packageName}
								type='text'
								placeholder={inventory.messages.packageName}
								value={filters?.search}
								onChange={(e) =>
									setFilters({
										...filters,
										search: e.target.value
									})
								}
							/>
						</div>

						<div className='main-label mx-md-2 col-6 col-md-2 controls-field'>
							<DatePickerField
								label={inventory.messages.requestDate}
								placeholder={inventory.messages.requestDate}
								date={filters?.request_date}
								onDateChange={(date) => {
									setFilters({
										...filters,
										request_date: date
									})
								}}
								isOutsideRange={(day) => {
									return false
								}}
							/>
						</div>

						<div className='main-label controls-field col-6 col-md-2'>
							<TextField
								label={inventory.messages.nationality}
								type='text'
								placeholder={inventory.messages.nationality}
								value={filters?.nationality}
								onChange={(e) =>
									setFilters({
										...filters,
										nationality: e.target.value
									})
								}
							/>
						</div>

						<div className='filter-btn col-12 col-md-3 d-flex align-items-center justify-content-start'>
							<div className='apply pointer'>
								<button
									className='btn btn-light px-3'
									onClick={() => {
										getRequestsList(filters)
									}}
								>
									<img src={correctIcon} alt=' ' /> {payment.messages.apply}
								</button>
							</div>
							<div className='clear text-secondary pointer'>
								<button
									className='btn btn-light px-3 mx-2'
									onClick={() => {
										resetFilters()
									}}
								>
									<img src={clearIcon} alt=' ' /> {payment.messages.clear}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Filterrequestes
