import React from "react";
import NewMarketServicesSearch from "./ServicesSearch";
import NewMarketSellingServices from "./SellingServices";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SpecialAllotment from "./SpecialAllotment";
import ShowSubscription from 'helpers/ShowSubscription';

export default function NewMarketViewServices() {
	const { tab, status } = useParams();
	return (
		<ShowSubscription module={"marketplace"}>
		<div className="view-services-wrapper">
			<NewMarketServicesSearch />
			{tab === "special-allotment" ? (
				<SpecialAllotment />
			) : (
				status === "umrah" ? <NewMarketSellingServices /> : null
			)}
		</div>
		</ShowSubscription>
	);
}
