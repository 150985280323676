import Footer from 'modules/WebBuilder-V2/Components/Content/Footer';
import HeroHeader from 'modules/WebBuilder-V2/Components/Content/Hero/Header';
import { useLocation, useParams, Link } from "react-router-dom";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import UploadFileComponent from 'modules/WebBuilder-V2/shared/UploadFile';
import { ReactComponent as UploadIcon } from "assets/images/webBuilder/upload-hero-img.svg";
import { ReactComponent as ChevronDown } from "assets/images/webBuilder/chevron-down.svg";
import AboutUs from 'modules/WebBuilder-V2/Components/Content/AboutUs';
import { useState } from 'react';



export default function AboutusThemePage() {
  const { content, pagesData, selectedLanguage } = useWebBuilderState();
  const dispatch = useWebBuilderDispatch();
  const location = useLocation();
  const params = useParams();
  const link = params.name ? `/public-page-v2/${params.name}` : `/web-builder-v2/preview/`;
  const preview = !location.pathname.includes('edit');
  const aboutPageData = pagesData?.find(page => page.id === "about-page");
  const aboutDetails = content?.find(item => item?.type === "aboutUs");
  const [focusContainer, setFocusContainer] = useState("");

  function handleFocus(value) {
    setFocusContainer(value);
  };


  const updatePageHero = ({ id, value }) => {
    dispatch({
      type: "updatePageHero",
      payload: {
        id,
        value
      },
    });
  };

  const handleUpload = (uuid) => {
    updatePageHero({ id: "about-page", value: uuid });
  };

  return (
    <div className="web-builder-preview  web-builder-page-contianer about-page">
      <HeroHeader />
      {aboutPageData &&
        <div className="web-builder-hero p-3"
          style={{
            backgroundImage: `linear-gradient(#00000021 50%, #00000021), url(${aboutPageData?.hero})`,
          }}
        >
          <div className="d-flex flex-column gap-24">

            <h1>
              {aboutPageData.mainHeader[selectedLanguage]}
            </h1>
            {preview &&
              <div className='page-bread-crumb'>
                <Link className='home' to={link}>Home</Link>
                <span> <ChevronDown /></span>
                <span className='search'>{aboutPageData.mainHeader[selectedLanguage]}</span>
              </div>
            }
          </div>

          {!preview ? (
            <div className="update-hero-layout">
              <UploadFileComponent
                icon={UploadIcon}
                id="hero-bg"
                label="Change image"
                onUpload={handleUpload}
              />
            </div>
          ) : null}


        </div>
      }
      {/* content */}
      <div className="web-builder-content">
        {aboutDetails &&
          <AboutUs
            details={aboutDetails}
            onFocus={handleFocus}
            focusContainer={focusContainer}
          />
        }
      </div>
      <Footer focusContainer={focusContainer} handleFocus={handleFocus} />
    </div>
  )
}
