import CustomModal from "modules/WebBuilder-V2/shared/CustomModal";
import React, { useState } from "react";
import Locale from "translations";
import { Main1, Main2, Main3 } from "./images";
import { useWebBuilderState } from "context/webBuilder";
const MainSectionModal = ({ isOpen, toggle, handleLayout }) => {
	const [contentLayout, setContentLayout] = useState(null);
	const { hero } = useWebBuilderState();
	const layoutType = hero?.mainContent?.layout?.layoutType;
	const { webBuilder } = Locale;
	const apply = () => {
	
		handleLayout({ name: "layoutType", value: contentLayout ?? layoutType });
		toggle()
	};

	return (
		<CustomModal
			isOpen={isOpen}
			toggle={toggle}
			header={webBuilder.mainSection}
			size="sm"
		>
			<div className="container main-modal">
				<div className="layout d-flex justify-content-between flex-wrap">
					<p>{webBuilder.layout}</p>
					<div
						className={`${(contentLayout ?? layoutType) === 1 ? "active" : ""}`}
						onClick={() => setContentLayout(1)}
					>
						<Main1 />
					</div>
					<div
						className={`${(contentLayout ?? layoutType) === 2 ? "active" : ""}`}
						onClick={() => setContentLayout(2)}
					>
						<Main2 />
					</div>
					<div
						className={`${(contentLayout ?? layoutType) === 3 ? "active" : ""}`}
						onClick={() => setContentLayout(3)}
					>
						<Main3 />
					</div>
				</div>
				<hr />
				<button className="btn primary-button mt-2" onClick={apply}>{webBuilder.apply}</button>
			</div>
		</CustomModal>
	);
};

export default MainSectionModal;
