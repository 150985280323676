import { useSBSState } from "context/global";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Company from "./shared/company";
import { fetchMarketPackagesDetails } from "services/marketplace";
import Locale from 'translations';
import ProductItinerary from './ProductItinerary';
import PackageHotelView from './HotelView';
import { ReactComponent as RequiredDocIcon } from "assets/images/market-place/required-document-text.svg";
import { ReactComponent as CheckMarkCircleIcon } from "assets/images/market-place/tick-circle.svg";
import { ReactComponent as StopIcon } from "assets/images/market-place/stop-icon.svg";
import { ReactComponent as DollarCircleIcon } from "assets/images/market-place/dollar-circle.svg";
import PackageAttractionsView from './AttractionsView';
import { useMarketplaceDispatch } from 'context/marketplace';
import { useHistory } from 'react-router-dom';
import FLightView from 'modules/WebBuilder/PreviewServices/Flights';
import TextField from 'components/Form/TextField/TextField';
import validate from "helpers/validate";
import { itineraryElementsIds } from '../helpers/formatServicesItems';
import PackageCategoryPricing from './PackageCategoryPricing';
import { formatPrice } from 'helpers/utils';



export default function PackageDetails() {
	const { locale } = useSBSState();
	const { packages, marketPlace, productsBuilder, inventory } = Locale;
	const packagesDispatch = useMarketplaceDispatch();
	const history = useHistory();
	const itineraryId = "tab-101";

	const isMobile = false;
	const [reservationDetails, setReservationDetails] = useState();
	const [activeTab, setActiveTab] = useState(itineraryId);
	const { uuid } = useParams();
	const imageUrl = process.env.REACT_APP_API_URL + "/v1/files/fetch/";

	const [paxCount, setPaxCount] = useState({
		adultPax: 0,
		childsPax: 0,
	});

	const [sharedPaxRooms, setSharedPaxRooms] = useState(null);
	const [privatePaxRooms, setPrivatePaxRooms] = useState([
		{ roomType: null, roomCount: 0, childCount: 0, price: 0 },
	]);

	const [errors, setErrors] = useState({});
	const [isNavDisappeared, setIsNavDisappeared] = useState(false);
	const [categoryType, setCategoryType] = useState(null);

	function scrollToElement(eleId) {
		if (document && eleId) {
			document?.getElementById(eleId)?.scrollIntoView({ behavior: "smooth" });
		}
	}


	const productItemsTabs = useMemo(() => {
		let items = [];
		const productHasItinerary = reservationDetails?.itinerary && reservationDetails?.itinerary?.length > 0;
		if (productHasItinerary) {
			items.push({ name: productsBuilder.itinerary, id: 101 })
		}
		reservationDetails?.product_items?.forEach(element => {
			const elemetID = element?.item?.id
			switch (elemetID) {
				case itineraryElementsIds.external:
					items.push({ name: inventory.messages.flights, id: elemetID })
					break;
				case itineraryElementsIds.hotel:
					items.push({ name: inventory.messages.hotels, id: elemetID })
					break;
				case itineraryElementsIds.sightseeing:
					items.push({ name: productsBuilder.attraction, id: elemetID })
					break;
				default:
					break;
			}
		});

		items = [...new Map(items.map(item => [item['name'], item])).values(), { name: productsBuilder.packagePricing, id: 102 }];
		return items;
	}, [reservationDetails?.product_items]);


	const HotelElements = () => {
		return reservationDetails?.product_items?.map((element, index) => {
			if (element?.item?.id === itineraryElementsIds.hotel) {
				return <PackageHotelView hotelData={element?.itemable} key={index} />;
			}
			return null;
		});
	};

	const AttractionElements = () => {
		return reservationDetails?.product_items?.map((element, index) => {
			if (element?.item?.id === itineraryElementsIds.sightseeing) {
				return <PackageAttractionsView attractionData={element?.itemable} key={index} />;
			}
			return null;
		});
	};


	const ExternalElements = () => {
		//To Return uniqe External Element
		let externalElementAirLines = reservationDetails?.product_items?.filter(
			(element) => element?.item?.id === itineraryElementsIds.external
		);
		let uniqueAirLinesIds = {};
		const uniqueExtrenalAirLines = externalElementAirLines?.filter((item) => {
			const airLineId = item?.itemable?.transporter?.id;
			if (uniqueAirLinesIds[airLineId] === undefined) {
				uniqueAirLinesIds = { ...uniqueAirLinesIds, [airLineId]: item };
				return item;
			}
		});
		if (uniqueExtrenalAirLines?.length > 0) {
			return uniqueExtrenalAirLines?.map((externalTransport) => {
				return (
					<Company
						key={externalTransport.id}
						title={marketPlace.airlines}
						name={externalTransport?.itemable?.transporter?.name}
						logo={externalTransport?.itemable?.transporter?.logo}
					/>
				);
			});
		} else {
			return null;
		}
	};


	useEffect(() => {
		async function FetchDetails() {
			const res = await fetchMarketPackagesDetails(uuid);
			setReservationDetails(res?.data);
			// const servicesList = formatProductItems(res?.data?.product_items, locale);
			// if (!productItemsPrices) {
			// 	setProductItemsPrices(servicesList);
			// }
		}
		if (uuid) {
			FetchDetails();
		}
	}, [uuid]);


	const packageContainHotel = productItemsTabs.filter(item => item?.id === itineraryElementsIds.hotel).length > 0;
	const packageContainAttraction = productItemsTabs.filter(item => item?.id === itineraryElementsIds.sightseeing).length > 0;

	// calc
	const calcTotalPrices = useMemo(() => {
		let total = 0;
		if (packageContainHotel) {

			if (categoryType?.value === "private") {
				// calc items for each pax
				let b2bPricing = reservationDetails?.product_price_combinations?.filter(p => p?.pricing_for === "b2b");
				let itemAdultPrices = Number(b2bPricing[0]?.adult_final_items_price) * (paxCount.adultPax || 0);
				let totalchidlPrice = Number(b2bPricing[0]?.child_price_after_discount) * (paxCount.childsPax || 0);
				// calc hotel rooms prices
				privatePaxRooms?.forEach(room => total += room?.price);
				total = total + itemAdultPrices + totalchidlPrice;
			}

			else if (categoryType?.value === "shared" && sharedPaxRooms?.adults?.length > 0) {

				sharedPaxRooms?.adults?.forEach(adult => total += adult?.price);
				sharedPaxRooms?.children?.forEach(child => total += child?.price);
			}
		} else {
			let totalAdultPrices = +paxCount.adultPax * reservationDetails?.product_price_combinations[0]?.breakdown_price_after_discount;
			let totalchildrenPrices = +paxCount.childsPax * reservationDetails?.product_price_combinations[0]?.child_price_after_discount;
			total = totalAdultPrices + totalchildrenPrices;
		}

		return total || 0;
	}, [categoryType?.value, packageContainHotel, paxCount.adultPax, paxCount.childsPax, privatePaxRooms, reservationDetails?.product_price_combinations, sharedPaxRooms]);


	function checkout() {
		packagesDispatch({
			type: "setPackagesDetails",
			payload: { reservationDetails, totalPrice: calcTotalPrices, roomsData: categoryType?.value === "shared" ? sharedPaxRooms : privatePaxRooms, paxCount, reservationCategory: categoryType },
		});

		history.push(`/market-package/checkout-package/${uuid}`);
	}


	useEffect(() => {
		window.scrollTo(0, 0);
		const observer = new IntersectionObserver(([entry]) => {
			setIsNavDisappeared(entry.isIntersecting);
		},
			{ rootMargin: "-74px" }
		);
		observer.observe(document.querySelector("#root"));
		return () => observer.disconnect();
	}, []);


	return (
		<div className='package-details-container market-place-packages'>
			<ul className='packages-navigation' style={{ top: isNavDisappeared ? "74px" : "0" }} >
				{productItemsTabs?.map(tab => {
					return (
						<li key={`tab-${tab.id}`} className={`${+activeTab === tab.id ? "active" : ""} `}
							onClick={() => {
								setActiveTab(tab.id);
								scrollToElement(`tab-${tab.id}`)
							}}
						>
							{tab.name}
						</li>
					)
				})}
			</ul>
			{/* content */}
			<div className="container">
				<div className="row">
					<div className="col-lg-9 col-12">
						{/* Itinerary */}
						{reservationDetails?.itinerary?.length > 0 ?
							<div className='package-item' id="tab-101">
								<h2 className='package-title'>{productsBuilder.itinerary}</h2>
								<ProductItinerary
									itineraryData={reservationDetails?.itinerary}
									itineraryElementsIds={itineraryElementsIds}
								/>
							</div>
							:
							null
						}
						{/* flight */}
						<div id={`tab-${itineraryElementsIds.external}`}>
							{reservationDetails?.product_items?.filter(item => item?.item?.id === 1)?.map((item, index) => {
								return (
									<div className='public-page-preview-services mt-4' index={index} key={item?.itemable?.id}>
										<FLightView details={item} />
									</div>
								)
							})}
						</div>

						{/* Hotels */}
						{packageContainHotel ?
							<div className='package-item' id={`tab-${itineraryElementsIds.hotel}`}>
								<h2 className='package-title'>{inventory.messages.hotels}</h2>
								{HotelElements()}
							</div>
							:
							null
						}

						{/* Attraction */}
						{packageContainAttraction ?
							<div className='package-item' id={`tab-${itineraryElementsIds.sightseeing}`}>
								<h2 className='package-title'>{productsBuilder.attraction}</h2>
								{AttractionElements()}
							</div>
							:
							null
						}

						{/* services */}
						{reservationDetails?.product_items?.length > 1 &&
							<div className='package-item'>
								<h2 className='package-title'>{marketPlace.Services}</h2>
								<div className='mt-4'>
									<p className="d-flex align-items-center mb-2">
										{reservationDetails?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.internal)?.length > 0
											? <CheckMarkCircleIcon />
											: <StopIcon />
										}
										<span className="mx-1">{productsBuilder.internalTransportations}</span>
									</p>
									<p className="d-flex align-items-center mb-2">
										{reservationDetails?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.visa && item?.itemable?.included)?.length > 0
											? <CheckMarkCircleIcon />
											: <StopIcon />
										}
										<span className="mx-1">{productsBuilder.visa}</span>
									</p>
									<p className="d-flex align-items-center mb-2">
										{reservationDetails?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.insurance && item?.itemable?.included)?.length > 0
											? <CheckMarkCircleIcon />
											: <StopIcon />
										}
										<span className="mx-1">{productsBuilder.insurance}</span>
									</p>
									<p className="d-flex align-items-center mb-2">
										{reservationDetails?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.otherServices)?.length > 0
											? <CheckMarkCircleIcon />
											: <StopIcon />
										}
										<span className="mx-1">{productsBuilder.other}</span>
									</p>
								</div>

							</div>
						}
						{/* Terms & conditions */}
						<div className='package-item'>
							<h2 className='package-title'>{packages.termsAndConditions}</h2>
							<div className='mt-4'>
								{reservationDetails?.share_terms ?? (
									<p className="text-muted text-center">{marketPlace.NotFoundData}</p>
								)}
							</div>
						</div>

						{/* Required Documents */}
						<div className='package-item required-docs'>
							<div className='d-flex align-items-center'>
								<RequiredDocIcon />
								<h2 className='package-title mx-2'>{productsBuilder.requiredDocuments}</h2>
							</div>
							<div className='mt-4'>
								{reservationDetails?.share_required_documents ?? (
									<p className="text-muted text-center">{marketPlace.NotFoundData}</p>
								)}
							</div>
						</div>
						{/* packages price */}
						{packageContainHotel ?
							<div className='package-item' id='tab-102'>
								<h2 className='package-title'>{productsBuilder.packagePricing}</h2>
								{reservationDetails && reservationDetails?.roomAvailabilities && reservationDetails?.roomAvailabilities.length > 0 ?
									<PackageCategoryPricing
										reservationDetails={reservationDetails}
										paxCount={paxCount}
										setPaxCount={setPaxCount}
										errors={errors}
										setErrors={setErrors}
										privatePaxRooms={privatePaxRooms}
										setPrivatePaxRooms={setPrivatePaxRooms}
										sharedPaxRooms={sharedPaxRooms}
										setSharedPaxRooms={setSharedPaxRooms}
										categoryType={categoryType}
										setCategoryType={setCategoryType}
									/>
									:
									null
								}
							</div>
							:
							<div className='package-item' id='tab-102'>
								<h2 className='package-title'>{productsBuilder.packagePricing}</h2>
								<div className='d-flex mt-4 flex-wrap'>
									<div className=' col-md-5 col-12'>
										<TextField
											type="number"
											label={marketPlace.Numberpax}
											id={"adults-pax-count"}
											name={"adultPax"}
											max={+reservationDetails?.remaining_adults_pax}
											value={paxCount.adultPax}
											onChange={(e) => {
												setPaxCount({ ...paxCount, adultPax: e.target.value > 0 ? Number(e.target.value).toString() : 0 });
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
														maxNumber: +reservationDetails?.remaining_adults_pax,
													}),
												})
											}}
											color={errors.adultPax?.required || errors.adultPax?.maxNumber ? "danger" : ""}
											errors={errors.adultPax}
										/>
									</div>
									{/* children pax */}
									<div className='col-md-5 col-12'>
										<TextField
											type="number"
											label={marketPlace.NumberChildrenpax}
											id={"children-pax-count"}
											name={"childsPax"}
											max={+reservationDetails?.remaining_children_pax}
											value={paxCount.childsPax}
											onChange={(e) => {
												setPaxCount({ ...paxCount, childsPax: e.target.value > 0 ? Number(e.target.value).toString() : 0 });
												setErrors({
													...errors,
													...validate(e.target, {
														maxNumber: +reservationDetails?.remaining_children_pax,
													}),
												})
											}}
											color={errors.childsPax?.maxNumber ? "danger" : ""}
											errors={errors.childsPax}
										/>
									</div>
								</div>

							</div>
						}
						<div className='d-flex justify-content-between align-items-center mt-4 p-4 bg-white' style={{ borderRadius: "12px" }}>
							<p>
								<span>{productsBuilder.totalPrice}</span>
								<span className='mx-2 text-success font-weight-bold'>
									{formatPrice(calcTotalPrices)} {" "}
									{reservationDetails?.currency}
								</span>
							</p>
							<button className='btn checkout-btn' onClick={checkout}
								disabled={
									(
										+paxCount.adultPax === 0 || +paxCount.adultPax > +reservationDetails?.remaining_adults_pax ||
										+paxCount.childsPax > +reservationDetails?.remaining_children_pax
									)
								}
							>
								{packages.compeleteCheckout}
							</button>
						</div>


					</div>

					{!isMobile && (
						<div className="col-lg-3 col-12 mt-4">
							<>
								<Company
									title={packages.travelAgent}
									name={reservationDetails?.company_name?.[locale]}
									logo={
										reservationDetails?.company_avatar &&
										`${imageUrl}${reservationDetails?.company_avatar}`
									}
								/>
								{ExternalElements()}
								<button
									className="btn w-100 view-price"
									onClick={() => scrollToElement('tab-102')}
								>
									<DollarCircleIcon />
									<span className='mx-1'>{packages.viewPrice}</span>
								</button>
							</>
						</div>
					)}


				</div>
			</div>
		</div >
	);
};


