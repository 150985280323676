import domesticFlight from " ../../assets/images/icons/domesticFlight.svg";
import Model from "components/model";
import { useProductsBuilderDispatch } from "context/productsBuilder";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Col } from "reactstrap";
import {
	cloneProduct,
	deleteProduct,
	unpublishedProduct,
} from "services/productbuilder";
import Locale from "../../../translations";
import { store } from "react-notifications-component";
import SharePackageModal from "./SharePackageModal";
import ShowForPermission from "helpers/showForPermission";
import { BusIcon } from "../Accommodations/Buses/Icons";
import VisaIcon from "../Pricing/IconsSVG/VisaIcon";
import ExternalIcon from "../Pricing/IconsSVG/ExternalIcon";
import AttractionIcon from "../Pricing/IconsSVG/AttractionIcon";
import ServiecIcon from "../Pricing/IconsSVG/ServiceIcon";
import InsuranceIcon from "../Pricing/IconsSVG/InsuranceIcon";
import PenIcon from "../Pricing/IconsSVG/PenIcon";
import StarIcon from "../Pricing/IconsSVG/StarIcon";
import DoneIcon from "../Pricing/IconsSVG/DoneIcon";
import MoreIcon from "../Pricing/IconsSVG/MoreIcon";
import DeleteIcon from "../Pricing/IconsSVG/DeleteIcon";
import DuplicateIcon from "../Pricing/IconsSVG/DuplicateIcon";
import PublishIcon from "../Pricing/IconsSVG/PublishIcon";
import ViewIcon from "../Pricing/IconsSVG/ViewIcon";
import { ReactComponent as HotelIcon } from "assets/images/hotelAccommodation/hotel.svg";

export default function ProductsTableRow({
	product,
	fetchProducts,
	setIsopen,
	Isopen,
}) {
	const { productsBuilder, productsList } = Locale;

	
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	// const dispatch = useDispatch();
	const dispatch = useProductsBuilderDispatch();
	const history = useHistory();
	const location = useLocation();
	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
		active: false,
	});

	const unpublished = async () => {
		const res = await unpublishedProduct(product.product_uuid);
		if (res.status === 200) {
			setModal({
				isOpen: !modal.isOpen,
				type: "Unpublished",
				title: "Unpublished",
			});
			store.addNotification({
				title: "Info!",
				message: productsList.UnpublishedMessages,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			fetchProducts();
		} else {
			store.addNotification({
				title: "Error!",
				message: "Error!",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const cloneProducts = async () => {
		const res = await cloneProduct(product.product_uuid);
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: productsBuilder.Info,
				message: `${productsBuilder.clonesuccessfully}`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			fetchProducts();
		} else {
			store.addNotification({
				title: "Error!",
				message: "Error!",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const toggle = () => {
		setIsopen(null)
		setModal({
			isOpen: !modal.isOpen,
			type: "delete",
			title: productsBuilder.deleteProduct,
		});
	};

	const toggleUnpublished = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: productsBuilder.Unpublished,
			title: productsBuilder.Unpublished,
		});
	};

	let id = product.product_uuid;
	const deleteProducts = async () => {
		setModal({ ...modal, active: true });
		const res = await deleteProduct(id);
		if (res.status === 200) {
			setModal({});
			fetchProducts();
		} else {
			setModal({ ...modal, active: false, isOpen: false });
		}
	};
	const editProducts = async () => {
		localStorage.setItem("uuid", product.product_uuid);
		dispatch({ type: "saveBasicDetails", payload: { isEditMode: true } });
		history.push({
			pathname: `/products-builder/create`,
			search:location.search,
			state: {
				id,
			},
		});
	};
	const showProducts = async () => {
		localStorage.setItem("uuid", product.product_uuid);
		//dispatch({ type: "saveBasicDetails", payload: { isEditMode: true } });
		history.push({
			pathname: `/products-builder/show`,
			state: {
				id:id,
			},
			search:'version=2'
		});
	};

	const moreActionsRef = useRef(null)
	useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (moreActionsRef.current && !moreActionsRef.current.contains(event.target)) {
        setIsopen(null)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	const statusColor = () => {
		switch (product.product_status.id) {
			case 2:
				return "text-primary";
			case 1:
				return "text-warning";
			case 3:
				return "text-success";
			default:
				break;
		}
	};

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	//
	const [shareModelOpen, setShareModelOpen] = useState(false);

	function toggleShareModel() {
		setShareModelOpen(!shareModelOpen);
	}
	return (
		<>
			<Model
				isOpen={modal.isOpen}
				toggle={toggle}
				type={modal.type}
				title={modal.title}
				submit={modal.type === "delete" ? deleteProducts : unpublished}
				disabled={modal.active}
			>
				{modal.type === "delete" ? (
					<Col className="p-3">{productsBuilder.deleteProductQoute}</Col>
				) : (
					<Col className="p-3">{productsBuilder.ProductUnpublished}</Col>
				)}
			</Model>

			{shareModelOpen ? (
				<SharePackageModal
					shareModelOpen={shareModelOpen}
					toggleShareModel={toggleShareModel}
					product_uuid={product.product_uuid}
					validation_date_from_at={product.validation_date_from_at}
					validation_date_to_at={product.validation_date_to_at}
					fetchProducts={fetchProducts}
					is_break_down={product.is_break_down}
					product_type={product.product_type}
				/>
			) : null}

			<tr className="td-TB" key={product.product_uuid}>
				<td>
					<span className="text-primary">#{product.id}</span>
				</td>
				<td>
					<p className="tb-date">{product?.name}</p>
					<p className="tb-date-s">{product?.product_type?.name}</p>
				</td>
				<td>
					<p className="tb-date">{moment(product.created_at).format("ll")}</p>
				</td>
				<td>
					{product.product_classification ? (
						<p className="ele-round">
							<StarIcon />
							{product.product_classification.id}
						</p>
					) : (
						""
					)}
				</td>
				<ShowForPermission permission="Manage-Trip-Management-Module">
					<td>
						{product.is_has_price ? (
							<p className="ele-round">
								<DoneIcon />
								<span>{productsBuilder.done}</span>
							</p>
						) : (
							<button
								
								className={`bg-transparent border-0 ${product.items.length === 0 ? "opacity-5":""} `}
								disabled={product.items.length === 0}
								onClick={() => {
									localStorage.setItem("uuid", product.product_uuid);
									dispatch({
										type: "saveBasicDetails",
										payload: { isEditMode: true },
									});
									history.push({
										pathname: `/products-builder/create`,
										search:location.search,
										state: {
											id,
											tab:"pricing"
										},
									});
								}}
								
							>
								<span className="add-price-underline">
									{productsBuilder.addPrice}
								</span>
							</button>
						)}
					</td>
				</ShowForPermission>
				<td className=" ">
					{product.product_items.external_transportation ? (
						<p className="ele-round d-inline-block">
							<ExternalIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.internal_transportation ? (
						<p className="ele-round d-inline-block">
							<BusIcon fillColor={"#707170"} size={24} />
						</p>
					) : (
						// <img src={internalGold} alt="" srcset="" />

						""
					)}
					{product.product_items.domestic ? (
						<p className="ele-round d-inline-block">
							<img src={domesticFlight} alt="" srcset="" />
						</p>
					) : (
						""
					)}
					{product.product_items.visa ? (
						<p className="ele-round d-inline-block">
							<VisaIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.insurance ? (
						<p className="ele-round d-inline-block">
							<InsuranceIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.sightseeing ? (
						<p className="ele-round d-inline-block">
							<AttractionIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.hotel ? (
						<p className="ele-round d-inline-block">
							<HotelIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.other_service ? (
						// <i className="fas fa-braille fa-fw"></i>
						<p className="ele-round d-inline-block">
							<ServiecIcon size={24} />
						</p>
					) : (
						""
					)}
				</td>
				<td>
					<p className="tb-date">
						{moment(product.validation_date_from_at).format("ll")}
					</p>
					<p className="tb-date-s">
						{moment(product.validation_date_to_at).format("ll")}
					</p>
				</td>
				<td>
					<p className="tb-date">
						{product.departure_date_at
							? moment(product.departure_date_at).format("ll")
							: null}
					</p>
					<p className="tb-date-s ">
						{product.return_date_at
							? moment(product.return_date_at).format("ll")
							: null}
					</p>
				</td>

				<ShowForPermission permission="Manage-Trip-Management-Module">
					<td>
						{product.product_status.name === "غير منشورة" ||
						product.product_status.name === "unpublished" ? (
							<>
								{/* <Switch
									height={24}
									width={45}
									handleDiameter={20}
									className="mx-2 align-middle"
									checked={false}
									onChange={toggleShareModel}
								/>
								<small className="">{product.product_status.name}</small> */}
								<p className="tb-unpublished">{product.product_status.name}</p>
							</>
						) : (
							<>
								{/* <Switch
									height={24}
									width={45}
									handleDiameter={20}
									className="mx-2 align-middle"
									checked={true}
									onColor="00b437"
									onChange={() => toggleUnpublished()}
								/>
								<small>{product.product_status.name}</small> */}
								<p className="tb-publish">{product.product_status.name}</p>
							</>
						)}
						{/* 					<span className={statusColor()}>{product.product_status.name}</span>
						 */}{" "}
					</td>
				</ShowForPermission>

				{/* Action */}
				<ShowForPermission permission="Manage-Trip-Management-Module">
					{/* <button className="btn-clear" onClick={() => {}}>
					<i className="far fa-eye text-primary"></i>
				</button> */}
					<td className="actions position-relative">
					
						<button
							className="btn"
							onClick={() => {
								setIsopen(product.id);
							}}
						>
							<MoreIcon />
						</button>
						{Isopen == product.id && (
							<div style={{ position: "absolute",bottom:"15px",left:"-42px",zIndex:6 }} >
								
								<div className="more-popup"
									ref={moreActionsRef}
								>
									<p
										title="Publish"
										role="button"
										onClick={() => {
											setIsopen(null)
											if (
												product?.product_status?.name === "غير منشورة" ||
												product?.product_status?.name === "unpublished"
											) {
												toggleShareModel();
											} else {
												toggleUnpublished();
											}
										}}
									>
										<PublishIcon />
									{product?.product_status?.id === 1 ? productsBuilder.Publish :productsBuilder.Unpublished}	
									</p>
									<p
										title="clone"
										role="button"
										onClick={() => {
											setIsopen(null)
											cloneProducts();
										}}
									>
										<DuplicateIcon />
										{productsBuilder.Duplicate}
									</p>
									<p
										title="delete"
										className="border-top"
										role="button"
										onClick={toggle}
									>
										<DeleteIcon />
										{productsBuilder.delete}
									</p>
									{product?.product_items?.internal_transportation &&
										product?.has_reservation && (
											<p
												className="border-top pointer"
												onClick={() =>
													history.push(
														`${product?.product_uuid}/bus-accommodation`
													)
												}
											>
												<BusIcon fillColor="#ce7c0d" />
												{productsBuilder.busAccommodation}
											</p>
										)}
									{
										product?.has_reservation && (
											<p
												className="border-top pointer"

												onClick={() =>
													history.push(
														`/products-builder/hotel-accommodation/${product?.product_uuid}`
													)
												}
											>
												<HotelIcon fillColor="#ce7c0d" />
												{productsBuilder.hotelAccommodation}
											</p>
										)}
								</div>
							</div>
						)}
						{!product?.has_reservation ? (
							<button className="btn" title="edit" onClick={editProducts}>
								<PenIcon />
							</button>
						) : (
							<button
								className="edit-product"
								title="show"
								onClick={showProducts}
							>
								<ViewIcon />
							</button>
						)}

						
					</td>
				</ShowForPermission>
			</tr>
		</>
	);
}
