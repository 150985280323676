import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import React, { useState } from "react";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import ToursCarouselOverLayView from './ToursCarouselOverLayView';
import ToursCarouselView from './ToursCarouselView';
import Locale from 'translations';
import ToursTheme3 from './ToursTheme3';


function ToursService({ details, onFocus, focusContainer }) {
	const { selectedLanguage } = useWebBuilderState();
	const { webBuilder } = Locale;

	const { id, type, body, title, items, tours_type } = details;
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);

	const dispatch = useWebBuilderDispatch();

	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	};

	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const updateTours = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language
			},
		});
	};

	const sortToursItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};

	const addItemToTours = (item) => {
		updateItem
			? dispatch({
				type: "editItemInContainer",
				payload: {
					id,
					item,
					itemIndex: updateItem.index,
				},
			})
			: dispatch({
				type: "addItemToContainer",
				payload: {
					id,
					item,
				},
			});
		setUpdateItem(null);
	};

	function renderToursViews() {
		return {
			"carousel": <ToursCarouselView
				items={items}
				sortToursItems={sortToursItems}
				focusContainer={focusContainer}
				editItem={editItem}
				itemsBoxActions={itemsBoxActions}
				openEditModal={openEditModal}
			/>,
			"carousel-overlay": <ToursCarouselOverLayView
				items={items}
				sortToursItems={sortToursItems}
				focusContainer={focusContainer}
				editItem={editItem}
				itemsBoxActions={itemsBoxActions}
				openEditModal={openEditModal}
			/>,
			"theme-3": <ToursTheme3
				items={items}
				sortToursItems={sortToursItems}
				focusContainer={focusContainer}
				editItem={editItem}
				itemsBoxActions={itemsBoxActions}
				openEditModal={openEditModal}
			/>
		}
	}


	const selectedIds = items ? items.map((i) => i.id) : [];

	return (
		<EditBox editMode={focusContainer === type}>
			<div
				onClick={() => onFocus(type)}
				className="web-builder-content-tours container"
				id={id}
			>
				<div className="service-top-section">
					<ServicesContent
						header={title}
						body={body}
						changeHeader={(value) => updateTours({ value, name: "title", language: selectedLanguage })}
						changebody={(value) => updateTours({ value, name: "body", language: selectedLanguage })}
					/>
					<AddElement
						onClick={handleOpenAddModal}
						title={webBuilder.addMoreAttractions}
					/>
				</div>

				<div className="tours-detials services-carousel-container">
					{renderToursViews()[tours_type]}
				</div>

				{openAdd && <AddService
					handleModalState={handleOpenAddModal}
					open={openAdd}
					item_id={7}
					label={"Service"}
					header={"Tour"}
					onAdd={addItemToTours}
					details={updateItem}
					selectedIds={selectedIds}
				/>}
			</div>
		</EditBox>
	);
}

export default ToursService;
