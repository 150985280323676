import Header from "components/Header/Header";
import { useSBSDispatch, useSBSState } from "context/global";
import { Steps } from "intro.js-react";
import { useEffect } from "react";
import { getCredit, getDebit } from "services/wallet";
import "intro.js/introjs.css";
// import { useSetpsList } from "layouts/useSetpsList";
import Locale from "translations";
import { useHistory, useLocation, Link } from "react-router-dom";
import { ReactComponent as LiveChatIcon } from "assets/images/live-chat-icon.svg";

// 

export default function DashboardLayout(props) {
	const dispatch = useSBSDispatch();
	const { companyInfo } = useSBSState();
	const { companyDetails } = Locale;
	const history = useHistory();
	const location = useLocation()

	const isServiceCompnay = companyInfo?.company_category === "services_company"
	const search = window.location.search;
	const name = new URLSearchParams(search);
	const { guide, userInfo } = useSBSState();
	// const { menuSteps, hotelsTabs, vacationsIndividualSteps } = useSetpsList();

	useEffect(() => {
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}

		if (x.hasOwnProperty("token")) {
		} else {
			async function fetchSafaWallet() {
				const res = await getCredit();
				dispatch({ type: "getSafaBalance", payload: res?.data?.data });
			}

			async function fetchMyWallet() {
				const res = await getDebit();
				dispatch({ type: "getMyBalance", payload: res?.data?.data?.[0] });
			}
			if (!isServiceCompnay) {
				fetchSafaWallet();
				fetchMyWallet();
			}
		}
	}, []);

	// useEffect(() => {
	// 	if (
	// 		userInfo?.is_first_login &&
	// 		document.getElementById("sidenav-market-search")
	// 	) {
	// 		dispatch({
	// 			type: "guide",
	// 			payload: {
	// 				enabled: true,
	// 				initialStep: 0,
	// 				steps: [...menuSteps, ...hotelsTabs, ...vacationsIndividualSteps],
	// 			},
	// 		});
	// 	}
	// }, [document.getElementById("sidenav-market-search")]);

	useEffect(() => {
		if (!companyInfo?.company_category && location.pathname !== "/company-profile" && location.pathname !== "/edit-company-profile" ) {
			history.push('/company-profile')
		}
	}, [companyInfo?.company_category, history, location.pathname])

	const path = window.location.href;

	return (
		<div
			className={` ${path.includes("umrah-operations") ? "bg-white" : "bg-gray-100"}  ${!path.includes("hyperpay") ? "vh-100" : ""}`}
		>
			{/* guids */}
			{companyDetails?.next ? (
				<Steps
					options={{
						nextLabel: companyDetails?.next,
						prevLabel: companyDetails?.back,
					}}
					enabled={guide.enabled}
					steps={guide.steps}
					initialStep={guide.initialStep}
					onExit={() => {
						dispatch({
							type: "guide",
							payload: { enabled: false, initialStep: 0, steps: [] },
						});
						if (
							userInfo?.is_first_login &&
							document.getElementById("sidenav-market-search")
						) {
							dispatch({
								type: "removeFirstLogin",
								payload: {
									...userInfo,
									is_first_login: false,
								},
							});
						}
					}}
				/>
			) : null}
			{/* {path.includes("visa") ? (
				<div className="Mobile_SBS">
					<img
						className="center-block mt-5"
						width="300px"
						src={locale == "ar" ? MobileSBS : MobileSBSEn}
						alt=""
						srcset=""
					/>
				</div>
			) : null} */}

			<Header />

			<div className="bg-gray-100 container_layout " {...props}>
				{props.children}
			</div>
			<a href="https://bit.ly/3yENkbJ" className="live-chat" target="_blank" rel="noopener noreferrer">
				<LiveChatIcon />
			</a>
		</div>
	);
}
