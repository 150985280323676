import UploadFileComponent from "modules/WebBuilder-V2/shared/UploadFile";
import React, { useState } from "react";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import { useLocation } from "react-router-dom";
import ServicesWithImage from "./ServicesWithImage";
import ServicesWithIcons from "./ServicesWithIcons";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import { useWebBuilderDispatch } from "context/webBuilder";

function ServiceCard({
	icon,
	title,
	typeTitle,
	body,
	type,
	visible,
	editCardDetails,
	id,
	ourServicesType,
	header
}) {
	const location = useLocation();
	const dispatch = useWebBuilderDispatch();

	const preview = !location.pathname.includes("edit");
	const isTransfer = type === "transport";
	const isPackage = type === "packages";

	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	};
	const logoImage = icon ? <img src={icon} alt="Logo" /> : null;
	const logo = preview ? (logoImage) 
	:
	!logoImage ? (
		<AddElement
			onClick={handleOpenAddModal}
		/>
	) : (
		<UploadFileComponent
			icon={AddIcon}
			id={`${type}-service`}
			label=""
			onUpload={(value) => editCardDetails(value, "icon")}
			image={logoImage}
			disabled={isTransfer}
		/>
	);

	const addItem = (item) => {
		debugger;
		updateItem
			? dispatch({
					type: "editItemInServices",
					payload: {
						id,
						item,
					},
			  })
			: dispatch({
					type: "addItemToService",
					payload: {
						id,
						item,
					},
			  });
		setUpdateItem(null);
	};
	let item_id;
	switch (type) {
		case "hotels":
			item_id = 6; //6
			break;
		case "flights":
			item_id = 1; //1
			break;
		case "tours":
			item_id = 7; //7
			break;
		case "visa":
			item_id = 4;
			break;
		default:
			item_id = "";
	}

	return (
		<div className={`web-builder-content-our-service_card h-100`}>
			{/* {isTransfer ? <span className="soon">soon</span> : null} */}
			<div className={`${ isTransfer ? "opacity-50 user-select-none pe-defualt" : "h-100"}`}
			>
				{/* <div className="our-service-icon">
					{logo}
					<div className="img-data">
						<p className="service-body text-secondary-color1">
							{type?.toLocaleUpperCase()}
						</p>
						<EditableParagraph
							className="service-header h5 "
							contentEditable={!isTransfer}
							onInput={(value) => {
								editCardDetails(value, "title");
							}}
							text={title}
						/>
					</div>
				</div>
				<div>
					<EditableParagraph
						className="text-secondary-color service-content"
						contentEditable={!isTransfer}
						onInput={(value) => {
							editCardDetails(value, "body");
						}}
						text={body}
					/>
				</div>

				{!preview ? (
					<ToggleCheckbox
						checked={visible}
						onChange={(e) => {
							const value = e.target.checked;
							editCardDetails(value, "visible");
						}}
						disabled={isTransfer}
						label="Visible"
					/>
				) : null} */}
				{ourServicesType === "with_images" ? (
					<ServicesWithImage
						logo={logo}
						type={type}
						isTransfer={isTransfer}
						isPackage={isPackage}
						editCardDetails={editCardDetails}
						header={header}
						body={body}
						preview={preview}
						visible={visible}
						typeTitle={typeTitle}
					/>
				) : (
					<ServicesWithIcons
						logo={logo}
						type={type}
						isTransfer={isTransfer}
						isPackage={isPackage}
						editCardDetails={editCardDetails}
						header={header}
						body={body}
						preview={preview}
						visible={visible}
						typeTitle={typeTitle}
					/>
				)}
			</div>
			{openAdd && (
				<AddService
					handleModalState={handleOpenAddModal}
					open={openAdd}
					item_id={item_id || null}
					label={"Service"}
					header={"Service"}
					onAdd={addItem}
					details={updateItem}
					// selectedIds={selectedIds}
				/>
			)}
		</div>
	);
}

export default ServiceCard;
