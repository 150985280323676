import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { addPayments } from "services/productbuilder";
import Locale from "translations";
export default function PaymentsModal({
	modal,
	toggle,
	setReload,
	Reload,
	reservationDetails,
}) {
	const { inventory, payment } = Locale;
	const { locale } = useSBSState();
	const { id } = useParams();
	const [paymentsState, setPaymentsState] = useState({
		amount: "",
		pay_date: undefined,
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	function checkFormErrors() {
		let SubmitError = {};
		Object.keys(paymentsState).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: paymentsState[key] },
					{ required: true }
				),
			};
		});
		setErrors(() => SubmitError);
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			const addPaymentsFn = async () => {
				let data = {
					package_reservation_id: +id,
					amount: +paymentsState?.amount,
					pay_date: moment(paymentsState?.pay_date).format("YYYY-MM-DD"),
				};
				const response = await addPayments(data);
				if (response.status === 200 || response.status === 201) {
					store.addNotification({
						title: "info!",
						message: response.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 1500,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							setReload((prev) => !prev);
							toggle();
							setPaymentsState({
								amount: "",
								pay_date: undefined,
							})
						},
					});
				}
			};
			addPaymentsFn();
		}
	}, [isErrorLoaded]);

	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}
	return (
		<>
			<Modal size="lg" isOpen={modal.isOpen} toggle={toggle} hasFooter={false}>
				<ModalHeader className="align-items-center" toggle={toggle}>
					{inventory.messages.addpayment}
				</ModalHeader>
				<ModalBody>
					<div className="container">
						<div className="row payment-model mb-2">
							<div className="col-12 d-flex justify-content-evenly my-4">
								<div className="col-4 d-flex Total">
									<p className="model-lebal">
										{inventory.messages.totalPrice}
									</p>
									<p className="model-amount ">
										{reservationDetails?.total_price}{" "}
										{reservationDetails?.currency}{" "}
									</p>
								</div>
								<div className="col-4 d-flex Paid">
									<p className="model-lebal ">{payment.messages.paid}</p>
									<p className="model-amount">
										{reservationDetails?.payments_total_paid}{" "}
										{reservationDetails?.currency}{" "}
									</p>
								</div>
								<div className="col-4 d-flex Outstanding">
									<p className="model-lebal ">{payment.messages.Outstanding}</p>
									<p className="model-amount">
									{reservationDetails?.payments_outstanding}{" "}
										{reservationDetails?.currency}
									</p>
								</div>
							</div>
							<div className="col-6">
								<TextField
									label={payment.messages.amount}
									type="text"
									placeholder={inventory.messages.enteramount}
									name="amount"
									value={paymentsState.amount}
									onChange={(e) => {
										setPaymentsState({
											...paymentsState,
											amount: e.target.value,
										});
										setErrors({
											...errors,
											...validate(
												{ name: "amount", value: e.target.value },
												{ required: true }
											),
										});
									}}
									color={errors?.amount?.required ? "danger" : ""}
									errors={errors?.amount}
								/>
							</div>
							<div className="col-6 ">
								<DatePickerField
									label={inventory.messages.ondate}
									placeholder={"DD/MM/YYYY"}
									name="pay_date"
									date={paymentsState.pay_date?moment(paymentsState.pay_date):""}
									onChangeDate={(e) => {
										setPaymentsState({ ...paymentsState, pay_date: e });
										setErrors({
											...errors,
											...validate(
												{ name: "pay_date", value: e },
												{ required: true }
											),
										});
									}}
									isOutsideRange={(day) => {
										return false;
									}}
									color={errors?.pay_date?.required ? "danger" : ""}
									errors={errors?.pay_date}
								/>
							</div>
							<div className="col-12 d-flex justify-content-end align-items-center ">
								<button className="btn w-50  py-2 btn-model" onClick={submit}>
									{inventory.messages.addpayment}
								</button>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
