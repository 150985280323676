import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import UAEImg from "assets/images/market-place/d01d5a6b570904a986cb4774ec059690.jpg";
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import moment from 'moment';
import { ReactComponent as ClockIcon } from "assets/images/visa/clock.svg";
import { ReactComponent as VisaIcon } from "assets/images/market-place/services-visaonline-icon.svg"
import { ReactComponent as VisaLightStrokeIcon } from "assets/images/market-place/visa-light-stroke-icon.svg"
import { ReactComponent as VisaPayIcon } from "assets/images/market-place/visa-pay-card.svg"
import { ReactComponent as VisaUploadDocsIcon } from "assets/images/market-place/visa-upload.svg"
import { ReactComponent as ArrowIcon } from "assets/images/visa/arrow-right.svg"
import { ReactComponent as WalletSearchIcon } from "assets/images/market-place/wallet-search.svg"
import { ReactComponent as WarningIcon } from "assets/images/market-place/warning-outline-icon.svg";
import { ReactComponent as CheckMarkIcon } from "assets/images/market-place/checkmark-icon.svg";
import { ReactComponent as CalenderIcon } from "assets/images/market-place/calendar-outline-icon.svg";
import NationalityIconSrc from "assets/images/market-place/global-nationality-icon.svg";
import PaxIconSrc from "assets/images/market-place/people-pax-icon.svg"
import SelectField from 'components/Form/SelectField/SelectField';
import { useSBSState } from 'context/global';
import TextField from 'components/Form/TextField/TextField';
import Locale from 'translations';
import CustomModal from './components/Modal';
import validate, { isFormValid } from "helpers/validate";
import NewPaymentAction from 'components/NewPaymentAction';
import { store } from 'react-notifications-component';
import { createOnlineVisaRequest } from 'services/VisaOnline';
import useShowPermission from 'hooks/useShowPermission';
import NumberField from 'components/Form/NumberField/NumberField';

export default function GlobalVisaDeatails() {
  const { marketPlace, tdsVisa, inventory, productsBuilder, commons } = Locale;
  const { allCountries, locale } = useSBSState();
  const { onlineVisaSearch } = useMarketplaceState();
  const dispatch = useMarketplaceDispatch();
  const history = useHistory();


  const [visaData, setVisaData] = useState({
    destination: onlineVisaSearch?.destination,
    visa_type: onlineVisaSearch?.visa_type,
    residence: onlineVisaSearch?.residence,
    expiration_days: moment(new Date()).add(
      onlineVisaSearch?.residence?.expiration_days,
      "d"
    ),
    price_per_one: +onlineVisaSearch?.package_plan?.package_price,
    package_plan: onlineVisaSearch?.package_plan,
    nationality: onlineVisaSearch?.nationality,
    pax: onlineVisaSearch?.pax,
    terms: onlineVisaSearch?.terms,
    visaTerms: false,
    visaNotRefundableCheck: false,
    visaEntryStamp: false,
  });

  const [termsConditionIsOpen, setTermsConditionIsOpen] = useState(false);
  const [entryStampModal, setEntryStampModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isReadyForPay, setIsReadyForPay] = useState(false);
  const [ModalPayment, setModalPayment] = useState(false);

  const [actionType, setActionType] = useState("");
  const [formData, setFormData] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
  const inventoryPermission = useShowPermission({
    permission: ["View-Inventory-Visa", "Manage-Inventory-Visa"],
  });

  const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
    ? JSON.parse(process.env.REACT_APP_OTP_CHECK)
    : false;

  function toggleTermsConditionModal() {
    setTermsConditionIsOpen(!termsConditionIsOpen)
  }

  function toggleModelPayment() {
    setActionType("");
    setFormData({});
    setModalPayment(!ModalPayment);
  }

  function toggleEntryStampModal() {
    setEntryStampModal(!entryStampModal);
  }

  function checkFormErrors() {
    let submitError = {};
    Object.keys(visaData).forEach((key) => {
      if (key === "visaEntryStamp" && !visaData?.package_plan?.entry_stamp) return;
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: visaData[key] },
          {
            required: key === "visaNotRefundableCheck" || key === "visaEntryStamp" || key === "visaTerms" ? isReadyForPay : true,
            minNumber: key === "pax" && visaData?.package_plan?.range_from,
            maxNumber: key === "pax" && visaData?.package_plan?.range_to,
          }
        ),
      };
    });
    setErrors(() => submitError);
  }

  const sumbit = () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }



  // pay visa fees
  async function payOnlineVisa(paymentType, currency, otp, price) {
    
    const data = {
      destination_id: visaData?.destination?.country_code,
      residence_id: onlineVisaSearch?.residence_id,
      configuration_uuid: visaData?.residence?.uuid,
      package_uuid: visaData?.package_plan?.package_uuid,
      visa_type_id: visaData?.visa_type?.id,
      pax: visaData?.pax,
      currency: currency,
      expiration_days: visaData?.residence?.expiration_days,
      payment_method: paymentType,
      otp: otp,
    };
    const response = await createOnlineVisaRequest(data);
    if (response.status === 200) {
      store.addNotification({
        title: inventory.messages.bookingSuccessfully,
        message: commons.successfullyCompleted,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          pauseOnHover: true,
        },
      });
      history.push({
        pathname: inventoryPermission
          ? `/inventory/visa-requests/edit/${response?.data?.data?.id}`
          : "/statistics",
      });
      dispatch({
        type: "onlineVisaSearch",
        payload: [],
      });
      toggleModelPayment();
    } else if (response?.status === 202) {
      setActionType(response?.data?.actionType);
      setFormData(response?.data?.data?.formData);
    }
  }
  // search
  function calculateVisaPrice() {
    if (!isReadyForPay) {
      setVisaData({
        ...visaData,
        expireDate: moment(new Date()).add(visaData?.residence?.expiration_days, "d"),
        totalPrice: visaData?.pax * visaData?.price_per_one,
        currency: visaData?.residence?.currency,
      });
      setIsReadyForPay(true);
    } else {
      // setModalPayment(true);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      if (!isReadyForPay) {
        setIsReadyForPay(true);
      } else {
        setModalPayment(true);
      }
    }
  }, [isErrorLoaded]);


  return (
    <>
      <div className="visa-details-container"
        style={{
          background: `linear-gradient(180deg, #00000050, rgb(0 0 4 / 60%)),url(${visaData?.destination?.country_photo
            ? fetchFilesURL + "/" + visaData.destination?.country_photo
            : UAEImg
            }) no-repeat center/cover`,
        }}
      >
        <div className="container d-flex align-items-center">
          <h2 className="font-weight-bold h3 m-0">
            {visaData?.destination?.country}
          </h2>
        </div>
      </div>

      {/* content */}
      <div className="container-fluid  global-visa-details">
        <div className="col-md-10 col-12 pb-4 mx-auto">
          {/* bread crump */}
          <div className="mt-3 col-12 my-4 bread-crumb">
            <Link to="/market-place-new/global/visa" style={{ color: "#F5B950" }}>
              {marketPlace.onlineVisa}/
            </Link>
            <span className='country-name'> {visaData?.destination?.country}</span>
          </div>

          {/*  Package Details */}
          <div className="package-details visa-wrapper">
            <h3 className='header-title'>
              {tdsVisa.packageDetails}
            </h3>

            <div className='row mx-0'>

              <div className="item">
                <p className='d-flex align-items-center'>
                  <ClockIcon />
                  <span className='mx-2 font-grey'>{marketPlace.processingTime}</span>
                </p>
                <p className='font-weight-bold direction-initial'>{visaData?.residence?.processing_time}</p>

              </div>


              <div className="item">
                <p className='d-flex align-items-center'>
                  <VisaIcon width="24px" height="24px" />
                  <span className='mx-2 font-grey'>{inventory.messages.visaType}</span>
                </p>
                <p className='font-weight-bold direction-initial'>{visaData?.visa_type?.name}</p>
              </div>

            </div>

          </div>

          {/* How to apply  */}
          <div className="visa-wrapper visa-apply">
            <h3 className='header-title'>
              {`${marketPlace.HowToApplyFor} ${inventory.messages.visa} ${visaData?.visa_type?.name} ?`}
            </h3>
            <div className='row mx-0 align-items-center justify-content-center my-2'>
              <div className='d-flex flex-column align-items-center mx-3'>
                <VisaPayIcon />
                <span className='mt-2 font-grey'>{inventory.messages.pay}</span>
              </div>

              <div className="d-none d-md-block arrow-icon mx-3">
                <ArrowIcon />
              </div>

              <div className='d-flex flex-column align-items-center mx-3'>
                <VisaUploadDocsIcon />
                <span className='mt-2 font-grey'>{marketPlace.uploadDocuments}</span>
              </div>

              <div className="d-none d-md-block arrow-icon mx-3">
                <ArrowIcon />
              </div>

              <div className='d-flex flex-column align-items-center mx-3'>
                <VisaLightStrokeIcon />
                <span className='mt-2 font-grey'>{marketPlace.getVisa}</span>
              </div>
            </div>
          </div>

          {/* terms and conditions */}
          <div className="visa-wrapper visa-terms">
            <h3 className='header-title'>
              {marketPlace.generalTermsAndConditions}
            </h3>
            <ol>
              {visaData?.terms?.[locale]?.map((term, index) => {
                return <li className='my-2' key={`terms-${index}`}>{term}</li>
              })}
            </ol>
          </div>

          {/* apply online */}
          <div className="visa-wrapper visa-apply">
            <h3 className='header-title'>
              {marketPlace.applyOnline}
            </h3>
            {/* form */}
            <div className='d-flex flex-wrap justify-content-center align-items-start gap-10'>
              {/* Nationally */}
              <div className="global-visa-form-item col-md-2 col-12">
                <label className='visa-label'>{inventory.messages.nationality}</label>
                <SelectField
                  hasLabel={false}
                  label={inventory.messages.nationality}
                  isImage={true}
                  image={NationalityIconSrc}
                  prependImage={true}
                  placeholder={productsBuilder.select}
                  options={
                    visaData?.residence?.all_nationalities ?
                      allCountries :
                      visaData?.residence?.nationalities?.map(nationality => ({ ...nationality, id: nationality?.country_id }))
                  }
                  value={visaData.nationality?.name}
                  onChange={(e) => {
                    setVisaData({ ...visaData, nationality: e });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "nationality", value: e },
                        { required: true }
                      ),
                    });
                  }}
                  errors={errors?.nationality}
                  color={errors?.nationality?.required ? "danger" : ""}
                />
              </div>
              {/* Pax */}
              <div className="global-visa-form-item col-md-2 col-12">
                <label className='visa-label'>{marketPlace.messages.pax}</label>
                <NumberField
                  type="number"
                  hasLabel={false}
                  removeArrow={true}
                  label={marketPlace.messages.pax}
                  isImage={true}
                  image={PaxIconSrc}
                  prependImage={true}
                  placeholder={marketPlace.typePax}
                  min={+visaData?.package_plan?.range_from}
                  max={+visaData?.package_plan?.range_to}
                  value={visaData?.pax}
                  onChange={(e) => {
                    setVisaData({
                      ...visaData,
                      totalPrice: +e.target.value * visaData?.price_per_one,
                      pax: e.target.value > 0 ? +e.target.value : "",
                    });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "pax", value: +e.target.value },
                        {
                          required: true,
                          minNumber: +visaData?.package_plan?.range_from,
                          maxNumber: +visaData?.package_plan?.range_to,
                        }),
                    });
                    if (
                      +e.target.value < visaData?.package_plan?.range_from ||
                      +e.target.value > visaData?.package_plan?.range_to
                    ) {
                      setIsReadyForPay(false);
                    }
                  }}
                  errors={errors?.pax}
                  color={errors?.pax?.required ? "danger" : ""}
                />
              </div>
              {/* calc price button */}
              <button className="btn calc-price-btn mx-2" onClick={calculateVisaPrice} 
              disabled={
                !visaData.nationality || 
                !visaData.pax || 
                +visaData.pax < +visaData?.package_plan?.range_from ||  
                +visaData.pax > +visaData?.package_plan?.range_to
              }
              >
                <WalletSearchIcon /> {marketPlace.calculatePrice}
              </button>
            </div>

            {/* visa checkout */}
            {isReadyForPay ?
              <div className="visa-checkout">
                <div className='info'>
                  {/* Processing Time */}
                  <div className='item'>
                    <WarningIcon width="20px" />
                    <p className='d-flex flex-column'>
                      <span>
                        {marketPlace.processingTime}
                      </span>
                      <span className='direction-initial'>
                        {visaData?.residence?.processing_time}
                      </span>
                    </p>
                  </div>
                  {/* expire date */}
                  <div className='item'>
                    <CalenderIcon width="20px" />
                    <p className='d-flex flex-column'>
                      <span>{marketPlace.messages.requestExpireDate}</span>
                      <span>{moment(visaData?.expireDate).format("DD-MM-YYYY")}</span>
                    </p>
                  </div>
                </div>

                {/* price */}
                <div className='price'>
                  <span>{inventory.messages.TotalPrice}</span>
                  <span className='total'>
                    {visaData?.totalPrice?.toFixed(2)} {" "}
                    {visaData?.residence?.currency}
                  </span>
                  <span className='per-visa'>
                    {visaData?.price_per_one} {" "}
                    {visaData?.residence?.currency}/{marketPlace.perVisa}
                  </span>
                </div>

                {/* accept terms */}
                <div>
                  {/* terms checkbox */}
                  <div className='terms'>
                    <input
                      type="checkbox"
                      id="visa-terms"
                      name="visa-terms"
                      className="checkbox-input"
                      onChange={() =>
                        setVisaData({
                          ...visaData,
                          visaTerms: !visaData?.visaTerms,
                        })
                      }
                      checked={visaData?.visaTerms}
                    />
                    <label htmlFor="visa-terms" className='mx-2 pointer'>
                      <span>{marketPlace.acceptAll}</span>
                      {" "}
                      <button className='terms-modal-btn'
                        onClick={toggleTermsConditionModal}
                      >
                        {marketPlace.termsAndCondition}
                      </button>
                    </label>
                  </div>
                  {/* refund checkbox */}
                  <div className='refund'>
                    <input
                      type="checkbox"
                      id="visa-refund"
                      name="visa-refund"
                      className="checkbox-input"
                      onChange={() =>
                        setVisaData({
                          ...visaData,
                          visaNotRefundableCheck: !visaData?.visaNotRefundableCheck,
                        })
                      }
                      checked={visaData?.visaNotRefundableCheck}
                    />
                    <label htmlFor="visa-refund" className='mx-2 pointer'>
                      {marketPlace.messages.visaNotRefundable}
                    </label>
                  </div>
                  {/* entry stamp checkbox */}
                  {visaData?.package_plan?.entry_stamp ?
                    <div className="entry-stamp">
                      <input
                        type="checkbox"
                        name="entry-stamp-agreement"
                        id="entry-stamp-agreement"
                        className="checkbox-input"
                        onChange={() =>
                          setVisaData({
                            ...visaData,
                            visaEntryStamp: !visaData?.visaEntryStamp,
                          })
                        }
                        checked={visaData?.visaEntryStamp}
                      />
                      <label htmlFor="entry-stamp-agreement" className='mx-2 pointer'>
                        <span>{marketPlace.messages.confirmRead}</span>{" "}
                        <button className='terms-modal-btn'
                          onClick={toggleEntryStampModal}
                        >
                          {marketPlace.messages.visaAgreement}
                        </button>
                      </label>
                    </div>
                    :
                    null
                  }

                  {/*  */}
                  <button className="btn bg-nxt w-100 checkout-btn"
                    onClick={sumbit}
                    disabled={
                      isReadyForPay ?
                        !visaData.visaNotRefundableCheck
                        ||
                        (visaData?.package_plan?.entry_stamp && !visaData.visaEntryStamp)
                        ||
                        !visaData?.visaTerms
                        :
                        false
                    }
                  >
                    <CheckMarkIcon />
                    <span className='mx-2'>{marketPlace.applyVisa}</span>
                  </button>
                </div>
              </div>
              :
              null}
          </div>

        </div>
      </div>


      <CustomModal
        modalIsOpen={termsConditionIsOpen}
        size={"md"}
        toggle={toggleTermsConditionModal}
        centered={false}
        header={marketPlace.generalTermsAndConditions}
        classNames={"terms-conditions-modal"}
      >
        <ol>
          {visaData?.terms?.[locale]?.map((term, index) => {
            return <li className='my-2' key={`terms-${index}`}>{term}</li>
          })}
        </ol>
      </CustomModal>


      <CustomModal
        modalIsOpen={entryStampModal}
        size={"md"}
        toggle={toggleEntryStampModal}
        centered={false}
        header={"Agreement"}
        classNames={"terms-conditions-modal"}
      >
        <p>
          Agreement Agreement Agreement Agreement Agreement Agreement Agreement
          Agreement Agreement{" "}
        </p>
      </CustomModal>



      {ModalPayment && (
        <NewPaymentAction
          isOpen={ModalPayment}
          toggleModal={toggleModelPayment}
          OTP={OTP_CHECK}
          canPayLater={false}
          formData={formData}
          actionType={actionType}
          Price={visaData?.price_per_one * +visaData?.pax}
          currency={visaData?.residence?.currency}
          // secondCurrency={visaData?.currency?.currency}
          onPayment={payOnlineVisa}
          setActionType={setActionType}
          setFormData={setFormData}
        />
      )}
    </>

  )
}
