import { useSBSDispatch, useSBSState } from "context/global";
import React, { useEffect, useState } from "react";
import bg from "assets/images/customRegister/Group 3787.png";
import bgAR from "assets/images/customRegister/Group 3787AR.png";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import Locale from "translations";
import TextField from "components/Form/TextField/TextField";
import validate, { isFormValid } from "helpers/validate";
import PasswordField from "components/Form/PasswordField/PasswordField";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import safaLogo from "assets/images/safa_logo.png";
import {  loginWithSafaKey, loginWithSafaKeyToken } from "services/auth";
import { useHistory } from "react-router-dom";
import { store } from "react-notifications-component";
import safaLogo2 from "assets/images/bannerLogin.png";
import Logosafa from "assets/images/LogoSafa.svg";
import bus from "assets/images/main-icon/bus.svg";
import flight from "assets/images/main-icon/flight.svg";
import hotel from "assets/images/main-icon/hotel.svg";
import toury from "assets/images/main-icon/toury.svg";
import passport from "assets/images/main-icon/passport.svg";
import transfer from "assets/images/main-icon/transfer.svg";
import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
export default function LoginWithSafa() {
	const { locale } = useSBSState();
	const dispatch = useSBSDispatch();
	const { login, commons, newLand } = Locale;
	const history = useHistory();
	const [spin, setSpin] = useState(false);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const isMobile = window.innerWidth < 426 ? true : false;
	const search = window.location.search;
	const [params, setParams] = useState(search);
	const name = new URLSearchParams(search);

	const [loginData, setLoginData] = useState({
		safa_key: "",
		email: "",
		password: "",
	});
	useEffect(() => {
		async function handleLoginWithSafaKey() {
			//
			const userDontHaveSBSAccountCode = "AUTH-010";
			
			if (
				params.includes("token") 
		
			) {
				setSpin(true);
				let x = {};
				name.forEach(function (value, key) {
					x = { ...x, [key]: value };
				});
        const data = {
          token: x?.token,
        
        };
				const loginWithSafaRes = await loginWithSafaKeyToken(data);
				if (loginWithSafaRes?.status === 200) {
					dispatch({
						type: "login",
						isAuth: true,
						token: loginWithSafaRes.data.data.token,
						userInfo: {
							...loginWithSafaRes.data.data.user,
							is_first_login: isMobile
								? 0
								: loginWithSafaRes?.data?.data?.user?.is_first_login,
						},
						companyInfo: loginWithSafaRes.data.data.company,
					});
					history.push("/market-place-new");
				} else if (
					loginWithSafaRes?.status === 400 &&
					loginWithSafaRes?.data?.err_code === userDontHaveSBSAccountCode
				) {
					store.addNotification({
						title: commons.somethingWentWrong,
						message: loginWithSafaRes?.data?.message,
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => history.push("/auth/link_safa_account"),
					});
				} else {
					dispatch({
						type: "setError",
						payload: true,
						message: {
							title: commons.somethingWentWrong,
							body: loginWithSafaRes?.data?.message,
						},
					});
					// setSpin(false);
				}
			}
		}
		handleLoginWithSafaKey();
	}, []);

	// 
	function checkFormErrors() {
		let submitError = {};
		Object.keys(loginData).forEach((key) => {
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: loginData[key] },
					{
						required: true,
						email: key === "email",
					}
				),
			};
		});
		setErrors(() => submitError);
	}

	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}

	async function handleLoginWithSafaKey() {
		setSpin(true);
		const userDontHaveSBSAccountCode = "AUTH-010";
		const data = {
			safa_key: loginData?.safa_key,
			email: loginData?.email,
			password: loginData?.password,
		};

		const loginWithSafaRes = await loginWithSafaKey(data);
		if (loginWithSafaRes?.status === 200) {
			dispatch({
				type: "login",
				isAuth: true,
				token: loginWithSafaRes.data.data.token,
				userInfo: {
					...loginWithSafaRes.data.data.user,
					is_first_login: isMobile
						? 0
						: loginWithSafaRes?.data?.data?.user?.is_first_login,
				},
				companyInfo: loginWithSafaRes.data.data.company,
			});
			history.push("/market-place-new");
		} else if (
			loginWithSafaRes?.status === 400 &&
			loginWithSafaRes?.data?.err_code === userDontHaveSBSAccountCode
		) {
			store.addNotification({
				title: commons.somethingWentWrong,
				message: loginWithSafaRes?.data?.message,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => history.push("/auth/link_safa_account"),
			});
		} else {
			dispatch({
				type: "setError",
				payload: true,
				message: {
					title: commons.somethingWentWrong,
					body: loginWithSafaRes?.data?.message,
				},
			});
		}
		setSpin(false);
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			handleLoginWithSafaKey();
		}
		return () => {};
	}, [isErrorLoaded]);

	return (
		<div className="bg-layout login-page">
			<div className="container d-flex justify-content-around">
			<figure className="col-6 m-0 login-page-img">
					<div className="d-flex hidden-mobile justify-content-between py-2 align-items-center w-100">
							<Link to="/" className="text-dark">
								<img src={SafaLogo} alt="" srcset="" />
							</Link>
							
						</div>
						<div className="d-flex justify-content-center align-items-center" style={{flexDirection:"column",minHeight:"calc(100vh - 40px )"}}>
						<img
							src={ safaLogo2 }
							alt=""
							className="img-fluid"
						/>

						<p className="m-0 mt-2 Safa-title w-100">{login.SafaLive}</p>
						<p className="m-0 w-100 mt-2 Safa-dec">{login.AnOnl}</p>
						<p className="m-0 w-100 Safa-dec">
{login.introducesAnOnl}
						</p>

						<div className='d-flex align-items-center mt-3 w-100 justify-content-between '>
								<img src={flight} alt="flight" srcset="" />
								<img src={toury} alt="toury" srcset="" />
								<img src={hotel} alt="hotel" srcset="" />
								<img src={passport} alt="passport" srcset="" />
								<img src={transfer} alt="transfer" srcset="" />
								<img src={bus} alt="bus" srcset="" />
						</div>
<div>

</div>
						</div>
					</figure>

				{/* Login Form */}
				<div className="col-md-5 col-sm-9 d-flex  flex-column  justify-content-between login-page-contant">
						<div className="d-flex hidden-mobile justify-content-between py-2 align-items-center w-100">
						<Link to="/" className="text-dark linkHeader linkHeaderActive">
								{newLand.home}
							</Link>
							<Link to="/about-us" className="text-dark linkHeader">
								{newLand.aboutUs}
							</Link>
							<Link to="/contact-us" className="text-dark linkHeader">
								{newLand.contactUs}
							</Link>
						
							<LanguageSwitcher />
						</div>


					<img src={safaLogo} alt="" className="safa_logo d-none" />

					<div className="w-100 login-page-data">
						<h1 className="text-title font-weight-bold">{login.welcomeBack}</h1>
						<p className="text-headline">{login.loginMessage}</p>

						{/* login form */}
						<div className="login-with-safa mt-4">
							{/* safa key */}
							<TextField
								label={login.safa_key}
								placeholder={login.safaPlaceholder}
								name="safa_key"
								id="safa_key"
								value={loginData.safa_key}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
										}),
									});
									setLoginData({
										...loginData,
										safa_key: e.target.value,
									});
								}}
								color={errors?.safa_key?.required ? "danger" : ""}
								errors={errors?.safa_key}
							/>

							{/* username */}
							<TextField
								placeholder={login.usernamePlaceholder}
								label={login.username}
								value={loginData.email}
								name="email"
								id="email"
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
											email: true,
										}),
									});
									setLoginData({
										...loginData,
										email: e.target.value,
									});
								}}
								color={
									errors?.email?.required || errors?.email?.email
										? "danger"
										: ""
								}
								errors={errors?.email}
							/>

							{/* password */}
							<PasswordField
								placeholder={login.passwordPlaceholder}
								hasLabel={true}
								label={login.password}
								name="safa_password"
								id="safa_password"
								value={loginData.password}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{ name: "password", value: e.target.value },
											{ required: true }
										),
									});
									setLoginData({
										...loginData,
										password: e.target.value,
									});
								}}
								color={errors?.password?.required ? "danger" : ""}
								errors={errors?.password}
							/>

							<button
								className="btn bg-nxt w-100 mt-3 d-flex align-items-center justify-content-center"
								onClick={submit}
								disabled={spin}
							>
								{spin ? (
									<Spinner color="light" size="sm" className="mx-1" />
								) : null}
								{login.loginTitle}
							</button>

							<Link
								to="/auth/login"
								className="btn btn-white-border w-100 mt-3"
							>
								{login.loginWihtEmail}
							</Link>
						</div>
					</div>

					<div></div>
				</div>
			</div>
		</div>
	);
}
