import React, { useEffect, useState } from "react";
import { fetchPackagesList } from "services/inventory";
import Locale from "translations";
import PackagesFilter from "./Filter";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import Arrow from "assets/images/icon-arrow-horizontal.svg";
import Pagination from "components/Pagination";
import moment from "moment";
import { useSBSState } from "context/global";
import { formatPrice } from 'helpers/utils';

export default function PackagesRequests() {
	const { locale } = useSBSState();
	const { backOffice, inventory, marketPlace } = Locale;
	const [packagesList, setPackagesList] = useState([]);
	const [meta, setMeta] = useState(null);
	const [reload, setReload] = useState(false);
	const [filters, setFilters] = useState({
		search: "",
		trip_type: "",
		departure_date: null,
		status: null,
	});

	function search() {
		setReload(!reload);
	}

	function goTo(page) {
		
		
		setFilters({ ...filters, page: page });
		search();
	}

	function handleChangeFilters(value, filterKey) {
		setFilters({ ...filters, page:1,[filterKey]: value });
	}

	function handleResetFilters() {
		setFilters({
			search: "",
			trip_type: "",
			created_at: "",
			page:1
		});
		search();
	}

	async function getPackagesList() {
		const packagesRes = await fetchPackagesList({
			departure_date: filters?.departure_date
				? moment(filters?.departure_date).format("YYYY-MM-DD")
				: "",
			product_type_id: filters.trip_type?.id,
			status: filters?.status?.title,
			search: filters?.search,
		},filters?.page);
		if (packagesRes?.status === 200) {
			setPackagesList(packagesRes?.data?.data);
			setMeta(packagesRes?.data?.meta);
		}
	}

	const statusLocale = (status) => {
		switch (status) {
			case "pending":
				return inventory.messages.pending;
			case "approved":
				return inventory.messages.approved;
			case "rejected":
				return inventory.messages.rejected;
			case "confirmed":
				return inventory.messages.confirmed;
			case "paid":
				return inventory.messages.paid;
			case "canceled":
				return inventory.messages.Canceled;
			case "expired":
				return inventory.messages.expired;
			default:
				return status;
		}
	};

	const statusColor = (status) => {
		switch (status) {
			case "pending":
				return "text-yellow";
			case "approved":
			case "confirmed":
			case "paid":
				return "text-success";
			case "rejected":
			case "canceled":
			case "expired":
				return "text-danger";
			default:
				return status;
		}
	};

	useEffect(() => {
		getPackagesList();
	}, [reload]);

	return (
		<div className="transfer-container bg-white">
			<div className="product-header-list p-4 inventory-header-totals">
				<h3 className="font-weight-bold text-capitalize">
					{inventory.messages.packages}
				</h3>
			</div>

			<PackagesFilter
				filters={filters}
				handleChangeFilters={handleChangeFilters}
				handleResetFilters={handleResetFilters}
				search={search}
			/>
			<div className="table-container">
				<Table
					striped
					className="table-update p-2 bg-white table table-striped"
				>
					<thead>
						<tr>
							<th>{inventory.messages.refNumber}</th>
							<th>{backOffice.companyName}</th>
							<th>{inventory.messages.tripType}</th>
							<th>{inventory.messages.airLine}</th>
							<th>{inventory.messages.departureDate}</th>
							<th>{marketPlace.Nights}</th>
							<th>{inventory.messages.pax}</th>
							<th>{inventory.messages.Amount}</th>
							<th>{inventory.messages.status}</th>
						</tr>
					</thead>
					<tbody>
						{packagesList.length > 0 ? (
							packagesList?.map((packageData) => {
								return (
									<tr key={packageData?.id}>
										<td>{packageData?.reservation_num}</td>
										<td>
											{packageData?.owner_company?.name?.[locale] ||
												packageData?.owner_company?.name?.en}
										</td>
										<td>{packageData?.trip_type}</td>
										<td>{packageData?.airlines?.[0]?.name?.[locale]}</td>
										<td>
											{moment(packageData?.departure_date).format("DD-MM-YYYY")}
										</td>
										<td>{packageData?.nights}</td>
										<td>{packageData?.pax}</td>
										<td>
											{formatPrice(packageData?.total_price)} {packageData?.currency}{" "}
										</td>
										<td>
											<p
												class={`text-white text-capitalize py-1 rounded text-center 
                            ${statusColor(packageData?.reservation_status)}`}
											>
												{statusLocale(packageData?.reservation_status)}
											</p>
										</td>
										<td>
											<Link
												to={`/inventory/packages/${packageData?.id}`}
												className="transfer-arrow"
											>
												<img src={Arrow} alt="arrow" />
											</Link>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="9">
									<div className="product-build__product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
						{packagesList.length > 0 ? (
							<Pagination info={meta} goTo={goTo} />
						) : null}
					</tbody>
				</Table>
			</div>
		</div>
	);
}
