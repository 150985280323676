import { useWebBuilderState } from 'context/webBuilder'
import React from 'react'
import CenteredFooter from './CenteredFooter'
import FooterTempTwo from './FooterTempTwo'
import { ReactComponent as FacebookIcon } from 'assets/images/webBuilder/Facebook.svg'
// import { ReactComponent as TwitterIcon } from 'assets/images/webBuilder/Twitter.svg'
import { ReactComponent as XIcon } from 'assets/images/webBuilder/XIcon.svg'
import { ReactComponent as YouTubeIcon } from 'assets/images/webBuilder/youtube.svg'
import { ReactComponent as LinkedInIcon } from 'assets/images/webBuilder/linkedin.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/webBuilder/instagram.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/webBuilder/snapchat-icon.svg'
import { ReactComponent as TiktokIcon } from 'assets/images/webBuilder/tiktok-icon.svg'



export default function Footer({ focusContainer, handleFocus }) {

	const { footer: footerDetails } = useWebBuilderState()
	const footer_position = footerDetails?.footerPosition;

	const socialMediaIcons = {
		facebook: <FacebookIcon />,
		twitter: <XIcon />,
		youtube: <YouTubeIcon />,
		instagram: <InstagramIcon />,
		linkedin: <LinkedInIcon />,
		snapchat: <SnapchatIcon />,
		tiktok: <TiktokIcon />
	}

	function renderFooter() {
		return {
			center: <CenteredFooter
				focusContainer={focusContainer}
				handleFocus={handleFocus}
				socialMediaIcons={socialMediaIcons}
			/>,
			
			between: <FooterTempTwo
				focusContainer={focusContainer}
				handleFocus={handleFocus}
				socialMediaIcons={socialMediaIcons}
			/>,
		};
	}

	return (
		<footer className='footer-container'>
			{renderFooter()?.[footer_position]}
		</footer>
	)
}
