import React, { useState } from "react";
import HeroHeader from "./Header";
import HeroContent from "./MainContent";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { ReactComponent as EditIcon } from "assets/images/webBuilder/edit-hero.svg";
import { ReactComponent as GalleryEditIcon } from "assets/images/webBuilder/gallery-edit.svg";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import NavagationBarModal from "./models/navagationBar";
import MainSectionModal from "./models/mainSection";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { useLocation } from "react-router-dom";
import ToolsModals from "./models/ToolsModals";
import Carousel from "react-multi-carousel";
import CustomRightArrow from 'modules/WebBuilder-V2/shared/CustomRightArrow';
import CustomLeftArrow from 'modules/WebBuilder-V2/shared/CustomLeftArrow';


function Hero() {
	const { hero,  } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const location = useLocation();
	const preview = !location.pathname.includes("edit");

	const updateHeroContent = ({ name, value, type }) => {
		dispatch({
			type: "updateHeroContent",
			payload: {
				name,
				value,
				type,
			},
		});
	};

	const updateSliderHero = ({ name, value, type }) => {
		dispatch({
			type: "updateSliderContent",
			payload: {
				name,
				value,
				type,
			},
		});
	};

	const updateHeroHeader = ({ name, value, type }) => {
		dispatch({
			type: "updateHeroHeader",
			payload: {
				name,
				value,
				type,
			},
		});
	};

	const handleHeaderLayout = ({ name, value }) => {
		updateHeroHeader({ type: "layout", name, value });
	};
	const handleContentLayout = ({ name, value }) => {
		updateHeroContent({ type: "layout", name, value });
	};
	const bgImage = hero?.mainContent?.backgroundImage ?? "";
	const sliders = hero?.mainContent?.sliders ?? [];
	const hasSlider = hero?.mainContent?.hasSlider;

	const contentLayout = hero?.mainContent?.layout?.layoutType;
	//modals
	const [navIsOpen, setNavIsOpen] = useState(false);
	const [mainSecIsOpen, setMainSecIsOpen] = useState(false);
	const [editHeader, setEditHeader] = useState(false);
	const [toolsIsOpen, setToolsIsOpen] = useState(false);

	const navToggle = () => setNavIsOpen(!navIsOpen);
	const mainToggle = () => setMainSecIsOpen(!mainSecIsOpen);
	const toolToggle = () => setToolsIsOpen(!toolsIsOpen);

	const headerActions = (
		<span className="pointer-event" onClick={navToggle}>
			<SettingIcon />
		</span>
	);

	const openEditHeader = (e) => {
		e.stopPropagation();
		setEditHeader(true);
	};

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};




	return (
		<>
			<div
				className={`web-builder-hero`}
				style={{
					...(contentLayout === 1 &&
						!hasSlider && {
						backgroundImage: `linear-gradient(#00000021 50%, #00000021), url(${bgImage?.src ? bgImage.src : bgImage})`,
					}),
				}}
				onClick={(e) => setEditHeader(false)}
			>


				{hasSlider ? (
					<Carousel
						responsive={responsive}
						itemClass={"web-builder-slide"}
						slidesToSlide={1}
						keyBoardControl={true}
						customRightArrow={<CustomRightArrow />}
						customLeftArrow={<CustomLeftArrow />}
						removeArrowOnDeviceType={["tablet", "mobile"]}
						renderButtonGroupOutside={false}
						showDots={true}
						infinite={true}
					>
						{sliders.map((slide) => {
							return <img src={slide.src || slide} key={slide.id || slide} alt="" />;
						})}
					</Carousel>
				) : null}
				<div className={`hero-content-container ${!preview ? "justify-content-between" : ""}`}>
					<EditBox editMode={editHeader} defaultIcon={headerActions}>
						<HeroHeader editHeader={openEditHeader} />
					</EditBox>

					<HeroContent />

					{!preview ? (
						<div className="update-hero-layout">
							<button
								className="hero-content-layout"
								style={{ borderRight: "1px solid #DDDCDC" }}
								// onClick={mainToggle}
								onClick={toolToggle}
							>
								<EditIcon />
							</button>
							<button className="hero-content-layout" onClick={toolToggle}>
								<GalleryEditIcon />
								<span className="mx-1">Change Image</span>

							</button>
						</div>
					) : null}

				</div>

				{navIsOpen && (
					<NavagationBarModal
						isOpen={navIsOpen}
						toggle={navToggle}
						handleLayout={handleHeaderLayout}
					/>
				)}
				{mainSecIsOpen && (
					<MainSectionModal
						isOpen={mainSecIsOpen}
						toggle={mainToggle}
						handleLayout={handleContentLayout}
					/>
				)}
				<ToolsModals
					isOpen={toolsIsOpen}
					toggle={toolToggle}
					updateHeroContent={updateHeroContent}
					updateSliderHero={updateSliderHero}
					hasSlider={hasSlider}
					slides={sliders}
				/>
			</div>
		</>
	);
}

export default Hero;
