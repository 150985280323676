import React, { useEffect, useState } from "react";
import moment from "moment";
import ArrivalDeparture from "./arrivalDeparture";
import BusWaitTime from "./busWaitTime";
import CheckoutHotel from "./checkoutHotel";
import Attractions from "./attractions";
import DurationBusHotel from "./durationBusHotel";
import MovementDuraion from "./movementDuraion";
import AttractionDuration from "./attractionDuration";
import { store } from "react-notifications-component";
import {
	addTimingSettings,
	fetchTimingSettings,
} from "services/operationStatement";
import BreadCrump from "../../shared/BreadCrump";
import { formatTimingSetting } from "modules/UmrahOperations/helper/formatTimingSetting";
import { SaveIcon } from "modules/UmrahOperations/shared/Icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";
import ShowForPermission from "helpers/showForPermission";
import ShowSubscription from 'helpers/ShowSubscription';

export default function TimingSettings() {
	const { operationStatement } = Locale;

	const [settingsState, setsettingsState] = useState({
		arrival_departure: [
			{
				city_id: 134,
				city_name: "Jeddah",
				port_type: 2,
				arrival: null,
				departure: null,
			},
			{
				city_id: 4,
				city_name: "Madinah",
				port_type: 2,
				arrival: null,
				departure: null,
			},
			{
				city_id: 224,
				city_name: "Yanbu",
				port_type: 2,
				arrival: null,
				departure: null,
			},
			{
				city_id: 134,
				city_name: "Jeddah",
				port_type: 3,
				arrival: null,
				departure: null,
			},
			{
				city_id: 224,
				city_name: "Yanbu",
				port_type: 3,
				arrival: null,
				departure: null,
			},
		],
		bus_wait_time: [
			{
				city_id: 3,
				city_name: "Makkah",
				time: null,
			},
			{
				city_id: 4,
				city_name: "Madinah",
				time: null,
			},
			{
				city_id: 134,
				city_name: "Jeddah",
				time: null,
			},
		],
		hotel_checkout: [
			{
				city_id: 3,
				city_name: "Makkah",
				time: null,
			},
			{
				city_id: 4,
				city_name: "Madinah",
				time: null,
			},
			{
				city_id: 134,
				city_name: "Jeddah",
				time: null,
			},
		],
		attractions: [
			{
				title: "attending_bus",
				from_makkah: null,
				from_jeddah: null,
				from_madinah: null,
			},
		],
		port_durations: [
			{
				port_type: 2,
				city_id: 134,
				city_name: "Jeddah",
				makkah_duration: null,
				madinah_duration: null,
				jeddah_duration: null,
				yanbu_duration: null,
			},
			{
				port_type: 2,
				city_id: 4,
				city_name: "Madinah",
				makkah_duration: null,
				madinah_duration: null,
				jeddah_duration: null,
				yanbu_duration: null,
			},
			{
				port_type: 2,
				city_id: 224,
				city_name: "Yanbu",
				makkah_duration: null,
				madinah_duration: null,
				jeddah_duration: null,
				yanbu_duration: null,
			},
			{
				port_type: 3,
				city_id: 134,
				city_name: "Jeddah",
				makkah_duration: null,
				madinah_duration: null,
				jeddah_duration: null,
				yanbu_duration: null,
			},
			{
				port_type: 3,
				city_id: 224,
				city_name: "Yanbu",
				makkah_duration: null,
				madinah_duration: null,
				jeddah_duration: null,
				yanbu_duration: null,
			},
		],
		city_durations: [
			{
				from_city_id: 3,
				from_city_name: "Makkah",
				to_city_id: 4,
				to_city_name: "Madinah",
				duration: null,
			},
			{
				from_city_id: 3,
				from_city_name: "Makkah",
				to_city_id: 134,
				to_city_name: "Jeddah",
				duration: null,
			},
			{
				from_city_id: 4,
				from_city_name: "Madinah",
				to_city_id: 134,
				to_city_name: "Jeddah",
				duration: null,
			},
		],
		attraction_durations: [
			{
				city_id: 3,
				city_name: "Makkah",
				duration: null,
			},
			{
				city_id: 4,
				city_name: "Madinah",
				duration: null,
			},
			{
				city_id: 134,
				city_name: "Jeddah",
				duration: null,
			},
		],
		test_data: [
			{
				city_id: 134,
				city_name: "Jeddah",
				port_type: 2,
				arrival: null,
				departure: null,
			},
			{
				city_id: 4,
				city_name: "Madinah",
				port_type: 2,
				arrival: null,
				departure: null,
			},
			{
				city_id: 224,
				city_name: "Yanbu",
				port_type: 2,
				arrival: null,
				departure: null,
			},
			{
				city_id: 134,
				city_name: "Jeddah",
				port_type: 3,
				arrival: null,
				departure: null,
			},
			{
				city_id: 224,
				city_name: "Yanbu",
				port_type: 3,
				arrival: null,
				departure: null,
			},
		],
	});
	const history = useHistory();
	const handleInputs = (value, arr_setting, port_type, city_id, type) => {
		debugger
		const clone = settingsState[arr_setting];
		const special_key = clone?.find((item) => {
			if (isNaN(port_type)) {
				return item.city_id === city_id && item.city_name == port_type;
			} else {
				return item.city_id === city_id && item.port_type == port_type;
			}
		});
		special_key[type] = value;
		setsettingsState({ ...settingsState, [arr_setting]: [...clone] });
		console.log("settingsState", settingsState.test_data);
	};
	async function getTiming() {
		const res = await fetchTimingSettings();
		if (res) {
			let data = await formatTimingSetting(res);
			setsettingsState(data);
		}
	}
	useEffect(() => {
		getTiming();
		return () => { };
	}, []);
	const addTiming = async () => {
		debugger
		const data = {
			arrival_departure: settingsState.arrival_departure.map((item) => {
				return {
					...item,
					arrival: item.arrival ? moment(item.arrival).format("HH:mm") : null,
					departure: item.departure
						? moment(item.departure).format("HH:mm")
						: null,
				};
			}),
			bus_wait_time: settingsState.bus_wait_time.map((item) => {
				return {
					...item,
					time: item.time ? moment(item.time).format("HH:mm") : null,
				};
			}),
			hotel_checkout: settingsState.hotel_checkout.map((item) => {
				return {
					...item,
					time: item.time
						? moment(item.time).utc(true).toISOString().split("T")[1]
						: null,
				};
			}),
			attractions: settingsState.attractions.map((item) => {
				return {
					...item,
					from_makkah: item.from_makkah
						? moment(item.from_makkah).format("HH:mm")
						: null,
					from_jeddah: item.from_jeddah
						? moment(item.from_jeddah).format("HH:mm")
						: null,
					from_madinah: item.from_madinah
						? moment(item.from_madinah).format("HH:mm")
						: null,
				};
			}),
			port_durations: settingsState.port_durations.map((item) => {
				return {
					...item,
					makkah_duration: item.makkah_duration
						? moment(item.makkah_duration).format("HH:mm")
						: null,
					madinah_duration: item.madinah_duration
						? moment(item.madinah_duration).format("HH:mm")
						: null,
					jeddah_duration: item.jeddah_duration
						? moment(item.jeddah_duration).format("HH:mm")
						: null,
					yanbu_duration: item.yanbu_duration
						? moment(item.yanbu_duration).format("HH:mm")
						: null,
				};
			}),
			city_durations: settingsState.city_durations.map((item) => {
				return {
					...item,
					duration: item.duration
						? moment(item.duration).format("HH:mm")
						: null,
				};
			}),
			attraction_durations: settingsState.attraction_durations.map((item) => {
				return {
					...item,
					duration: item.duration
						? moment(item.duration).format("HH:mm")
						: null,
				};
			}),
		};
		const res = await addTimingSettings(data);
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message ?? "Update Timing Settings Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push("/umrah-operations/operation-statement");
				},
			});
		}
	};

	//*****Start Cutsom Time */
	// let timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
	// const [time, setTime] = useState("");

	// if (timeRegex.test(time)) {
	// 	let [hours, minutes] = time.split(":");
	// 	console.log("Hours:", hours);
	// 	console.log("Minutes:", minutes);
	// }

	// const handleTimeChange = (event) => {
	// 	let value = event.target.value
	// 	//.padStart(2, "0");

	// 	if (value.length > 2 && value[0] === "0") {
	// 		value = value.slice(1);
	// 	}

	// 	if (+value > 23) {
	// 		value = "00";
	// 	}
	// 	if (value.length == 2 && typeof +value == "number") {
	// 		value = value + ":";
	// 	}
	// 	if (value.length > 2) {
	// 		// let value1 = value.slice(3);
	// 		// console.log(value1,"sqsq	sq");
	// 		value = value.slice(1);

	// 		if (+value > 59) {
	// 			value = "59";
	// 		}
	// 	}

	// 	setTime(value);
	// };

	//*****End Cutsom Time */
	return (
		<ShowSubscription module="operation_setting">
			<div className="operations-settings">
				<BreadCrump title={operationStatement.TimingSettings}></BreadCrump>

				{/* <input
				type="text"
				id="timeInput"
				step="1"
				value={time}
				onChange={handleTimeChange}
			/> */}

				<div className="px-3">
					<ArrivalDeparture state={settingsState} handleInputs={handleInputs} />
					<div className="d-flex " style={{ gap: "25px" }}>
						<BusWaitTime state={settingsState} handleInputs={handleInputs} />
						<CheckoutHotel state={settingsState} handleInputs={handleInputs} />
					</div>
					<Attractions
						state={settingsState}
						setsettingsState={setsettingsState}
					/>
					<DurationBusHotel state={settingsState} handleInputs={handleInputs} />

					<div className="d-flex" style={{ gap: "25px" }}>
						<MovementDuraion
							state={settingsState}
							setsettingsState={setsettingsState}
						/>
						<AttractionDuration
							state={settingsState}
							handleInputs={handleInputs}
						/>
					</div>

					{/* <Test state={settingsState} handleInputs={handleInputs} /> */}
				</div>
				<div className="action-btn px-3">
					<ShowForPermission permission="Manage-operation-statement">
						<button className="btn btn-yellow" onClick={addTiming}>
							<SaveIcon />
							{operationStatement.SaveChanges}
						</button>
					</ShowForPermission>
				</div>
			</div>
		</ShowSubscription>
	);
}
