import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Locale from "translations";

const SelectField = (props) => {
	const [isOpened, setIsOpened] = useState(false);
	const [isFoucsed, setIsFoucsed] = useState(false);
	const isMobile = window.innerWidth < 550 ? true : false;

	const selectRef = useRef(null);
	const { commons } = Locale;

	const customStyles = {
		singleValue: (defaultStyles) => {
			return {
				...defaultStyles,
				marginLeft: "12px",
				marginTop: "2px",
				marginRight: "12px",
				fontSize: "15px",
			};
		},

		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				marginLeft: "12px",
				marginRight: "12px",
				fontSize:"15px",
				color: props.isPlaceholderDark ? "#000" : "",
				fontWeight: props.isPlaceholderBold ? "800" : "",
			};
		},
	};
	const handleClickOutside = (e) => {
		if (selectRef.current && !selectRef.current.contains(e.target))
			setIsOpened(false);
		else setIsOpened(true);
	};

	useEffect(() => {
		window.addEventListener("click", handleClickOutside);
		return () => window.removeEventListener("click", handleClickOutside);
	}, []);

	if (props.extruded) {
		if (props.options) {
			props.options.forEach((element) => (element.disabled = false));
			props.options.forEach((element) => {
				props.extruded.forEach((ele) => {
					if (ele.value === element.id) {
						element.disabled = true;
					}
				});
			});
		}
	}

	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(` should be at least ${props.min} digits`);
				} else if (k === "email" && v) {
					message.push(`should be valid`);
				} else if (k === "serverError" && v) {
					if (Array.isArray(v)) {
						v.forEach((i) => message.push(i));
					} else {
						message.push(v);
					}
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};
	const image = props.isImage ? (
		<img src={props.image} alt="Icon " className="" width={25} height={25} />
	) : (
		""
	);
	return (
		<div className={`${props.class || ""}`}>
			<div>
				{/* Text Field Body */}

				<div
					className={`control-field__body p-0 `}
					onClick={() => setIsOpened(!isOpened)}
					ref={selectRef}
				>
					{/* Label */}
					{props.hasLabel ? (
						<label
							className={`control-field__label ${
								props.color ? " control-field__label--" + props.color : ""
							} ${isFoucsed && !props.color ? "custom-select-text" : ""}`}
						>
							{props.label}{props.withStar?<span style={{color:"red"}}>*</span>:null}
						</label>
					) : null}
					{/* Text Input */}
					<div
						className={`row no-gutters control-field p-2  my-1 align-item-center ${
							props.color ? " control-field--" + props.color : ""
						}
						${isFoucsed && !props.color ? "custom-control-field" : ""}
						 ${props.disabled ? "isDisabled" : ""}
						 ${props.labelInner ? "control-field-inner innerPadding" : ""}
						 ${props.isImage && props.prependImage?"d-flex":""}

						 `}
					>
						{/* Label */}
						{props.labelInner && (
							<span className="font-weight-bolder">{props.label}</span>
						)}
						{props.prependImage ? image :null }
						<Select
							className={`control-field__select 
							${props.isImage && props.prependImage?"w-89":""}
							`}
							id={props.id}
							value={
								props.room
									? props.value && !props.multi
										? { label: props.value }
										: props.placeholder
									: props.value && !props.multi
									? props.value === ""
										? null
										: { label: props.value }
									: props.placeholder === undefined
									? null
									: props.placeholder
							}
							// getOptionLabel={(option) => option.name}
							// getOptionValue={(option) => option.id}
							styles={
								props.styles
									? { ...customStyles, ...props.styles }
									: { ...customStyles }
							}
							name={props.name}
							isMulti={props.multi}
							isSearchable={props.searchable}
							isDisabled={props.disabled}
							placeholder={
								props.placeholder ? props.placeholder : commons.select
							}
							onChange={props.onChange}
							onClick={props.onClick}
							options={
								props.options && props.options.length > 0
									? props.options.map((option) => ({
											...option,
											value: option.id,
											label: option.name,
											Disabled: option.disabled,
									  }))
									: []
							}
							isClearable={false}
							onFocus={() => {
								setIsFoucsed(true);
							}}
							onBlur={(e) => {
								if (!isMobile) {
									setIsFoucsed(false);
									props.onBlur && props.onBlur(e);
								}
							}}
							isOptionDisabled={(option) => option.Disabled}
							defaultValue={props.defaultValue}
							hasLabel={props.hasLabel}
						/>
					</div>
					{/* Feedback */}
					<small
						className={`control-field__feedback control-field__feedback--${
							props.color
						} ${props.isInvalid ? "d-block" : "d-none"}`}
					>
						{props.feedbackMessage}
					</small>
					<small
						className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
					>
						{generateErrorsMsgs(props.errors)}
					</small>
				</div>
			</div>
		</div>
	);
};
SelectField.defaultProps = {
	hasLabel: true,
};

SelectField.defaultProps = {
	hasLabel: true,
};

SelectField.defaultProps = {
	hasLabel: true,
};

export default SelectField;
