import Ham from "assets/images/dashboard/ham.svg";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import SideNav from "components/SideNav";
import UserSettings from "components/UserSettings";
import { useSBSDispatch, useSBSState } from "context/global";
import { useEffect, useState } from "react";
import { getPermissions, getSubscripedModules } from "services/auth";
import { NotifactionHeader } from "./notifactionHeader";
import WalletHeader from "./walletHeader";
import safaLogoMinimal from "assets/images/dashboard/safaLogoSmall.svg";
import ShowForPermission from "helpers/showForPermission";
import CurrencySwitcher from "./CurrencySwitcher";
// import rightOutline from "assets/images/arrow-left-right-outline.png";
// import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Locale from "translations";
import globalSrc from "assets/images/market-place/servicesIcon/new-global.png";
import umrahSrc from "assets/images/market-place/servicesIcon/new-umrah.png";
import { ReactComponent as SwitchServicesIcon } from "assets/images/market-place/switch-services-icon.svg";
import { ReactComponent as MonitorIcon } from "assets/images/webBuilder/monitor-mobbile.svg";
import { ReactComponent as UpgradeSubscription } from "assets/images/upgrade-subscription.svg";

import hotelIcon from "assets/images/market-place/servicesIcon/hotelIcon.png";
import packagesIcon from "assets/images/market-place/servicesIcon/packagesIcon.png";
// import mosqueIcon from "assets/images/market-place/servicesIcon/mazaratIcon.png";
import toursIcon from "assets/images/market-place/servicesIcon/tour-global.png";
import visaIcon from "assets/images/market-place/servicesIcon/visaIcon.png";
import flightIcon from "assets/images/market-place/servicesIcon/fligtIcon.png";
// import busIcon from "assets/images/market-place/servicesIcon/umrah-transfer.png";
import busGlogalIcon from "assets/images/market-place/servicesIcon/global-transfer.png";
import LanguageSwitcherMobile from "components/LanguageSwitcher/LanguageSwitcherMobile";
import UserSettingsMobile from "components/UserSettingsMobile";
import WalletHeaderMobile from "./walletHeaderMobile";
import CurrencySwitcherMobile from "./CurrencySwitcherMobile";
import { NotifactionHeaderMobile } from "./notifactionHeaderMobile";
import { fetchsubscriptionActivePlan } from 'services/subscription';
import moment from 'moment';

export default function Header() {
	const location = useLocation();
	const { marketPlace, inventory, subscriptionModule } = Locale;

	const search = window.location.search;
	const name = new URLSearchParams(search);
	const dispatch = useSBSDispatch();
	const [show, setShow] = useState(false);
	const [showBack, setShowBack] = useState(false);
	const [activePlan, setActivePlan] = useState(null);
	// const history = useHistory();
	const isUmrahService = location.pathname.includes("market-place-new/umrah");
	const isGlobalService = location.pathname.includes("market-place-new/global");
	const isSpeicalAllotment = location.pathname.includes("special-allotment");

	const showToggle = () => {
		setShow(!show);
	};
	// console.log(history, location);
	const serviceType = localStorage.getItem("market-palce-service") ?? "global";
	function switchBetweenUmrahAndGlobal(serviceUrl) {
		let url = location.pathname;
		if (isUmrahService && isSpeicalAllotment) {
			url = location.pathname.replace(
				"umrah/special-allotment",
				"global/hotel"
			);
		} else if (isGlobalService) {
			url = location.pathname.replace("global", "umrah");
		} else if (isUmrahService) {
			url = location.pathname.replace("umrah", "global");
		}
		return url;
	}

	// const showToggleOpen = () => {
	// 	setShow(true);
	// };

	const { isAuth, token_data, token, companyInfo, subscriptionPlan } = useSBSState();
	const isServiceCompnay = companyInfo?.company_category === "services_company"
	const OPEND_SIDE_NAV_WIDTH = "260px";
	const CLOSED_SIDE_NAV_WIDTH = "70px";
	const [width, setWidth] = useState(window.innerWidth);
	const hasWebBuilderSubscription = subscriptionPlan?.includes("website_builder");

	const showNav = {
		position: "sticky",
		width: "100%",
		top: "0",
		borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
	};
	const showItem = {
		borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
	};
	const hamBtnStyle = {
		margin: `${width > 600 ? "-20px" : "0"} ${width > 600 ? show ? OPEND_SIDE_NAV_WIDTH : CLOSED_SIDE_NAV_WIDTH : ""}`,
		position: "absolute",
		border: 0,
		backgroundColor: "transparent",
		transition: ".7s",
		// width: "290px",
		display: 'inline-flex',
		justifyContent: 'center',
	};

	useEffect(() => {
		async function fetchPermissions() {
			const res = await getPermissions();

			dispatch({ type: "getPermissions", payload: res?.data?.data });
		}
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}

		if (x.hasOwnProperty("token") && token != null) {
			fetchPermissions();
		} else if (!x.hasOwnProperty("token")) {
			fetchPermissions();
		}
	}, []);

	useEffect(() => {
		async function fetchSubsciptionPlan() {
			const res = await getSubscripedModules();
			if (res?.status === 200) {
				dispatch({ type: "subscriptionPlan", payload: res?.data?.data });
			}
		}
		fetchSubsciptionPlan();
	}, []);

	useEffect(() => {
		async function getActivePlan() {
			let res = await fetchsubscriptionActivePlan();
			if (res?.status === 200 && res?.data?.data?.is_free_trial) {
				setActivePlan(res?.data?.data);
			}
		}
		if (!activePlan && companyInfo?.company_category === "umrah_company") {
			getActivePlan();
		}
	}, [activePlan, companyInfo?.company_category])


	useEffect(() => {
		const getData = async () => {
			const response = await fetch("https://ipapi.co/json/");
			const data = await response.json();
			localStorage.setItem("IPv4", data.ip);
		};
		getData();
	}, []);

	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	const packageService =
		window.location.pathname.includes("/city-packages") ||
		window.location.pathname.includes("/market-package/package-details");
	const flightService = window.location.pathname.includes("/outbound-view");
	const toursService = window.location.pathname.includes("/tours-result");
	const visaService = window.location.pathname.includes(
		"/market-search/visa-details"
	);
	const transferService = location.pathname === "/transfer";
	const hotelService = window.location.pathname.includes("/market-view");

	const serviceIsActive =
		packageService ||
		flightService ||
		toursService ||
		visaService ||
		hotelService ||
		transferService;

	const activeService = hotelService
		? "hotels"
		: transferService
			? "transfer"
			: visaService
				? "visa"
				: toursService
					? "tours"
					: flightService
						? "flights"
						: "packages";

	const buttonDetails = {
		hotels: {
			name: marketPlace.hotels,
			icon: hotelIcon,
			url: (serviceType) => `/market-place-new/${serviceType}/hotel`,
		},
		visa: {
			name: marketPlace.onlineVisa,
			icon: visaIcon,
			url: (serviceType) => `/market-place-new/${serviceType}/visa`,
		},
		transfer: {
			name: marketPlace.transfer,
			icon: busGlogalIcon,
			url: (serviceType) => `/market-place-new/${serviceType}/transfer`,
		},
		tours: {
			name: marketPlace.tours,
			icon: toursIcon,
			url: (serviceType) =>
				`/market-place-new/${serviceType}/${serviceType === "umrah" ? "mazarat" : "tours"
				}`,
		},
		flights: {
			name: marketPlace.flights,
			icon: flightIcon,
			url: (serviceType) => `/market-place-new/${serviceType}/flight`,
		},
		packages: {
			name: marketPlace.packages,
			icon: packagesIcon,
			url: (serviceType) => `/market-place-new/${serviceType}/packages`,
		},
	};
	useEffect(() => {
		setShowBack(
			// window.location.pathname.includes("market-search-new/umrah") ||
			// window.location.pathname.includes("market-search-new/global") ||
			!window.location.pathname.includes("umrah/packages") &&
			window.location.pathname.includes("market-place-new/umrah") ||
			window.location.pathname.includes("market-place-new/global") ||
			/////
			serviceIsActive
		);
	}, [location]);

	return (
		<>
			{/* {!show ? <TechnicalSupport /> : null} */}
			<div
				className="navbar bg-white  mb-5"
				style={show ? showNav : showItem}
			>
				<div className="d-flex position-absolute">


					<div style={hamBtnStyle}>
						<button
							type="button"
							className="side-nav-button mx-1 bg-transparent border-0"
							onClick={showToggle}
						>
							{!show ? (
								<img src={Ham} alt="" />
							) : (
								<i
									class="fa fa-times"
									aria-hidden="true"
									style={{ color: "#D29D4D", fontSize: "18px" }}
								></i>
							)}
						</button>
						{/* umrahSrc */}
						{showBack && !activePlan ? (
							<Link
								to={
									serviceIsActive
										? buttonDetails[activeService]?.url(serviceType)
										: switchBetweenUmrahAndGlobal()
								}
								onClick={() => {
									if (!serviceIsActive) {
										localStorage.setItem(
											"market-palce-service",
											isGlobalService ? "umrah" : "global"
										);
									}
								}}
								className="btn switch-services-btn shadow-none mx-2"
							>
								<img
									width={serviceIsActive ? "24px" : "38px"}
									height={serviceIsActive ? "24px" : "26px"}
									className="mx-1 rounded-lg"
									src={
										serviceIsActive
											? buttonDetails[activeService]?.icon
											: isUmrahService
												? globalSrc
												: umrahSrc
									}
									alt=""
								/>
								{serviceIsActive
									? buttonDetails[activeService]?.name
									: isUmrahService
										? inventory.globalServices
										: inventory.umrahServices}
								<span className="switch-services-icon">
									<SwitchServicesIcon />
								</span>
							</Link>
						) : null}
						{activePlan && activePlan?.target !== "default" &&
							<div className="umrah-trail-plan mx-2">
								<div className="d-flex flex-column">
									<span className="font-weight-bold" style={{ lineHeight: "18px" }}>{subscriptionModule.bravoTrialPeriod}</span>
									<p>
										<span className="expire_at">
											{subscriptionModule.expiresOn} {activePlan.end_date ? moment(activePlan.end_date).format("DD MMM Y") : ""} 
										</span>
										<span className="remaining_days"> ({moment(activePlan.end_date).diff(moment(), "days")} {subscriptionModule.daysLeft})</span>
									</p>
								</div>
								<Link to="/subscription/packages-plans" className="btb bg-nxt px-2 py-2 d-flex align-items-center gap-10 rounded-lg">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M16.7 18.9801H7.29995C6.87995 18.9801 6.40995 18.6501 6.26995 18.2501L2.12995 6.67011C1.53996 5.01011 2.22996 4.50011 3.64996 5.52011L7.54995 8.31011C8.19995 8.76011 8.93995 8.53011 9.21995 7.80011L10.98 3.11011C11.54 1.61011 12.47 1.61011 13.03 3.11011L14.79 7.80011C15.07 8.53011 15.81 8.76011 16.45 8.31011L20.11 5.70011C21.67 4.58011 22.42 5.15011 21.78 6.96011L17.74 18.2701C17.59 18.6501 17.12 18.9801 16.7 18.9801Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M6.5 22H17.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M9.5 14H14.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>

									{subscriptionModule.upgrade}
								</Link>
							</div>
						}
					</div>

					<span className={`navbar-brand-icon navbar-brand-icon-mobile`} style={{ margin: "0px 60px" }}>
						<img src={safaLogoMinimal} alt="Logo" />
					</span>

				</div>

				<div className="container-fluid">
					{/* <Link

						className="navbar-brand"
						onClick={() => {
							show && setShow(false);
							history.push("/market-search");
						}}
					// to="/"
					>
						<img className="img-fluid" src={SafaLogo} alt="Logo" width="70%" />
					</Link> */}

					<div>
						{/* {pathname !== "/auth/register" && isAuth ? (
							<div className="nav-item main-menu d-flex align-items-center">
								<div
									className="nav-link py-1 px-3 bg-nxt rounded-bottom d-flex custom-main pointer "
									// onMouseEnter={showToggleOpen}
									onClick={showToggle}
								>
									{!show ? (
										<img className="menu-img" src={MainMenu} alt="main menu" />
									) : (
										<i
											className={`fas fa-times pointer  mt-1 `}
											aria-hidden="true"
											onClick={() => setShow(!show)}
										></i>
									)}
									<span className="text-headline text-center w-100 ">
										{!show
											? dashboard.messages.mainMenu
											: dashboard.messages.closemainMenu}
									</span>
								</div>
							</div>
						) : null} */}
					</div>

					{width >= 320 && width <= 600 ? (
						<div
							className="dropdowns d-flex align-items-center"
							style={{ gap: "5px" }}
						>
							{ }
							{!isServiceCompnay &&
								<CurrencySwitcherMobile className="bg-white" />
							}

							{!search.includes("token") ? (
								<>
									{!isServiceCompnay &&
										<ShowForPermission
											permission={["View-Wallets", "Manage-Wallets"]}
										>
											<WalletHeaderMobile />
										</ShowForPermission>
									}
									<NotifactionHeaderMobile />
								</>
							) : token_data && token_data.discount != null ? (
								<p style={{ color: "#D92626" }} className="mx-2">
									<strong style={{ fontWeight: "700" }}>
										{token_data.discount}%
									</strong>{" "}
									OFF
								</p>
							) : (
								""
							)}

							<LanguageSwitcherMobile show={false} />
							{isAuth ? <UserSettingsMobile /> : null}
						</div>
					) : (
						<div className="dropdowns d-flex align-items-center">
							{companyInfo?.share_public && !isServiceCompnay && (!location.pathname.includes("/edit") || !location.pathname.includes("/edit")) ?
								<div className={`btn-light bg-white pointer lang-btn d-flex align-items-center gap-10 mx-2  ${location.pathname.includes("web-builder/preview") || location.pathname.includes("web-builder-v1/preview") || location.pathname.includes("web-builder-v2/preview") || location.pathname.includes("web/package/") ? "btn-preview" : ""}`} style={{ padding: "10px" }}>
									<MonitorIcon />
									<Link to={
										location.pathname.includes("web-builder/preview") ||
											location.pathname.includes("web-builder-v1/preview") ||
											location.pathname.includes("web-builder-v2/preview") ?
											location.pathname.includes("web-builder-v1/preview") ? "/web-builder-v1/edit" : "/web-builder-v2/edit" :
											"/web-builder/select"
									}
										className="text-dark header-webbuilder-btn"
									>
										{location.pathname.includes("web-builder/preview") || location.pathname.includes("web-builder-v1/preview") || location.pathname.includes("web-builder-v2/preview") ? subscriptionModule.BackToEditMode : subscriptionModule.WebsiteBuilder}
										{!hasWebBuilderSubscription ?
											<div className="upgrade">
												<UpgradeSubscription />
											</div>
											:
											null
										}
									</Link>
								</div>
								: null
							}
							<LanguageSwitcher show={show} />

							{!isServiceCompnay &&
								<CurrencySwitcher className="bg-white" />
							}

							{!search.includes("token") ? (
								<>
									{!isServiceCompnay &&
										<ShowForPermission
											permission={["View-Wallets", "Manage-Wallets"]}
										>
											<WalletHeader />
										</ShowForPermission>
									}
									{width >= 768 ? <NotifactionHeader /> : null}{" "}
								</>
							) : token_data && token_data.discount != null ? (
								<p style={{ color: "#D92626" }} className="mx-2">
									<strong style={{ fontWeight: "700" }}>
										{token_data.discount}%
									</strong>{" "}
									OFF
								</p>
							) : (
								""
							)}

							{isAuth ? <UserSettings /> : null}
						</div>
					)}
				</div>
			</div>
			<SideNav show={show} setShow={setShow} />
			{/* <Dashboard show={show} setShow={setShow} /> */}
		</>
	);
}
