import React from 'react'
import { ReactComponent as ArrowIcon } from 'assets/images/webBuilder/chevron-right.svg'
import { useSBSState } from 'context/global';

function PublicPageHeader({ service, packageData }) {
  const { locale } = useSBSState();

  return (
    <div className='public-page-header'>
      {/* <h1 className='page-name'>{packageData?.name?.[locale]}</h1> */}
      <div className='bread-crumb'>
        <span className='text-capitalize'>Home</span>
        <ArrowIcon />
        <span>{packageData?.name?.[locale]}</span>
      </div>
    </div>
  )
}

export default PublicPageHeader