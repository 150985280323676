import React, { useEffect } from "react";
import Filters from './Filters';
import SpecialData from './SpecialData';
import { useState } from 'react';
import { getSpecialAllotment, getSpecialAllotmentWithFilter } from 'services/marketplace';
import { useMarketplaceState } from 'context/marketplace';
import { useSBSState } from 'context/global';
import moment from 'moment';
export default function SpecialAllotment() {
  const [filter, setFilter] = useState({
    "name": null,
    "rating": null,
    "is_refundable": null,
    "facilities": [],
    "price":{
        "min": null,
        "max": null
    }
  })
  const { companyInfo} = useSBSState();

  const [listSpecial, setListSpecial] = useState([])
  const [lookup, setLookup] = useState({})
	const { locale } = useSBSState();
	const { filterHotels } = useMarketplaceState();

useEffect(() => {
  if (filterHotels) {
    fetchData({
			city_code: filterHotels?.city?.value,
			date_from: filterHotels?.date_from,
			date_to: filterHotels?.date_to,
			nights:  filterHotels?.nights,
			nationality: companyInfo?.country?.country_code,
      hotel_id:filterHotels?.hotel?.value ?filterHotels?.hotel?.value.toString(): null
		})
    setFilter(  {
      "name": null,
      "rating": null,
      "is_refundable": null,
      "facilities": [],
      "price":{
          "min": null,
          "max": null
      }
    })
  }
}, [filterHotels])


async function fetchData(e) {
	const listSpecialAllotment = await getSpecialAllotment(e);
	if (listSpecialAllotment.status === 200) {
		setListSpecial(listSpecialAllotment.data.hotels);
		setLookup(listSpecialAllotment.data.filters);
	}
}
  useEffect(() => {

    let startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
		let endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
		fetchData({
			city_code: "3",
			date_from: startOfMonth,
			date_to: endOfMonth,
			nights: null,
			nationality: companyInfo?.country?.country_code,
		});
	}, []);


  useEffect(() => {
    async function fetchData() {
    const res= await getSpecialAllotmentWithFilter({...filter  })
    if (res.status===200) {
      
      setListSpecial(res.data.hotels);
    }
    }
    
    if (lookup&&Object.keys(lookup).length>0) {
      fetchData()
    }

  }, [JSON.stringify(filter)])
  


	return (
		<div className="special-allotment">
			<div className="container-fluid">
          <div className='row'>
          <div className='col-md-4' >
          <Filters lookup={lookup} listSpecial={listSpecial} setFilter={setFilter} filter={filter}/>
          </div>
          <div className='col-md-8'>
          {listSpecial&&listSpecial.length> 0?   <SpecialData setFilter={setFilter} filter={filter} setListSpecial={setListSpecial} listSpecial={listSpecial} /> : <>
       <div className='w-100 mt-5' >
       <svg width="111" height="110" style={{display:"block",margin:"auto"}} viewBox="0 0 111 110" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M65.7051 83.0391C44.3281 83.0391 27.0332 65.7441 27.0332 44.3672C27.0332 22.9902 44.3281 5.69531 65.7051 5.69531C87.082 5.69531 104.377 22.9902 104.377 44.3672C104.377 65.7227 87.082 83.0391 65.7051 83.0391ZM65.7051 9.99219C46.7129 9.99219 31.3301 25.375 31.3301 44.3672C31.3301 63.3594 46.7129 78.7422 65.7051 78.7422C84.6758 78.7422 100.08 63.3594 100.08 44.3672C100.08 25.375 84.6973 9.99219 65.7051 9.99219Z" fill="#A5A3AE"/>
          <path d="M65.7051 73.6934C39.752 73.6934 26.4531 42.1328 44.9727 23.6348C56.4023 12.2051 75.0293 12.2051 86.459 23.6348C92.002 29.1777 95.0527 36.5469 95.0527 44.3672C95.0312 60.6523 81.8398 73.6934 65.7051 73.6934ZM48.002 26.6641C38.248 36.418 38.248 52.3164 48.002 62.0703C57.7559 71.8242 73.6543 71.8242 83.4082 62.0703C93.1836 52.2949 93.1836 36.4395 83.4082 26.6641C73.6328 16.9102 57.7559 16.9102 48.002 26.6641ZM32.2754 83.877L26.1953 77.7969C22.8438 74.4453 22.8438 69.0098 26.1953 65.6367C29.5469 62.2852 35.0039 62.2852 38.3555 65.6367L44.4355 71.7168C47.7871 75.0684 47.7871 80.5039 44.4355 83.877C41.0625 87.2285 35.627 87.2285 32.2754 83.877ZM29.2461 68.6875C27.5703 70.3633 27.5703 73.0918 29.2461 74.7676L35.3262 80.8476C37.002 82.5234 39.7305 82.5234 41.4062 80.8476C43.082 79.1719 43.082 76.4434 41.4062 74.7676L35.3047 68.6875C33.6504 67.0117 30.9219 67.0117 29.2461 68.6875Z" fill="#A5A3AE"/>
          <path d="M7.97665 102.094C3.0997 97.2171 3.95907 89.0745 9.75986 85.3362L26.5606 74.4652C27.42 73.9066 28.5372 74.0355 29.2462 74.7444L35.3263 80.8245C36.0352 81.5335 36.1642 82.6507 35.6056 83.5101L24.7345 100.332C22.9513 103.082 20.0509 104.866 16.7852 105.209C13.5411 105.553 10.3184 104.457 7.97665 102.094ZM27.42 79.0198L12.0802 88.9456C8.57822 91.2015 8.06259 96.1214 11.0059 99.0648C13.9493 102.008 18.8477 101.492 21.1251 97.9905L31.0509 82.6507L27.42 79.0198Z" fill="#A5A3AE"/>
          <path d="M10.168 32.2266C11.9478 32.2266 13.3906 30.7837 13.3906 29.0039C13.3906 27.2241 11.9478 25.7812 10.168 25.7812C8.38814 25.7812 6.94531 27.2241 6.94531 29.0039C6.94531 30.7837 8.38814 32.2266 10.168 32.2266Z" fill="#EDD7B2"/>
          <path d="M63.8789 98.8281C65.6587 98.8281 67.1016 97.3853 67.1016 95.6055C67.1016 93.8256 65.6587 92.3828 63.8789 92.3828C62.0991 92.3828 60.6562 93.8256 60.6562 95.6055C60.6562 97.3853 62.0991 98.8281 63.8789 98.8281Z" fill="#EDD7B2"/>
          <path d="M89.6602 8.59375C91.44 8.59375 92.8828 7.15092 92.8828 5.37109C92.8828 3.59127 91.44 2.14844 89.6602 2.14844C87.8803 2.14844 86.4375 3.59127 86.4375 5.37109C86.4375 7.15092 87.8803 8.59375 89.6602 8.59375Z" fill="#EDD7B2"/>
          <path d="M101.477 19.3359C102.663 19.3359 103.625 18.374 103.625 17.1875C103.625 16.001 102.663 15.0391 101.477 15.0391C100.29 15.0391 99.3281 16.001 99.3281 17.1875C99.3281 18.374 100.29 19.3359 101.477 19.3359Z" fill="#EDD7B2"/>
          <path d="M101.477 96.6797C102.663 96.6797 103.625 95.7178 103.625 94.5312C103.625 93.3447 102.663 92.3828 101.477 92.3828C100.29 92.3828 99.3281 93.3447 99.3281 94.5312C99.3281 95.7178 100.29 96.6797 101.477 96.6797Z" fill="#EDD7B2"/>
          <path d="M24.1328 8.59375C25.3194 8.59375 26.2812 7.63186 26.2812 6.44531C26.2812 5.25876 25.3194 4.29688 24.1328 4.29688C22.9463 4.29688 21.9844 5.25876 21.9844 6.44531C21.9844 7.63186 22.9463 8.59375 24.1328 8.59375Z" fill="#EDD7B2"/>
          <path d="M13.3906 55.8594C14.5772 55.8594 15.5391 54.8975 15.5391 53.7109C15.5391 52.5244 14.5772 51.5625 13.3906 51.5625C12.2041 51.5625 11.2422 52.5244 11.2422 53.7109C11.2422 54.8975 12.2041 55.8594 13.3906 55.8594Z" fill="#EDD7B2"/>
          <path d="M52.6855 54.3342L76.3184 30.7014C78.3379 28.7034 81.3672 31.7327 79.3477 33.7307L55.7148 57.3635C53.7168 59.3831 50.6875 56.3538 52.6855 54.3342Z" fill="#EDD7B2"/>
          <path d="M76.3184 57.384L52.6855 33.7512C50.6875 31.7532 53.7168 28.7024 55.7148 30.7219L79.3477 54.3547C81.3887 56.3743 78.3164 59.3606 76.3184 57.384Z" fill="#EDD7B2"/>
        </svg>
       </div>

      { locale==="en"? <p className='text-center font-weight-bolder h3 mt-5'>No special offers available</p>:<p className='text-center font-weight-bolder h3 mt-5'>لا توجد عروض متاحة</p>}
          </>} 
          </div>
        </div>
      </div>
		</div>
	);
}
