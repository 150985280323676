import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import NumberField from "components/Form/NumberField/NumberField";
import Select from "components/Form/SelectField/Select";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import validate, { isFormValid } from "helpers/validate";
import useShowPermission from 'hooks/useShowPermission';
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useHistory } from "react-router-dom";
import { fetchAllCurrencies } from 'services/lookups';
import { fetchHotelsGroup } from "services/marketplace";
import Locale from "translations";

export default function Groups({
	listAuto,
	setListAuto,
	getListAuto,
	setMsgResponse,
	allCountries,
	CurrenciesData,
	setModalsuccess,
	modalsuccess,
	mealPlanLookups,
	roomTypesLookups,
	roomViewsLookups,
}) {
	const { marketPlace,inventory,guide } = Locale;
	
	const inventoryPermission = useShowPermission({
		permission: [
			"View-Inventory-Hotels",
			"Manage-Inventory-Hotels",
		],
	});
	const history = useHistory();
	const [hotel, setHotel] = useState({
		goingTo: null,
		hotel_name: "",
		checkIn: null,
		checkOut: null,
		adult: 1,
		child: 0,
		rooms: [
			{
				roomType: null,
				roomView: null,
				number_of_adult: null,
				number_of_childs: null,
				BoxChild: false,
				children: [],
			},
		],
		meal: null,
		residence: null,
		nationality: null,
		currency_code: null,
		remarks: "",
	});


	const [errors, setErrors] = useState({ rooms: [] });
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [removeInput, setRemoveInput] = useState(true);

	let count =
		hotel?.rooms && hotel?.rooms.length > 0
			? hotel?.rooms.filter((res) => res.BoxChild).length
			: 0;

			const closeAllTaps = () => {	
				let x = [...hotel.rooms];
				let list = x.map((res) => {
					return { ...res, BoxChild: false };
				});
				setHotel({ ...hotel, rooms: list });
			};
			const ref = useRef(null);

			const [first, setfirst] = useState(false)
			const handleClickOutside = (event) => {
		
				if (ref.current && !ref.current.contains(event.target)) {
					
					setfirst(Math.random())
				}
		};
			useEffect(() => {
				closeAllTaps()
			}, [first])


			useEffect(() => {
				document.addEventListener('click', handleClickOutside, true);
				return () => {
						document.removeEventListener('click', handleClickOutside, true);
				};
		}, []);

	const setRoomsCount = (name, index, value) => {
		let x = hotel.rooms && hotel.rooms.length > 0 ? hotel.rooms : [];
		x[index] = { ...x[index], [name]: value };
		if (name === "roomLength") {
			let rooms = [...Array(+value).keys()].map((res) => ({
				...res,
				children: [],
			}));
			x[index] = { ...x[index], roomsList: rooms };
		}
		setHotel({ ...hotel, rooms: x });
	};

	const setChildrenCount = (name, index, value) => {
		let x = hotel.rooms;
		let rooms = [...Array(+value).keys()].map((res) => ({
			...res,
			age: "",
		}));
		let roomsList = x[index].roomsList;

		if (roomsList && roomsList.length > 0) {
			roomsList.map((res) => {
				res.children = rooms;
			});
		}
		x[index] = { ...x[index], [name]: rooms };
		setHotel({ ...hotel, rooms: x });
	};

	// const setAge = (index, DD, XX, value) => {
	// 	let x = hotel.rooms;
	// 	let c = x[index].roomsList[DD].children;
	// 	const newArr = c.map((ress, indexs) => {
	// 		if (indexs == XX) {
	// 			return { ...ress, age: +value };
	// 		}
	// 		return { ...ress };
	// 	});
	// 	x[index].roomsList[DD].children = newArr;
	// 	setHotel({ ...hotel, rooms: x });
	// };
	const [allCurrenciesData, setAllCurrenciesData] = useState([])
useEffect(() => {
	async function fetchAllGroupCurrencies() {
			const Currencies = await fetchAllCurrencies();
			let x = Currencies?.map((res) => {
				return { name: res.currency_code, id: res.currency_code };
			});
			setAllCurrenciesData(x);
		}
		fetchAllGroupCurrencies()
}, [])

	const setRoomTypeAndView = (name, index, type, view) => {
		let rooms = [...hotel.rooms];
		let room = { ...rooms[index] };
		room = { ...room, roomType: type, roomView: view };
		rooms[index] = room;
		setHotel({ ...hotel, rooms });
	};

	const setRoomType = (name, index, value) => {
		let rooms = [...hotel.rooms];
		let room = { ...rooms[index] };
		room = { ...room, roomType: value };
		rooms[index] = room;
		setHotel({ ...hotel, rooms });
	};

	const setRoomView = (name, index, value) => {
		let rooms = [...hotel.rooms];
		let room = { ...rooms[index] };
		room = { ...room, roomView: value };
		rooms[index] = room;
		setHotel({ ...hotel, rooms });
	};

	const handleChange = (e, flag = false) => {
		const name = e.target?.name;
		const value = e.target?.value;
		if (flag) {
			setHotel({
				...hotel,
				country: e["value"], // countries[value],
				goingTo: e.value, //countries[value]?.code,
			});
		} else setHotel({ ...hotel, [name]: value });
	};

	function checkFormErrors() {
		let roomError = [];
		hotel?.rooms?.map((res) => {
			let newError = {
				...validate(
					{ name: "roomLength", value: res.roomLength },
					{ required: true }
				),
				...validate(
					{ name: "number_of_adult", value: res.number_of_adult },
					{ required: true }
				),
			};
			roomError.push(newError);
		});
		setErrors({
			...errors,
			rooms: roomError,
			...validate(
				{ name: "hotel_name", value: hotel.hotel_name },
				{ required: true }
			),
			...validate(
				{ name: "residence", value: hotel.residence },
				{ required: true }
			),
			...validate(
				{ name: "nationality", value: hotel.nationality },
				{ required: true }
			),
			...validate(
				{ name: "currency_code", value: hotel.currency_code },
				{ required: true }
			),
			...validate(
				{ name: "goingTo", value: hotel.goingTo },
				{ required: true }
			),
			...validate(
				{ name: "checkIn", value: hotel.checkIn },
				{ required: true }
			),
			...validate(
				{ name: "checkOut", value: hotel.checkOut },
				{ required: true }
			),
		});
	}

	const handleSearchCheck = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	function handleBlur(name) {
		setErrors({
			...errors,
			...validate({ name, value: hotel[name] }, { required: true }),
		});
	}

	let goingToPrevValue = useRef(null);
	function clearSearchText(e) {
		goingToPrevValue.current = hotel.goingTo;
		const value = e.target.value;
		if (value.length > 0) {
			setHotel({ ...hotel, goingTo: null });
		}
	}

	// set intial valeus for the room
	useEffect(() => {
		setRoomsCount("roomLength", 0, 1);
		setRoomsCount("number_of_adult", 0, 2);
		setRoomTypeAndView(
			"",
			0,
			roomTypesLookups && roomTypesLookups[0],
			roomViewsLookups && roomViewsLookups[0]
		);
	}, []);

	useEffect(() => {
		if (Object.values(errors).length > 1) {
			const flag = errors.rooms.every((error) => {
				if (!isFormValid(error)) {
					return false;
				}
				return true;
			});
			if (isFormValid(errors) && flag) {
				const data = {
					destination_id: hotel?.goingTo?.id,
					hotel_name: hotel?.hotel_name,
					date_from: moment(hotel.checkIn).format("YYYY-MM-DD"),
					date_to: moment(hotel.checkOut).format("YYYY-MM-DD"),
					nationality: hotel?.nationality?.code,
					residency: hotel?.residence?.code,
					remarks: hotel?.remarks,
					meal_plan_id: hotel?.meal?.id,
					currency: hotel?.currency_code?.name,
					rooms_data: hotel?.rooms.map((room) => {
						return {
							count: +room.roomLength,
							number_of_adult: +room.number_of_adult,
							room_view_id: +room.roomView?.id || roomViewsLookups[0]?.id,
							room_type_id: +room.roomType?.id || roomTypesLookups[0]?.id,
							children: room?.children?.length,
						};
					}),
				};
				const handleSearch = async () => {
					const response = await fetchHotelsGroup(data);
					if (response.status === 200) {
						setMsgResponse(response?.data?.message);
						setModalsuccess(!modalsuccess);
						setTimeout(() => {
							setModalsuccess(false);
							setRemoveInput(false);
							setHotel({
								...hotel,
								goingTo: null,
								residence: null,
								nationality: null,
								meal: null,
							});
							history.push(inventoryPermission?"/inventory/hotels":"/statistics");
						}, 1000);
					}
				};
				handleSearch();
			}
		}
	}, [isErrorLoaded]);

	return (
		<div className="col-md-10 HotelSearch m-auto">
			<div className="row m-0">
				<div className="col-md-12 p-0">
					{/* destination and hotelname */}
					<div className="row m-0">
						<div className="col-md-6" style={{ position: "relative" }} id="hotel-vacations-groups-destination">
							<AutoCompleteField
								hasLabel={true}
								flag={removeInput ? hotel.goingTo?.country?.flag : ""}
								listAuto={listAuto}
								setListAuto={setListAuto}
								getListAuto={getListAuto}
								label={marketPlace.messages.destination}
								isSearchable={true}
								placeholder={marketPlace.messages.destination}
								//value={hotel.goingTo?.name}
								value={hotel.goingTo?.name || ""}
								onChange={(e) => {
									setRemoveInput(true);
									handleChange({ name: "goingTo", value: { name: e } }, true);
								}}
								onSelectValue={(e) => {
									handleChange({ name: "goingTo", value: e }, true);
									setErrors({
										...errors,
										...validate(
											{ name: "goingTo", value: e },
											{ required: true }
										),
									});
								}}
								onFocus={clearSearchText}
								onBlur={() =>
									setHotel({ ...hotel, goingTo: goingToPrevValue.current })
								}
								color={errors?.goingTo?.required ? "danger" : ""}
								errors={errors?.goingTo}
							/>
						</div>

						<div className="col-md-6" id="hotel-vacations-groups-hotelname">
							<TextField
								hasLabel={true}
								label={marketPlace.messages.hotelName}
								placeholder={marketPlace.messages.hotelName}
								name="hotel_name"
								onChange={(e) => {
									setHotel({ ...hotel, hotel_name: e.target.value });
									setErrors({
										...errors,
										...validate(
											{ name: "hotel_name", value: hotel.hotel_name },
											{ required: true }
										),
									});
								}}
								onBlur={() => handleBlur("hotel_name")}
								value={removeInput ? hotel?.hotel_name : ""}
								color={errors?.hotel_name?.required ? "danger" : ""}
								errors={errors?.hotel_name}
							/>
						</div>
					</div>
				</div>
				{/* checkin */}
				<div className="col-md-6" id="hotel-vacations-groups-checkin">
					<div className="mybooking-input-wrapper marketplace-date">
						<DatePickerField
							label={marketPlace.messages.checkIn}
							placeholder="DD/MM/YYYY"
							date={removeInput ? hotel.checkIn : ""}
							onChangeDate={(date) => {
								setRemoveInput(true);
								setHotel({
									...hotel,
									checkIn: date,
									checkOut: moment(date).isAfter(moment(hotel?.checkOut))
										? null
										: hotel?.checkOut,
								});
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{ name: "checkIn", value: hotel.checkIn },
										{ required: true }
									),
								})
							}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(0, "d"), "day")
							}
							color={errors?.checkIn?.required ? "danger" : ""}
							errors={errors?.checkIn}
						/>
					</div>
				</div>
				{/* checkout */}
				<div className="col-md-6" id="hotel-vacations-groups-checkout">
					<div className="mybooking-input-wrapper marketplace-date">
						<DatePickerField
							label={marketPlace.messages.checkOut}
							placeholder="DD/MM/YYYY"
							date={removeInput ? hotel.checkOut : ""}
							onChangeDate={(date) => {
								setRemoveInput(true);
								setHotel({
									...hotel,
									checkOut: date,
									checkIn: moment(date).isBefore(moment(hotel.checkIn))
										? null
										: hotel.checkIn,
								});
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{ name: "checkOut", value: hotel.checkOut },
										{ required: true }
									),
								})
							}
							isOutsideRange={(day) =>
								!day.isAfter(
									moment(hotel?.checkIn ? hotel?.checkIn : new Date()).add(
										0,
										"d"
									),
									"day"
								)
							}
							color={errors?.checkOut?.required ? "danger" : ""}
							errors={errors?.checkOut}
						/>
					</div>
				</div>

				{/* rooms container */}
				<div className="col-md-12">
					<label>{marketPlace.rooms}</label>
				</div>
				<div className="col-md-12  bg-Rooms py-3" id="hotel-vacations-groups-rooms">
					<div className="col-md-11 m-auto">
						<div className=" product-build__product-collpase branch-collapse my-0 mb-2">
							{/* rooms */}
							<div className="row">
								{hotel.rooms && hotel.rooms.length > 0
									? hotel.rooms.map((res, index) => {
											return (
												<>
													<div
														className="col-md-11"
														style={{ padding: "0 15px" }}
													>
														<div className="button-collapse my-2">
															<div className="title-style-container">
																<div
																	className="w-100"
																	onClick={() =>
																		setRoomsCount(
																			"BoxChild",
																			index,
																			!res.BoxChild
																		)
																	}
																>
																	<div className="w-100">
																		<p className="global-input font-weight-bold border-0 pt-4">
																			<span className="roomsNumber">
																				{res.roomLength} {"  "}
																				{marketPlace.messages.room}
																			</span>
																			<span className="adultNumber">
																				({res.roomType?.label}{" "}
																				{res.number_of_adult}{" "}
																				{marketPlace.messages.adult} {"  "},
																				{res?.children?.length}{" "}
																				{marketPlace.messages.child}){" "}
																				{marketPlace.perroom}
																			</span>
																		</p>
																	</div>
																</div>

																<div className="icon-items-section d-flex align-items-center">
																	<i
																		className={`fas fa-fw text-primary fa-lg ${
																			res.BoxChild !== true
																				? "fa-chevron-right "
																				: "fa-chevron-down"
																		}`}
																		onClick={() =>
																			setRoomsCount(
																				"BoxChild",
																				index,
																				!res.BoxChild
																			)
																		}
																	></i>
																</div>
															</div>
															{res.BoxChild ? (
																<div
																	className="row box-child"
																	ref={ref}
																>
																	<div
																		className="col-md-12"
																		style={{ padding: "0 15px" }}
																	>
																		{/* no of rooms */}
																		<div className="row">
																			<div className="col-md-4">
																				<div className="container-centerLabel px-2">
																					<label htmlFor="">
																						{" "}
																						{
																							marketPlace.messages.NoOfRooms
																						}{" "}
																					</label>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<NumberField
																					name="roomLength"
																					type="number"
																					placeholder={
																						marketPlace.messages.NoOfRooms
																					}
																					hasLabel={false}
																					label={marketPlace.messages.rooms}
																					increase={() => {
																						setRoomsCount(
																							"roomLength",
																							index,
																							res.roomLength
																								? +res.roomLength + 1
																								: 0 + 1
																						);
																						setChildrenCount(
																							"children",
																							index,
																							0
																						);
																					}}
																					decrease={() => {
																						setRoomsCount(
																							"roomLength",
																							index,
																							res.roomLength - 1
																						);
																						setChildrenCount(
																							"children",
																							index,
																							0
																						);
																					}}
																					max={Infinity}
																					min={1}
																					value={res.roomLength}
																					onChange={(e) => {
																						let removeZero = e.target.value;
																						if (e.target.value[0] == 0) {
																							removeZero =
																								e.target.value.substring(1);
																						}
																						setRoomsCount(
																							"roomLength",
																							index,
																							removeZero
																						);
																						
																						setChildrenCount(
																							"children",
																							index,
																							0
																						);
																					}}
																					
																					color={
																						errors?.rooms[index]?.roomLength
																							?.required
																							? "danger"
																							: ""
																					}
																					errors={
																						errors?.rooms[index]?.roomLength
																					}
																				/>
																			</div>
																		</div>
																		{/* room type */}
																		<div className="row">
																			<div className="col-md-4">
																				<div className="container-centerLabel px-2">
																					<label htmlFor="">
																						{" "}
																						{marketPlace.messages.roomType}{" "}
																					</label>
																				</div>
																			</div>
																			<div className="col-md-8 my-1">
																				<Select
																					name="roomType"
																					label={marketPlace.messages.roomType}
																					placeholder={`${marketPlace.select} ${marketPlace.messages.roomType}`}
																					value={
																						hotel?.rooms[index]?.roomType?.id
																					}
																					options={roomTypesLookups}
																					onChange={(e) => {
																						setRoomType(
																							"roomType",
																							index,
																							roomTypesLookups.filter(
																								(type) =>
																									type.id.toString() ===
																									e.target.value
																							)[0]
																						);
																						setErrors({
																							...errors,
																							...validate(
																								{
																									name: "roomType",
																									value: e.target.value,
																								},
																								{ required: true }
																							),
																						});
																					}}
																					color={
																						errors?.rooms[index]?.roomType
																							?.required
																							? "danger"
																							: ""
																					}
																					errors={
																						errors?.rooms[index]?.roomType
																					}
																				/>
																			</div>
																		</div>
																		{/* room view */}
																		<div className="row">
																			<div className="col-md-4">
																				<div className="container-centerLabel px-2">
																					<label htmlFor="">
																						{" "}
																						{marketPlace.messages.roomView}{" "}
																					</label>
																				</div>
																			</div>
																			<div className="col-md-8 my-1">
																				<Select
																					name="roomView"
																					label={marketPlace.messages.roomView}
																					placeholder={`${marketPlace.select} ${marketPlace.messages.roomView}`}
																					value={
																						hotel?.rooms[index]?.roomView?.id
																					}
																					options={roomViewsLookups}
																					onChange={(e) => {
																						setRoomView(
																							"roomView",
																							index,
																							roomViewsLookups.filter(
																								(view) =>
																									view.id.toString() ===
																									e.target.value
																							)[0]
																						);
																						setErrors({
																							...errors,
																							...validate(
																								{
																									name: "roomView",
																									value: e.target.value,
																								},
																								{ required: true }
																							),
																						});
																					}}
																					color={
																						errors?.rooms[index]?.roomView
																							?.required
																							? "danger"
																							: ""
																					}
																					errors={
																						errors?.rooms[index]?.roomView
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	{/* no of adults */}
																	<div
																		className="col-md-12"
																		style={{ padding: "0 15px" }}
																	>
																		<div className="row">
																			<div className="col-md-4">
																				<div className="container-centerLabel px-2">
																					<label
																						className="m-0 w-100"
																						htmlFor=""
																					>
																						{marketPlace.messages.adult}
																					</label>
																					<p className="small-title">
																						{marketPlace.ForEveryRoom}
																					</p>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<NumberField
																					type="number"
																					placeholder={
																						marketPlace.messages.NoOfAdults
																					}
																					hasLabel={false}
																					label={marketPlace.messages.adult}
																					value={res.number_of_adult}
																					name="number_of_adult"
																					increase={() => {
																						setRoomsCount(
																							"number_of_adult",
																							index,
																							res.number_of_adult
																								? +res.number_of_adult + 1
																								: 0 + 1
																						);
																					}}
																					decrease={() => {
																						setRoomsCount(
																							"number_of_adult",
																							index,
																							res.number_of_adult - 1
																						);
																					}}
																					max={Infinity}
																					min={1}
																					onChange={(e) => {
																						let removeZero = e.target.value;
																						if (e.target.value[0] == 0) {
																							removeZero =
																								e.target.value.substring(1);
																						}
																						setRoomsCount(
																							"number_of_adult",
																							index,
																							removeZero
																						);
																					}}
																					color={
																						errors?.rooms[index]
																							?.number_of_adult?.required
																							? "danger"
																							: ""
																					}
																					errors={
																						errors?.rooms[index]
																							?.number_of_adult
																					}
																				/>
																			</div>
																		</div>{" "}
																	</div>
																	{/* no of childs */}
																	<div
																		className="col-md-12"
																		style={{ padding: "0 15px" }}
																	>
																		<div className="row">
																			<div className="col-md-4">
																				<div className="container-centerLabel px-2">
																					<label
																						className="m-0 w-100"
																						htmlFor=""
																					>
																						{marketPlace.messages.child}
																					</label>
																					<p className="small-title">
																						{marketPlace.ForEveryRoom}
																					</p>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<NumberField
																					type="number"
																					placeholder={
																						marketPlace.messages.NoOfChildren
																					}
																					hasLabel={false}
																					label={marketPlace.messages.child}
																					name="rooms_count"
																					max={4}
																					min={0}
																					value={res?.children?.length?.toString()}
																					increase={() => {
																						setChildrenCount(
																							"children",
																							index,
																							res?.children?.length
																								? +res?.children?.length + 1
																								: 0 + 1
																						);
																					}}
																					decrease={() => {
																						setChildrenCount(
																							"children",
																							index,
																							res?.children.length - 1
																						);
																					}}
																					onChange={(e) => {
																						let removeZero = e.target.value;
																						if (e.target.value[0] == 0) {
																							removeZero =
																								e.target.value.substring(1);
																						}
																						setChildrenCount(
																							"children",
																							index,
																							+removeZero > 4
																								? 4
																								: removeZero < 0
																								? 0
																								: removeZero
																						);
																					}}
																					color={
																						errors?.rooms[index]?.children
																							?.required
																							? "danger"
																							: ""
																					}
																					errors={
																						errors?.rooms[index]?.children
																					}
																				/>
																			</div>
																		</div>{" "}
																	</div>
																	{/* rooms list */}
																	{/* <div className="col-md-12" style={{ padding: "0 15px" }}>
                                {res.roomsList && res.roomsList.length > 0 && res?.children && res?.children?.length > 0
                                  ?
                                  <p className="text-danger-custom my-3">
                                    {marketPlace.oldChildren}
                                  </p>
                                  :
                                  ""
                                }
                                {res.roomsList && res?.children?.length > 0 && res.roomsList.length > 0 ? res.roomsList.map((rese, indexRoom) => {
                                  return (
                                    <div className="row">
                                      <div className="col-md-3">
                                        <div className="container-centerLabel px-2">
                                          <label className="m-0 w-100" htmlFor="" >
                                            {marketPlace.messages.room}{" "}{+indexRoom + 1}
                                          </label>
                                        </div>
                                      </div>
                                      <div className=" row col-9">
                                        {rese?.children && rese?.children.length > 0
                                          ? rese?.children.map((child, indexChild) => {
                                            return (
                                              <>
                                                <div className="col-md-4 ">
                                                  <NumberField
                                                    type="number"
                                                    placeholder={`${marketPlace.messages.child} ${indexChild + 1}`}
                                                    hasLabel={false}
                                                    value={child.age}
                                                    max={12}
                                                    min={1}
                                                    name="setAge"
                                                    onChange={(e) => {
                                                      let removeZero = e.target.value;
                                                      setAge(index, indexRoom, indexChild, +removeZero > 12 ? 12 : removeZero < 1 ? 1 : removeZero);
                                                    }}
                                                    color={""}
                                                    errors={""}
                                                    onEnter={(e) => false}
                                                    increase={() => {
                                                      setAge(index, indexRoom, indexChild, +child?.age + 1);
                                                    }}
                                                    decrease={() => {
                                                      setAge(index, indexRoom, indexChild, +child?.age - 1);
                                                    }}
                                                  />
                                                </div>
                                              </>
                                            );
                                          })
                                          :
                                          ""
                                        }
                                      </div>
                                    </div>
                                  );
                                })
                                  : ""
                                }
                              </div> */}
																</div>
															) : null}
														</div>
													</div>

													<div className="col-md-1">
														{index > 0 ? (
															<span
																className="span-remove"
																onClick={() => {
																	let rooms = [...hotel.rooms];
																	rooms.splice(index, 1);
																	setHotel({ ...hotel, rooms: rooms });
																}}
															>
																<i class="fas fa-times"></i>
															</span>
														) : null}
													</div>
												</>
											);
									  })
									: null}
							</div>

							<div className="col-md-11 mt-1" style={{ height: "10px" }}>
								<p className="text-success  text-right">
									<span
										onClick={() => {
											let x = hotel.rooms;
											x.push({
												children: [],
												roomType: roomTypesLookups[0],
												roomView: roomViewsLookups[0],
											});
											setHotel({ ...hotel, rooms: x });
											setRoomsCount("roomLength", +hotel.rooms.length - 1, 1);
											setRoomsCount(
												"number_of_adult",
												+hotel.rooms.length - 1,
												2
											);
										}}
										style={{ cursor: "pointer" }}
									>
										<i class="fa fa-plus-circle"></i> {marketPlace.AddNewRoom}
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* meal */}
				<div className="col-md-4 mt-2" id="hotel-vacations-groups-meals">
					<SelectField
						label={marketPlace.messages.mealPlan}
						value={removeInput ? hotel?.meal?.label : ""}
						placeholder={`${marketPlace.select} ${marketPlace.messages.mealPlan} `}
						name="meal"
						options={mealPlanLookups}
						onChange={(e) => {
							setRemoveInput(true);
							setHotel({ ...hotel, meal: e });
				
						}}
						// onBlur={() => handleBlur("meal")}
						color={errors?.meal?.required ? "danger" : ""}
						errors={errors?.meal}
					/>
				</div>
				{/* residence */}
				<div className="col-md-4 mt-2" id="hotel-vacations-groups-residence">
					<SelectField
						label={marketPlace.messages.residence}
						value={removeInput ? hotel.residence?.label : ""}
						placeholder={`${marketPlace.select} ${marketPlace.messages.residence} `}
						name="residence"
						options={allCountries}
						onChange={(e) => {
							setRemoveInput(true);
							setHotel({ ...hotel, residence: e });
							setErrors({
								...errors,
								...validate(
									{ name: "residence", value: e.id },
									{ required: true }
								),
							});
						}}
						onBlur={() => handleBlur("residence")}
						color={errors?.residence?.required ? "danger" : ""}
						errors={errors?.residence}
					/>
				</div>
				{/* nationality */}
				<div className="col-md-4 mt-2" id="hotel-vacations-groups-nationality">
					<SelectField
						label={marketPlace.messages.nationality}
						value={removeInput ? hotel.nationality?.label : ""}
						placeholder={`${marketPlace.select} ${marketPlace.messages.nationality} `}
						name="nationality"
						options={allCountries}
						onChange={(e) => {
							setRemoveInput(true);
							setHotel({ ...hotel, nationality: e });
							setErrors({
								...errors,
								...validate(
									{ name: "nationality", value: e.id },
									{ required: true }
								),
							});
						}}
						onBlur={() => handleBlur("nationality")}
						color={errors?.nationality?.required ? "danger" : ""}
						errors={errors?.nationality}
					/>
				</div>
				{/* currencyName */}
				<div className="col-md-6 mt-2" id="hotel-vacations-groups-currency">
					<SelectField
						label={marketPlace.messages.currencyName}
						value={hotel.currency_code?.label}
						placeholder={`${marketPlace.select} ${marketPlace.messages.currencyName} `}
						name="currency_code"
						options={allCurrenciesData}
						onChange={(e) => {
							setRemoveInput(true);
							setHotel({ ...hotel, currency_code: e });
							setErrors({
								...errors,
								...validate(
									{ name: "currency_code", value: e.id },
									{ required: true }
								),
							});
						}}
						onBlur={() => handleBlur("currency_code")}
						color={errors?.currency_code?.required ? "danger" : ""}
						errors={errors?.currency_code}
					/>
				</div>
				{/* remarks */}
				<div className="col-md-6 mt-2" id="hotel-vacations-groups-remarks">
					<TextField
						hasLabel={true}
						label={inventory.viewReservation.Notes}
						placeholder={guide.vacations.remarks}
						name="remarks"
						onChange={(e) => {
							setRemoveInput(true);
							setHotel({
								...hotel,
								remarks: e.target.value,
							});
						}}
						value={removeInput ? hotel?.remarks : ""}
					/>
				</div>

				<div className="col-md-12 my-4">
					<div className="full-input search-button">
						<div className="full-input search-button ">
							<button
								onClick={handleSearchCheck}
								className="btn w-100 bg-nxt mb-3 mt-3"
								type="button"
							>
								{marketPlace.messages.sendrequest}
							</button>
							{/* <Link className="form-control btn-primary text-center" to="/market-view">{marketPlace.messages.search}</Link> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
