import React, { useEffect, useState } from "react";
import Locale from "translations";
import { ReactComponent as AddIcon } from "assets/images/add.svg";
import { ReactComponent as CloseIcon } from "assets/images/close.svg";
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { ReactComponent as TrashIcon } from "assets/images/product-builder/trash.svg";
import {
	addPackageExtraService,
	deletePackageExtraService,
	editPackageExtraService,
	packageExtraService,
} from "services/productbuilder";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import TextField from "components/Form/TextField/TextField";
import validate, { isFormValid } from "helpers/validate";

function ExtraService({ setReload, reservationDetails, Reload }) {
	const { inventory, marketPlace, reservation } = Locale;
	const { id } = useParams();
	const reservationHasPayments = reservationDetails?.payments?.length > 0;
	const initialData = {
		name: "",
		quantity: "",
		price: "",
	};
	const [modalStatus, setModalStatus] = useState({
		isOpen: false,
		type: null,
		data: initialData,
	});
	const [serviceList, setServiceList] = useState([]);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const fetchList = async () => {
		const res = await packageExtraService(id);
		if (res.status === 200) {
			setServiceList(res.data.data);
		}
	};
	useEffect(() => {
		fetchList();
		return () => {};
	}, [Reload]);

	const toggleModal = (type = null, data = initialData) => {
		setModalStatus((prev) => ({
			data,
			type,
			isOpen: !prev.isOpen,
		}));
	};

	const handleService = ({ key, value }) => {
		setModalStatus((prev) => ({
			...prev,
			data: { ...prev.data, [key]: value },
		}));
		setErrors({
			...errors,
			...validate(
				{
					name: key,
					value,
				},
				{ required: true }
			),
		});
	};

	function checkFormErrors() {
		let submitError = {};
		const data = modalStatus.data;
		Object.keys(data).forEach((key) => {
			submitError = {
				...submitError,
				...validate({ name: key, value: data[key] }, { required: true }),
			};
		});
		setErrors(() => submitError);
	}

	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}

	const onDeleteservice = async (serviceId) => {
		if (reservationHasPayments) return;
		const res = await deletePackageExtraService(id, serviceId);
		if (res.status === 200) {
			setReload((prev) => !prev);
		}
	};
	const addEditService = async () => {
		const res =
			modalStatus.type === "edit"
				? await editPackageExtraService(
						id,
						modalStatus.data.id,
						modalStatus.data
				  )
				: await addPackageExtraService(id, modalStatus.data);

		if (res.status === 200) {
			toggleModal();
			setReload((prev) => !prev);
		}
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			addEditService();
		}
	}, [isErrorLoaded]);

	return (
		<div className="extra-service-container">
			<div className="extra-service-header">
				<p>{inventory.extraService}</p>
				<button onClick={() => toggleModal("add")}>
					<AddIcon /> {inventory.AddExtraServices}
				</button>
			</div>

			<div className="border table-responsive mt-2">
				<table className="table m-0 w-100">
					<thead>
						<tr>
							<th>{marketPlace.messages.name}</th>
							<th>{marketPlace.messages.quantity}</th>
							<th>{inventory.messages.Price}</th>
							<th>{reservation.messages.action}</th>
						</tr>
					</thead>
					<tbody>
						{serviceList.map((service) => (
							<tr>
								<td>{service.name}</td>
								<td>{service.quantity}</td>
								<td className="font-weight-bold">{`${service.price} ${reservationDetails?.currency}`}</td>
								<td>
									<button
										onClick={() => onDeleteservice(service.id)}
										disabled={reservationHasPayments}
										className="border-0 bg-transparent"
									>
										<TrashIcon
											className={reservationHasPayments ? "opacity-50" : ""}
										/>
									</button>
									<button
										onClick={() => toggleModal("edit", service)}
										disabled={reservationHasPayments}
										className="mx-2 border-0 bg-transparent"
									>
										<EditIcon
											className={reservationHasPayments ? "opacity-50" : ""}
										/>
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Modal isOpen={modalStatus.isOpen} centered={true}>
				<div className="extra-service-modal">
					<div className="extra-service-modal-header">
						<div />
						<p>{inventory.extraService}</p>
						<CloseIcon
							className="pointer opacity"
							onClick={() => toggleModal()}
						/>
					</div>
					<hr />
					<TextField
						label={inventory.servicesName}
						placeholder={reservation.messages.fullName}
						value={modalStatus.data.name}
						onChange={(e) => {
							const value = e.target.value;
							handleService({ key: "name", value });
						}}
						color={errors?.name?.required ? "danger" : ""}
						errors={errors?.name}
					/>
					<TextField
						type="number"
						label={marketPlace.messages.quantity}
						placeholder={"0"}
						value={modalStatus.data.quantity}
						onChange={(e) => {
							const value = e.target.value ? +e.target.value : e.target.value;
							handleService({ key: "quantity", value });
						}}
						color={errors?.quantity?.required ? "danger" : ""}
						errors={errors?.quantity}
					/>
					<TextField
						type="number"
						label={inventory.messages.Price}
						placeholder="0"
						value={modalStatus.data.price}
						onChange={(e) => {
							const value = e.target.value ? +e.target.value : e.target.value;
							handleService({ key: "price", value });
						}}
						color={errors?.price?.required ? "danger" : ""}
						errors={errors?.price}
					/>
					<hr className="my-4" />
					<button onClick={submit} className="primary-button">
						{modalStatus.type === "add"
							? marketPlace.messages.add
							: inventory.messages.edit}
					</button>
				</div>
			</Modal>
		</div>
	);
}

export default ExtraService;
