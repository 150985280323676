import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import React, { useEffect, useState } from "react";
import SearchBarHorizontal from "./SearchBarHorizontal";
import SearchBarVertical from "./SearchBarVertical";
import useFetchCountries from 'hooks/useFetchCountries';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { handleSearch } from 'modules/WebBuilder-V2/helpers/handleSearch';
import { store } from 'react-notifications-component';



function HeroContent() {
	const { hero, ourServices, content, style, pagesData, selectedLanguage } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const mainContent = hero?.mainContent;
	const [countries] = useFetchCountries();
	const [countriesLookup, setCountriesLookup] = useState([]);
	const location = useLocation();
	const history = useHistory();
	const { name } = useParams();
	const preview = !location.pathname.includes("edit");
	const [searchState, setSearchState] = useState({
		country: null,
		proudctType: null,
	});
	const updateHero = ({ name, value, type, language }) => {
		dispatch({
			type: "updateHeroContent",
			payload: {
				name,
				value,
				type,
				language
			},
		});
	};
	const layoutType = hero?.mainContent?.layout?.layoutType;
	const searchbar_position = hero?.mainContent?.searchbarPosition;


	const visibleServices = ourServices ? ourServices?.services?.filter(service => service?.visible && service?.type !== "packages")?.map(service => ({
		id: service?.type,
		name: service?.type
	})) : [];

	const product_types = [
		{ id: "hajj", name: "Hajj" },
		{ id: "umrah", name: "Umrah" },
		{ id: "umrah-plus", name: "Umrah-plus" },
		{ id: "tourism", name: "Tourism" },
		...visibleServices
	]

	function handleSearchInputs(value, keyName) {
		if (keyName === "proudctType" && (value.id === "hajj" || value.id === "umrah" || value.id === "umrah-plus")) {
			setCountriesLookup(() => countriesLookup.filter(country => country?.id === 966))
		} else {
			setCountriesLookup(countries)
		}
		setSearchState({
			...searchState,
			country: keyName === "proudctType" && (value.id === "hajj" || value.id === "umrah" || value.id === "umrah-plus") ?
				{
					id: 966,
					name: "Saudi Arabia",
					code: "SA",
					nat_code: "SAR",
					currency: "SAR",
					nationality_id: 966,
					image: "3a00e3ac-a4c1-443f-84d9-0aef27ec9bbb",
					flag: "f4e1fb09-cc9e-473a-828f-02fb764d8394",
					phone_code: "+966",
					value: 966,
					label: "Saudi Arabia"
				}
				:
				searchState.country,
			[keyName]: value,
		});
	}

	function getSearchResults() {
		let searchResults = handleSearch(searchState?.country?.label, searchState?.proudctType?.id, content, pagesData);
		if (searchResults?.length > 0) {
			dispatch({
				type: "addPackagesResult",
				payload: searchResults,
			});
			if (name) {
				history.push(`/web-v2/${name}/packages-results?country=${searchState?.country?.label}&type=${searchState?.proudctType?.id}`);
			} else {
				history.push(`/web-builder-v2/preview/packages-results?country=${searchState?.country?.label}&type=${searchState?.proudctType?.id}`);
			}
		} else {
			store.addNotification({
				title: "No data found!",
				message: "No data matches the specified search criteria",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
			});
		}
	}
	useEffect(() => {
		if (countries) {
			setCountriesLookup(countries);
		}
	}, [countries])

	function renderSearchForm() {
		return {
			center: <SearchBarHorizontal
				product_types={product_types}
				countries={countriesLookup}
				searchState={searchState}
				handleSearchInputs={handleSearchInputs}
				handleSearch={getSearchResults}
				preview={preview}
			/>,
			start: <SearchBarVertical
				product_types={product_types}
				countries={countriesLookup}
				searchState={searchState}
				handleSearchInputs={handleSearchInputs}
				handleSearch={getSearchResults}
				preview={preview}
			/>,
		};
	}

	return (
		<div className={`web-builder-hero-content content-layout-${layoutType} content-layout-${style?.theme} justify-content-${searchbar_position}`}>
			<EditableParagraph
				className="header h3"
				contentEditable={true}
				onInput={(value) => {
					updateHero({ name: `title`, value, type: null, language: selectedLanguage });
				}}
				text={mainContent?.title[selectedLanguage]}
			/>
			<EditableParagraph
				className="body"
				contentEditable={true}
				onInput={(value) => {
					updateHero({ name: `body`, value, type: null, language: selectedLanguage });
				}}
				text={mainContent?.body[selectedLanguage]}
			/>

			{renderSearchForm()?.[searchbar_position]}
		</div>
	);
}

export default HeroContent;
