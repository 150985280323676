import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TextField from 'components/Form/TextField/TextField';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import moment from 'moment';
import SelectField from 'components/Form/SelectField/SelectField';
import { useSBSState } from 'context/global';
import validate, { isFormValid } from "helpers/validate";
import Locale from 'translations';
import { ReactComponent as CloseIcon } from "assets/images/product-builder/close-circle.svg";
import { ReactComponent as UploadIcon } from "assets/images/crm/upload.svg";
import { uploadFile } from 'services/auth';
import { CRMEditTraveler } from 'services/CRM';
import { store } from 'react-notifications-component';
import { roomTypesLookup } from 'modules/products-builder/Requestes/RequestView/helpers/localLookups';


const keysNotRequired = ["visa_number", "visa_status", "combination_id", "e_number", "is_adult", "country_id", "nationality_id", "passport_photo", "personal_photo", "national_id_photo", "reservation_category"];
const fileUrl = `${process.env.REACT_APP_FILEUPLOAD_URL}/fetch`;

function generateRoomTypeObj(roomType) {
  if (!roomType) return;
  return {
    name: roomTypesLookup[roomType],
    id: roomType,
  }
}

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}


export default function CRMEditTravelerModal({ isOpen, toggle, traveler, setReloadData, roomsCombinations }) {
  // global context 
  const { locale, allCountries } = useSBSState();
  // translation
  const { inventory, onlineVisa } = Locale;

  const genderLookups = [
    {
      id: 1,
      name: `${locale === "en" ? "Male" : "ذكر"}`,
      code: "M",
    },
    {
      id: 2,
      name: `${locale === "en" ? "Female" : "أنثي"}`,
      code: "F"
    },
  ];

  // states
  const [travelerData, setTravelerData] = useState({
    ...traveler,
    gender: traveler?.gender ? {
      label: traveler?.gender === "M" ? genderLookups[0]?.name : traveler?.gender === "F" ? genderLookups[1]?.name : "",
      value: traveler?.gender === "M" ? genderLookups[0]?.code : traveler?.gender === "F" ? genderLookups[1]?.code : "",
      code: traveler?.gender === "M" ? genderLookups[0]?.code : traveler?.gender === "F" ? genderLookups[1]?.code : "",
    } : "",
    room_type: generateRoomTypeObj(roomsCombinations?.find(combination => combination?.id === traveler?.combination_id)?.combinations[0]?.room_type) || null
  });


  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);


  // variables
  const roomAvailabilities = travelerData?.roomAvailabilities?.map(room => ({
    ...room,
    name: roomTypesLookup[room?.room_type],
    room_value: room?.room_type
  }))

  console.log(errors);
  /***** Funtions *****/

  // handle traveler data changes
  function handleInputsChanges(value, keyName) {
    setTravelerData({
      ...travelerData,
      [keyName]: value,
    });
    setErrors({
      ...errors,
      ...validate(
        { name: keyName, value },
        { required: keysNotRequired.includes(keyName) ? false : true }
      ),
    });
  }

  // handle upload traveler photos
  async function uploadImagesToServer(e, key) {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file?.name);
      formData.append("bucket", "productsBuilder");
      const response = await uploadFile(formData);
      if (response?.data?.data?.uuid) {
        setTravelerData({ ...travelerData, [key]: response.data.data.uuid })
      }
    }
  }
  // check form error before submit
  function checkFormErrors() {
    let submitError = {};
    Object.keys(travelerData).forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: travelerData[key] },
          { required: keysNotRequired.includes(key) ? false : true }
        ),
      };
    });
    setErrors(submitError);
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }
  // send traveler data to backend
  async function editTraveler() {
    const travelerId = travelerData?.id;
    const data = {
      first_name: travelerData?.first_name,
      last_name: travelerData?.last_name,
      gender: travelerData?.gender?.code,
      birth_date: travelerData?.birth_date ? moment(travelerData?.birth_date).format("YYYY-MM-DD") : null,
      nationality_id: `${travelerData?.nationality?.id}`,
      country_id: `${travelerData?.country?.id}`,
      passport_expiry: travelerData?.passport_expiry ? moment(travelerData?.passport_expiry).format("YYYY-MM-DD") : null,
      passport_number: travelerData?.passport_number,
      visa_number: travelerData?.visa_number,
      e_number: travelerData?.e_number,
      passport_photo: travelerData?.passport_photo,
      personal_photo: travelerData?.personal_photo,
      national_id_photo: travelerData?.national_id_photo,
      combination_id: roomsCombinations?.find(combination => +combination?.combinations[0]?.room_type === travelerData?.room_type?.room_value)?.id
    }
    const res = await CRMEditTraveler(travelerId, data);
    if (res.status === 200 || res.status === 201) {
      store.addNotification({
        title: "info!",
        message: "Traveler edited Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true,
        }
      });
      toggle();
      setReloadData(prev => !prev)
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      editTraveler();

    }
  }, [isErrorLoaded])




  return (
    <Modal isOpen={isOpen} size='lg' className='package-traveler-modal add_edit_passenger_modal'>
      <ModalHeader toggle={toggle} className='align-items-center'>
        Passport Info.
      </ModalHeader>
      <ModalBody>
        <div className='row mx-0 align-items-center'>
          {/* first name */}
          <div className="col-md-4 col-12">
            <TextField
              label={inventory.messages.firstName}
              placeholder={inventory.messages.enterFirstName}
              type="text"
              name="first_name"
              value={travelerData.first_name}
              onChange={(e) => handleInputsChanges(e.target.value, 'first_name')}
              color={errors?.first_name?.required ? "danger" : ""}
              errors={errors?.first_name}
            />
          </div>
          {/* last name */}
          <div className="col-md-4 col-12">
            <TextField
              label={inventory.messages.lastName}
              placeholder={inventory.messages.enterLastName}
              type="text"
              name="last_name"
              value={travelerData.last_name}
              onChange={(e) => handleInputsChanges(e.target.value, 'last_name')}
              color={errors?.last_name?.required ? "danger" : ""}
              errors={errors?.last_name}
            />
          </div>
          {/* gender */}
          <div className="col-md-4 col-12">
            <SelectField
              label={inventory.messages.gender}
              placeholder={inventory.messages.select}
              options={genderLookups}
              value={travelerData?.gender?.label}
              name="gender"
              onChange={(e) => handleInputsChanges(e, 'gender')}
              color={errors?.gender?.required ? "danger" : ""}
              errors={errors?.gender}
            />
          </div>
          {/* birth date */}
          <div className="col-md-4 col-12">
            <DatePickerField
              label={inventory.messages.BirthDate}
              placeholder={"DD/MM/YYYY"}
              date={travelerData?.birth_date ? moment(travelerData?.birth_date) : ""}
              name="birth_date"
              hasYears={true}
              onChangeDate={(e) => handleInputsChanges(e, "birth_date")}
              isOutsideRange={(day) => {
                return !day.isBefore(moment(), "day");
              }}
              color={errors?.birth_date?.required ? "danger" : ""}
              errors={errors?.birth_date}
              startYear={travelerData?.is_adult ? moment().subtract(120, "years") : moment().subtract(18, "years")}
              endYear={travelerData?.is_adult ? moment().subtract(18, "years") : moment().subtract(1, "years")}
              initialVisibleMonth={() =>
                !travelerData?.birth_date && travelerData?.is_adult ? moment().subtract(18, "years") :
                  !travelerData?.birth_date && !travelerData?.is_adult ? moment().subtract(1, "years") : moment(travelerData?.birth_date)
              }
              readOnly={true}
            />
          </div>
          {/* nationality */}
          <div className="col-md-4 col-12">
            <SelectField
              label={inventory.messages.nationality}
              placeholder={inventory.messages.select}
              name="nationality_id"
              value={travelerData.nationality?.name}
              options={allCountries}
              onChange={(e) => handleInputsChanges(e, "nationality")}
              color={errors?.nationality?.required ? "danger" : ""}
              errors={errors?.nationality}
            />

          </div>
          {/* country */}
          <div className="col-md-4 col-12">
            <SelectField
              label={inventory.messages.country}
              placeholder={inventory.messages.select}
              name="country_id"
              value={travelerData.country?.name}
              options={allCountries}
              onChange={(e) => handleInputsChanges(e, "country")}
              color={errors?.country?.required ? "danger" : ""}
              errors={errors?.country}
            />
          </div>
          {/* passport Expiry Date */}
          <div className="col-md-4 col-12">
            <DatePickerField
              label={inventory.messages.passportExpiryDate}
              placeholder={"DD/MM/YYYY"}
              date={travelerData?.passport_expiry ? moment(travelerData?.passport_expiry) : ""}
              name="passport_expiry"
              hasYears={true}
              onChangeDate={(e) => handleInputsChanges(e, "passport_expiry")}
              isOutsideRange={(day) => {
                return (
                  day.isBefore(moment().add("6", "month")) ||
                  day.isBefore(moment().subtract(10, "years"))
                );
              }}
              color={errors?.passport_expiry?.required ? "danger" : ""}
              errors={errors?.passport_expiry}
              startYear={moment().add(6, "month")}
              endYear={moment().add(10, "years")}
              initialVisibleMonth={() =>
                moment().add("6", "month") || moment(travelerData.passport_expiry)
              }
              readOnly={true}
            />
          </div>
          {/* passport number */}
          <div className="col-md-4 col-12">
            <TextField
              label={onlineVisa.PassportNumber}
              placeholder={inventory.messages.EnterPassportNo}
              type="text"
              name="passport_number"
              value={travelerData.passport_number}
              onChange={(e) => handleInputsChanges(e.target.value, "passport_number")}
              color={errors?.passport_number?.required ? "danger" : ""}
              errors={errors?.passport_number}
            />
          </div>
          {/* visa number */}
          <div className="col-md-8 col-12">
            <TextField
              label={inventory.messages.VisaNo}
              placeholder={inventory.messages.EnterVisaNo}
              type="text"
              name="visa_number"
              value={travelerData.visa_number}
              onChange={(e) => handleInputsChanges(e.target.value, "visa_number")}
              color={errors?.visa_number?.required ? "danger" : ""}
              errors={errors?.visa_number}
            />
          </div>
          {/* visa status waiting */}
          <div className="col-md-2 col-3 mt-4">
            <div className="boxwaiting me-4 d-flex align-items-center ">
              <input
                type="radio"
                name="visa_status"
                id="waiting"
                value="waiting"
                checked={travelerData?.visa_status === "waiting"}
                onChange={(e) => handleInputsChanges(e.target.value, "visa_status")}
              />
              <label htmlFor="waiting" className="waiting pointer">
                {inventory.messages.waiting}
              </label>
            </div>
          </div>
          {/* visa status accepted */}
          <div className="col-md-2 col-3 mt-4">
            <div className="boxAccepted d-flex align-items-center ">
              <input
                type="radio"
                name="visa_status"
                id="Accepted"
                value="accepted"
                checked={travelerData?.visa_status === "accepted"}
                onChange={(e) => handleInputsChanges(e.target.value, "visa_status")}

              />
              <label htmlFor="Accepted" className="Accepted pointer">
                {inventory.messages.accepted}
              </label>
            </div>
          </div>
          {/* e-number */}
          <div className="col-md-6 col-12">
            <TextField
              label={inventory.messages.enumber}
              type="text"
              placeholder={inventory.messages.EnterENumber}
              name="e_number"
              value={travelerData.e_number}
              onChange={(e) => handleInputsChanges(e.target.value, "e_number")}
            />
          </div>
          {/* combination */}
          <div className="col-md-6 col-12">
            <SelectField
              label={inventory.messages.roomType}
              type="text"
              placeholder={inventory.messages.select}
              name="room_type"
              options={getUniqueListBy(roomAvailabilities, "room_type")}
              value={travelerData.room_type?.name}
              onChange={(e) => handleInputsChanges(e, "room_type")}
            />
          </div>


          {/* photos */}
          <div className="row mx-0 my-3 w-100">
            {/* Passport Photo (Optional) */}
            <div className="col-md-4 col-12">
              <div className="upload_box">
                <label htmlFor="passport_photo" className="mt-0">Passport Photo</label>
                <div className="upload_box_input">
                  <input type="file"
                    id="passport_photo"
                    name="passport_photo"
                    accept="image/png, image/jpeg, application/pdf, image/jpg"
                    onChange={(e) => uploadImagesToServer(e, "passport_photo")}
                  />
                  <span className="opacity-50">Upload Passport Photo</span>
                  <UploadIcon />
                </div>
              </div>
              {travelerData.passport_photo ?
                <div className="preview_img mt-2">
                  <img src={`${fileUrl}/${travelerData.passport_photo}`} alt={travelerData.first_name} />
                  <button className="btn delete_img_btn"
                    onClick={() => setTravelerData({ ...travelerData, passport_photo: null })}
                  >
                    <CloseIcon />
                  </button>
                </div>
                :
                null
              }
            </div>
            {/* Personal Photo (Optional) */}
            <div className="col-md-4 col-12">
              <div className="upload_box">
                <label htmlFor="personal_photo" className="mt-0">Personal Photo</label>
                <div className="upload_box_input">
                  <input type="file"
                    id="personal_photo"
                    name="personal_photo"
                    accept="image/png, image/jpeg, application/pdf, image/jpg"
                    onChange={(e) => uploadImagesToServer(e, "personal_photo")}
                  />
                  <span className="opacity-50">Upload Personal Photo</span>
                  <UploadIcon />
                </div>
              </div>
              {travelerData.personal_photo ?
                <div className="preview_img mt-2">
                  <img src={`${fileUrl}/${travelerData.personal_photo}`} alt={travelerData.first_name} />
                  <button className="btn delete_img_btn"
                    onClick={() => setTravelerData({ ...travelerData, personal_photo: null })}
                  >
                    <CloseIcon />
                  </button>
                </div>
                :
                null
              }
            </div>
            {/* National ID Photo (Optional) */}
            <div className="col-md-4 col-12">
              <div className="upload_box">
                <label htmlFor="national_id_photo" className="mt-0">National ID Photo</label>
                <div className="upload_box_input">
                  <input type="file"
                    id="national_id_photo"
                    name="national_id_photo"
                    accept="image/png, image/jpeg, application/pdf, image/jpg"
                    onChange={(e) => uploadImagesToServer(e, "national_id_photo")}
                  />
                  <span className="opacity-50">Upload National ID Photo</span>
                  <UploadIcon />
                </div>
              </div>
              {travelerData.national_id_photo ?
                <div className="preview_img mt-2">
                  <img src={`${fileUrl}/${travelerData.national_id_photo}`} alt={travelerData.first_name} height="150px" />
                  <button className="btn delete_img_btn"
                    onClick={() => setTravelerData({ ...travelerData, national_id_photo: null })}
                  >
                    <CloseIcon />
                  </button>
                </div>
                :
                null
              }
            </div>
          </div>

          <div className="col-12 mt-3">
            <button className='btn bg-nxt w-100 py-2' onClick={submit}>
              Edit
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
