import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { ReactComponent as PlusIcon } from "assets/images/webBuilder/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/webBuilder/minus.svg";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import { uuidv4 } from "modules/WebBuilder/shared/DragAndDropContainer";
import _ from "lodash";
import CustomModal from "modules/WebBuilder/shared/CustomModal";
import { EditBox } from 'modules/WebBuilder/shared/EditBox';
import ServicesContent from 'modules/WebBuilder-V2/shared/ServicesContent';
import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import UploadFileComponent from 'modules/WebBuilder/shared/UploadFile';
import { useLocation } from "react-router-dom";
import { arrayToObject } from 'modules/WebBuilder-V2/helpers/formatPageData';
import { ReactComponent as MagicPenIcon } from "assets/images/webBuilder/magicpen.svg";
import { transalteText } from 'services/webBuilder';


function FAQs({ details, onFocus, focusContainer }) {
	const { selectedLanguage, languages } = useWebBuilderState();

	const { id, withImage, body, title, faqs, image } = details;
	const dispatch = useWebBuilderDispatch();
	const location = useLocation();

	const preview = !location.pathname.includes("edit");
	const [collapsedItems, setCollapsedItems] = useState([]);
	const [questions, setQuestions] = useState();
	const [faqModal, setFaqModal] = useState(false);

	const handleFaqModal = () => {
		setFaqModal((prev) => !prev);
	};

	useEffect(() => {
		if (faqModal && faqs) {
			const clone = _.cloneDeep(faqs);
			setQuestions(clone);
		}
		return () => { };
	}, [faqModal]);


	const updateFAQContainer = ({ name, value, itemId, questionIndex, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				questionIndex,
				language
			},
		});
	};

	function onChange({ value, itemId, name, questionIndex, language }) {
		updateFAQContainer({ name, value, itemId, questionIndex, language })
	}

	const handleFaqs = ({ value, name, questionIndex, language }) => {
		onChange({ value, itemId: id, name, questionIndex, language });
	};

	const insertQuestion = () => {
		onChange({ value: questions, itemId: id, name: "faqs" });
		handleFaqModal();
	};

	const handleQuestion = (value, index, language) => {
		const questionsClone = [...questions];
		questionsClone[index].question[language] = value;
		setQuestions(questionsClone);
	};

	const removeQuestion = (index) => {
		const questionsClone = [...questions];
		questionsClone.splice(index, 1);
		setQuestions(questionsClone);
	};

	const addQuestion = () => {
		const languagesCodes = languages.map(l => l?.code);
		const questionsClone = [
			...questions,
			{
				id: uuidv4(),
				question: arrayToObject(languagesCodes, {}, ""),
				body: arrayToObject(languagesCodes, {}, ""),
			},
		];

		setQuestions(questionsClone);
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "faq_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};

	const faqActions = (itemId) => {
		return [
			<span
				key={`${itemId}.setting`}
				onClick={handleFaqModal}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const Image = image ? <img src={image} className="faq-image" alt="Faq-section" /> : null;

	const faqImage = preview ? Image
		:
		<UploadFileComponent
			icon={AddIcon}
			id={`faq-section`}
			label=""
			onUpload={(value) => {
				updateFAQContainer({ name: "image", value });
			}}
			image={Image}
		/>
		;


	async function handleAutoTranslate(text, questionIndex) {
		const data = {
			to: selectedLanguage?.toUpperCase(),
			text: text
		}
		const res = await transalteText(data);
		if (res?.status === 200) {
			// setShowInput(false);
			// onInput(res?.data?.result);
			handleQuestion(res?.data?.result, questionIndex, selectedLanguage)
		}
	}

	return (
		<EditBox editMode={focusContainer === id} actions={[...faqActions("faq-section")]}>
			<div className="web-builder-faqs-container container mt-3"
				onClick={() => onFocus(id)}
				id={id}
			>
				<div className={` ${withImage ? "faq-with-image" : "faq-column-view"}`}>
					<div className="custom-container-top-section">
						<ServicesContent
							header={title}
							body={withImage ? "  " : body} // body in first hidden
							changeHeader={(value) =>
								updateFAQContainer({ value, name: "title", language: selectedLanguage })
							}
							changebody={(value) =>
								updateFAQContainer({ value, name: "body", language: selectedLanguage })
							}
						/>
					</div>
					<div className={`faq-detials`}>
						<div className="faq-questions-container">
							{faqs ? faqs.map((que, index) => {
								const isOpen = collapsedItems.includes(index);
								const openCollapse = () => {
									const updated = [...collapsedItems, index];
									setCollapsedItems(updated);
								};
								const closeCollapse = () => setCollapsedItems(collapsedItems.filter((i) => i !== index));

								return (
									<div className="faqs-question-wrapper" key={que.id}>
										<EditBox editMode={focusContainer === id} actions={[itemsBoxActions(que.id)]} defaultIcon="" className="mb-2" >
											<div className="faqs-container-question">
												<span> {que.question[selectedLanguage]} </span>
												{isOpen ? (
													<button
														className="bg-transparent border-0 faq-btn"
														onClick={closeCollapse}
													>
														<MinusIcon />
													</button>
												) : (
													<button
														className="bg-transparent border-0 faq-btn"
														onClick={openCollapse}
													>
														<PlusIcon />
													</button>
												)}
											</div>
											<Collapse isOpen={isOpen}>
												<EditableParagraph
													className="faqs-container-body"
													contentEditable={true}
													onInput={(value) =>
														handleFaqs({ value, name: "body", questionIndex: index, language: selectedLanguage })
													}
													text={que.body[selectedLanguage]}
												/>
											</Collapse>
										</EditBox>
									</div>
								);
							}) : null}
						</div>

						{withImage ?
							<>
								{faqImage}
							</>
							:
							null
						}
					</div>

					<CustomModal isOpen={faqModal} header={"FAQs"} toggle={handleFaqModal}>
						<div className="faq-modal">
							{questions?.length > 0 ? questions.map((faq, index) => (
								<div key={faq.id} className="faq-input">
									{/* {JSON.stringify(faq)} */}
									<div>
										<label htmlFor={`Question ${index + 1}`}>{`Question ${index + 1}`}</label>
										{questions[index].question[selectedLanguage]?.length > 0 ?
											<button className="magic-translate-btn"
												onClick={() => handleAutoTranslate(questions[index].question[selectedLanguage], index)}
											>
												<MagicPenIcon />
												<span>Magic translate</span>
											</button>
											:
											null
										}
										<input
											onChange={(e) => handleQuestion(e.target.value, index, selectedLanguage)}
											id={`Question ${index + 1}`}
											placeholder="type"
											value={questions[index].question[selectedLanguage]}
										/>
									</div>
									{index !== 0 ? (
										<button
											className="remove-icon"
											onClick={() => removeQuestion(index)}
										>
											<span>
												<MinusIcon />
											</span>
										</button>
									) : null}
								</div>
							)) : null}

							<button className="add-icon" onClick={addQuestion}>
								{" "}
								<PlusIcon />
							</button>
							<button className="insert-button" onClick={insertQuestion}>
								{" "}
								Insert
							</button>
						</div>
					</CustomModal>
				</div>
			</div>


		</EditBox>
	);
}

export default FAQs;
