import React from 'react'
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-gray.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as MoonIcon } from "assets/images/webBuilder/moon.svg";
import { ReactComponent as RouteIcon } from "assets/images/webBuilder/routing-2.svg";
import { ReactComponent as HotelIcon } from "assets/images/webBuilder/hotel.svg";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useParams } from 'react-router-dom';


export default function ResultItem({ item, proudctType }) {
  const params = useParams();
  const trimName = item?.name ? item?.name?.length > 25 ? `${item?.name?.substr(0, 25)}...` : item?.name : "";
  const nightsCount = item?.items?.find((item) => item?.item.id === 6)?.itemable?.night_count;
  const trimHotelName = item?.hotelName ? item?.hotelName?.length > 25 ? `${item?.hotelName?.substr(0, 25)}...` : item?.hotelName : "";
  const link = item?.product_uuid && params.name ? 
  `/web-v2/${params.name}/${proudctType}/${item?.product_uuid}` : 
  `/web-builder-v2/preview/${proudctType}/${item?.product_uuid}`;
  console.log(item);
  return (
    <div className="result-item">
      <img src={item?.image} alt="" />
      <div className="item-details">
        <div className="location">
          <LocationIcon /> <span >{item?.locationName}</span>
        </div>
        <div className="name">
          <h4 title={item?.name}>{trimName}</h4>
          {item?.stars > 0 &&
            <div className="start">
              {[...Array(5)].map((i, index) =>
                index < (item.product_classification?.id || item?.stars) ? <GoldenStarIcon key={`stars-${index}`} /> : <StarIcon key={`stars-${index}`} />
              )}
            </div>
          }
        </div>
        {item?.hotelName ?
          <div className="hotel-name my-2">
            <HotelIcon />
            <h5 title={item?.hotelName}>{trimHotelName}</h5>
          </div>
          :
          null
        }
        <div className="route">
          <div>
            <RouteIcon />
            <p className="mx-2">{item?.product_type?.name || proudctType}</p>
          </div>

          {nightsCount &&
            <div>
              <MoonIcon />
              <p>{item?.items?.find((item) => item?.item.id === 6)?.itemable?.night_count} Night</p>
            </div>
          }
        </div>
      </div>

      <div className="price">
        <div>
          <span> Start From</span>
          <p>
            {item?.price} <span>{item.currency}</span>
          </p>
        </div>
        <button className="btn">
          <Link to={link}>View More</Link>
        </button>
      </div>
    </div>
  )
}
