import React, { useState } from 'react'
import Locale from 'translations';
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import moment from 'moment';
import { CheckMarkIcon, DocumentDownloadIcon } from 'modules/UmrahOperations/shared/Icons';
import { useSBSState } from 'context/global';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import axios from 'axios';
import ShowForPermission from 'helpers/showForPermission';


const filesUrl = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function DelegationManagementTableRow({ movmentItem, toggleOfficerModal, toggleExecuteModal, handleExecuteMovement }) {
  const { operationStatement, movementManagement } = Locale;
  const { companyInfo } = useSBSState();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isServiceCompany = companyInfo?.company_category === "services_company";


  function toggle() {
    setDropdownOpen((prevState) => !prevState);
  }

  const handleDownload = async (fileUUID) => {
    const config = {
      method: "GET",
      responseType: "blob",
    };
    const res = await axios.get(`${process.env.REACT_APP_FILEUPLOAD_URL}/fetch/${fileUUID}`, config);
    const blob = new Blob([res.data], {
      type: res.headers["content-type"],
    });

    const objectUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.target = "_blank";
    anchor.href = objectUrl;
    anchor.setAttribute(
      "download",
      `attach-file-${moment().format("DD-MM-YYYY")}.${res.data.type.split("/")[1]}`
    );
    anchor.click();

    handleResponesNotification({ alertType: "success", message: "Downloaded Successfully", title: "Download" })
  };

  function movementTypesText(type) {
    switch (type) {
      case "arrival":
        return operationStatement.Arrival
      case "departure":
        return operationStatement.Departure
      case "attraction":
        return operationStatement.mazar
      case "internal_movement":
        return operationStatement.transfer
      default:
        break;
    }
  }

  const isNotContainsFiles = !movmentItem?.attach_file && !movmentItem?.entrance_stamp && !movmentItem?.bus_image;

  return (
    <tr>
      {/* type */}
      <td className={movmentItem?.type}>
        <div className='d-flex align-items-center'>
          <span className='indecator'></span>
          <div className='d-flex flex-column mx-2'>
            <span className='text-capitalize'>{movementTypesText(movmentItem?.type)}  </span>
            <span># {movmentItem?.company_statement_reference}</span>
          </div>
        </div>
      </td>

      {/* agent */}
      <td width="115px">
        <p>{movmentItem?.agent?.name}</p>
        <div className="d-flex align-items-center">
          <img
            src={movmentItem?.agent?.country?.flag ? `${filesUrl}/${movmentItem?.agent?.country?.flag}` : "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E"}
            alt={movmentItem?.agent?.country?.name}
            className={`rounded-circle ${!movmentItem?.agent?.country?.flag ? " border" : ""}`}
            width={"16px"}
            height={"16px"}
            style={{ flex: "0 0 16px" }}
          />
          <span className="font-light mx-1">
            {movmentItem?.agent?.country?.name}
          </span>
        </div>
      </td>

      {/* Supervisor */}
      <td>

        <p className='d-flex flex-nowrap'>
          <span>{movmentItem?.supervisor?.name}</span>
        </p>
        <p className='font-light direction-initial'>
          {movmentItem?.supervisor?.phone_code}{movmentItem?.supervisor?.phone}
        </p>

        {movmentItem?.nusuk ?
          <p>
            <span className='font-light' style={{ color: "#FF9F43" }}>{operationStatement.nusuk}</span>
            <span className='font-light'>: {movmentItem?.nusuk}</span>
          </p>
          :
          "-"
        }

      </td>

      {/* pax */}
      <td>{movmentItem?.pax}</td>

      {/* From */}
      <td className='from-color' width="130px">
        {movmentItem?.pickupPoints && movmentItem?.pickupPoints?.length > 0 ?
          movmentItem?.pickupPoints?.map(point => {
            return (
              <div key={`${point?.id}-${point?.item_type}`}>
                <p className='font-light text-capitalize'>{point?.city?.name || point?.port_type}</p>
                <p>{point?.title}</p>
              </div>
            )
          })
          :
          "-"
        }

      </td>
      {/* to */}
      <td className='to-color' width="130px">
        {movmentItem?.destinations && movmentItem?.destinations?.length > 0 ?
          movmentItem?.destinations?.map(point => {
            return (
              <div key={`${point?.id}-${point?.item_type}`}>
                <p className='font-light'>{point?.city?.name || point?.port_type}</p>
                <p>{point?.title}</p>
              </div>
            )
          })
          :
          "-"
        }
      </td>


      {/* Vehicle's drivers */}
      <td>
        <div className='d-flex justify-content-between align-items-center gap-24'>
          {/* drivers list */}
          <div className='d-flex flex-column mx-2'>
            {movmentItem?.drivers?.map((driver, index) => {
              return (
                <div className='d-flex align-items-baseline justify-content-between mb-2' key={`${driver?.id}-${index}`} >
                  <p className='d-flex flex-column mx-1'>
                    <span>{driver?.name}</span>
                    <span className='font-light direction-initial'>{driver?.phone_code}{driver?.phone}</span>
                  </p>
                </div>
              )
            })}
          </div>

        </div>
      </td>

      <td>
        {isServiceCompany ?
          (movmentItem?.original_company_name || "-")
          :
          <div className="d-flex align-items-baseline mb-2">
            {(movmentItem?.delegation_company_name || "-")}

            {movmentItem?.delegation_company_name &&
              <ShowForPermission permission="Manage-Delegation-Management">
                <button className="btn add-official-btn"
                  onClick={() => toggleOfficerModal("company", movmentItem, movmentItem?.id, movmentItem?.type)}
                >
                  <EditIcon width="16px" height="16px" />
                </button>
              </ShowForPermission>
            }
          </div>
        }
      </td>

      {/* Movement official */}
      <td>
        <div className='d-flex align-items-baseline mb-2'>

          {/* if request assigend officer */}
          {movmentItem?.officer ?
            <>
              <p className='d-flex flex-column mx-1'>
                <span>{movmentItem?.officer?.name}</span>
                <span className='font-light  direction-initial'>
                  {movmentItem?.officer?.phone_code}{movmentItem?.officer?.phone}
                </span>
              </p>

              {(!movmentItem?.delegation_company_name || isServiceCompany) &&
                <ShowForPermission permission="Manage-Delegation-Management">
                  <button className='btn p-0'
                    onClick={() => toggleOfficerModal("edit", movmentItem, movmentItem?.id, movmentItem?.type)}
                  >
                    <EditIcon width="16px" height="16px" />
                  </button>
                </ShowForPermission>
              }

            </>
            : null
          }

          {/* if request assigend to company */}
          {movmentItem?.delegation_company_name && !movmentItem?.officer ?
            <div className="d-flex align-items-center">
              <span>{Locale.guideRequests.waitingToAssignOfficer}</span>
              {isServiceCompany &&
                <ShowForPermission permission="Manage-Delegation-Management">
                  <button className="btn add-official-btn"
                    onClick={() => toggleOfficerModal("edit", movmentItem, movmentItem?.id, movmentItem?.type)}
                  >
                    <EditIcon width="16px" height="16px" />
                  </button>
                </ShowForPermission>
              }
            </div>
            :
            null
          }
          {/* if there is no officer and company assigend to request */}
          {!movmentItem?.officer && !movmentItem?.delegation_company_name ?
            <ShowForPermission permission="Manage-Delegation-Management">
              <button className="btn px-0 add-official-btn"
                onClick={() => toggleOfficerModal("add", movmentItem?.officer, movmentItem?.id, movmentItem?.type)}
              >
                {Locale.guideRequests.addOfficial}
                <EditIcon width="16px" height="16px" className="mx-2" />
              </button>
            </ShowForPermission>
            :
            null
          }


        </div>
      </td>

      <td>
        <span className="text-grey-500" style={{ fontSize: "14px" }}>
          {movmentItem?.start_date ? moment(movmentItem?.start_date).format("HH:mm") : "-"}
        </span>
        <br />
        <span className="text-black-900">
          {movmentItem?.start_date ? moment(movmentItem?.start_date).format("DD-MM-YYYY") : "-"}
        </span>

      </td>
      {/* Action */}
      <td>
        <div className="table-actions justify-content-end">
          {(movmentItem?.type === "arrival" || movmentItem?.type === "departure") && !isNotContainsFiles ?
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle style={{ all: "unset" }}>
                <DocumentDownloadIcon color="#707170" />
              </DropdownToggle>
              <DropdownMenu positionFixed>
                <DropdownItem onClick={() => handleDownload(movmentItem?.attach_file)}>
                  {movementManagement.TAFWEEJ}
                </DropdownItem>
                {movmentItem?.entrance_stamp &&
                  <DropdownItem onClick={() => handleDownload(movmentItem?.entrance_stamp)}>
                    {movementManagement.entranceStamp}
                  </DropdownItem>
                }
                {movmentItem?.bus_image &&
                  <DropdownItem onClick={() => handleDownload(movmentItem?.bus_image)}>
                    {movementManagement.busImage}
                  </DropdownItem>
                }
              </DropdownMenu>
            </Dropdown>
            : null
          }


          {!movmentItem?.is_executed ?
            <ShowForPermission permission="Manage-Delegation-Management">
              <button className="btn unexcuted-btn"
                onClick={() => {
                  if (movmentItem?.type === "arrival" || movmentItem?.type === "departure") {
                    toggleExecuteModal({ movementId: movmentItem?.id })
                  } else {
                    handleExecuteMovement(null, movmentItem?.id);
                  }
                }}
              >
                <span>{movementManagement.unexecuted}</span>
              </button>
            </ShowForPermission>
            :
            <p className="excuted-btn py-2 px-3">
              <CheckMarkIcon color="#fff" />
              <span>{movementManagement.executed}</span>
            </p>
          }

        </div>
      </td>
    </tr>
  )
}
