import moment from 'moment'

export async function formatTimingSetting(data) {
  //moment(`${today} ${"00:00"}`).toDate();
  const cityName = (cityId) => {
    switch (cityId) {
      case 3:
        return "Makkah";
      case 4:
        return "Madinah"
      case 134:
        return "Jeddah"
      default:
        return cityId
    }
  }
  const today = new Date().toLocaleDateString()
  return {
    arrival_departure: data.arrival_departure.map((item) => {
      return {
        ...item,
        arrival: item.arrival ? moment(`${today} ${item.arrival}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
        departure: item.departure ? moment(`${today} ${item.departure}`).toDate() :
          moment(`${today} ${"00:00"}`).toDate(),

      }
    }),
    bus_wait_time: data.bus_wait_time.map((item) => {
      return {
        ...item,
        city_name: cityName(item?.city_id),
        time: item.time ? moment(`${today} ${item.time}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
      }
    }),
    hotel_checkout: data.hotel_checkout.map((item) => {
      return {
        ...item,
        city_name: cityName(item?.city_id),
        time: item.time ? moment(`${today} ${item.time}`).subtract(3, "hours").toDate() : moment(`${today} ${"00:00"}`).toDate(),
      }
    }),
    attractions: data.attractions.map((item) => {
      return {
        ...item,
        from_makkah: item.from_makkah ? moment(`${today} ${item.from_makkah}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
        from_jeddah: item.from_jeddah ? moment(`${today} ${item.from_jeddah}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
        from_madinah: item.from_madinah ? moment(`${today} ${item.from_madinah}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
      }
    }),
    port_durations: data.port_durations.map((item) => {
      return {
        ...item,
        makkah_duration: item.makkah_duration ? moment(`${today} ${item.makkah_duration}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
        madinah_duration: item.madinah_duration ? moment(`${today} ${item.madinah_duration}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
        jeddah_duration: item.jeddah_duration ? moment(`${today} ${item.jeddah_duration}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
        yanbu_duration: item.yanbu_duration ? moment(`${today} ${item.yanbu_duration}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
      }
    }),
    city_durations: data.city_durations.map((item) => {
      return {
        ...item,
        duration: item.duration ? moment(`${today} ${item.duration}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
      }
    }),
    attraction_durations: data.attraction_durations.map((item) => {
      return {
        ...item,
        duration: item.duration ? moment(`${today} ${item.duration}`).toDate() : moment(`${today} ${"00:00"}`).toDate(),
      }
    }),
    test_data: data.arrival_departure.map((item) => {
      return {
        ...item,
        arrival: item.arrival ?? "00:00",
        departure: item.departure ?? "00:00"

      }
    }),
  }
}