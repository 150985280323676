export function formatPrice(price, options = {}) {
  if (!price) return "0,00";

  const defaultOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatter = new Intl.NumberFormat("en-US", { ...defaultOptions, ...options });
  return formatter.format(price || 0);


}